define('m03/locales_wuerth/se/translations', ['exports'], function (exports) {

  'use strict';

  exports['default'] = {

    berechnung_pdf: 'Beräkningen som PDF',
    setztiefe: 'Sättdjup',
    vorgebohrt: 'Förborrade hål',
    bauteile: 'Komponenter',
    hausnummer: 'Husnummer',
    gew_schraube: 'Vald skruv:',
    SH: 'Stål-Trä',
    email: 'e-post',
    scherkraft: 'Skjuvkraft',
    nicht_vorgebohrt: 'Icke förborrade hål',
    scherfl_vbm: 'Skjuvningsplan per fästdon',
    beschreibung: 'Beskrivning',
    festigkeit: 'Hållfasthetsklass',
    scherfl2: 'Tvåskärigt förband',
    scherfl1: 'Enskärigt förband',
    modul_alt: 'M03 - Skruvdimensionering',
    mat: 'Material 1',
    staendig: 'Permanent (P)',
    lasteinwirkung2: 'Dimensionerande lastvärden',
    tt_text: 'Statiska verifieringar i PDF-format',
    ergebnis: 'Resultat',
    eingabeerforderlich: 'Inmatning krävs!',
    plz: 'Postnummer',
    kompakterg: 'Kompakt resultat',
    eingabe_ok: 'Korrekt inmatning',
    winkel: 'Vinkel mellan komponenter',
    sehr_kurz: 'Momentan (I)',
    msg: 'Visa inte godkända skruvar',
    bezeichnung: 'Beteckning',
    lang: 'Långtid (L)',
    tel: 'Telefon',
    schnittig2: 'Två sektioner',
    ort: 'Ort',
    schnittig1: 'En sektion',
    land: 'Land',
    projektdaten: 'Projektdata',
    fax: 'Fax',
    kdnum: 'Kundnummer:',
    verbindungsmittel: 'Fästdon',
    geometrie: 'Geometri',
    def_bereich: 'Inmatningsvärden',
    schichtfolge: 'Efterföljande skikt',
    wklscherkraft: 'Vinkel på skjuvkraft',
    artnr: 'Artikelnummer',
    name: 'Namn',
    nkl: 'Säkerhetsklass',
    adresse: 'Gata, husnummer',
    mittel: 'Medellång (M)',
    lasteinwirkung: 'Dimensionerande lastvärde',
    modul: 'Skruvdimensionering - M03',
    led: 'Lastvaraktighet',
    scherfl_anschl: 'Skjuvplan per förband',
    verarbeiter: 'Användare / kund',
    vbms: 'Fästdon',
    nkl3: 'Säkerhetsklass 3',
    hoehe: 'Höjd',
    mat1: 'Material 2',
    nkl2: 'Säkerhetsklass 2',
    nkl1: 'Säkerhetsklass 1',
    position: 'Position',
    axialkraft: 'Axiell kraft',
    strasse: 'Gata',
    antrieb: 'Bits',
    bauvorhaben: 'Byggprojekt',
    vbm: 'Fästdon',
    hinweisLaubholz: 'Skruvarna måste förborras när de används i hårt trä!',
    auftraggeber: 'Kund',
    dicke: 'Tjocklek',
    raxtd: 'Dimensionerande draghållfasthet',
    HH: 'Trä / Plywood',
    rlatd: 'Dimensionerande skjuvbärförmåga',
    kurz: 'Korttid (S)',

    nachBlass: 'Mellanskiktet enligt Blass',
    nachWerner: 'Förstärkning enligt Werner',
    winkelKlammerruecken: 'Vinkelbeslag baksidan - fiberriktning',
    zwischenschichtVerstaerkung: 'Mellanskikt/Förstärkning',
    zwischenschicht: 'Mellanskikt',
    verstaerkung: 'Förstärkning',
    umwelteinfluesse: 'Påverkan från omgivningen, impregnering',
    erhoehtesKorrosionsrisiko: 'ökad risk för korrosion',

    bemessungssoftware: 'Würth dimensioneringsprogram',
    wertebereich: "Tillåten värdesintervall:",
    ergebnisanzeigen: "Visa resultat:",
    einstellung: "Inställningar",
    spracheinstellungen: "Språkinställningar",
    eingabe: "Inmatning",
    ausgabe: "Språkinställningar för utdata",
    landeseinstellungen: "Landsinställningar",
    bemessungsvorschrift: "Beräkningsregler",
    liefersortiment: "Leveranssortiment",
    produktkatalog: "Produktkatalog",
    produktinfo: "Produktinformation",
    eta: "ETA",
    cad: "CAD",
    impressum: "Imprint",
    extras: "Verktyg",
    bemerkungen: '› Kommentarer',
    bemerkungstext: "Kommentar text",
    eingabedaten: "Indata",
    berechnungalspdf: "Beräkningen som PDF",
    berechnungspeichern: "Spara beräkningen",
    berechnungoeffnen: "Öppna beräkningen",
    downloadinstructions1: "Öppna filen i ett nytt fönster: vänsterklicka på ikonerna.",
    downloadinstructions2: "Högerklicka på ikonen -> « Spara som » / « Spara länk som » för att spara filen på hårddisken lokalt.",
    downloadinstructions3: "Ange ett filnamn under vilket du vill hämta de valda filerna som ett nedladdningsbart paket.",
    eingabedatennichtgespeichert: "Indata har inte sparats.",
    pdfnochnichterstellt: "Ingen PDF har genererats ännu.",
    dlfilename: "Filnamn",
    dlpaketErstellen: "Skapa nedladdningsbart paket",
    nochkeineschraubegewaehlt: "Ingen skruv har valts ännu.",
    nochkeindlerzeugt: "Inget nedladdningsbart paket har skapats.",
    neueberechnung: "Ny beräkning",
    kontakt: "Kontaktperson",
    herausgeber: "Utgivare",
    registergericht: "Register domstol",
    geschaeftsfuehrer: "VD",
    entwickeltvon: "Utvecklats av",

    dansk: "Danska",
    deutsch: "Tyska",
    englisch: "Engelska",
    franzoesisch: "Franska",
    italienisch: "Italiensk",
    nederlands: "Dutch",
    polnisch: "Polska",
    espanol: "Spanska",
    suomi: "Finska",
    schwedisch: "Svenska",

    schweiz: "Schweiz",
    daenemark: "Danmark",
    deutschland: "Tyskland",
    oesterreich: "Österrike",
    england: "England",
    frankreich: "Frankrike",
    italien: "Italien",
    niederlande: "Nederländerna",
    polen: "Polen",
    spanien: "Spanien",
    chile: "Chile",
    finnland: "Finland",
    schweden: "Sverige",

    MatGrp0000: "Massivt barrträ",
    MatGrp0001: "Limträ, homogent",
    MatGrp0002: "Fanerlaminatträ (LVL)",
    MatGrp0003: "Limträbalk",
    MatGrp0004: "Flerskikts massivträskiva ",
    MatGrp0005: "xPlywood",
    MatGrp0006: "Fanerlaminatträ  (LVL), fibrerna i samma riktning",
    MatGrp0007: "Spånskivor (EN 13986)",
    MatGrp0008: "Cementbundna spånskivor",
    MatGrp0009: "Hård träfiberskivor HB.HLA2",
    MatGrp0010: "Medelhård träfiberskivor MBH.LA2",
    MatGrp0012: "Massivt lövträ",
    MatGrp0013: "Limträ,  kombinerat",
    MatGrp0015: "Korslimmad massivträpanel (KLH)",
    MatGrp0020: "OSB-skiva (Oriented Stranded Board)",
    MatGrp0021: "Plywood",
    MatGrp0022: "Gipskivor DIN 18180",
    MatGrp0030: "Fanerlaminatträ (LVL), Pollmeier Bau-Buche",
    MatGrp1000: "Stål",
    MatGrp9100: "Betong",
    MatGrp9999: "Luft",

    Mat1000: "Stål S235JR",
    Mat1001: "Stål S355JR",
    Mat9999: "----",
    Mat000: "C14",
    Mat001: "C16",
    Mat002: "C18",
    Mat003: "C20",
    Mat004: "C22",
    Mat005: "C24",
    Mat006: "C27",
    Mat007: "C30",
    Mat008: "C35",
    Mat009: "C40",
    Mat010: "C45",
    Mat011: "C50",
    Mat050: "D30",
    Mat051: "D35",
    Mat052: "D40",
    Mat053: "D50",
    Mat054: "D60",
    Mat055: "D70",
    Mat020: "GL24h",
    Mat030: "GL24c",
    Mat021: "GL28h",
    Mat031: "GL28c",
    Mat022: "GL32h",
    Mat032: "GL32c",
    Mat023: "GL36h",
    Mat033: "GL36c",
    Mat160: "Konsthartsomr. Spånpl kl. P4 (EN 312)",
    Mat170: "Konsthartsomr. Spånpl kl. P5 (EN 312)",
    Mat180: "Konsthartsomr. Spånpl kl. P6 (EN 312)",
    Mat190: "Konsthartsomr. Spånpl kl. P7 (EN 312)",
    Mat200: "Cementbund. Träspånpl. Kl. 1+2 (EN 634)",
    Mat220: "Träfiberplattor HB.HLA2 (EN 622-2)",
    Mat230: "Träfiberplattor MBH.LA2 (EN 622-3)",
    Mat125: "OSB/2 (EN 300)",
    Mat126: "OSB/3 (EN 300)",
    Mat127: "OSB/4 (EN 300)",
    Mat110: "Plywood F25/10",
    Mat111: "Plywood F40/40 E60/40",
    Mat112: "Plywood F50/25 E70/25",
    Mat113: "Plywood F60/10 E90/10",
    Mat520: "Gipskivor DIN 18180",
    Mat9120: "C20/25",
    Mat9125: "C25/30",
    Mat9130: "C30/37",
    Mat9135: "C35/45",
    Mat9140: "C40/50",
    Mat9145: "C45/55",
    Mat024: "GL30h",
    Mat034: "GL30c",
    Mat301: "GL70",

    errors: {
      inclusion: "is not included in the list",
      exclusion: "is reserved",
      invalid: "Felaktig indata.",
      confirmation: "matchar inte {{attribut}}",
      accepted: "måste accepteras",
      empty: "kan inte vara tomt",
      blank: "måste fyllas i",
      present: "måste vara tomt",
      tooLong: "Inmatning för lång. Maximalt tillåten längd {{count}} tecken.",
      tooShort: "!2",
      wrongLength: "har fel längd (ska vara {{count}} tecken)",
      notANumber: "är inte en siffra",
      notAnInteger: "måste vara ett heltal",
      greaterThan: "måste vara större än {{count}}",
      greaterThanOrEqualTo: "måste vara större än eller lika med {{count}}",
      equalTo: "måste vara lika med {{count}}",
      lessThan: "måste vara mindre än {{count}}",
      lessThanOrEqualTo: "måste vara mindre än eller lika med {{count}}",
      otherThan: "måste vara något annat än {{count}}",
      odd: "måste vara udda",
      even: "must be even"
    },
    'language-select.language.de': "Tyska",
    'language-select.language.en': "English"

  };

});