define('m03/controllers/anschlussgeometrie', ['exports', 'ember', 'ember-validations', 'ember-i18n'], function (exports, Ember, EmberValidations, ember_i18n) {

  'use strict';

  exports['default'] = Ember['default'].Controller.extend(EmberValidations['default'], {

    initTrigger: false,

    debug: false,
    openFromFile: false,
    displayErrors: true,
    schraubendisabled: true,
    schraubenanordnung: true,
    ausmittigDisabled: true,

    exzentritaetDiagonaleDisabled: true,
    winkelEndAbschnittDiagonaleGRADDisabled: true,
    ausmittigerstabanschluss: false,

    exzentrizitaetDiagonale: "",
    winkel: "",
    ueberstandDiagonaleVertikal: "",
    winkelEndAbschnittGRAD: 1,
    winkelAbschnittDiagonaleGRAD: "",
    winkelEndAbschnittDiagonaleGRAD: "",

    //-- folgende werte nicht anpassen --
    laengenFaktor: 1, // 1 = default (cm). dieser wert ändert sich dynamisch und sollte nicht händisch geändert werden
    laengenLabel: "cm", // cm = default. dieser wert ändert sich dynamisch und sollte nicht händisch geändert werden
    laengenNachkomma: 1, // 1 = default (cm). dieser wert ändert sich dynamisch und sollte nicht händisch geändert werden
    syslaengenFaktor: 100, // 100 = default (m). dieser wert ändert sich dynamisch und sollte nicht händisch geändert werden
    syslaengenLabel: "m", // m = default. dieser wert ändert sich dynamisch und sollte nicht händisch geändert werden
    syslaengenNachkomma: 3, // 3 = default (m). dieser wert ändert sich dynamisch und sollte nicht händisch geändert werden
    kraefteFaktor: 1, // 1 = default (kN). dieser wert ändert sich dynamisch und sollte nicht händisch geändert werden
    kraefteLabel: "kN", // kN = default. dieser wert ändert sich dynamisch und sollte nicht händisch geändert werden
    kraefteNachkomma: 2, // 2 = default (kN). dieser wert ändert sich dynamisch und sollte nicht händisch geändert werden
    //--

    ttExDiagonale: Ember['default'].computed('i18n.locale', {

      get: function get(key) {
        return this.get('i18n').t('wertebereich') + " 0 - 999 [cm]";
      },
      set: function set(key, value) {
        return value;
      }

    }),
    ttUeberstandDiagonaleVertikal: Ember['default'].computed('i18n.locale', {

      get: function get(key) {
        return this.get('i18n').t('wertebereich') + " 0 - 999 [cm]";
      },
      set: function set(key, value) {
        return value;
      }
    }),
    ttWinkel: Ember['default'].computed('i18n.locale', {

      get: function get(key) {
        return this.get('i18n').t('wertebereich') + " 30 - 90 [°]";
      },
      set: function set(key, value) {
        return value;
      }

    }),

    i18n: Ember['default'].inject.service(),

    validations: {
      ueberstandDiagonaleVertikal: {
        numericality: {
          greaterThanOrEqualTo: 0,
          lessThanOrEqualTo: 999
        }
      },
      exzentrizitaetDiagonale: {
        numericality: {
          greaterThanOrEqualTo: 0,
          lessThanOrEqualTo: 999
        }
      },
      winkelAbschnittDiagonaleGRAD: {
        numericality: {
          greaterThanOrEqualTo: 90,
          lessThanOrEqualTo: 90
        }
      },
      winkel: {
        numericality: {
          greaterThanOrEqualTo: 0,
          lessThanOrEqualTo: 90
        }
      }
    },

    winkelEndContentArray: [{
      name: "abschnittSenkrechtDiagonalenachse",
      ind: 1,
      disabled: false
    }, {
      name: "abschnittParallelGurtachse",
      ind: 2,
      disabled: false
    }, {
      name: "freieEingabe",
      ind: 3,
      disabled: true
    }],

    winkelEndContent: [],

    winkelHilfetext: "",
    winkelHilfetextFocused: false,
    winkelEndAbschnittGRADHilfetext: "",
    winkelEndAbschnittGRADHilfetextFocused: false,
    winkelEndAbschnittDiagonaleGRADHilfetext: "",
    winkelEndAbschnittDiagonaleGRADHilfetextFocused: false,
    ueberstandDiagonaleVertikalHilfetext: "",
    ueberstandDiagonaleVertikalHilfetextFocused: false,
    ausmittigerstabanschlussHilfetext: "",
    ausmittigerstabanschlussHilfetextFocused: false,
    winkelAbschnittDiagonaleGRADHilfetext: "",
    winkelAbschnittDiagonaleGRADHilfetextFocused: false,
    exzentrizitaetDiagonaleHilfetext: "",
    exzentrizitaetDiagonaleHilfetextFocused: false,

    letzteAnschlussVriante: 3,
    letzteAnschnittVriante: 11,

    av1selected: false,
    av2selected: false,
    av3selected: true,
    av4selected: false,
    av5selected: false,
    av6selected: false,
    av7selected: false,
    av8selected: false,
    av9selected: false,

    asv11selected: false,
    asv12selected: false,
    asv13selected: false,
    asv14selected: false,
    asv21selected: false,
    asv22selected: false,
    asv23selected: false,
    asv24selected: false,
    asv31selected: false,
    asv32selected: false,
    asv33selected: false,
    asv34selected: false,
    asv41selected: false,
    asv42selected: false,
    asv43selected: false,
    asv44selected: false,

    init: function init() {

      this._super();

      this.set("winkel", "0.0");
      this.set("ueberstandDiagonaleVertikal", "0.0");
      this.set("exzentrizitaetDiagonale", "0.0");
      this.set("winkelAbschnittDiagonaleGRAD", "90.0");
      this.set("winkelEndAbschnittDiagonaleGRAD", "90.0");

      this.setSelectFieldsContent();
    },

    applyFactor: function applyFactor(m) {
      console.log("applyfactor(): " + this.laengenFaktor + " / " + this.kraefteFaktor);
      var result = "";
      for (var p in this[m]) {
        console.log("for");
        if (this[m].hasOwnProperty(p)) {
          console.log(p);
          if (this[m][p].type == "l") {
            console.log("laenge wird angepasst");
            this[m][p].numericality.lessThanOrEqualTo = parseFloat(this[m][p].numericality.lessThanOrEqualTo * this.laengenFaktor);
            this[m][p].numericality.greaterThanOrEqualTo = parseFloat(this[m][p].numericality.greaterThanOrEqualTo * this.laengenFaktor);
            // this.set(this[m][p].tooltip, this.get('i18n').t('wertebereich') + parseFloat(this[m][p].numericality.greaterThanOrEqualTo).toFixed(this.laengenNachkomma) + " - " +  parseFloat(this[m][p].numericality.lessThanOrEqualTo).toFixed(this.laengenNachkomma) + " [" + this.laengenLabel + "]");
          } else if (this[m][p].type == "sl") {
              console.log("systemlaenge wird angepasst");
              this[m][p].numericality.lessThanOrEqualTo = parseFloat(this[m][p].numericality.lessThanOrEqualTo * this.syslaengenFaktor);
              this[m][p].numericality.greaterThanOrEqualTo = parseFloat(this[m][p].numericality.greaterThanOrEqualTo * this.syslaengenFaktor);
              // this.set(this[m][p].tooltip, this.get('i18n').t('wertebereich') + parseFloat(this[m][p].numericality.greaterThanOrEqualTo).toFixed(this.syslaengenNachkomma) + " - " +  parseFloat(this[m][p].numericality.lessThanOrEqualTo).toFixed(this.syslaengenNachkomma) + " [" + this.syslaengenLabel + "]");
            } else if (this[m][p].type == "k") {
                console.log("kraft wird angepasst");
                this[m][p].numericality.lessThanOrEqualTo = parseFloat(this[m][p].numericality.lessThanOrEqualTo * this.kraefteFaktor);
                this[m][p].numericality.greaterThanOrEqualTo = parseFloat(this[m][p].numericality.greaterThanOrEqualTo * this.kraefteFaktor);
                // this.set(this[m][p].tooltip, this.get('i18n').t('wertebereich') + parseFloat(this[m][p].numericality.greaterThanOrEqualTo).toFixed(this.kraefteNachkomma) + " - " +  parseFloat(this[m][p].numericality.lessThanOrEqualTo).toFixed(this.kraefteNachkomma) + " [" + this.kraefteLabel + "]");
              }
        }
      }
    },

    setValues: function setValues(values) {
      var self = this;

      if (self.debug) {
        console.log("values in anschlussgeometrie: ");
        console.log(values);
      }

      var winkelAbschnittDiagonaleGRADEvent = {
        target: {
          name: "winkelAbschnittDiagonaleGRAD"
        }
      };
      var winkelEvent = {
        target: {
          name: "winkel"
        }
      };
      var exzentrizitaetDiagonaleEvent = {
        target: {
          name: "exzentrizitaetDiagonale"
        }
      };
      var ueberstandDiagonaleVertikalEvent = {
        target: {
          name: "ueberstandDiagonaleVertikal"
        }
      };

      this.set('openFromFile', true);

      this.set('ausmittigerstabanschluss', values.abschnittDiagonaleBerechnen);
      this.set('winkelAbschnittDiagonaleGRAD', parseFloat(values.winkelAbschnittDiagonaleGRAD).toFixed(1));

      this.set('winkel', parseFloat(values.ALFA_Elements_GRAD).toFixed(1));
      this.set('lagenebentraeger', parseInt(values.SrVar));
      this.set('hthoehe', values.SrPaare);

      this.set('winkelAbschnittDiagonaleGRAD', Number(values.winkelAbschnittDiagonaleGRAD));
      this.set('winkelEndAbschnittDiagonaleGRAD', Number(values.winkelEndAbschnittDiagonaleGRAD));
      this.set('winkelEndAbschnittGRAD', parseInt(values.winkelEndAbschnittGRAD));

      this.setX3D(parseFloat(values.winkelAbschnittDiagonaleGRAD), winkelAbschnittDiagonaleGRADEvent);
      this.setX3D(parseFloat(values.ALFA_Elements_GRAD), winkelEvent);

      this.set('ueberstandDiagonaleVertikal', (Number(values.ueberstandDiagonaleVertikal) * self.laengenFaktor).toFixed(self.laengenNachkomma));
      this.setX3D((values.ueberstandDiagonaleVertikal * self.laengenFaktor).toFixed(self.laengenNachkomma), ueberstandDiagonaleVertikalEvent);

      this.set('exzentrizitaetDiagonale', (Number(values.exzentrizitaetDiagonale) * self.laengenFaktor).toFixed(self.laengenNachkomma));
      this.setX3D((values.exzentrizitaetDiagonale * self.laengenFaktor).toFixed(self.laengenNachkomma), exzentrizitaetDiagonaleEvent);

      this.send('validation');

      this.set('openFromFile', false);
    },

    setSelectFieldsContent: function setSelectFieldsContent() {
      var self = this;
      self.setWinkelEndAbschnittGRAD();
    },

    setWinkelEndAbschnittGRAD: function setWinkelEndAbschnittGRAD() {
      var self = this;
      var indices = [1, 2, 3];
      self.set('winkelEndContent', self.getSelectFieldContent('winkelEndContent', indices));
    },

    getSelectFieldContent: function getSelectFieldContent(db, indexes) {
      var self = this;
      var tmp = [];
      var dbContent = self.getDBContent(db);

      for (var i = 0; i < indexes.length; i++) {
        for (var k = 0; k < dbContent.length; k++) {
          if (dbContent[k].ind === indexes[i]) {
            tmp.push({ ind: dbContent[k].ind, name: this.get('i18n').t(dbContent[k].name), disabled: dbContent[k].disabled });
          }
        }
      }
      return tmp;
    },

    getDBContent: function getDBContent(db) {
      var self = this;
      var dbContent = [];
      switch (db) {
        case 'winkelEndContent':
          dbContent = self.winkelEndContentArray;
          break;
      }
      return dbContent;
    },

    watchAusmittigerStabanschluss: (function () {
      var self = this;
      var application = self.controllerFor('application');
      var x3d = application.get('model').x3ddefault.objectAt(0);
      var anschlussgeometrie = this.controllerFor('application').get('model').anschlussgeometrie.objectAt(0);

      if (x3d.get('ergebnisGeladen') === true) {
        x3d.set('ergebnisGeladen', false);
        x3d.set('aktuellerSchraubenTyp', 'standardSchraube');
        x3d.set('schraubenlisteAktualisiert', !x3d.get('schraubenlisteAktualisiert'));
      }

      if (self.get('ausmittigerstabanschluss')) {
        anschlussgeometrie.set('abschnittDiagonaleBerechnen', true);
      } else {
        anschlussgeometrie.set('abschnittDiagonaleBerechnen', false);
        self.set('exzentrizitaetDiagonale', '0.0');
        x3d.set('exzentrizitaetDiagonale', 0);
        anschlussgeometrie.set('exzentrizitaetDiagonale', '0.0');
      }

      var applicationdata = self.controllerFor('application').get('model').application.objectAt(0);
      applicationdata.set('treeLoaded', false);
      applicationdata.set('initialized', false);
      applicationdata.set('pdfErstellt', false);
      applicationdata.set('dxfErstellt', false);

      self.set('exzentritaetDiagonaleDisabled', !self.get('exzentritaetDiagonaleDisabled'));
      x3d.set('ausmittigerstabanschluss', self.get('ausmittigerstabanschluss'));

      x3d.set('transformHelper', !x3d.get('transformHelper'));
      x3d.set('arc2dPositionTriggern', !x3d.get('arc2dPositionTriggern'));
      application.zentriertObjekt();
    }).observes('ausmittigerstabanschluss'),

    watchUeberstandDiagonale: (function () {

      var self = this;
      var anschlussgeometrie = this.controllerFor('application').get('model').anschlussgeometrie.objectAt(0);

      if (parseFloat(self.get('ueberstandDiagonaleVertikal')) > 0) {
        anschlussgeometrie.set('ueberstandDiagonaleBerechnen', true);
      } else {
        anschlussgeometrie.set('ueberstandDiagonaleBerechnen', false);
      }
    }).observes('ueberstandDiagonaleVertikal'),

    watchNumericalInputs: (function () {

      if (!this.openFromFile) {

        if (this.get('winkel').indexOf(",") !== -1) {
          this.set('winkel', this.get('winkel').replace(",", "."));
        }

        if (this.get('ueberstandDiagonaleVertikal').indexOf(",") !== -1) {
          this.set('ueberstandDiagonaleVertikal', this.get('ueberstandDiagonaleVertikal').replace(",", "."));
        }

        if (this.get('exzentrizitaetDiagonale').indexOf(",") !== -1) {
          this.set('exzentrizitaetDiagonale', this.get('exzentrizitaetDiagonale').replace(",", "."));
        }
      }
    }).observes('winkel', 'ueberstandDiagonaleVertikal', 'exzentrizitaetDiagonale'),

    watchWinkel: (function () {
      if (parseFloat(this.get('winkel')) === 90 || parseFloat(this.get('winkel')) === 0) {
        this.set('exzentritaetDiagonaleDisabled', true);
        this.set('ausmittigerstabanschluss', false);
        this.set('ausmittigDisabled', true);
      } else {
        //this.set('exzentritaetDiagonaleDisabled', false);
        this.set('ausmittigDisabled', false);
      }

      this.maxExzDiagBerechnen();
    }).observes('winkel'),

    watchUeDiagVertikal: (function () {
      this.maxExzDiagBerechnen();
    }).observes('ueberstandDiagonaleVertikal'),

    watchComboboxes: (function () {

      var self = this;

      var applicationdata = self.controllerFor('application').get('model').application.objectAt(0);
      applicationdata.set('treeLoaded', false);
      applicationdata.set('initialized', false);
      applicationdata.set('pdfErstellt', false);
      applicationdata.set('dxfErstellt', false);

      var application = self.controllerFor('application');
      application.set('verbindungsmittelInvalid', true);

      var downloadcenter = self.controllerFor('downloadcenter');
      downloadcenter.set('pdfErstellt', false);
      downloadcenter.set('dxfErstellt', false);

      var x3d = application.get('model').x3ddefault.objectAt(0);
      x3d.set('winkelEndAbschnittGRAD', self.get('winkelEndAbschnittGRAD'));

      if (x3d.get('ergebnisGeladen') === true) {
        x3d.set('ergebnisGeladen', false);
        x3d.set('aktuellerSchraubenTyp', 'standardSchraube');
        x3d.set('schraubenlisteAktualisiert', !x3d.get('schraubenlisteAktualisiert'));
      }

      var anschlussgeometrie = this.controllerFor('application').get('model').anschlussgeometrie.objectAt(0);

      var tmp = self.get('winkelEndAbschnittGRAD');
      if (tmp === 1) {
        self.set('winkelEndAbschnittDiagonaleGRAD', "90.0");
        x3d.set('winkelEndAbschnittDiagonaleGRAD', "90.0");
      } else if (tmp === 2) {
        self.set('winkelEndAbschnittDiagonaleGRAD', self.get('winkel'));
        x3d.set('winkelEndAbschnittDiagonaleGRAD', self.get('winkel'));
      }

      anschlussgeometrie.set('winkelEndAbschnittGRAD', self.get('winkelEndAbschnittGRAD'));

      self.setMindestueberstand();
      self.send('validation', self.get("ueberstandDiagonaleVertikal"), { target: { name: "ueberstandDiagonaleVertikal" } });

      x3d.set('transformHelper', !x3d.get('transformHelper'));
      x3d.set('arc2dPositionTriggern', !x3d.get('arc2dPositionTriggern'));
      application.zentriertObjekt();
    }).observes('winkelEndAbschnittGRAD'),

    /*screwState: function () {
      this.controllerFor('application').get('model').x3ddefault.objectAt(0).set('schraubenGekreuzt', this.get('schraubenanordnung'));
       var application = this.controllerFor('application');
       if (!this.get('schraubenanordnung')) {
        application.get('model').x3ddefault.objectAt(0).get('bauteile').forEach(function (item) {
          if ((item.id.substring(0, 8) === 'schraube') && (item.get('translations').objectAt(0).get('x') > 0)) {
             var hWinkel = application.get('model').x3ddefault.objectAt(0).get('hWinkel') * Math.PI / 180;
             var itemTranslations = item.get('translations').objectAt(0);
            var itemLaenge = Math.cos(item.get('rotations').objectAt(0).get('z')) * item.get('screwProperties').objectAt(0).get('length');
             var zAlt = itemTranslations.get('z');
            itemTranslations.set('z', zAlt - Math.cos(hWinkel) * itemLaenge);
          }
        });
      } else {
        application.get('model').x3ddefault.objectAt(0).get('bauteile').forEach(function (item) {
          if ((item.id.substring(0, 8) === 'schraube') && (item.get('translations').objectAt(0).get('x') > 0)) {
             var hWinkel = application.get('model').x3ddefault.objectAt(0).get('hWinkel') * Math.PI / 180;
             var itemTranslations = item.get('translations').objectAt(0);
            var itemLaenge = Math.cos(item.get('rotations').objectAt(0).get('z')) * item.get('screwProperties').objectAt(0).get('length');
             var zAlt = itemTranslations.get('z');
            itemTranslations.set('z', zAlt + Math.cos(hWinkel) * itemLaenge);
          }
        });
      }
      application.get('model').x3ddefault.objectAt(0).set('ergebnisGeladen', false);
      application.get('model').x3ddefault.objectAt(0).set('aktuellerSchraubenTyp', 'standardSchraube');
      application.get('model').x3ddefault.objectAt(0).set('schraubenlisteAktualisiert', !application.get('model').x3ddefault.objectAt(0).get('schraubenlisteAktualisiert'));
     }.observes('schraubenanordnung'),*/

    setX3D: function setX3D(value, event) {

      var application = this.controllerFor('application');
      var x3d = application.get('model').x3ddefault.objectAt(0);
      var bauteile = x3d.get('bauteile');

      var self = this;

      x3d.set('ergebnisGeladen', false);
      x3d.set('aktuellerSchraubenTyp', 'standardSchraube');
      x3d.set('schraubenlisteAktualisiert', !x3d.get('schraubenlisteAktualisiert'));

      value = parseFloat(value).toFixed(1);

      switch (event.target.name) {
        case 'winkel':
          if (this.errors.winkel.length === 0) {
            x3d.set('vWinkel', value);
            x3d.set('vWinkelEingetragen', true);
            self.setMindestueberstand();
          } else {
            x3d.set('vWinkelEingetragen', false);
          }
          break;
        case 'winkelEndAbschnittDiagonaleGRAD':
          if (this.errors.winkelEndAbschnittDiagonaleGRAD.length === 0) {
            x3d.set('winkelEndAbschnittDiagonaleGRAD', value);
            x3d.set('winkelEndAbschnittDiagonaleGRADEingetragen', true);
          } else {
            x3d.set('winkelEndAbschnittDiagonaleGRADEingetragen', false);
          }
          break;
        case 'ueberstandDiagonaleVertikal':
          if (this.errors.ueberstandDiagonaleVertikal.length === 0) {
            x3d.set('ueberstandDiagonaleVertikal', value);
            x3d.set('ueberstandDiagonaleVertikalEingetragen', true);
          } else {
            x3d.set('ueberstandDiagonaleVertikalEingetragen', false);
          }
          break;
        case 'winkelAbschnittDiagonaleGRAD':
          if (this.errors.winkelAbschnittDiagonaleGRAD.length === 0) {
            x3d.set('winkelAbschnittDiagonaleGRAD', value);
            x3d.set('winkelAbschnittDiagonaleGRADEingetragen', true);
          } else {
            x3d.set('winkelAbschnittDiagonaleGRADEingetragen', false);
          }
          break;
        case 'exzentrizitaetDiagonale':
          if (this.errors.exzentrizitaetDiagonale.length === 0) {
            x3d.set('exzentrizitaetDiagonale', value);
            x3d.set('exzentrizitaetDiagonaleEingetragen', true);
          } else {
            x3d.set('exzentrizitaetDiagonaleEingetragen', false);
          }
          break;
      }

      x3d.set('transformHelper', !x3d.get('transformHelper'));
      x3d.set('arc2dPositionTriggern', !x3d.get('arc2dPositionTriggern'));
      application.zentriertObjekt();
    },

    maxExzDiagBerechnen: function maxExzDiagBerechnen() {
      var self = this;
      var bauteile = self.controllerFor('bauteile');
      var hDiag = bauteile.get('hthoehe') / Number(bauteile.get('laengenFaktor'));
      if (hDiag === "") {
        hDiag = 0;
      } else {
        hDiag = parseFloat(hDiag);
      }
      var hGurt = bauteile.get('nthoehe') / Number(bauteile.get('laengenFaktor'));
      if (hGurt === "") {
        hGurt = 0;
      } else {
        hGurt = parseFloat(hGurt);
      }
      var alfa = parseFloat(self.get('winkel'));
      var beta = parseFloat(self.get('winkelEndAbschnittDiagonaleGRAD'));
      var ueDiagVert = parseFloat(self.get('ueberstandDiagonaleVertikal'));

      // console.log('hDiag: '+hDiag);
      // console.log('hGurt: '+hGurt);
      // console.log('alfa: '+alfa);
      // console.log('beta: '+beta);
      // console.log('ueDiagVert: '+ueDiagVert);
      //
      var dy = ueDiagVert - hDiag * Math.sin(beta * Math.PI / 180 - alfa * Math.PI / 180);

      // console.log('dy: '+dy);

      var maxExzDiag = 999;

      if (alfa !== 0) {
        maxExzDiag = (dy / Math.tan(alfa * Math.PI / 180) + hDiag / 2 / Math.sin(alfa * Math.PI / 180) + hGurt / 2 / Math.tan(alfa * Math.PI / 180)).toFixed(1);
      }

      // console.log("###: " + maxExzDiag);

      self.set('validations.exzentrizitaetDiagonale.numericality.greaterThanOrEqualTo', 0);

      self.set('validations.exzentrizitaetDiagonale.numericality.lessThanOrEqualTo', Number(maxExzDiag));

      self.set('ttExDiagonale', self.get('i18n').t('wertebereich') + "0 - " + maxExzDiag + " [cm]");
    },

    actions: {
      validation: function validation(value, event) {

        var self = this;

        if (!self.openFromFile) {

          this.setX3D(value, event);
        }

        self.validate().then(function () {
          // all validations pass

        })['catch'](function () {
          // any validations fail

        })['finally'](function () {

          var validation = self.get('isValid');
          var application = self.controllerFor('application');
          if (validation === true) {
            self.set('displayErrors', false);
            application.set('anschlussgeometrieInvalid', false);

            var applicationdata = self.controllerFor('application').get('model').application.objectAt(0);
            applicationdata.set('treeLoaded', false);
            applicationdata.set('initialized', false);
            applicationdata.set('pdfErstellt', false);
            applicationdata.set('dxfErstellt', false);

            var downloadcenter = self.controllerFor('downloadcenter');
            downloadcenter.set('pdfErstellt', false);
            downloadcenter.set('dxfErstellt', false);

            application.set('verbindungsmittelInvalid', true);

            var anschlussgeometrie = self.controllerFor('application').get('model').anschlussgeometrie.objectAt(0);

            anschlussgeometrie.set('ueberstandDiagonaleVertikal', parseFloat(self.get('ueberstandDiagonaleVertikal')).toFixed(1));
            anschlussgeometrie.set('winkelEndAbschnittDiagonaleGRAD', parseFloat(self.get('winkelEndAbschnittDiagonaleGRAD')).toFixed(1));
            anschlussgeometrie.set('winkelAbschnittDiagonaleGRAD', parseFloat(self.get('winkelAbschnittDiagonaleGRAD')).toFixed(1));
            anschlussgeometrie.set('ALFA_Elements_GRAD', parseFloat(self.get('winkel')).toFixed(1));
            anschlussgeometrie.set('exzentrizitaetDiagonale', parseFloat(self.get('exzentrizitaetDiagonale')).toFixed(1));
          } else {
            self.set('displayErrors', true);
            application.set('anschlussgeometrieInvalid', true);
          }
        });
      },

      hWinkelIsSelected: function hWinkelIsSelected() {
        var hwinkelaktiv = this.controllerFor('application').get('model').x3ddefault.objectAt(0).get('hWinkelAktiv');
        this.controllerFor('application').get('model').x3ddefault.objectAt(0).set('hWinkelAktiv', !hwinkelaktiv);

        if (this.get('rwinkelh') !== "") {
          var rwinkelhwork = parseFloat(this.get('rwinkelh').replace(",", "."));
          var rwinkelhrounded = rwinkelhwork.toFixed(1);
          this.set('rwinkelh', rwinkelhrounded);
          this.send('validation');
        }

        if (hwinkelaktiv === false) {
          document.getElementsByName('rwinkelh')[0].setSelectionRange(0, this.get('rwinkelh').length);
        }
      },

      winkelAbschnittDiagonaleGRADIsSelected: function winkelAbschnittDiagonaleGRADIsSelected() {
        var winkelAbschnittDiagonaleGRAD = this.controllerFor('application').get('model').x3ddefault.objectAt(0).get('winkelAbschnittDiagonaleGRADAktiv');
        this.controllerFor('application').get('model').x3ddefault.objectAt(0).set('winkelAbschnittDiagonaleGRADAktiv', !winkelAbschnittDiagonaleGRAD);

        if (this.get('winkelAbschnittDiagonaleGRAD') !== "") {
          var value = parseFloat(this.get('winkelAbschnittDiagonaleGRAD').replace(",", "."));
          var valuerounded = value.toFixed(1);
          this.set('winkelAbschnittDiagonaleGRAD', valuerounded);
          this.send('validation', valuerounded, { target: { name: "winkelAbschnittDiagonaleGRAD" } });
        }

        if (winkelAbschnittDiagonaleGRAD === false) {
          document.getElementsByName('winkelAbschnittDiagonaleGRAD')[0].setSelectionRange(0, this.get('winkelAbschnittDiagonaleGRAD').length);
        }
      },

      ueberstandDiagonaleVertikalIsSelected: function ueberstandDiagonaleVertikalIsSelected() {
        var ueberstandDiagonaleVertikal = this.controllerFor('application').get('model').x3ddefault.objectAt(0).get('ueberstandDiagonaleVertikalAktiv');
        this.controllerFor('application').get('model').x3ddefault.objectAt(0).set('ueberstandDiagonaleVertikalAktiv', !ueberstandDiagonaleVertikal);

        if (this.get('ueberstandDiagonaleVertikal') !== "") {
          var value = parseFloat(this.get('ueberstandDiagonaleVertikal').replace(",", "."));
          var valuerounded = value.toFixed(1);
          this.set('ueberstandDiagonaleVertikal', valuerounded);
          this.send('validation', valuerounded, { target: { name: "ueberstandDiagonaleVertikal" } });
        }

        if (ueberstandDiagonaleVertikal === false) {
          document.getElementsByName('ueberstandDiagonaleVertikal')[0].setSelectionRange(0, this.get('ueberstandDiagonaleVertikal').length);
        }
      },

      exzentrizitaetDiagonaleIsSelected: function exzentrizitaetDiagonaleIsSelected() {
        var exzentrizitaetDiagonale = this.controllerFor('application').get('model').x3ddefault.objectAt(0).get('exzentrizitaetDiagonaleAktiv');
        this.controllerFor('application').get('model').x3ddefault.objectAt(0).set('exzentrizitaetDiagonaleAktiv', !exzentrizitaetDiagonale);

        if (this.get('exzentrizitaetDiagonale') !== "") {
          var value = parseFloat(this.get('exzentrizitaetDiagonale').replace(",", "."));
          var valuerounded = value.toFixed(1);
          this.set('exzentrizitaetDiagonale', valuerounded);
          this.send('validation', valuerounded, { target: { name: "exzentrizitaetDiagonale" } });
        }

        if (exzentrizitaetDiagonale === false) {
          document.getElementsByName('exzentrizitaetDiagonale')[0].setSelectionRange(0, this.get('exzentrizitaetDiagonale').length);
        }
      },

      winkelIsSelected: function winkelIsSelected() {
        var self = this;
        var vWinkelAktiv = this.controllerFor('application').get('model').x3ddefault.objectAt(0).get('vWinkelAktiv');
        this.controllerFor('application').get('model').x3ddefault.objectAt(0).set('vWinkelAktiv', !vWinkelAktiv);

        //self.set('validations.winkelEndAbschnittDiagonaleGRAD.numericality.greaterThanOrEqualTo', parseFloat(self.get('winkel')));
        //self.set('ttBreiteA', self.get('i18n').t('wertebereich') + self.get('winkel') + " - 90 [°]");

        var tmp = self.get('winkelEndAbschnittGRAD');
        if (tmp === 2) {
          self.set('winkelEndAbschnittDiagonaleGRAD', self.get('winkel'));
          this.controllerFor('application').get('model').x3ddefault.objectAt(0).set('winkelEndAbschnittDiagonaleGRAD', Number(self.get('winkel')));
        }

        if (this.get('winkel') !== "") {
          var winkelwork = parseFloat(this.get('winkel').replace(",", "."));
          var winkelrounded = winkelwork.toFixed(1);
          this.set('winkel', winkelrounded);
          this.send('validation', winkelrounded, {
            target: {
              name: "winkel"
            }
          });
        }

        if (vWinkelAktiv === false) {
          document.getElementsByName('winkel')[0].setSelectionRange(0, this.get('winkel').length);
        }
      },

      removeClass: function removeClass(element) {
        var self = this;
        self.set(element, false);
      },

      setAnschlussVarianteSelected: function setAnschlussVarianteSelected(index) {
        var self = this;
        self.set('av' + self.get('letzteAnschlussVriante') + 'selected', false);
        self.set('av' + index + 'selected', true);
        self.set('letzteAnschlussVriante', index);

        self.set('av' + self.get('letzteAnschnittVriante') + 'selected', false);
      },

      setAnschnittVarianteSelected: function setAnschnittVarianteSelected(index) {
        var self = this;
        self.set('av' + self.get('letzteAnschnittVriante') + 'selected', false);
        self.set('av' + index + 'selected', true);
        self.set('letzteAnschnittVriante', index);
      }

    }, // Ende Actions

    setMindestueberstand: function setMindestueberstand() {
      var self = this;

      var application = this.controllerFor('application');
      var x3d = application.get('model').x3ddefault.objectAt(0);

      var bauteile = self.controllerFor('bauteile');
      var hDiag = bauteile.get('hthoehe') / Number(bauteile.get('laengenFaktor'));
      var alfa = parseFloat(self.get('winkel')) * Math.PI / 180;
      var beta = parseFloat(self.get('winkelEndAbschnittDiagonaleGRAD')) * Math.PI / 180;

      var ueberstand = 0.0;

      if (alfa !== 0 && self.get('winkelEndAbschnittGRAD') === 1) {
        ueberstand = (Math.ceil(100 * Math.abs(hDiag * Math.sin(beta - alfa))) / 100).toFixed(1);
      }

      self.set('ueberstandDiagonaleVertikal', Number(ueberstand).toFixed(1).toString());
      x3d.set('ueberstandDiagonaleVertikal', Number(ueberstand));
      if (Number(ueberstand) !== 0) {
        x3d.set('ueberstandDiagonaleVertikalEingetragen', true);
      } else {
        x3d.set('ueberstandDiagonaleVertikalEingetragen', false);
      }

      self.set('validations.ueberstandDiagonaleVertikal.numericality.greaterThanOrEqualTo', Number(ueberstand));
      self.set('validations.ueberstandDiagonaleVertikal.numericality.lessThanOrEqualTo', 999);
      self.set('ttUeberstandDiagonaleVertikal', self.get('i18n').t('wertebereich') + ueberstand + " - 999 [cm]");
    },

    //hilfeTexte observes

    observes_winkelHilfetext: (function () {
      var self = this;
      var hilfetextArray = self.controllerFor('supercontroller').getHelpText("Anschlussgeometrie", "winkel", "0");
      self.controllerFor("supercontroller").setHilfetexte("anschlussgeometrie", hilfetextArray);
    }).observes('winkel', 'initTrigger'),

    observes_winkelEndAbschnittGRADHilfetext: (function () {
      var self = this;
      var hilfetextArray = self.controllerFor('supercontroller').getHelpText("Anschlussgeometrie", "winkelEndAbschnittGRAD", "0");
      self.controllerFor("supercontroller").setHilfetexte("anschlussgeometrie", hilfetextArray);
    }).observes('winkelEndAbschnittGRAD', 'initTrigger'),

    observes_winkelEndAbschnittDiagonaleGRADHilfetext: (function () {
      var self = this;
      var hilfetextArray = self.controllerFor('supercontroller').getHelpText("Anschlussgeometrie", "winkelEndAbschnittDiagonaleGRAD", "0");
      self.controllerFor("supercontroller").setHilfetexte("anschlussgeometrie", hilfetextArray);
    }).observes('winkelEndAbschnittDiagonaleGRAD', 'initTrigger'),

    observes_ueberstandDiagonaleVertikalHilfetext: (function () {
      var self = this;
      var hilfetextArray = self.controllerFor('supercontroller').getHelpText("Anschlussgeometrie", "ueberstandDiagonaleVertikal", "0");
      self.controllerFor("supercontroller").setHilfetexte("anschlussgeometrie", hilfetextArray);
    }).observes('ueberstandDiagonaleVertikal', 'initTrigger'),

    observes_ausmittigerstabanschlussHilfetext: (function () {
      var self = this;
      var hilfetextArray = self.controllerFor('supercontroller').getHelpText("Anschlussgeometrie", "ausmittigerstabanschluss", "0");
      self.controllerFor("supercontroller").setHilfetexte("anschlussgeometrie", hilfetextArray);
    }).observes('ausmittigerstabanschluss', 'initTrigger'),

    observes_winkelAbschnittDiagonaleGRADHilfetext: (function () {
      var self = this;
      var hilfetextArray = self.controllerFor('supercontroller').getHelpText("Anschlussgeometrie", "winkelAbschnittDiagonaleGRAD", "0");
      self.controllerFor("supercontroller").setHilfetexte("anschlussgeometrie", hilfetextArray);
    }).observes('winkelAbschnittDiagonaleGRAD', 'initTrigger'),

    observes_exzentrizitaetDiagonaleHilfetext: (function () {
      var self = this;
      var hilfetextArray = self.controllerFor('supercontroller').getHelpText("Anschlussgeometrie", "exzentrizitaetDiagonale", "0");
      self.controllerFor("supercontroller").setHilfetexte("anschlussgeometrie", hilfetextArray);
    }).observes('exzentrizitaetDiagonale', 'initTrigger'),

    // hilfeTexte display properties

    display_winkelHilfetext: (function () {
      var self = this;
      return self.controllerFor("supercontroller").setHilfetexteDisplayed(self.get('winkelHilfetext'));
    }).property('winkelHilfetext'),

    display_winkelEndAbschnittGRADHilfetext: (function () {
      var self = this;
      return self.controllerFor("supercontroller").setHilfetexteDisplayed(self.get('winkelEndAbschnittGRADHilfetext'));
    }).property('winkelEndAbschnittGRADHilfetext'),

    display_winkelEndAbschnittDiagonaleGRADHilfetext: (function () {
      var self = this;
      return self.controllerFor("supercontroller").setHilfetexteDisplayed(self.get('winkelEndAbschnittDiagonaleGRADHilfetext'));
    }).property('winkelEndAbschnittDiagonaleGRADHilfetext'),

    display_ueberstandDiagonaleVertikalHilfetext: (function () {
      var self = this;
      return self.controllerFor("supercontroller").setHilfetexteDisplayed(self.get('ueberstandDiagonaleVertikalHilfetext'));
    }).property('ueberstandDiagonaleVertikalHilfetext'),

    display_ausmittigerstabanschlussHilfetext: (function () {
      var self = this;
      return self.controllerFor("supercontroller").setHilfetexteDisplayed(self.get('ausmittigerstabanschlussHilfetext'));
    }).property('ausmittigerstabanschlussHilfetext'),

    display_winkelAbschnittDiagonaleGRADHilfetext: (function () {
      var self = this;
      return self.controllerFor("supercontroller").setHilfetexteDisplayed(self.get('winkelAbschnittDiagonaleGRADHilfetext'));
    }).property('winkelAbschnittDiagonaleGRADHilfetext'),

    display_exzentrizitaetDiagonaleHilfetext: (function () {
      var self = this;
      return self.controllerFor("supercontroller").setHilfetexteDisplayed(self.get('exzentrizitaetDiagonaleHilfetext'));
    }).property('exzentrizitaetDiagonaleHilfetext')

  });

});