define('m03/locales_wuerth/fi/translations', ['exports'], function (exports) {

  'use strict';

  exports['default'] = {

    berechnung_pdf: 'Laskenta PDF-muodossa',
    setztiefe: 'Tunkeumasyvyys',
    vorgebohrt: 'Esiporatut reiät',
    bauteile: 'Rakenneosat ',
    hausnummer: 'Talo-nro',
    gew_schraube: 'Valittu',
    SH: 'Teräs-puu',
    email: 'Sähköposti',
    scherkraft: 'Leikkausvoima',
    nicht_vorgebohrt: 'Ei esiporattuja reikiä',
    scherfl_vbm: 'Leikkauspinnat/liitososa',
    beschreibung: 'Kuvaus',
    festigkeit: 'Lujuusluokka',
    scherfl2: '2 leikkauspintaa',
    scherfl1: '1 leikkauspinta',
    modul_alt: 'M03 - ruuvien mitoitus',
    mat: 'Materiaali 1',
    staendig: 'Pysyvä',
    lasteinwirkung2: 'Kuormitus',
    tt_text: 'Rakennesuunnittelusuositus PDF-muodossa',
    ergebnis: 'Tulos',
    eingabeerforderlich: 'Syöte tarpeen!',
    plz: 'Postinumero',
    kompakterg: 'Kokonaistulos',
    eingabe_ok: 'Oikea syöte',
    winkel: 'Rakenneosien välinen kulma',
    sehr_kurz: 'Hetkellinen',
    msg: 'Näytä ei-sallitut ruuvit',
    bezeichnung: 'Nimike',
    lang: 'Pitkä-aikainen',
    tel: 'Puhelin',
    schnittig2: '2-leikkausreuna',
    ort: 'Paikkakunta',
    schnittig1: '1-leikkausreuna',
    land: 'Maa',
    projektdaten: 'Projektitiedot',
    fax: 'Faksi',
    kdnum: 'Asiakasnumero',
    verbindungsmittel: 'Liitososa',
    geometrie: 'Geometria',
    def_bereich: 'Määritysalue',
    schichtfolge: 'Kerrosjärjestys',
    wklscherkraft: 'Leikkausvoiman kulma',
    artnr: 'Tuotekoodi',
    name: 'Nimi ',
    nkl: 'Käyttöluokka',
    adresse: 'Katuosoite',
    mittel: 'Keski-pitkä',
    lasteinwirkung: 'Kuormitus',
    modul: 'Ruuvien mitoitus - M03',
    led: 'Kuormituksen kestoaika',
    scherfl_anschl: 'Leikkauspinta/liitos',
    verarbeiter: 'Laatija / tilaaja / rakennuttaja',
    vbms: 'Liitososa',
    nkl3: 'Käyttöluokka 3',
    hoehe: 'Korkeus',
    mat1: 'Materiaali 2',
    nkl2: 'Käyttöluokka 2',
    nkl1: 'Käyttöluokka 1',
    position: 'Osa',
    axialkraft: 'Aksiaalinen voima',
    strasse: 'Katu ',
    antrieb: 'Käyttö',
    bauvorhaben: 'Rakennushanke',
    vbm: 'Liitososa',
    hinweisLaubholz: 'Ruuville on tehtävä esiporaus käytettäessä lehtipuuhun!',
    auftraggeber: 'Tilaaja/rakennettuja',
    dicke: 'Paksuus',
    raxtd: 'Vetokuormituksen mitoitusarvo',
    HH: 'Puu/vaneri',
    rlatd: 'Leikkauskuormituksen mitoitusarvo',
    kurz: 'Lyhytaikainen',

    nachBlass: 'Välikerros Blass',
    nachWerner: 'Vahvistaminen Werner',
    winkelKlammerruecken: 'Kulma liitoksen liike - syynsuunasta',
    zwischenschichtVerstaerkung: 'Välikerros / Vahvistaminen',
    zwischenschicht: 'Välikerroksen ',
    verstaerkung: 'Vahviste',
    umwelteinfluesse: 'Ympäristön vaikutuksesta, kyllästys',
    erhoehtesKorrosionsrisiko: 'lisääntynyt riski korroosioon',

    bemessungssoftware: 'Würth puurakentamisen ohjelmisto',
    wertebereich: "Sallittu arvoalue ",
    ergebnisanzeigen: "Näytä tulokset:",
    einstellung: "Asetukset",
    spracheinstellungen: "Kieliasetukset",
    eingabe: "Syöte ",
    ausgabe: "Kieliasetus",
    landeseinstellungen: "Maa-asetukset",
    bemessungsvorschrift: "Mitoitusmääräys",
    liefersortiment: "Toimitusvalikoima",
    produktkatalog: "Tuoteluettelo",
    produktinfo: "Tuotetiedot",
    eta: "ETA",
    cad: "CAD",
    impressum: "Julkaisutiedot",
    extras: "Työkalut",
    bemerkungen: '› Huomautuksia',
    bemerkungstext: "Kommenttiteksti",
    eingabedaten: "Lähtötiedot",
    berechnungalspdf: "Laskenta PDF-muodossa",
    berechnungspeichern: "Tallenna laskenta",
    berechnungoeffnen: "Avaa laskenta",
    downloadinstructions1: "Kuvakkeen vasen klikkaus: Avaa uusi tiedosto uudessa ikkunassa.",
    downloadinstructions2: "Kuvakkeen oikea klikkaus -> \"Tallenna\" / \"Tallenna nimellä\" tiedoston paikalliselle kiintolevylle.",
    downloadinstructions3: "Määritä tieodston nimi, jota haluat hakea yhdistääksesi valituun tiedostoon.",
    eingabedatennichtgespeichert: "Lähtötitoja ei ole tallennettu.",
    pdfnochnichterstellt: "PDF ei ole luotu.",
    dlfilename: "Tiedostonimi",
    dlpaketErstellen: "Muodosta latauspaketti",
    nochkeineschraubegewaehlt: "Ruuvia ei ole valittu.",
    nochkeindlerzeugt: "Ladattavaa pakettia ei ole luotu.",
    neueberechnung: "Uusi laskenta",
    kontakt: "Kontakti",
    herausgeber: "Julkaisija",
    registergericht: "Rekisteröintialue",
    geschaeftsfuehrer: "Toiminnanjohtaja",
    entwickeltvon: "Kehittäjä",

    dansk: "Tanskalainen",
    deutsch: "Saksa",
    englisch: "Englanti",
    franzoesisch: "Ranskalainen",
    italienisch: "Italialainen",
    nederlands: "Hollantilainen",
    polnisch: "Puola",
    espanol: "Espanjalainen",
    suomi: "Suomalainen",
    schwedisch: "Ruotsalainen",

    schweiz: "Sveitsi",
    daenemark: "Tanska",
    deutschland: "Saksa",
    oesterreich: "Itävalta",
    england: "Englanti",
    frankreich: "Ranska",
    italien: "Italia",
    niederlande: "Hollanti",
    polen: "Puola",
    spanien: "Espanja",
    chile: "Chile",
    finnland: "Suomi",
    schweden: "Ruotsi",

    MatGrp0000: "Täyspuu, havupuu",
    MatGrp0001: "Liimapuu, homogeeninen",
    MatGrp0002: "Viilupuu",
    MatGrp0003: "Kerrospuu (duo, trio)",
    MatGrp0004: "Monikerroksinen massiivipuulevy",
    MatGrp0005: "Vanerilevyt",
    MatGrp0006: "Viilupuu (samansuuntaiset syyt)",
    MatGrp0007: "Lastulevy, keinohartsiliimattu",
    MatGrp0008: "Lastulevy, sementti",
    MatGrp0009: "Kuitulevyt, kova HB.HLA2",
    MatGrp0010: "Kuitulevyt, kova MHB.LA2",
    MatGrp0012: "Täyspuu, lehtipuu",
    MatGrp0013: "Liimapuu, yhdistetty",
    MatGrp0015: "CLT-rakennuslevyt",
    MatGrp0020: "OSB-levyt",
    MatGrp0021: "Vanerilevyt",
    MatGrp0022: "Kipsilevy (DIN 18180)",
    MatGrp0030: "Pollmeier Bau-Buche",
    MatGrp1000: "Teräs",
    MatGrp9100: "Betoni",
    MatGrp9999: "Luft",

    Mat1000: "Steel S235JR",
    Mat1001: "Steel S355JR",
    Mat9999: "----",
    Mat000: "C14",
    Mat001: "C16",
    Mat002: "C18",
    Mat003: "C20",
    Mat004: "C22",
    Mat005: "C24",
    Mat006: "C27",
    Mat007: "C30",
    Mat008: "C35",
    Mat009: "C40",
    Mat010: "C45",
    Mat011: "C50",
    Mat050: "D30",
    Mat051: "D35",
    Mat052: "D40",
    Mat053: "D50",
    Mat054: "D60",
    Mat055: "D70",
    Mat020: "GL24h",
    Mat030: "GL24c",
    Mat021: "GL28h",
    Mat031: "GL28c",
    Mat022: "GL32h",
    Mat032: "GL32c",
    Mat023: "GL36h",
    Mat033: "GL36c",
    Mat160: "Lastulevy, luokka P4 (EN 312)",
    Mat170: "Lastulevy, luokka P5 (EN 312)",
    Mat180: "Lastulevy, luokka P6 (EN 312)",
    Mat190: "Lastulevy, luokka P7 (EN 312)",
    Mat200: "Sementtisidosteinen lastuvely luokka 1+2 (EN 634)",
    Mat220: "Lastulevy, kova HB.HLA2 (EN 622-2)",
    Mat230: "Lastulevy, kova MBH.LA2 (EN 622-3)",
    Mat125: "OSB/2 (EN 300)",
    Mat126: "OSB/3 (EN 300)",
    Mat127: "OSB/4 (EN 300)",
    Mat110: "Vaneri F25/10",
    Mat111: "Vaneri F40/40 E60/40",
    Mat112: "Vaneri F50/25 E70/25",
    Mat113: "Vaneri F60/10 E90/10",
    Mat520: "Kipsilevy (DIN 18180)",
    Mat9120: "C20/25",
    Mat9125: "C25/30",
    Mat9130: "C30/37",
    Mat9135: "C35/45",
    Mat9140: "C40/50",
    Mat9145: "C45/55",
    Mat024: "GL30h",
    Mat034: "GL30c",
    Mat301: "GL70",

    errors: {
      inclusion: "is not included in the list",
      exclusion: "is reserved",
      invalid: "Virheellinen syöte.",
      confirmation: "doesn\'t match {{attribute}}",
      accepted: "must be accepted",
      empty: "can\'t be empty",
      blank: "can\'t be blank",
      present: "must be blank",
      tooLong: "Eingabe zu lang. Maximal erlaubte Länge sind {{count}} Zeichen.",
      tooShort: "!2",
      wrongLength: "is the wrong length (should be {{count}} characters)",
      notANumber: "is not a number",
      notAnInteger: "must be an integer",
      greaterThan: "must be greater than {{count}}",
      greaterThanOrEqualTo: "must be greater than or equal to {{count}}",
      equalTo: "must be equal to {{count}}",
      lessThan: "must be less than {{count}}",
      lessThanOrEqualTo: "must be less than or equal to {{count}}",
      otherThan: "must be other than {{count}}",
      odd: "must be odd",
      even: "must be even"
    },
    'language-select.language.de': "Saksa",
    'language-select.language.en': "English"

  };

});