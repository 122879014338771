define('m03/controllers/supercontroller', ['exports', 'ember', 'ember-validations', 'ember-i18n'], function (exports, Ember, EmberValidations, ember_i18n) {

  'use strict';

  exports['default'] = Ember['default'].Controller.extend(EmberValidations['default'], {

    i18n: Ember['default'].inject.service(),
    debug: true,

    testAusgabe: function testAusgabe(text) {},

    uebergabedatenErzeugen: function uebergabedatenErzeugen() {

      var self = this;
      var application = self.controllerFor('application');
      var bauteile = self.controllerFor('bauteile');
      var lasteinwirkung = self.controllerFor('lasteinwirkung');

      //var applicationdata = application.model.objectAt(0);
      var applicationdata = application.get('model').application.objectAt(0);
      var applicationdataJSON = JSON.stringify(applicationdata);

      var projektdatendata = application.get('model').projektdaten.objectAt(0);
      var projektdatendataJSON = JSON.stringify(projektdatendata);

      var geometriedata = application.get('model').geometrie.objectAt(0);
      var geometriedataJSON = JSON.stringify(geometriedata);

      var bauteiledata = application.get('model').bauteile.objectAt(0);
      var bauteiledataJSON = JSON.stringify(bauteiledata);

      var zwverdata = application.get('model').zwischenschichtverstaerkung.objectAt(0);
      var zwverdataJSON = JSON.stringify(zwverdata);

      var anschlussgeometriedata = application.get('model').anschlussgeometrie.objectAt(0);
      var anschlussgeometriedataJSON = JSON.stringify(anschlussgeometriedata);

      var lasteinwirkungdata = application.get('model').lasteinwirkung.objectAt(0);
      var lasteinwirkungdataJSON = JSON.stringify(lasteinwirkungdata);

      var optionendata = application.get('model').optionen.objectAt(0);
      var optionendataJSON = JSON.stringify(optionendata);

      if (self.debug) {
        console.log("projektdatendata: " + projektdatendataJSON);
        console.log("applicationdataJSON: " + applicationdataJSON);
        console.log("geometriedataJSON: " + geometriedataJSON);
        console.log("bauteiledataJSON: " + bauteiledataJSON);
        console.log("lasteinwirkungdataJSON: " + lasteinwirkungdataJSON);
      }

      var uebergabedaten = $.extend(true, JSON.parse(applicationdataJSON), JSON.parse(projektdatendataJSON), JSON.parse(geometriedataJSON), JSON.parse(bauteiledataJSON), JSON.parse(zwverdataJSON), JSON.parse(anschlussgeometriedataJSON), JSON.parse(lasteinwirkungdataJSON), JSON.parse(optionendataJSON));

      var shearconnection = {
        "ConnectionTyp": geometriedata.get('ConnectionType'),
        "von1zu2": true,
        "ZwischenSchichtVorh": geometriedata.get('ZwischenSchichtVorh'),
        "VerstaerkungVorh": geometriedata.get('VerstaerkungVorh'),
        "ShearPlaneMultiplicator": geometriedata.get('ShearPlaneMultiplicator'),
        "Setztiefe": Number(geometriedata.get('Setztiefe')).toFixed(1),
        "ShowMessageOnError": false,
        "StarreZwischenSchicht": zwverdata.get('StarreZwischenSchicht'),
        "Klammer30": geometriedata.get('Klammer30')
      };

      if (bauteile.get('htStahl') === true) {
        var timberelement1 = {
          "Querschnittswerte": {
            "t_Fastener": bauteiledata.get('TimberElement2_b'),
            "b": bauteiledata.get('TimberElement2_b'),
            "h": bauteiledata.get('TimberElement2_h')
          },
          "alphaRAD_KraftFaser": bauteiledata.get('TE2_alphaRAD_KraftFaser'),
          "alphaRAD_vmFaser": bauteiledata.get('TE2_alphaRAD_vmFaser'),
          "betaRAD_vmFurnier": bauteiledata.get('TE2_betaRAD_vmFurnier'),
          "vorgebohrt": bauteiledata.get('TimberElement2Predrilled'),
          "MatKey": bauteiledata.get('MatKeyTimberElement1'),
          "FKLKey": parseInt(bauteiledata.get('FKLKeyTimberElement1'))
        };
      } else {
        var timberelement1 = {
          "Querschnittswerte": {
            "QuerschnittsID": "1",
            "t_Fastener": Number(bauteiledata.get('TimberElement1_b')).toFixed(2),
            "b": Number(bauteiledata.get('TimberElement1_b')).toFixed(2),
            "h": Number(bauteiledata.get('TimberElement1_h')).toFixed(2)
          },
          "alphaRAD_KraftFaser": bauteiledata.get('TE1_alphaRAD_KraftFaser'),
          "alphaRAD_vmFaser": bauteiledata.get('TE1_alphaRAD_vmFaser'),
          "betaRAD_vmFurnier": bauteiledata.get('TE1_betaRAD_vmFurnier'),
          "vorgebohrt": bauteiledata.get('TimberElement1Predrilled'),
          "MatKey": bauteiledata.get('MatKeyTimberElement1'),
          "FKLKey": parseInt(bauteiledata.get('FKLKeyTimberElement1'))
        };
      }

      var timberelement2 = {
        "Querschnittswerte": {
          "QuerschnittsID": "1",
          "t_Fastener": Number(bauteiledata.get('TimberElement2_b')).toFixed(2),
          "b": Number(bauteiledata.get('TimberElement2_b')).toFixed(2),
          "h": Number(bauteiledata.get('TimberElement2_h')).toFixed(2)
        },
        "alphaRAD_KraftFaser": bauteiledata.get('TE2_alphaRAD_KraftFaser'),
        "alphaRAD_vmFaser": bauteiledata.get('TE2_alphaRAD_vmFaser'),
        "betaRAD_vmFurnier": bauteiledata.get('TE2_betaRAD_vmFurnier'),
        "vorgebohrt": bauteiledata.get('TimberElement2Predrilled'),
        "MatKey": bauteiledata.get('MatKeyTimberElement2'),
        "FKLKey": parseInt(bauteiledata.get('FKLKeyTimberElement2'))
      };

      if (lasteinwirkung.get('zsver') === 0) {
        var interlayerelement = {
          "Querschnittswerte": {
            "QuerschnittsID": "1",
            "t_Fastener": 0.0,
            "b": 0.0,
            "h": 0.0
          },
          "alphaRAD_KraftFaser": "0.0",
          "alphaRAD_vmFaser": "90.0",
          "betaRAD_vmFurnier": "90.0",
          "vorgebohrt": false,
          "MatKey": 0,
          "FKLKey": 0
        };
      } else if (lasteinwirkung.get('zsver') === 1) {
        var interlayerelement = {
          "Querschnittswerte": {
            "QuerschnittsID": "1",
            "t_Fastener": zwverdata.get('dickeBlass'),
            "b": zwverdata.get('dickeBlass'),
            "h": 0.0
          },
          "alphaRAD_KraftFaser": zwverdata.get('Blass_alphaRAD_KraftFaser'),
          "alphaRAD_vmFaser": zwverdata.get('Blass_alphaRAD_vmFaser'),
          "betaRAD_vmFurnier": zwverdata.get('Blass_betaRAD_vmFurnier'),
          "vorgebohrt": zwverdata.get('materialBlassPredrilled'),
          "MatKey": zwverdata.get('materialBlass'),
          "FKLKey": parseInt(zwverdata.get('fklBlass'))
        };
      } else if (lasteinwirkung.get('zsver') === 2) {
        var interlayerelement = {
          "Querschnittswerte": {
            "QuerschnittsID": "1",
            "t_Fastener": zwverdata.get('dickeWerner'),
            "b": zwverdata.get('dickeWerner'),
            "h": 0.0
          },
          "alphaRAD_KraftFaser": zwverdata.get('Werner_alphaRAD_KraftFaser'),
          "alphaRAD_vmFaser": zwverdata.get('Werner_alphaRAD_vmFaser'),
          "betaRAD_vmFurnier": zwverdata.get('Werner_betaRAD_vmFurnier'),
          "vorgebohrt": zwverdata.get('materialWernerPredrilled'),
          "MatKey": zwverdata.get('materialWerner'),
          "FKLKey": parseInt(zwverdata.get('fklWerner'))
        };
      }

      var fkl_key = -1;

      try {
        fkl_key = enums.Stahl.findBy("typ", lasteinwirkung.getStahlname(Number(bauteile.get('htfkl')))).id;
      } catch (e) {}

      if (bauteile.get('htStahl') === true) {
        var steelplate = {
          // "Bezeichnung": "S235",
          "Bezeichnung": lasteinwirkung.getStahlname(Number(bauteile.get('htfkl'))),
          // "Bezeichnung":this.get('i18n').t(fklDB_new.fkl_stahl.fklassen.findBy('id', Number(bauteile.get('htfkl'))).name).toString(),
          "f_yk": Number(bauteile.get('htfkl')).toFixed(1),
          "Querschnittswerte": {
            "t_Fastener": Number(bauteiledata.get('TimberElement1_b')).toFixed(1),
            "b": Number(bauteiledata.get('TimberElement1_b')).toFixed(1),
            "h": Number(bauteiledata.get('TimberElement1_h')).toFixed(1)
          },
          "alphaRAD_KraftFaser": "0.0",
          "alphaRAD_vmFaser": "90.0",
          "betaRAD_vmFurnier": "90.0",
          "vorgebohrt": true,
          "MatKey": bauteiledata.get('MatKeyTimberElement2'),
          "FKLKey": fkl_key
        };
      } else {
        var steelplate = {
          // "Bezeichnung": "S235",
          "Bezeichnung": lasteinwirkung.getStahlname(Number(bauteile.get('ntfkl'))),
          "f_yk": Number(bauteile.get('ntfkl')).toFixed(1),
          "Querschnittswerte": {
            "t_Fastener": Number(bauteiledata.get('TimberElement2_b')).toFixed(1),
            "b": Number(bauteiledata.get('TimberElement2_b')).toFixed(1),
            "h": Number(bauteiledata.get('TimberElement2_h')).toFixed(1)
          },
          "alphaRAD_KraftFaser": "0.0",
          "alphaRAD_vmFaser": "90.0",
          "betaRAD_vmFurnier": "90.0",
          "vorgebohrt": true,
          "MatKey": bauteiledata.get('MatKeyTimberElement2'),
          "FKLKey": fkl_key
        };
      };

      var abschnittBauteil1 = {
        "abschnittRechts": anschlussgeometriedata.get('abschnittRechts'),
        "abschnittLinks": anschlussgeometriedata.get('abschnittLinks'),
        "a1": anschlussgeometriedata.get('a1'),
        "a2": anschlussgeometriedata.get('a2'),
        "alphaRAD1": anschlussgeometriedata.get('alpha1'),
        "alphaRAD2": anschlussgeometriedata.get('alpha2'),
        "alphaRAD3": anschlussgeometriedata.get('alpha3')
      };

      var abschnittBauteil2 = {
        "abschnittOben": anschlussgeometriedata.get('abschnittOben'),
        "abschnittUnten": anschlussgeometriedata.get('abschnittUnten'),
        "b1": anschlussgeometriedata.get('b1'),
        "b2": anschlussgeometriedata.get('b2'),
        "betaRAD1": anschlussgeometriedata.get('beta1'),
        "betaRAD2": anschlussgeometriedata.get('beta2'),
        "betaRAD3": anschlussgeometriedata.get('beta3')
      };

      var berechnungsoptionen = {
        "faserversatz": optionendata.get('verbindungsmittelVersetzt'),
        "uScheibe": optionendata.get('uScheibe'),
        "querschnittsnachweise": optionendata.get('querschnittsnachweise'),
        "aussermitteBeruecksichtigen": optionendata.get('aussermitteBeruecksichtigen')
      };

      uebergabedaten.ShearConnection = shearconnection;
      uebergabedaten.TimberElement1 = timberelement1;
      uebergabedaten.TimberElement2 = timberelement2;
      uebergabedaten.InterlayerElement = interlayerelement;
      uebergabedaten.SteelPlate = steelplate;
      uebergabedaten.abschnittBauteil1 = abschnittBauteil1;
      uebergabedaten.abschnittBauteil2 = abschnittBauteil2;
      uebergabedaten.berechnungsoptionen = berechnungsoptionen;

      return uebergabedaten;
    },

    resetApplication: function resetApplication() {
      var self = this;

      var applicationdata = self.controllerFor('application').get('model').application.objectAt(0);
      applicationdata.set('treeLoaded', false);
      applicationdata.set('initialized', false);
      applicationdata.set('pdfErstellt', false);
      applicationdata.set('dxfErstellt', false);

      var application = self.controllerFor('application');
      application.set('verbindungsmittelInvalid', true);

      var downloadcenter = self.controllerFor('downloadcenter');
      downloadcenter.set('pdfErstellt', false);
      downloadcenter.set('dxfErstellt', false);
      downloadcenter.set('etaFehlt', true);
      downloadcenter.set('etaNummer', "");

      var x3d = self.controllerFor('application').get('model').x3ddefault.objectAt(0);

      x3d.set('aktuellerSchraubenTyp', 'standardSchraube');
      x3d.set('ergebnisGeladen', false);
      x3d.set('schraubenlisteAktualisiert', !x3d.get('schraubenlisteAktualisiert'));
    },

    erzeugeAufrufzusatz: function erzeugeAufrufzusatz(zweiterzusatz) {

      // Aufrufe	Zusatz1	Zusatz2
      // initialisiereBerechnung	"?"+kennungland_loadsNA_timberNA
      // getKompaktergebnis	"?"+kennungland_loadsNA_timberNA	"_"+artikelnummer.replace(' ','+')
      // erzeugeSchraubenliste	"?"+kennungland_loadsNA_timberNA
      // erzeugeAusgabe	"?"+kennungland_loadsNA_timberNA	"_"+artikelnummer.replace(' ','+')
      // erzeugeDXF	"?"+kennungland_loadsNA_timberNA	"_"+artikelnummer.replace(' ','+')
      // speichereBerechnung	"?"+kennungland_loadsNA_timberNA
      //
      // Beispiel
      // erzeugeAusgabe/?CH_CH_DE_0170+350+45

      var self = this;

      var application = self.controllerFor('application');
      var applicationdata = application.get('model').application.objectAt(0);
      var verbindungsmittel = self.controllerFor('verbindungsmittel');

      var zusatzString = "?" + applicationdata.get("kennungland") + "_" + applicationdata.get("loadsNA") + "_" + applicationdata.get("timberNA");

      if (zweiterzusatz === true) {

        var artikelnummer;

        if (typeof verbindungsmittel.get('selectedNodes')[0] === 'undefined') {
          artikelnummer = "Keine Artikel ausgewaehlt";
        } else {
          artikelnummer = verbindungsmittel.get('selectedNodes')[0].data.ArtNr;
        }

        zusatzString = zusatzString + "_" + artikelnummer.replace(/ /g, '+');
      }

      return zusatzString;
    },

    getHelpText: function getHelpText(reiter, steuerElemente, bedingung) {
      return hilfeTexteDB_wuerth.reiter.findBy("bezeichnung", reiter).steuerElemente.findBy("bezeichnung", steuerElemente).hilfeTexte.findBy("abhaengigkeit", bedingung).hilfetextfelder;
    },

    setHilfetexte: function setHilfetexte(controllerName, hilfetextArray) {
      var self = this;
      var controller = self.controllerFor(controllerName);
      for (var i = 0; i < hilfetextArray.length; i++) {
        if (hilfetextArray[i].text !== controller.get(hilfetextArray[i].bezeichnung)) {
          controller.set(hilfetextArray[i].bezeichnung + "Focused", true);
        }
        // controller.set(hilfetextArray[i].bezeichnung, hilfetextArray[i].text);

        controller.set(hilfetextArray[i].bezeichnung, self.get('i18n').t(hilfetextArray[i].text.toString()));
      }
    },

    setHilfetexteDisplayed: function setHilfetexteDisplayed(textProperty) {
      var self = this;
      // if(textProperty === "" || typeof self.get('i18n').t(textProperty) === "undefined" || self.get('i18n').t(textProperty) === ""){
      if (textProperty === "" || textProperty.toString().substring(0, 20) === "Missing translation:" || typeof textProperty === "undefined" || self.get('i18n').t(textProperty) === "") {
        return false;
      } else {
        return true;
      }
    },

    logaufruf: function logaufruf(_aufruf, _status, _showOutput, _logAnServerSendenErzwingen) {
      var self = this;
      var application = self.controllerFor('application');
      var applicationdata = application.get('model').application.objectAt(0);

      var date = new Date();
      var datuminticks = date.getTime() * 10000 + 621355968000000000;

      if (_showOutput) {
        console.log("***** logaufruf von *****");
        console.log(_aufruf);
      }

      var tc = application.get('model').application.objectAt(0).get('timberCode').replace(" ", "").substring(0, 3);
      var lc = application.get('model').application.objectAt(0).get('loadsCode').replace(" ", "").substring(0, 3);

      var guid = "00000000-0000-0000-0000-000000000000";
      var guidlength = guid.length;
      var cidlength = application.get('model').application.objectAt(0).get('Calculation_ID').length;
      guid = guid.substring(0, guidlength - cidlength) + application.get('model').application.objectAt(0).get('Calculation_ID');

      var logdaten = {
        "datum": datuminticks,
        "bid": guid,
        "request": 0,
        "firma": enums.Firma.findBy("bezeichnung", application.get('model').application.objectAt(0).get('firm')).id,
        "modul": enums.Modul.findBy("bezeichnung", "m03").id,
        "bemessung": enums.NormBemessung.findBy("bezeichnung", tc).id,
        "bemessungNAD": enums.Sprache.findBy("bezeichnung", application.get('model').application.objectAt(0).get('timberNA').toLowerCase()).id,
        "lastannahmen": enums.NormLastannahmen.findBy("bezeichnung", lc).id,
        "lastannahmenNAD": enums.Sprache.findBy("bezeichnung", application.get('model').application.objectAt(0).get('loadsNA').toLowerCase()).id,
        "eingabeSprache": enums.Sprache.findBy("bezeichnung", application.get('model').application.objectAt(0).get('spracheinput').toLowerCase()).id,
        "ausgabeSprache": enums.Sprache.findBy("bezeichnung", application.get('model').application.objectAt(0).get('spracheoutput').toLowerCase()).id,
        "kennungLand": enums.Sprache.findBy("bezeichnung", application.get('model').application.objectAt(0).get('kennungland').toLowerCase()).id,
        "katalog": enums.Sprache.findBy("bezeichnung", application.get('model').application.objectAt(0).get('katalog').toLowerCase()).id,
        "methode": enums.Methode.findBy("bezeichnung", _aufruf).id,
        "userIp": null,
        "userName": application.get('model').application.objectAt(0).get('userName'),
        "url": null,
        "status": _status,
        "artikelnummer": application.get('model').application.objectAt(0).get('selectedScrew')
      };

      if (_showOutput) {
        console.log("zu lockende Daten:");
        console.log(logdaten);
      }

      var form = new FormData();
      form.append("", JSON.stringify(logdaten));

      var environment = externalConfig.environments[application.get('environment')];
      var url = externalConfig[environment].uriLogservice;

      var liveVersion = application.get('environment').search('dev') !== -1 ? false : true;

      if (liveVersion === true || _logAnServerSendenErzwingen === true) {

        $.ajax({
          "type": "POST",
          "url": url,
          "processData": false,
          "contentType": false,
          data: form

        }).done(function (data, statusText, xhr) {
          if (_showOutput) {
            console.log("Daten gelockt: " + data);
            // var status = xhr.status;                //200
            // console.log("statusText");
            // console.log(statusText);
            // console.log("status");
            // console.log(status);
            console.log(" ");
          }
        });
      }
      // testen von der Browserkonsole --> M03.__container__.lookup('controller:supercontroller').logaufruf('erzeugeDXF', 4444, true);
    }

  });

});