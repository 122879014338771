define('m03/locales_ITW/dk/translations', ['exports'], function (exports) {

  'use strict';

  exports['default'] = {

    berechnung_pdf: 'Beregning som PDF',
    setztiefe: 'Forankringslængde',
    vorgebohrt: 'Forborede huller',
    bauteile: 'Samlingselementer',
    hausnummer: 'Husnummer',
    gew_schraube: 'Valgt',
    SH: 'Stål-Træ',
    email: 'E-mail',
    scherkraft: 'Forskydningskraft',
    nicht_vorgebohrt: 'Ikke-forborede huller',
    scherfl_vbm: 'Snitflader pr. forbindelsesmiddel',
    beschreibung: 'Beskrivelse',
    festigkeit: 'Styrkeklasse',
    scherfl2: '2 snitflader',
    scherfl1: '1 snitflade',
    modul_alt: 'M03',
    mat: 'Materiale 1',
    staendig: 'Permanent last (P-last)',
    lasteinwirkung2: 'Last',
    tt_text: 'Exporter til PDF',
    ergebnis: 'Resultat',
    eingabeerforderlich: 'Data påkrævet !',
    plz: 'Postnummer',
    kompakterg: 'Kompakt resultat',
    eingabe_ok: 'Korrekt indlæsning',
    winkel: 'Vinkel mellem samlingselementer',
    sehr_kurz: 'Øjeblikkelig last (Ø-last)',
    msg: 'Vis ugyldige forbindelsesmidler',
    bezeichnung: 'Betegnelse',
    lang: 'Langvarig last (L-last)',
    tel: 'Telefon',
    schnittig2: '2-snit',
    ort: 'By',
    schnittig1: '1-snit',
    land: 'Land',
    projektdaten: 'Projektdata',
    fax: 'Fax',
    kdnum: 'Kundenummer',
    verbindungsmittel: 'Forbindelsesmiddel',
    geometrie: 'Geometri',
    def_bereich: 'Definitionsområde',
    schichtfolge: 'Lagrækkefølge',
    wklscherkraft: 'Vinkel for forskydningskraft',
    artnr: 'Artikelnummer',
    name: 'Navn',
    nkl: 'Anvendelsesklasse',
    adresse: 'Gade, husnummer',
    mittel: 'Middelvarig last (M-last)',
    lasteinwirkung: 'Regningsmæssig lastpåvirkning',
    modul: 'M03',
    led: 'Lastvarighed',
    scherfl_anschl: 'Snitflader pr. tilslutning',
    verarbeiter: 'Medarbejder/opgavestiller',
    vbms: 'Forbindelsesmiddel',
    nkl3: 'Anvendelsesklasse 3',
    hoehe: 'Højde',
    mat1: 'Materiale 2',
    nkl2: 'Anvendelsesklasse 2',
    nkl1: 'Anvendelsesklasse 1',
    position: 'Position',
    axialkraft: 'Udtrækskraft',
    strasse: 'Gade',
    antrieb: 'Kærv',
    bauvorhaben: 'Byggeprojekt',
    vbm: 'Forbindelsesmiddel',
    hinweisLaubholz: 'Der skal forbores ved brug af forbindelsesmidler i løvtræ !',
    auftraggeber: 'Ordregiver',
    dicke: 'Tykkelse',
    raxtd: 'Regningsmæssig trækbæreevne',
    HH: 'Træ/Plade - Træ',
    rlatd: 'Regningsmæssig forskydningsbæreevne',
    kurz: 'Kortvarig last (K-last)',

    nachBlass: 'Mellemliggende lag iht. BLASS',
    nachWerner: 'Forstærkning i henhold til WERNER',
    winkelKlammerruecken: 'Vinkel mellem klammeryg og fiberretning',
    zwischenschichtVerstaerkung: 'Mellemliggende lag/Forstærkning',
    zwischenschicht: 'Mellemliggende lag',
    verstaerkung: 'Forstærkning',
    umwelteinfluesse: 'Korrosionsmiljø',
    erhoehtesKorrosionsrisiko: 'forhøjet risiko for korrosion',

    bemessungssoftware: 'ITW Beregningsprogram',
    wertebereich: "Tilladt værdiinterval:",
    ergebnisanzeigen: "Vis resultat:",
    einstellung: "Indstillinger",
    spracheinstellungen: "Sprogindstillinger",
    eingabe: "Indtastning",
    ausgabe: "Udskrift",
    landeseinstellungen: "Landeindstillinger",
    bemessungsvorschrift: "Beregningsstandard",
    liefersortiment: "Produktsortiment",
    produktkatalog: "Produktkatalog",
    produktinfo: "Produktinformation",
    eta: "ETA",
    cad: "CAD",
    impressum: "Impressum",
    extras: "Ekstra",
    bemerkungen: "Kommentarer",
    bemerkungstext: "Kommentartekst",
    eingabedaten: "Inddata",
    berechnungalspdf: "Beregning som PDF",
    berechnungspeichern: "Gem beregning",
    berechnungoeffnen: "Åbn beregning",
    downloadinstructions1: "Åbn filen i et nyt vindue ved at venstreklikke på ikonerne.",
    downloadinstructions2: "Ved højreklik på ikonerne -> « Gem som » / « Gem link som » for at gemme filen lokalt på harddisken.",
    downloadinstructions3: "Angiv et filnavn, hvorunder du vil downloade de valgte filer som samlet pakke.",
    eingabedatennichtgespeichert: "Inddata er ikke gemt.",
    pdfnochnichterstellt: "Der er endnu ikke blevet genereret et PDF-dokument.",
    dlfilename: "Filnavn",
    dlpaketErstellen: "Opret pakke til download",
    nochkeineschraubegewaehlt: "Der er endnu ikke valgt et forbindelsesmiddel.",
    nochkeindlerzeugt: "Der er endnu ikke genereret en pakke til download.",
    neueberechnung: "Ny beregning",
    kontakt: "Kontakt",
    herausgeber: "Udgiver",
    registergericht: "Registerdomstol",
    geschaeftsfuehrer: "Administrerende direktør",
    entwickeltvon: "Udviklet af",

    dansk: "Dansk",
    deutsch: "Tysk",
    englisch: "Engelsk",
    franzoesisch: "Fransk",
    italienisch: "Italiensk",
    nederlands: "Hollandsk",
    polnisch: "Polsk",
    espanol: "Spansk",
    suomi: "Finsk",
    schwedisch: "Svensk",

    schweiz: "Schweizisk",
    daenemark: "Danmark",
    deutschland: "Tyskland",
    oesterreich: "Østrig",
    england: "England",
    frankreich: "Frankrig",
    italien: "Italien",
    niederlande: "Holland",
    polen: "Polen",
    spanien: "Spanien",
    chile: "Chile",
    finnland: "Finland",
    schweden: "Sverige",

    MatGrp0000: "Konstruktionstræ, nåletræ",
    MatGrp0001: "Limtræ, homogent",
    MatGrp0002: "Lamineret finertræ (LVL)",
    MatGrp0003: "Limtræsbjælke",
    MatGrp0004: "Flerlags-plade i massivt træ",
    MatGrp0005: "xKrydsfiner",
    MatGrp0006: "Lamineret finertræ (alle fibre parallelle)",
    MatGrp0007: "Kunstharpiksbundne spånplader",
    MatGrp0008: "Cementbundne træspånplader",
    MatGrp0009: "Træfiberplader, hård HB.HLA2",
    MatGrp0010: "Træfiberplader, medium HB.LA2",
    MatGrp0012: "Konstruktionstræ, løvtræ",
    MatGrp0013: "Limtræ, kombineret",
    MatGrp0015: "Krydslamineret massivtræ",
    MatGrp0020: "OSB-plader",
    MatGrp0021: "Krydsfiner",
    MatGrp0022: "Gipskartonplader (DIN 18180)",
    MatGrp0030: "Pollmeier LVL Beech",
    MatGrp1000: "Stål",
    MatGrp9100: "Beton",
    MatGrp9999: "Luft",

    Mat1000: "Stål S235JR",
    Mat1001: "Stål S355JR",
    Mat9999: "----",
    Mat000: "C14",
    Mat001: "C16",
    Mat002: "C18",
    Mat003: "C20",
    Mat004: "C22",
    Mat005: "C24",
    Mat006: "C27",
    Mat007: "C30",
    Mat008: "C35",
    Mat009: "C40",
    Mat010: "C45",
    Mat011: "C50",
    Mat050: "D30",
    Mat051: "D35",
    Mat052: "D40",
    Mat053: "D50",
    Mat054: "D60",
    Mat055: "D70",
    Mat020: "GL24h",
    Mat030: "GL24c",
    Mat021: "GL28h",
    Mat031: "GL28c",
    Mat022: "GL32h",
    Mat032: "GL32c",
    Mat023: "GL36h",
    Mat033: "GL36c",
    Mat160: "Kunstharpiksbundne spånplader kl. P4 (EN 312)",
    Mat170: "Kunstharpiksbundne spånplader kl. P5 (EN 312)",
    Mat180: "Kunstharpiksbundne spånplader kl. P6 (EN 312)",
    Mat190: "Kunstharpiksbundne spånplader kl. P7 (EN 312)",
    Mat200: "Cementbundne træspånplader kl. 1+2 (EN 634)",
    Mat220: "Træfiberplade, hård HB.HLA2 (EN 622-2)",
    Mat230: "Træfiberplade, medium MBH.LA2 (EN 622-2)",
    Mat125: "OSB/2 (EN 300)",
    Mat126: "OSB/3 (EN 300)",
    Mat127: "OSB/4 (EN 300)",
    Mat110: "Krydsfiner F25/10",
    Mat111: "Krydsfiner F40/40 E60/40",
    Mat112: "Krydsfiner F50/25 E70/25",
    Mat113: "Krydsfiner F60/10 E90/10",
    Mat520: "Gipskartonplader (DIN 18180)",
    Mat9120: "C20/25",
    Mat9125: "C25/30",
    Mat9130: "C30/37",
    Mat9135: "C35/45",
    Mat9140: "C40/50",
    Mat9145: "C45/55",
    Mat024: "GL30h",
    Mat034: "GL30c",
    Mat301: "GL70",

    ohneZwischenschicht: "Uden en mellemliggende lag",
    zwischenschichtBlass: "Mellemliggende lag iht. BLASS",
    verstaerkungWerner: "Forstærkning i henhold til WERNER",
    groesserGleich30: "≥ 30°",
    kleiner30: "< 30°",

    anschlussgeometrie: "",
    anschlussvarianten: "",
    winkelEndabschnittDiagonale: "",
    ueberstandDiagonaleVertikal: "",
    ausmittigerStabanschluss: "",
    winkelabschnittDiagonale: "",
    exzentrizitaetDiagonale: "##Exzentrizitaet Diagonale",
    abschnittSenkrechtDiagonalenachse: "",
    abschnittParallelGurtachse: "",
    freieEingabe: "Frit input",

    starr: "Hårdt mellemlæg",
    verschieblich: "Blødt mellemlæg",
    nachricht: "##Nachricht",
    nachrichtversenden: "##Ihre Eingabedaten werden automatisch mit der Nachricht übermittelt.",
    nachrichtversendet: "##Ihre Nachricht wurde übermittelt.",
    nachrichtSenden: "##Nachricht senden",
    neueNachricht: "##Neue Nachricht",

    hilfe: "Hjælp",
    bemessungslastText: "Hvis designet belastningen ikke er kendt, er den maksimale kapacitet på forbindelsen midler vises, hvis du indtaster nul.",
    ledTooltipStaendig: "mere end 10 år, f.eks. egenlast",
    ledTooltipLang: "6 mnd. - 10 år, f.eks. oplagring",
    ledTooltipMittel: "1 uge - 6 mnd., f.eks. snelast",
    ledTooltipKurz: "under 1 uge, f.eks. snelast og vindlast",
    ledTooltipSehrKurz: "f.eks. vindlast og ulykkeslast",

    nklTooltip1: "Lukkede opvarmede bygninger. (Hvor fugtindholdet i materialet er svarende til en temperatur på 20°C, og den relative luftfugtighed kun overstiger 65% et par uger pr.år)",
    nklTooltip2: "Beskyttede udvendige områder. (Hvor fugtindholdet i materialet er svarende til en temperatur på 20°C, og den relative luftfugtighed kun overstiger 85% et par uger pr.år)",
    nklTooltip3: "Ubeskyttede udvendige områder. (Klimatiske forhold som leder til højere fugtindhold end anvendelsesklasse 2)",

    nutzungsbedingungen: "Vilkår og betingelser",

    errors: {
      inclusion: "is not included in the list",
      exclusion: "is reserved",
      invalid: "Ugyldig inddata.",
      confirmation: "doesn\'t match {{attribute}}",
      accepted: "must be accepted",
      empty: "can\'t be empty",
      blank: "can\'t be blank",
      present: "must be blank",
      tooLong: "Indtastning er for lang. Maximal længde er {{count}} tegn.",
      tooShort: "!2",
      wrongLength: "is the wrong length (should be {{count}} characters)",
      notANumber: "is not a number",
      notAnInteger: "must be an integer",
      greaterThan: "must be greater than {{count}}",
      greaterThanOrEqualTo: "must be greater than or equal to {{count}}",
      equalTo: "must be equal to {{count}}",
      lessThan: "must be less than {{count}}",
      lessThanOrEqualTo: "must be less than or equal to {{count}}",
      otherThan: "must be other than {{count}}",
      odd: "must be odd",
      even: "must be even"
    },
    'language-select.language.de': "Tysk",
    'language-select.language.en': "English"

  };

});