define('m03/templates/uscheib-e', ['exports'], function (exports) {

  'use strict';

  exports['default'] = Ember.HTMLBars.template((function() {
    var child0 = (function() {
      return {
        isHTMLBars: true,
        revision: "Ember@1.12.0",
        blockParams: 1,
        cachedFragment: null,
        hasRendered: false,
        build: function build(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("\n            ");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("transform");
          dom.setAttribute(el1,"translation","0 0 0");
          var el2 = dom.createTextNode("\n                ");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("shape");
          dom.setAttribute(el2,"uSE","segment1");
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n            ");
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n\n");
          dom.appendChild(el0, el1);
          return el0;
        },
        render: function render(context, env, contextualElement, blockArguments) {
          var dom = env.dom;
          var hooks = env.hooks, set = hooks.set, get = hooks.get, attribute = hooks.attribute;
          dom.detectNamespace(contextualElement);
          var fragment;
          if (env.useFragmentCache && dom.canClone) {
            if (this.cachedFragment === null) {
              fragment = this.build(dom);
              if (this.hasRendered) {
                this.cachedFragment = fragment;
              } else {
                this.hasRendered = true;
              }
            }
            if (this.cachedFragment) {
              fragment = dom.cloneNode(this.cachedFragment, true);
            }
          } else {
            fragment = this.build(dom);
          }
          var element0 = dom.childAt(fragment, [1]);
          var attrMorph0 = dom.createAttrMorph(element0, 'center');
          var attrMorph1 = dom.createAttrMorph(element0, 'rotation');
          set(env, context, "segment", blockArguments[0]);
          attribute(env, attrMorph0, element0, "center", get(env, context, "drehzentrum"));
          attribute(env, attrMorph1, element0, "rotation", get(env, context, "segment.rotation"));
          return fragment;
        }
      };
    }());
    return {
      isHTMLBars: true,
      revision: "Ember@1.12.0",
      blockParams: 0,
      cachedFragment: null,
      hasRendered: false,
      build: function build(dom) {
        var el0 = dom.createDocumentFragment();
        var el1 = dom.createElement("group");
        dom.setAttribute(el1,"dEF","unterlegescheibe1");
        var el2 = dom.createTextNode("\n    ");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("transform");
        var el3 = dom.createTextNode("\n        ");
        dom.appendChild(el2, el3);
        var el3 = dom.createElement("transform");
        dom.setAttribute(el3,"scale","1 1 1");
        var el4 = dom.createTextNode("\n            ");
        dom.appendChild(el3, el4);
        var el4 = dom.createElement("group");
        dom.setAttribute(el4,"dEF","segment1");
        var el5 = dom.createTextNode("\n                ");
        dom.appendChild(el4, el5);
        var el5 = dom.createElement("shape");
        var el6 = dom.createTextNode("\n                    ");
        dom.appendChild(el5, el6);
        var el6 = dom.createElement("appearance");
        var el7 = dom.createTextNode("\n                        ");
        dom.appendChild(el6, el7);
        var el7 = dom.createElement("material");
        dom.setAttribute(el7,"dEF","_material0");
        dom.setAttribute(el7,"transparency","0.3");
        dom.appendChild(el6, el7);
        var el7 = dom.createTextNode("\n                    ");
        dom.appendChild(el6, el7);
        dom.appendChild(el5, el6);
        var el6 = dom.createTextNode("\n                    ");
        dom.appendChild(el5, el6);
        var el6 = dom.createElement("indexedFaceSet");
        dom.setAttribute(el6,"solid","false");
        dom.setAttribute(el6,"ccw","true");
        dom.setAttribute(el6,"solid","true");
        dom.setAttribute(el6,"usegeocache","true");
        dom.setAttribute(el6,"lit","true");
        dom.setAttribute(el6,"normalpervertex","true");
        dom.setAttribute(el6,"normalupdatemode","fast");
        dom.setAttribute(el6,"convex","true");
        dom.setAttribute(el6,"normalindex","");
        dom.setAttribute(el6,"texcoordindex","");
        var el7 = dom.createTextNode(" \n                        ");
        dom.appendChild(el6, el7);
        var el7 = dom.createElement("coordinate");
        dom.appendChild(el6, el7);
        var el7 = dom.createTextNode("\n                    ");
        dom.appendChild(el6, el7);
        dom.appendChild(el5, el6);
        var el6 = dom.createTextNode("\n                ");
        dom.appendChild(el5, el6);
        dom.appendChild(el4, el5);
        var el5 = dom.createTextNode("\n            ");
        dom.appendChild(el4, el5);
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("\n        ");
        dom.appendChild(el3, el4);
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("\n\n");
        dom.appendChild(el2, el3);
        var el3 = dom.createComment("");
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("    ");
        dom.appendChild(el2, el3);
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n");
        dom.appendChild(el1, el2);
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n");
        dom.appendChild(el0, el1);
        return el0;
      },
      render: function render(context, env, contextualElement) {
        var dom = env.dom;
        var hooks = env.hooks, get = hooks.get, attribute = hooks.attribute, block = hooks.block;
        dom.detectNamespace(contextualElement);
        var fragment;
        if (env.useFragmentCache && dom.canClone) {
          if (this.cachedFragment === null) {
            fragment = this.build(dom);
            if (this.hasRendered) {
              this.cachedFragment = fragment;
            } else {
              this.hasRendered = true;
            }
          }
          if (this.cachedFragment) {
            fragment = dom.cloneNode(this.cachedFragment, true);
          }
        } else {
          fragment = this.build(dom);
        }
        var element1 = dom.childAt(fragment, [0, 1]);
        var element2 = dom.childAt(element1, [1, 1, 1]);
        var element3 = dom.childAt(element2, [1, 1]);
        var element4 = dom.childAt(element2, [3]);
        var element5 = dom.childAt(element4, [1]);
        var attrMorph0 = dom.createAttrMorph(element1, 'translation');
        var attrMorph1 = dom.createAttrMorph(element1, 'rotation');
        var attrMorph2 = dom.createAttrMorph(element3, 'diffuseColor');
        var attrMorph3 = dom.createAttrMorph(element4, 'coordIndex');
        var attrMorph4 = dom.createAttrMorph(element5, 'point');
        var morph0 = dom.createMorphAt(element1,3,3);
        attribute(env, attrMorph0, element1, "translation", get(env, context, "translation"));
        attribute(env, attrMorph1, element1, "rotation", get(env, context, "rotation"));
        attribute(env, attrMorph2, element3, "diffuseColor", get(env, context, "farbe"));
        attribute(env, attrMorph3, element4, "coordIndex", get(env, context, "coordIndex"));
        attribute(env, attrMorph4, element5, "point", get(env, context, "koordinaten"));
        block(env, morph0, context, "each", [get(env, context, "view.segmentliste")], {}, child0, null);
        return fragment;
      }
    };
  }()));

});