define('m03/templates/traege-r', ['exports'], function (exports) {

  'use strict';

  exports['default'] = Ember.HTMLBars.template((function() {
    var child0 = (function() {
      return {
        isHTMLBars: true,
        revision: "Ember@1.12.0",
        blockParams: 0,
        cachedFragment: null,
        hasRendered: false,
        build: function build(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("    ");
          dom.appendChild(el0, el1);
          var el1 = dom.createComment("");
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n    ");
          dom.appendChild(el0, el1);
          var el1 = dom.createComment("");
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          return el0;
        },
        render: function render(context, env, contextualElement) {
          var dom = env.dom;
          var hooks = env.hooks, get = hooks.get, inline = hooks.inline;
          dom.detectNamespace(contextualElement);
          var fragment;
          if (env.useFragmentCache && dom.canClone) {
            if (this.cachedFragment === null) {
              fragment = this.build(dom);
              if (this.hasRendered) {
                this.cachedFragment = fragment;
              } else {
                this.hasRendered = true;
              }
            }
            if (this.cachedFragment) {
              fragment = dom.cloneNode(this.cachedFragment, true);
            }
          } else {
            fragment = this.build(dom);
          }
          var morph0 = dom.createMorphAt(fragment,1,1,contextualElement);
          var morph1 = dom.createMorphAt(fragment,3,3,contextualElement);
          inline(env, morph0, context, "indexedlinese-t", [], {"name": get(env, context, "view.name"), "model": get(env, context, "view.model"), "typ": "hoehe"});
          inline(env, morph1, context, "tex-t", [], {"name": get(env, context, "view.name"), "model": get(env, context, "view.model"), "typ": "hoehe"});
          return fragment;
        }
      };
    }());
    var child1 = (function() {
      return {
        isHTMLBars: true,
        revision: "Ember@1.12.0",
        blockParams: 0,
        cachedFragment: null,
        hasRendered: false,
        build: function build(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("    ");
          dom.appendChild(el0, el1);
          var el1 = dom.createComment("");
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n    ");
          dom.appendChild(el0, el1);
          var el1 = dom.createComment("");
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          return el0;
        },
        render: function render(context, env, contextualElement) {
          var dom = env.dom;
          var hooks = env.hooks, get = hooks.get, inline = hooks.inline;
          dom.detectNamespace(contextualElement);
          var fragment;
          if (env.useFragmentCache && dom.canClone) {
            if (this.cachedFragment === null) {
              fragment = this.build(dom);
              if (this.hasRendered) {
                this.cachedFragment = fragment;
              } else {
                this.hasRendered = true;
              }
            }
            if (this.cachedFragment) {
              fragment = dom.cloneNode(this.cachedFragment, true);
            }
          } else {
            fragment = this.build(dom);
          }
          var morph0 = dom.createMorphAt(fragment,1,1,contextualElement);
          var morph1 = dom.createMorphAt(fragment,3,3,contextualElement);
          inline(env, morph0, context, "indexedlinese-t", [], {"name": get(env, context, "view.name"), "model": get(env, context, "view.model"), "typ": "breite"});
          inline(env, morph1, context, "tex-t", [], {"name": get(env, context, "view.name"), "model": get(env, context, "view.model"), "typ": "breite"});
          return fragment;
        }
      };
    }());
    var child2 = (function() {
      return {
        isHTMLBars: true,
        revision: "Ember@1.12.0",
        blockParams: 0,
        cachedFragment: null,
        hasRendered: false,
        build: function build(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("    ");
          dom.appendChild(el0, el1);
          var el1 = dom.createComment("");
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n    ");
          dom.appendChild(el0, el1);
          var el1 = dom.createComment("");
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          return el0;
        },
        render: function render(context, env, contextualElement) {
          var dom = env.dom;
          var hooks = env.hooks, get = hooks.get, inline = hooks.inline;
          dom.detectNamespace(contextualElement);
          var fragment;
          if (env.useFragmentCache && dom.canClone) {
            if (this.cachedFragment === null) {
              fragment = this.build(dom);
              if (this.hasRendered) {
                this.cachedFragment = fragment;
              } else {
                this.hasRendered = true;
              }
            }
            if (this.cachedFragment) {
              fragment = dom.cloneNode(this.cachedFragment, true);
            }
          } else {
            fragment = this.build(dom);
          }
          var morph0 = dom.createMorphAt(fragment,1,1,contextualElement);
          var morph1 = dom.createMorphAt(fragment,3,3,contextualElement);
          inline(env, morph0, context, "indexedlinese-t", [], {"name": get(env, context, "view.name"), "model": get(env, context, "view.model"), "typ": "setztiefe"});
          inline(env, morph1, context, "tex-t", [], {"name": get(env, context, "view.name"), "model": get(env, context, "view.model"), "typ": "setztiefe"});
          return fragment;
        }
      };
    }());
    var child3 = (function() {
      return {
        isHTMLBars: true,
        revision: "Ember@1.12.0",
        blockParams: 0,
        cachedFragment: null,
        hasRendered: false,
        build: function build(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("    ");
          dom.appendChild(el0, el1);
          var el1 = dom.createComment("");
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          return el0;
        },
        render: function render(context, env, contextualElement) {
          var dom = env.dom;
          var hooks = env.hooks, get = hooks.get, inline = hooks.inline;
          dom.detectNamespace(contextualElement);
          var fragment;
          if (env.useFragmentCache && dom.canClone) {
            if (this.cachedFragment === null) {
              fragment = this.build(dom);
              if (this.hasRendered) {
                this.cachedFragment = fragment;
              } else {
                this.hasRendered = true;
              }
            }
            if (this.cachedFragment) {
              fragment = dom.cloneNode(this.cachedFragment, true);
            }
          } else {
            fragment = this.build(dom);
          }
          var morph0 = dom.createMorphAt(fragment,1,1,contextualElement);
          inline(env, morph0, context, "indexedlinese-t", [], {"name": "scherkraftPfeil", "typ": "lastenPfeil", "model": get(env, context, "view.model")});
          return fragment;
        }
      };
    }());
    return {
      isHTMLBars: true,
      revision: "Ember@1.12.0",
      blockParams: 0,
      cachedFragment: null,
      hasRendered: false,
      build: function build(dom) {
        var el0 = dom.createDocumentFragment();
        var el1 = dom.createElement("transform");
        dom.setAttribute(el1,"class","ember-view testClassNameXXX");
        dom.setAttribute(el1,"bboxcenter","0 0 0");
        dom.setAttribute(el1,"bboxsize","-1,-1,-1");
        dom.setAttribute(el1,"scale","1,1,1");
        dom.setAttribute(el1,"scaleorientation","0,0,0,0");
        dom.setAttribute(el1,"render","true");
        var el2 = dom.createTextNode("\n  ");
        dom.appendChild(el1, el2);
        var el2 = dom.createComment(" Traeger ");
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n  ");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("shape");
        dom.setAttribute(el2,"class","ember-view");
        dom.setAttribute(el2,"bboxcenter","0,0,0");
        dom.setAttribute(el2,"bboxsize","-1,-1,-1");
        dom.setAttribute(el2,"ispickable","0");
        dom.setAttribute(el2,"def","");
        dom.setAttribute(el2,"render","true");
        var el3 = dom.createTextNode("\n    ");
        dom.appendChild(el2, el3);
        var el3 = dom.createElement("appearance");
        dom.setAttribute(el3,"class","ember-view");
        dom.setAttribute(el3,"sorttype","auto");
        dom.setAttribute(el3,"alphaclipthreshold","0.1");
        var el4 = dom.createTextNode("\n      ");
        dom.appendChild(el3, el4);
        var el4 = dom.createElement("material");
        dom.setAttribute(el4,"class","ember-view");
        dom.setAttribute(el4,"ambientintensity","0.2");
        dom.setAttribute(el4,"shininess","0.2");
        dom.setAttribute(el4,"specularcolor","0,0,0");
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("\n    ");
        dom.appendChild(el3, el4);
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("\n    ");
        dom.appendChild(el2, el3);
        var el3 = dom.createElement("indexedfaceset");
        dom.setAttribute(el3,"class","ember-view testClassNameXXX");
        dom.setAttribute(el3,"colorindex","0 0 0 0 0 0 0");
        dom.setAttribute(el3,"colorpervertex","false");
        dom.setAttribute(el3,"ccw","true");
        dom.setAttribute(el3,"solid","true");
        dom.setAttribute(el3,"usegeocache","true");
        dom.setAttribute(el3,"lit","true");
        dom.setAttribute(el3,"normalpervertex","true");
        dom.setAttribute(el3,"normalupdatemode","fast");
        dom.setAttribute(el3,"convex","true");
        dom.setAttribute(el3,"normalindex","");
        dom.setAttribute(el3,"texcoordindex","");
        var el4 = dom.createTextNode("\n      ");
        dom.appendChild(el3, el4);
        var el4 = dom.createElement("coordinate");
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("\n      ");
        dom.appendChild(el3, el4);
        var el4 = dom.createElement("color");
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("\n    ");
        dom.appendChild(el3, el4);
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("\n  ");
        dom.appendChild(el2, el3);
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n\n  ");
        dom.appendChild(el1, el2);
        var el2 = dom.createComment(" Kontur ");
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n  ");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("transform");
        dom.setAttribute(el2,"class","ember-view testClassNameXXX");
        dom.setAttribute(el2,"bboxcenter","0 0 0");
        dom.setAttribute(el2,"bboxsize","-1,-1,-1");
        dom.setAttribute(el2,"center","0 0 0");
        dom.setAttribute(el2,"scale","1,1,1");
        dom.setAttribute(el2,"scaleorientation","0,0,0,0");
        dom.setAttribute(el2,"render","true");
        dom.setAttribute(el2,"translation","0,0,0");
        dom.setAttribute(el2,"rotation","0,0,0,0");
        var el3 = dom.createTextNode("\n    ");
        dom.appendChild(el2, el3);
        var el3 = dom.createElement("shape");
        dom.setAttribute(el3,"render","true");
        dom.setAttribute(el3,"bboxcenter","0,0,0");
        dom.setAttribute(el3,"bboxsize","-1,-1,-1");
        dom.setAttribute(el3,"ispickable","true");
        var el4 = dom.createTextNode("\n      ");
        dom.appendChild(el3, el4);
        var el4 = dom.createElement("appearance");
        dom.setAttribute(el4,"sorttype","auto");
        dom.setAttribute(el4,"alphaclipthreshold","0.1");
        var el5 = dom.createTextNode("\n        ");
        dom.appendChild(el4, el5);
        var el5 = dom.createElement("material");
        dom.setAttribute(el5,"emissivecolor","0 0 0");
        dom.setAttribute(el5,"ambientintensity","0.2");
        dom.setAttribute(el5,"diffusecolor","0.8,0.8,0.8");
        dom.setAttribute(el5,"shininess","0.2");
        dom.setAttribute(el5,"specularcolor","0,0,0");
        dom.appendChild(el4, el5);
        var el5 = dom.createTextNode("\n      ");
        dom.appendChild(el4, el5);
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("\n      ");
        dom.appendChild(el3, el4);
        var el4 = dom.createElement("indexedlineset");
        dom.setAttribute(el4,"solid","true");
        dom.setAttribute(el4,"ccw","true");
        dom.setAttribute(el4,"usegeocache","true");
        dom.setAttribute(el4,"lit","true");
        dom.setAttribute(el4,"colorpervertex","true");
        dom.setAttribute(el4,"colorindex","");
        var el5 = dom.createTextNode("\n        ");
        dom.appendChild(el4, el5);
        var el5 = dom.createElement("coordinate");
        dom.appendChild(el4, el5);
        var el5 = dom.createTextNode("\n      ");
        dom.appendChild(el4, el5);
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("\n    ");
        dom.appendChild(el3, el4);
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("\n  ");
        dom.appendChild(el2, el3);
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n\n  ");
        dom.appendChild(el1, el2);
        var el2 = dom.createComment(" Faserrichtung ");
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n  ");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("transform");
        dom.setAttribute(el2,"class","ember-view testClassNameXXX");
        dom.setAttribute(el2,"bboxcenter","0 0 0");
        dom.setAttribute(el2,"bboxsize","-1,-1,-1");
        dom.setAttribute(el2,"center","0 0 0");
        dom.setAttribute(el2,"scale","1,1,1");
        dom.setAttribute(el2,"scaleorientation","0,0,0,0");
        dom.setAttribute(el2,"render","true");
        dom.setAttribute(el2,"translation","0,0,0");
        dom.setAttribute(el2,"rotation","0,0,0,0");
        var el3 = dom.createTextNode("\n    ");
        dom.appendChild(el2, el3);
        var el3 = dom.createElement("shape");
        dom.setAttribute(el3,"render","true");
        dom.setAttribute(el3,"bboxcenter","0,0,0");
        dom.setAttribute(el3,"bboxsize","-1,-1,-1");
        dom.setAttribute(el3,"ispickable","true");
        var el4 = dom.createTextNode("\n      ");
        dom.appendChild(el3, el4);
        var el4 = dom.createElement("appearance");
        dom.setAttribute(el4,"sorttype","auto");
        dom.setAttribute(el4,"alphaclipthreshold","0.1");
        var el5 = dom.createTextNode("\n        ");
        dom.appendChild(el4, el5);
        var el5 = dom.createElement("material");
        dom.setAttribute(el5,"emissivecolor","0 0 0");
        dom.setAttribute(el5,"ambientintensity","0.2");
        dom.setAttribute(el5,"diffusecolor","0.8,0.8,0.8");
        dom.setAttribute(el5,"shininess","0.2");
        dom.setAttribute(el5,"specularcolor","0,0,0");
        dom.appendChild(el4, el5);
        var el5 = dom.createTextNode("\n      ");
        dom.appendChild(el4, el5);
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("\n      ");
        dom.appendChild(el3, el4);
        var el4 = dom.createElement("indexedlineset");
        dom.setAttribute(el4,"coordindex","0 1 -1 1 2 -1 2 3 -1");
        dom.setAttribute(el4,"solid","true");
        dom.setAttribute(el4,"ccw","true");
        dom.setAttribute(el4,"usegeocache","true");
        dom.setAttribute(el4,"lit","true");
        dom.setAttribute(el4,"colorpervertex","true");
        dom.setAttribute(el4,"colorindex","");
        var el5 = dom.createTextNode("\n        ");
        dom.appendChild(el4, el5);
        var el5 = dom.createElement("coordinate");
        dom.appendChild(el4, el5);
        var el5 = dom.createTextNode("\n      ");
        dom.appendChild(el4, el5);
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("\n    ");
        dom.appendChild(el3, el4);
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("\n  ");
        dom.appendChild(el2, el3);
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n\n");
        dom.appendChild(el1, el2);
        var el2 = dom.createComment("");
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n");
        dom.appendChild(el1, el2);
        var el2 = dom.createComment("");
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n");
        dom.appendChild(el1, el2);
        var el2 = dom.createComment("");
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n  ");
        dom.appendChild(el1, el2);
        var el2 = dom.createComment(" Scherkraftpfeil ");
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n");
        dom.appendChild(el1, el2);
        var el2 = dom.createComment("");
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n");
        dom.appendChild(el1, el2);
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n");
        dom.appendChild(el0, el1);
        return el0;
      },
      render: function render(context, env, contextualElement) {
        var dom = env.dom;
        var hooks = env.hooks, get = hooks.get, attribute = hooks.attribute, block = hooks.block;
        dom.detectNamespace(contextualElement);
        var fragment;
        if (env.useFragmentCache && dom.canClone) {
          if (this.cachedFragment === null) {
            fragment = this.build(dom);
            if (this.hasRendered) {
              this.cachedFragment = fragment;
            } else {
              this.hasRendered = true;
            }
          }
          if (this.cachedFragment) {
            fragment = dom.cloneNode(this.cachedFragment, true);
          }
        } else {
          fragment = this.build(dom);
        }
        var element0 = dom.childAt(fragment, [0]);
        var element1 = dom.childAt(element0, [3]);
        var element2 = dom.childAt(element1, [1, 1]);
        var element3 = dom.childAt(element1, [3]);
        var element4 = dom.childAt(element3, [1]);
        var element5 = dom.childAt(element3, [3]);
        var element6 = dom.childAt(element0, [7, 1, 3]);
        var element7 = dom.childAt(element6, [1]);
        var element8 = dom.childAt(element0, [11, 1, 3, 1]);
        var attrMorph0 = dom.createAttrMorph(element0, 'translation');
        var attrMorph1 = dom.createAttrMorph(element0, 'rotation');
        var attrMorph2 = dom.createAttrMorph(element0, 'center');
        var attrMorph3 = dom.createAttrMorph(element2, 'diffusecolor');
        var attrMorph4 = dom.createAttrMorph(element2, 'transparency');
        var attrMorph5 = dom.createAttrMorph(element2, 'emissivecolor');
        var attrMorph6 = dom.createAttrMorph(element3, 'coordindex');
        var attrMorph7 = dom.createAttrMorph(element4, 'point');
        var attrMorph8 = dom.createAttrMorph(element5, 'color');
        var attrMorph9 = dom.createAttrMorph(element6, 'coordindex');
        var attrMorph10 = dom.createAttrMorph(element7, 'point');
        var attrMorph11 = dom.createAttrMorph(element8, 'point');
        var morph0 = dom.createMorphAt(element0,13,13);
        var morph1 = dom.createMorphAt(element0,15,15);
        var morph2 = dom.createMorphAt(element0,17,17);
        var morph3 = dom.createMorphAt(element0,21,21);
        attribute(env, attrMorph0, element0, "translation", get(env, context, "translation"));
        attribute(env, attrMorph1, element0, "rotation", get(env, context, "rotation"));
        attribute(env, attrMorph2, element0, "center", get(env, context, "center"));
        attribute(env, attrMorph3, element2, "diffusecolor", get(env, context, "farbe"));
        attribute(env, attrMorph4, element2, "transparency", get(env, context, "transparency"));
        attribute(env, attrMorph5, element2, "emissivecolor", get(env, context, "farbe"));
        attribute(env, attrMorph6, element3, "coordindex", get(env, context, "coordIndex"));
        attribute(env, attrMorph7, element4, "point", get(env, context, "koordinaten"));
        attribute(env, attrMorph8, element5, "color", get(env, context, "farbe"));
        attribute(env, attrMorph9, element6, "coordindex", get(env, context, "coordIndex"));
        attribute(env, attrMorph10, element7, "point", get(env, context, "koordinaten"));
        attribute(env, attrMorph11, element8, "point", get(env, context, "koordinatenFaserrichtungsPfeil"));
        block(env, morph0, context, "if", [get(env, context, "view.getMassketteHoehe")], {}, child0, null);
        block(env, morph1, context, "if", [get(env, context, "view.getMassketteBreite")], {}, child1, null);
        block(env, morph2, context, "if", [get(env, context, "view.getMassketteSetztiefe")], {}, child2, null);
        block(env, morph3, context, "if", [get(env, context, "view.getscherkraftPfeil")], {}, child3, null);
        return fragment;
      }
    };
  }()));

});