define('m03/locales_ITW/it/translations', ['exports'], function (exports) {

  'use strict';

  exports['default'] = {

    berechnung_pdf: 'Calcolo in pdf',
    setztiefe: 'Profondità di penetrazione',
    vorgebohrt: 'Con pre-foratura',
    bauteile: 'Elementi',
    hausnummer: 'Numero',
    gew_schraube: 'Selezionato',
    SH: 'Acciaio-Legno',
    email: 'Email',
    scherkraft: 'Forza di taglio',
    nicht_vorgebohrt: 'Senza pre-foratura',
    scherfl_vbm: 'Piani di taglio per fissaggio',
    beschreibung: 'Descrizione ',
    festigkeit: 'Classe di resistenza',
    scherfl2: '2 piani di taglio',
    scherfl1: '1 piano di taglio',
    modul_alt: 'M03',
    mat: 'Materiale 1',
    staendig: 'permanente',
    lasteinwirkung2: 'Carico',
    tt_text: 'Salva in PDF',
    ergebnis: 'Risultato',
    eingabeerforderlich: 'Inserimento dati necessario !',
    plz: 'Cap',
    kompakterg: 'Risultato sintetico',
    eingabe_ok: 'Inserimento corretto',
    winkel: 'Angolo fra gli elementi',
    sehr_kurz: 'istantaneo',
    msg: 'Sono visualizzati fissaggi non ammessi',
    bezeichnung: 'Nome',
    lang: 'lungo durata',
    tel: 'Telefono',
    schnittig2: 'Doppio taglio',
    ort: 'Località ',
    schnittig1: 'Taglio semplice',
    land: 'Paese',
    projektdaten: 'Informazioni progetto',
    fax: 'Fax',
    kdnum: 'Codice cliente',
    verbindungsmittel: 'Fissaggio',
    geometrie: 'Geometria',
    def_bereich: 'Area definita',
    schichtfolge: 'Successione degli strati',
    wklscherkraft: 'Angolo della forza di taglio',
    artnr: 'Codice articolo ',
    name: 'Nome',
    nkl: 'Classe di servizio',
    adresse: 'Via, Numero',
    mittel: 'media durata',
    lasteinwirkung: 'Azione del carico (valore di progetto)',
    modul: 'M03',
    led: 'Durata del carico',
    scherfl_anschl: 'Piani di taglio per collegamento',
    verarbeiter: 'Utente/Committente',
    vbms: 'Fissaggio',
    nkl3: 'Classe di servizio 3',
    hoehe: 'Altezza',
    mat1: 'Materiale 2',
    nkl2: 'Classe di servizio 2',
    nkl1: 'Classe di servizio 1',
    position: 'Posizione',
    axialkraft: 'Forza assiale (trazione)',
    strasse: 'Via',
    antrieb: 'Intaglio',
    bauvorhaben: 'Progetto edilizio',
    vbm: 'Fissaggio',
    hinweisLaubholz: 'L’installazione con preforatura è obbligatoria nel legno duro !',
    auftraggeber: 'Committente',
    dicke: 'Spessore',
    raxtd: 'Capacità portante assiale di progetto',
    HH: 'Legno/Pannello - Legno',
    rlatd: 'Capacità portante laterale di progetto',
    kurz: 'breve durata',

    nachBlass: 'Elemento centrale secondo BLAAS',
    nachWerner: 'Rinforzo secondo WERNER',
    winkelKlammerruecken: 'Angolo tra la graffa e la fibratura',
    zwischenschichtVerstaerkung: 'Elemento centrale/Rinforzi',
    zwischenschicht: 'Elemento centrale',
    verstaerkung: 'Rinforzo',
    umwelteinfluesse: 'Ambiente, trattamenti',
    erhoehtesKorrosionsrisiko: 'particolare rischio di corrosione',

    bemessungssoftware: 'Software di calcolo ITW',
    wertebereich: "Campo di valore ammissibile:",
    ergebnisanzeigen: "Mostra risultato:",
    einstellung: "Impostazione",
    spracheinstellungen: "Impostazione lingua",
    eingabe: "Entrata",
    ausgabe: "Uscita",
    landeseinstellungen: "Impostazioni del Paese",
    bemessungsvorschrift: "Regole di calcolo",
    liefersortiment: "Gamma di vendita ",
    produktkatalog: "Catalogo prodotti ",
    produktinfo: "Informazione prodotto",
    eta: "ETA",
    cad: "CAD",
    impressum: "Impressum",
    extras: "Strumenti",
    bemerkungen: "Note",
    bemerkungstext: "Testo delle note",
    eingabedaten: "Dati di ingresso",
    berechnungalspdf: "Calcolo in pdf",
    berechnungspeichern: "Salvare il calcolo",
    berechnungoeffnen: "Aprire il calcolo",
    downloadinstructions1: "Cliccare sopra le icone: Apertura dei dati in una nuova finestra.",
    downloadinstructions2: "Pulsante destro del mouse sulle icone ->« Salva come » / « Salva collegamento come » per memorizzare localmente i dati sul disco fisso.",
    downloadinstructions3: "Specificare il nome della cartella in cui si desidera scaricare i file selezionati.",
    eingabedatennichtgespeichert: "I dati di ingresso non sono stati salvati.",
    pdfnochnichterstellt: "Non è stato ancora generato il PDF.",
    dlfilename: "Nome del file",
    dlpaketErstellen: "Crea una cartella di download",
    nochkeineschraubegewaehlt: "Non è stata ancora scelta una elemento di collegamento.",
    nochkeindlerzeugt: "Non è stata creata una cartella per il download.",
    neueberechnung: "Nuovo calcolo",
    kontakt: "Contatto",
    herausgeber: "Distributore",
    registergericht: "Registro d\'impresa",
    geschaeftsfuehrer: "Direttore",
    entwickeltvon: "Sviluppato da",

    dansk: "Danese",
    deutsch: "Tedesco",
    englisch: "Inglese",
    franzoesisch: "Francese",
    italienisch: "Italiano",
    nederlands: "Olandese",
    polnisch: "Polacco",
    espanol: "Spagnolo",
    suomi: "Finlandese",
    schwedisch: "Svedese",

    schweiz: "Svizzera",
    daenemark: "Danimarca",
    deutschland: "Germania ",
    oesterreich: "Austria",
    england: "Inghilterra",
    frankreich: "Francia",
    italien: "Italia",
    niederlande: "Paesi Bassi",
    polen: "Polonia",
    spanien: "Spagna",
    chile: "Chile",
    finnland: "Finlandia",
    schweden: "Svezia",

    MatGrp0000: "Legno massiccio tenero",
    MatGrp0001: "Legno lamellare incollato, omogeneo",
    MatGrp0002: "Pannello stratificato (LVL)",
    MatGrp0003: "Legno bilama-trilama (travi duo-trio)",
    MatGrp0004: "Pannelli stratificati in legno massiccio",
    MatGrp0005: "xPannello stratificato da costruzione",
    MatGrp0006: "Pannello stratificato (a fibratura parallela)",
    MatGrp0007: "Truciolare (pannelli di particelle)",
    MatGrp0008: "Cemento-legno (truciolare legato cemento)",
    MatGrp0009: "Pannello duro, fibrati ad alta densità (HB)",
    MatGrp0010: "Pannello semiduro a media densità (MHB)",
    MatGrp0012: "Legno massiccio duro",
    MatGrp0013: "Legno lamellare incollato, combinato",
    MatGrp0015: "Pannelli stratificati - Compensato di tavole",
    MatGrp0020: "Pannelli OSB (Oriented Strand Board)",
    MatGrp0021: "Pannello stratificato da costruzione",
    MatGrp0022: "Lastra in cartongesso (DIN 18180)",
    MatGrp0030: "Pollmeier BauBuche",
    MatGrp1000: "Acciaio",
    MatGrp9100: "Calcestruzzo",
    MatGrp9999: "Luft",

    Mat1000: "Acciaio S235JR",
    Mat1001: "Acciaio S355JR",
    Mat9999: "----",
    Mat000: "C14",
    Mat001: "C16",
    Mat002: "C18",
    Mat003: "C20",
    Mat004: "C22",
    Mat005: "C24",
    Mat006: "C27",
    Mat007: "C30",
    Mat008: "C35",
    Mat009: "C40",
    Mat010: "C45",
    Mat011: "C50",
    Mat050: "D30",
    Mat051: "D35",
    Mat052: "D40",
    Mat053: "D50",
    Mat054: "D60",
    Mat055: "D70",
    Mat020: "GL24h",
    Mat030: "GL24c",
    Mat021: "GL28h",
    Mat031: "GL28c",
    Mat022: "GL32h",
    Mat032: "GL32c",
    Mat023: "GL36h",
    Mat033: "GL36c",
    Mat160: "Truciolare P4 (EN 312)",
    Mat170: "Truciolare P5 (EN 312)",
    Mat180: "Truciolare P6 (EN 312)",
    Mat190: "Truciolare P7 (EN 312)",
    Mat200: "Pannello truciolare con cemento, classe 1+2 (EN 634)",
    Mat220: "Pannello duro, fibrato ad alta densità, HB (EN 622-2)",
    Mat230: "Pannello semiduro a media densità, MHB (EN 622-3)",
    Mat125: "OSB/2 (EN 300)",
    Mat126: "OSB/3 (EN 300)",
    Mat127: "OSB/4 (EN 300)",
    Mat110: "Compensato F25/10",
    Mat111: "Compensato F40/40 E60/40",
    Mat112: "Pannello stratificato F50/25 E70/25",
    Mat113: "Pannello stratificato F60/10 E90/10",
    Mat520: "Lastra in cartongesso (DIN 18180)",
    Mat9120: "C20/25",
    Mat9125: "C25/30",
    Mat9130: "C30/37",
    Mat9135: "C35/45",
    Mat9140: "C40/50",
    Mat9145: "C45/55",
    Mat024: "GL30h",
    Mat034: "GL30c",
    Mat301: "GL70",

    ohneZwischenschicht: "Senza elemento centrale",
    zwischenschichtBlass: "Elemento centrale secondo BLAAS",
    verstaerkungWerner: "Rinforzo secondo WERNER",
    groesserGleich30: "≥ 30°",
    kleiner30: "< 30°",

    anschlussgeometrie: "#no value#",
    anschlussvarianten: "#no value#",
    winkelEndabschnittDiagonale: "#no value#",
    ueberstandDiagonaleVertikal: "#no value#",
    ausmittigerStabanschluss: "#no value#",
    winkelabschnittDiagonale: "#no value#",
    exzentrizitaetDiagonale: "##Exzentrizitaet Diagonale",
    abschnittSenkrechtDiagonalenachse: "#no value#",
    abschnittParallelGurtachse: "#no value#",
    freieEingabe: "Inserimento libero",

    starr: "Elemento centrale rigido",
    verschieblich: "Elemento centrale non rigido",
    nachricht: "##Nachricht",
    nachrichtversenden: "##Ihre Eingabedaten werden automatisch mit der Nachricht übermittelt.",
    nachrichtversendet: "##Ihre Nachricht wurde übermittelt.",
    nachrichtSenden: "##Nachricht senden",
    neueNachricht: "##Neue Nachricht",

    hilfe: "Aiuto",
    bemessungslastText: "Se il carico di progetto non è noto, la capacità massima dei mezzi di collegamento viene visualizzato se si immette zero.",
    ledTooltipStaendig: "più di 10 anni, es. peso proprio",
    ledTooltipLang: "6 mesi - 10 anni, es. magazzini",
    ledTooltipMittel: "1 settimana - 6 mesi, es. sovraccarichi sul solaio, neve",
    ledTooltipKurz: "inferiore ad una settimana, es. neve, vento",
    ledTooltipSehrKurz: "es. vento, carichi accidentali",

    nklTooltip1: "Umidità del materiale riferita a 20°C ed umidità relativa dell\'ambiente circostante superiore a 65% solo per poche settimane all\'anno (es. ambienti chiusi, edifici riscaldati)",
    nklTooltip2: "Umidità del materiale riferita a 20°C ed umidità relativa dell\'ambiente circostante superiore a 85% solo per poche settimane all\'anno (es. ambienti chiusi, edifici riscaldati)",
    nklTooltip3: "Condizioni climatiche tali da indurre un contenuto d\'umidità superiore a quello della classe 2 (es. strutture esposte agli agenti atmosferici)",

    nutzungsbedingungen: "Termini e condizioni",

    errors: {
      inclusion: "is not included in the list",
      exclusion: "is reserved",
      invalid: "Inserimento non valido.",
      confirmation: "doesn\'t match {{attribute}}",
      accepted: "must be accepted",
      empty: "can\'t be empty",
      blank: "can\'t be blank",
      present: "must be blank",
      tooLong: "Inserimento troppo lungo. La massima lunghezza consentita è {{count}}",
      tooShort: "!2",
      wrongLength: "is the wrong length (should be {{count}} characters)",
      notANumber: "is not a number",
      notAnInteger: "must be an integer",
      greaterThan: "must be greater than {{count}}",
      greaterThanOrEqualTo: "must be greater than or equal to {{count}}",
      equalTo: "must be equal to {{count}}",
      lessThan: "must be less than {{count}}",
      lessThanOrEqualTo: "must be less than or equal to {{count}}",
      otherThan: "must be other than {{count}}",
      odd: "must be odd",
      even: "must be even"
    },
    'language-select.language.de': "Tedesco",
    'language-select.language.en': "English"

  };

});