define('m03/locales_ITW/es/translations', ['exports'], function (exports) {

  'use strict';

  exports['default'] = {

    berechnung_pdf: 'Cálculo como PDF',
    setztiefe: 'Profundidad de colocación',
    vorgebohrt: 'Agujeros pretaladrados',
    bauteile: 'Elementos de construcción',
    hausnummer: 'Número de casa',
    gew_schraube: 'Tornillo seleccionado',
    SH: 'Acero-madera',
    email: 'Correo electrónico',
    scherkraft: 'Fuerza de la tensión cortante',
    nicht_vorgebohrt: 'Agujeros sin pretaladrar',
    scherfl_vbm: 'Superficies con tensión cortante por conector',
    beschreibung: '#Beschreibung',
    festigkeit: 'Clase de resistencia',
    scherfl2: '2 superficies con tensión cortantes',
    scherfl1: '1 superficie con tensión cortante',
    modul_alt: 'M03 - Schraubenbemessung',
    mat: 'Material 1',
    staendig: 'permanente',
    lasteinwirkung2: 'Acción de carga',
    tt_text: 'Recomendación para el análisis estructural en formato PDF',
    ergebnis: 'Resultado',
    eingabeerforderlich: '¡Se requiere entrada de datos!',
    plz: 'Código postal',
    kompakterg: 'Resultado compacto',
    eingabe_ok: 'Entrada de datos correcta',
    winkel: 'Ángulo entre los elementos de construcción',
    sehr_kurz: 'muy corto',
    msg: '#Unzulässige Schrauben einblenden',
    bezeichnung: '#Bezeichnung',
    lang: 'largo',
    tel: 'Teléfono',
    schnittig2: 'con 2 secciones',
    ort: 'Lugar',
    schnittig1: 'con 1 sección',
    land: '#Land',
    projektdaten: 'Datos del proyecto',
    fax: 'Fax',
    kdnum: '#Kundennummer',
    verbindungsmittel: 'Conector',
    geometrie: 'Geometría',
    def_bereich: 'Área de definición',
    schichtfolge: 'orden de capas',
    wklscherkraft: 'Ángulo direccional de la fuerza de cizallamiento',
    artnr: 'Código',
    name: 'Nombre',
    nkl: 'Clase de uso',
    adresse: 'Calle, número',
    mittel: 'mediano',
    lasteinwirkung: 'Acción de carga',
    modul: 'Schraubenbemessung - M03',
    led: 'Duración de la acción de carga',
    scherfl_anschl: 'Superficie con tensión cortante por conexión',
    verarbeiter: '',
    vbms: 'Conector',
    nkl3: 'Clase de uso 3',
    hoehe: 'Altura',
    mat1: 'Material 2',
    nkl2: 'Clase de uso 2',
    nkl1: 'Clase de uso 1',
    position: '',
    axialkraft: 'Fuerza axial',
    strasse: 'Calle',
    antrieb: 'Accionamiento',
    bauvorhaben: '',
    vbm: 'Conector',
    hinweisLaubholz: '#no value#',
    auftraggeber: 'Comitente',
    dicke: 'Espesor',
    raxtd: 'Valor asignado de la resistencia a la carga/tracción',
    HH: 'Madera / contrachapado',
    rlatd: 'Valor asignado de la resistencia al cizallamiento',
    kurz: 'corto',

    nachBlass: '#no value#',
    nachWerner: '#no value#',
    winkelKlammerruecken: '#no value#',
    zwischenschichtVerstaerkung: '#no value#',
    zwischenschicht: '',
    verstaerkung: 'Reforzamiento',
    umwelteinfluesse: '#no value#',
    erhoehtesKorrosionsrisiko: '#no value#',

    bemessungssoftware: '#no value#',
    wertebereich: "#no value#",
    ergebnisanzeigen: "#no value#",
    einstellung: "Configuración",
    spracheinstellungen: "Configuración de idioma",
    eingabe: "Configuración de idioma para la entrada de datos",
    ausgabe: "Configuración de idioma para los datos de salida",
    landeseinstellungen: "#no value#",
    bemessungsvorschrift: "Norma de dimensionamiento",
    liefersortiment: "Configuración de idioma para el catálogo",
    produktkatalog: "#Produktkatalog",
    produktinfo: "#no value#",
    eta: "#ETA",
    cad: "#CAD",
    impressum: "#Impressum",
    extras: "#Extras",
    bemerkungen: "#no value#",
    bemerkungstext: "#no value#",
    eingabedaten: "#no value#",
    berechnungalspdf: "Cálculo como PDF",
    berechnungspeichern: "",
    berechnungoeffnen: "",
    downloadinstructions1: "#no value#",
    downloadinstructions2: "#no value#",
    downloadinstructions3: "#no value#",
    eingabedatennichtgespeichert: "#no value#",
    pdfnochnichterstellt: "#no value#",
    dlfilename: "#no value#",
    dlpaketErstellen: "#no value#",
    nochkeineschraubegewaehlt: "#no value#",
    nochkeindlerzeugt: "#no value#",
    neueberechnung: "",
    kontakt: "#no value#",
    herausgeber: "#no value#",
    registergericht: "#no value#",
    geschaeftsfuehrer: "#no value#",
    entwickeltvon: "#no value#",

    dansk: "#no value#Danish",
    deutsch: "Alemán",
    englisch: "Inglés",
    franzoesisch: "#no value#French",
    italienisch: "#no value#Italian",
    nederlands: "#no value#Dutch",
    polnisch: "#no value#Polish",
    espanol: "#no value#Spanish",
    suomi: "#no value#Finnish",
    schwedisch: "#no value#Swedich",

    schweiz: "#no value#Switzerland",
    daenemark: "#no value#Denmark",
    deutschland: "#Deutschland",
    oesterreich: "",
    england: "#no value#England",
    frankreich: "#no value#France",
    italien: "#no value#Italy",
    niederlande: "#no value#Netherlands",
    polen: "#no value#Poland",
    spanien: "#no value#Spain",
    chile: "#no value#Chile",
    finnland: "#no value#Finland",
    schweden: "#no value#Sweden",

    MatGrp0000: "Madera maciza de conífera",
    MatGrp0001: "Madera laminada encolada, homogénea",
    MatGrp0002: "Madera laminada enchapada",
    MatGrp0003: "Viga laminada enchapada",
    MatGrp0004: "Tabla de madera maciza de varias chapas",
    MatGrp0005: "xMadera contrachapada",
    MatGrp0006: "Madera laminada enchapada (todas las fibras paralelas)",
    MatGrp0007: "Tableros de partículas laminados con resina sintética",
    MatGrp0008: "Tableros de partículas laminados con hormigón",
    MatGrp0009: "Tableros de fibra de madera HB.HLA2",
    MatGrp0010: "Tableros de fibra de madera MHB.LA2",
    MatGrp0012: "Madera maciza, madera dura",
    MatGrp0013: "Madera laminada encolada, combinada",
    MatGrp0015: "Madera contralaminada",
    MatGrp0020: "Tableros OSB",
    MatGrp0021: "Madera contrachapada",
    MatGrp0022: "",
    MatGrp0030: "Pollmeier Bau-Buche",
    MatGrp1000: "Acero",
    MatGrp9100: "Beton",
    MatGrp9999: "Luft",

    Mat1000: "",
    Mat1001: "",
    Mat9999: "----",
    Mat000: "",
    Mat001: "",
    Mat002: "",
    Mat003: "",
    Mat004: "",
    Mat005: "",
    Mat006: "",
    Mat007: "",
    Mat008: "",
    Mat009: "",
    Mat010: "",
    Mat011: "",
    Mat050: "",
    Mat051: "",
    Mat052: "",
    Mat053: "",
    Mat054: "",
    Mat055: "",
    Mat020: "",
    Mat030: "",
    Mat021: "",
    Mat031: "",
    Mat022: "",
    Mat032: "",
    Mat023: "",
    Mat033: "",
    Mat160: "",
    Mat170: "",
    Mat180: "",
    Mat190: "",
    Mat200: "",
    Mat220: "",
    Mat230: "",
    Mat125: "",
    Mat126: "",
    Mat127: "",
    Mat110: "",
    Mat111: "",
    Mat112: "",
    Mat113: "",
    Mat520: "",
    Mat9120: "C20/25",
    Mat9125: "C25/30",
    Mat9130: "C30/37",
    Mat9135: "C35/45",
    Mat9140: "C40/50",
    Mat9145: "C45/55",
    Mat024: "",
    Mat034: "",
    Mat301: "GL70",

    errors: {
      inclusion: "is not included in the list",
      exclusion: "is reserved",
      invalid: "#no value#",
      confirmation: "doesn\'t match {{attribute}}",
      accepted: "must be accepted",
      empty: "can\'t be empty",
      blank: "can\'t be blank",
      present: "must be blank",
      tooLong: "Eingabe zu lang. Maximal erlaubte Länge sind {{count}} Zeichen.",
      tooShort: "!2",
      wrongLength: "is the wrong length (should be {{count}} characters)",
      notANumber: "is not a number",
      notAnInteger: "must be an integer",
      greaterThan: "must be greater than {{count}}",
      greaterThanOrEqualTo: "must be greater than or equal to {{count}}",
      equalTo: "must be equal to {{count}}",
      lessThan: "must be less than {{count}}",
      lessThanOrEqualTo: "must be less than or equal to {{count}}",
      otherThan: "must be other than {{count}}",
      odd: "must be odd",
      even: "must be even"
    },
    'language-select.language.de': "Alemán",
    'language-select.language.en': "English"

  };

});