define('m03/components/mittelbautei-l', ['exports', 'ember'], function (exports, Ember) {

  'use strict';

  var Transform = Ember['default'].Component.extend({

    tagName: 'transform',
    layoutName: 'traege-r',
    attributeBindings: ['coordIndex', 'colorindex', 'colorPerVertex', 'ccw'],
    classNames: ['testClassNameXXX'],
    colorPerVertex: "false",
    bboxsize: "-1,-1,-1",
    ccw: "true",

    objektSkalierung: 1,

    ueberstand: 2,

    yDeltaOben: 0,
    yDeltaUnten: 0,

    center: (function () {

      var name = this.get('name');
      var x3d = this.get('model').objectAt(0);
      var bauteile = x3d.get('bauteile');
      var item = bauteile.findBy('id', name);

      var y = item.get('boxsizes').objectAt(0).get('y') / 10;

      return "0 " + y / 2 + " 0";
    }).property('model.firstObject.transformHelper'),

    translation: (function () {
      var name = this.get('name');
      var x3d = this.get('model').objectAt(0);
      var bauteile = x3d.get('bauteile');
      var item = bauteile.findBy('id', name);

      var values = '0 0 0';

      var xVerschiebung = 0,
          yVerschiebung = 0,
          zVerschiebung = 0,
          verstaerkung = 0,
          deltaVerstaerkung = 0;

      if (x3d.get('verstaerkungBlass') === true && x3d.get('verstaerkungWerner') === false) {
        verstaerkung = Number(x3d.get('dickeBlass')) / 10;
        deltaVerstaerkung = verstaerkung;
      } else if (x3d.get('verstaerkungBlass') === true && (x3d.get('verstaerkungWerner') === true && x3d.get('sFolgeHolzHolz') === true)) {
        verstaerkung = Number(x3d.get('dickeWerner')) / 10;
        deltaVerstaerkung = 2 * verstaerkung;
      } else if (x3d.get('verstaerkungBlass') === true && (x3d.get('verstaerkungWerner') === true && x3d.get('sFolgeHolzHolz') === false)) {
        verstaerkung = Number(x3d.get('dickeWerner')) / 10;
        deltaVerstaerkung = verstaerkung;
      }

      switch (name) {
        case 'nebenTraeger':
          xVerschiebung = item.get('translations').objectAt(0).get('x') / 10;
          yVerschiebung = -(bauteile.findBy('id', 'nebenTraeger').get('boxsizes').objectAt(0).get('y') / 10 - bauteile.findBy('id', 'hauptTraeger1').get('boxsizes').objectAt(0).get('y') / 10) / 2;
          zVerschiebung = item.get('translations').objectAt(0).get('z') / 10;
          break;
        case 'verstaerkungBlass1':
          xVerschiebung = item.get('translations').objectAt(0).get('x') / 10;
          yVerschiebung = -(bauteile.findBy('id', 'nebenTraeger').get('boxsizes').objectAt(0).get('y') / 10 - bauteile.findBy('id', 'hauptTraeger1').get('boxsizes').objectAt(0).get('y') / 10) / 2;
          zVerschiebung = verstaerkung / 2 + bauteile.findBy('id', 'nebenTraeger').get('boxsizes').objectAt(0).get('z') / 20;
          break;
        case 'verstaerkungBlass2':
          xVerschiebung = item.get('translations').objectAt(0).get('x') / 10;
          yVerschiebung = -(bauteile.findBy('id', 'nebenTraeger').get('boxsizes').objectAt(0).get('y') / 10 - bauteile.findBy('id', 'hauptTraeger1').get('boxsizes').objectAt(0).get('y') / 10) / 2;
          zVerschiebung = -(verstaerkung / 2 + bauteile.findBy('id', 'nebenTraeger').get('boxsizes').objectAt(0).get('z') / 20);
          break;
      }

      values = xVerschiebung + " " + yVerschiebung + " " + zVerschiebung;

      return values;
    }).property('model.firstObject.transformHelper', 'model.firstObject.istHt', 'model.firstObject.schraubenGekreuzt'),

    rotation: (function () {
      var name = this.get('name');
      var x3d = this.get('model').objectAt(0);
      var bauteile = x3d.get('bauteile');
      var values = '0 0 0 0';

      return values;
    }).property('model.firstObject.transformHelper', 'model.firstObject.vWinkel', 'model.firstObject.hWinkel', 'model.firstObject.schraubenGekreuzt'),

    koordinaten: (function () {
      var name = this.get('name');

      var typ = this.get('typ');
      var x3d = this.get('model').objectAt(0);
      var bauteile = x3d.get('bauteile');

      var item = x3d.get('bauteile').findBy('id', name);

      var p0 = '0 0 0',
          p1 = '0 0 0',
          p2 = '0 0 0',
          p3 = '0 0 0',
          p4 = '0 0 0',
          p5 = '0 0 0',
          p6 = '0 0 0',
          p7 = '0 0 0',
          p8 = '0 0 0',
          p9 = '0 0 0';

      var vorzeichen = Number(x3d.get('vorzeichenBWerte'));

      var x = item.get('boxsizes').objectAt(0).get('x') / 10;
      var y = Number(item.get('boxsizes').objectAt(0).get('y')) / 10;
      var z = item.get('boxsizes').objectAt(0).get('z') / 10;

      if (name.substring(0, 17) === 'verstaerkungBlass' && x3d.get('verstaerkungBlass') === true && x3d.get('verstaerkungWerner') === true) {
        z = x3d.get('dickeWerner') / 10;
      }

      var Hg = y;
      var Hd = Number(x3d.get('bauteile').findBy('id', 'hauptTraeger1').get('boxsizes').objectAt(0).get('y')) / 10;

      var deltaXht = 0;
      var xSeitenbauteilOben = 0;
      var xSeitenbauteilUnten = 0;

      var xMinusOberkante = 0;
      var xMinusUnterkante = 0;
      var xPlusOberkante = 0;
      var xPlusUnterkante = 0;

      var phi = Number(x3d.get('phi'));

      var b1 = Number(x3d.get('b1')) / 10;
      var b2 = Number(x3d.get('b2')) / 10;

      var tanPhi = Number(x3d.get('phi')) === 90 ? 1 : Number(x3d.get('phi')) === 0 ? 1 : Math.tan(phi * Math.PI / 180);
      var sinPhi = Number(x3d.get('phi')) === 0 ? 1 : Math.sin(phi * Math.PI / 180);

      // var l1 = (Hd/2)/tanPhi + (Hg/2)/sinPhi;
      // var l2 = Number(x3d.get('phi')) === 90 ? 0 : Number(x3d.get('phi')) === 0 ? 0 : Hd/tanPhi;

      var l1 = Hd / 2 / sinPhi + Hg / 2 / tanPhi;
      var l2 = Number(x3d.get('phi')) === 90 ? 0 : Number(x3d.get('phi')) === 0 ? 0 : Hd / sinPhi;

      // console.log(' ');
      // console.log('tanPhi: '+tanPhi);
      // console.log('sinPhi: '+sinPhi);
      // console.log('(Hd/2)/tanPhi: '+(Hd/2)/tanPhi);
      // console.log('(Hg/2)/sinPhi: '+(Hg/2)/sinPhi);

      // console.log('l1: '+l1);
      // console.log('l2: '+l2);
      // console.log('l1-l2: '+(l1-l2));
      //
      // console.log('b1/sinPhi: '+Math.abs(b1)+'/'+sinPhi+' = '+Math.abs(b1)/sinPhi);
      // console.log('b2/sinPhi: '+b2+'/'+sinPhi+' = '+a2/sinPhi);
      //
      // console.log('l1+b2/sinPhi: '+(l1+b2/sinPhi));
      // console.log('(l1-l2)-Math.abs(b1)/sinPhi: '+((l1-l2)+Math.abs(b1)/sinPhi));

      xMinusOberkante = vorzeichen === -1 ? l2 - l1 + b2 : l1 + 1;
      xMinusUnterkante = vorzeichen === -1 ? l1 - b1 : l1 + 1;

      xPlusOberkante = vorzeichen === 1 ? l1 + b2 : l1;
      xPlusUnterkante = vorzeichen === 1 ? l2 - l1 + b1 : l1;

      // xMinusUnterkante = vorzeichen === -1 ? l1-b1/sinPhi : l1+1;
      // xMinusOberkante =  vorzeichen === -1 ? (l1-l2)+Math.abs(b2)/sinPhi : l1+1;
      //
      // xPlusUnterkante = vorzeichen === 1 ? (l1-l2)+Math.abs(b1)/sinPhi : l1;
      // xPlusOberkante =  vorzeichen === 1 ? l1+b2/sinPhi : l1;

      // console.log('xMinusOberkante: '+xMinusOberkante);
      // console.log('xMinusUnterkante: '+xMinusUnterkante);

      // console.log('beta3: '+Number(x3d.get('beta3')));

      var beta1 = Number(x3d.get('beta1'));
      var beta2 = Number(x3d.get('beta2'));

      var p8 = 0 + ' ' + 0 + ' ' + 0;
      var p9 = 0 + ' ' + 0 + ' ' + 0;

      if (parseInt(x3d.get('beta3')) !== 180) {

        // console.log('----- koordinaten: beta3 !== 180 -----');

        var xy = vorzeichen === -1 ? this.getXYbyVector(vorzeichen, { "x": -xMinusUnterkante, "y": 0 }, { "x": -xMinusOberkante, "y": y }, beta1, -beta2) : this.getXYbyVector(vorzeichen, { "x": xPlusUnterkante, "y": 0 }, { "x": xPlusOberkante, "y": y }, -beta1, beta2);

        var dx = xy.x;
        var dy = xy.y;

        p8 = dx + ' ' + dy + ' ' + -z / 2;
        p9 = dx + ' ' + dy + ' ' + z / 2;
      }

      var p0 = -xMinusUnterkante + ' ' + 0 + ' ' + -z / 2;
      var p1 = -xMinusUnterkante + ' ' + 0 + ' ' + z / 2;
      var p2 = xPlusUnterkante + ' ' + 0 + ' ' + z / 2;
      var p3 = xPlusUnterkante + ' ' + 0 + ' ' + -z / 2;
      var p4 = -xMinusOberkante + ' ' + y + ' ' + -z / 2;
      var p5 = -xMinusOberkante + ' ' + y + ' ' + z / 2;
      var p6 = xPlusOberkante + ' ' + y + ' ' + z / 2;
      var p7 = xPlusOberkante + ' ' + y + ' ' + -z / 2;

      var cords = p0 + ', ' + p1 + ', ' + p2 + ', ' + p3 + ', ' + p4 + ', ' + p5 + ', ' + p6 + ', ' + p7 + ', ' + p8 + ', ' + p9;

      return cords;
    }).property('model.firstObject.transformHelper'),

    coordIndex: (function () {
      var name = this.get('name');
      var typ = this.get('typ');
      var x3d = this.get('model').objectAt(0);
      var bauteile = x3d.get('bauteile');
      var values = '';

      var item = x3d.get('bauteile').findBy('id', name);

      var values = "0 3 2 1 0 -1 1 2 6 5 1 -1 4 5 6 7 4 -1 0 4 7 3 0 -1 0 1 5 4 0 -1 2 3 7 6 2 -1";

      if (Number(x3d.get('beta3')) !== 180 && Number(x3d.get('b1')) < 0) {
        values = "0 3 2 1 0 -1 1 2 6 5 9 1 -1 4 5 6 7 4 -1 0 8 4 7 3 0 -1 0 1 9 8 0 -1 4 8 9 5 4 -1 2 3 7 6 2 -1";
      } else if (Number(x3d.get('beta3')) !== 180 && Number(x3d.get('b1')) > 0) {
        values = "0 3 2 1 0 -1 1 2 9 6 5 1 -1 4 5 6 7 4 -1 0 4 7 8 3 0 -1 0 1 5 4 0 -1 7 6 9 8 7 -1 3 8 9 2 3 -1";
      }

      return values;
    }).property('model.firstObject.transformHelper', 'model.firstObject.istHt'),

    colorindex: (function () {

      var values = "0 0 0 0 0 0 0";

      return values;
    }).property('model.firstObject.transformHelper', 'model.firstObject.istHt'),

    farbe: (function () {

      var name = this.get('name');
      var typ = this.get('typ');
      var x3d = this.get('model').objectAt(0);
      var bauteile = x3d.get('bauteile');

      var item = x3d.get('bauteile').findBy('id', name);
      var values = item.get('diffusecolors').objectAt(0).get('rot') + " " + item.get('diffusecolors').objectAt(0).get('gruen') + " " + item.get('diffusecolors').objectAt(0).get('blau');

      return values;
    }).property('model.firstObject.transformHelper', 'model.firstObject.istHt'),

    koordinatenFaserrichtungsPfeil: (function () {

      var name = this.get('name');
      var x3d = this.get('model').objectAt(0);
      var bauteile = x3d.get('bauteile');
      var item = bauteile.findBy('id', name);

      var delta = 0.15;

      var ueberstand = Number(this.get('ueberstand'));

      var x = 0,
          y = 0,
          z = 0;

      var winkel = 0;

      if (item.get('typ') === 'box') {
        x = bauteile.findBy('id', name).get('boxsizes').objectAt(0).get('x') / 20;
        y = 0.15;
        z = item.get('boxsizes').objectAt(0).get('z') / 20;

        if (name.substring(0, 17) === 'verstaerkungBlass' && x3d.get('verstaerkungBlass') === true && x3d.get('verstaerkungWerner') === true) {
          z = x3d.get('dickeWerner') / 20;
        }

        var xSeitenbauteil = Math.abs(Number(x3d.get('xSeitenbauteil'))) / 10;
        var xMittelbauteil = Math.abs(Number(x3d.get('xMittelbauteil'))) / 10;

        if (name === 'hauptTraeger1' || name === 'hauptTraeger2') {
          x = 0;
        } else {
          x = 1;
        }
      } else if (item.get('typ') === 'platte') {

        x = -Number(item.get('punkt').objectAt(1).get('x')) / 10;
        y = Number(item.get('punkt').objectAt(1).get('y')) / 10 + 0.15;
        z = Number(item.get('punkt').objectAt(1).get('z')) / 10 + item.get('platte').objectAt(0).get('thickness') / 10;

        if (name.substring(0, 21) === 'verstaerkungdiagonale' || name.substring(0, 13) === 'seitenbauteil') {
          winkel = x3d.get('phi') * Math.PI / 180;
        }
      }

      var cords = -x + this.deltaX(0.15 + delta, delta * 2, winkel) + ' ' + (y + this.deltaY(0.15 + delta, delta * 2, winkel)) + ' ' + z + ', ' + (-x + this.deltaX(delta, delta, winkel)) + ' ' + (y + this.deltaY(delta, delta, winkel)) + ' ' + z + ', ' + (-x + this.deltaX(1, delta, winkel)) + ' ' + (y + this.deltaY(1, delta, winkel)) + ' ' + z + ', ' + (-x + this.deltaX(0.85, 0, winkel)) + ' ' + (y + this.deltaY(0.85, 0, winkel)) + ' ' + z;

      return cords;
    }).property('model.firstObject.transformHelper'),

    deltaX: function deltaX(x, y, winkel) {
      var erg = x * Math.cos(winkel) - y * Math.sin(winkel);
      return erg;
    },

    deltaY: function deltaY(x, y, winkel) {
      var erg = x * Math.sin(winkel) + y * Math.cos(winkel);
      return erg;
    },

    transparency: (function () {

      var name = this.get('name');
      var x3d = this.get('model').objectAt(0);
      var bauteile = x3d.get('bauteile');
      var value;

      var item = bauteile.findBy('id', name);

      if ((item.get('typ') === 'box' || item.get('typ') === 'platte') && !x3d.get('istGitterModell')) {
        value = item.get('appearances').objectAt(0).get('transparency');
      } else if ((item.get('typ') === 'box' || item.get('typ') === 'platte') && x3d.get('istGitterModell')) {
        value = 1;
      } else {
        value = 0;
      }

      return value;
    }).property('model.x3d.bauteile', 'model.firstObject.istGitterModell'),

    getXYbyVector: function getXYbyVector(vorzeichen, AB, CD, beta1, beta2) {

      console.log(' ');

      console.log('vorzeichen: ' + vorzeichen);
      console.log('AB.x: ' + AB.x + " AB.y: " + AB.y);
      console.log('CD.x: ' + CD.x + " CD.y: " + CD.y);
      console.log('beta1: ' + beta1 + ' beta2: ' + beta2);

      var vUntenX = 0 - AB.x;
      var vUntenY = 0 - AB.y;

      var vObenX = 0 - CD.x;
      var vObenY = CD.y - CD.y;

      console.log('vUntenX: ' + vUntenX + " vUntenY: " + vUntenY);
      console.log('vObenX: ' + vObenX + " vObenY: " + vObenY);

      var ax = vUntenX * Math.cos(beta1 * Math.PI / 180) - vUntenY * Math.sin(beta1 * Math.PI / 180);
      var ay = vUntenX * Math.sin(beta1 * Math.PI / 180) + vUntenY * Math.cos(beta1 * Math.PI / 180);

      var bx = vObenX * Math.cos(beta2 * Math.PI / 180) - vObenY * Math.sin(beta2 * Math.PI / 180);
      var by = vObenX * Math.sin(beta2 * Math.PI / 180) + vObenY * Math.cos(beta2 * Math.PI / 180);

      console.log('ax: ' + ax + " ay: " + ay);
      console.log('bx: ' + bx + " by: " + by);

      var r = (AB.y + ay * ((CD.x - AB.x) / ax) - CD.y) / (by - ay * (bx / ax));
      console.log("AB.y - ay*(CD.x-AB.x)/ax - CD.y = " + AB.y + "+" + ay + "*(" + CD.x + "-" + AB.x + ")/" + ax + " - " + CD.y + " = " + (AB.y + ay * ((CD.x - AB.x) / ax) - CD.y));
      console.log("by-ay*bx/ax = " + by + "-" + ay + "*(" + bx + "/" + ax + ") = " + (by - ay * (bx / ax)));
      console.log("r: " + r);
      var x = CD.x + r * bx;
      var y = CD.y + r * by;

      console.log('x: ' + x + " y: " + y);

      return { "x": x, "y": y };
    },
    get_dlxy: function get_dlxy() {

      var name = this.get('name');
      var x3d = this.get('model').objectAt(0);
      var bauteile = x3d.get('bauteile');
      var item = x3d.get('bauteile').findBy('id', name);

      var vorzeichen = Number(x3d.get('vorzeichenBWerte'));

      var y = Number(item.get('boxsizes').objectAt(0).get('y')) / 10;

      var Hd = y;
      var Hg = Number(x3d.get('bauteile').findBy('id', 'hauptTraeger1').get('boxsizes').objectAt(0).get('y')) / 10;

      // console.log('Hd: '+Hd);

      var b1 = Math.abs(Number(x3d.get('b1')) / 10);
      var b2 = Math.abs(Number(x3d.get('b2')) / 10);

      // console.log('b1: '+b1);
      // console.log('b2: '+b2);

      var beta1 = Number(x3d.get('beta1'));
      var beta2 = Number(x3d.get('beta2'));
      var beta3 = Number(x3d.get('beta3'));

      var phi = Number(x3d.get('phi'));

      var b1 = Number(x3d.get('b1')) / 10;
      var b2 = Number(x3d.get('b2')) / 10;

      var tanPhi = phi === 90 ? 1 : phi === 0 ? 1 : Math.tan(phi * Math.PI / 180);
      var sinPhi = phi === 0 ? 1 : Math.sin(phi * Math.PI / 180);

      var l1 = Hd / 2 / tanPhi + Hg / 2 / sinPhi;
      var l2 = Number(x3d.get('phi')) === 90 ? 0 : Number(x3d.get('phi')) === 0 ? 0 : Hd / tanPhi;

      var loberkante = vorzeichen === -1 ? l1 + b2 / sinPhi : l1;
      var lunterkante = vorzeichen === -1 ? l1 - l2 + Math.abs(b1) / sinPhi : l1;

      // console.log('lunterkante: '+lunterkante);
      // console.log('loberkante: '+loberkante);

      var lk = lunterkante - loberkante;

      // console.log('lk: '+lk);

      var hypotenuse = Math.sqrt(Math.pow(lk, 2) + Math.pow(Hd, 2));

      // console.log('hypotenuse: '+hypotenuse);

      // console.log('lk/Hd: '+lk/Hd);
      // console.log('Hd/lk: '+Hd/lk);

      // console.log('Math.atan(2.2/2.2452): '+Math.atan(2.2/2.2452));

      var dbeta2 = lk <= 0 ? Math.atan(lk / Hd) : Math.atan(Hd / lk);

      // console.log('dbeta2: '+dbeta2);

      var gamma = lk <= 0 ? beta2 * Math.PI / 180 - dbeta2 - 90 * Math.PI / 180 : beta2 * Math.PI / 180 - dbeta2;

      // console.log('gamma: '+gamma);

      var dlxy = hypotenuse / Math.sin(beta3 * Math.PI / 180) * Math.sin(gamma);

      // console.log('dlxy: '+dlxy);

      return dlxy;
    },

    getXSeitenbauteil: function getXSeitenbauteil() {
      var name = this.get('name');
      var x3d = this.get('model').objectAt(0);
      var bauteile = x3d.get('bauteile');
      var item = x3d.get('bauteile').findBy('id', name);

      var omega = (180 - Number(x3d.get('beta1'))) * Math.PI / 180;

      return this.get_dlxy() * Math.cos(omega);
    },

    getYSeitenbauteil: function getYSeitenbauteil() {
      var name = this.get('name');
      var x3d = this.get('model').objectAt(0);
      var bauteile = x3d.get('bauteile');
      var item = x3d.get('bauteile').findBy('id', name);

      var omega = (180 - Number(x3d.get('beta1'))) * Math.PI / 180;

      return this.get_dlxy() * Math.sin(omega);
    },

    getMassketteHoehe: (function () {
      var value = false;
      var x3d = this.get('model').objectAt(0);
      var bauteile = x3d.get('bauteile');

      if (x3d.get('ergebnisGeladen') !== true && (x3d.get('abstaendeAnzeigen') && (this.get('name') === 'nebenTraeger' || this.get('name') === 'hauptTraeger1' || this.get('name') === 'mittelbauteil' || this.get('name') === 'seitenbauteil0' && Number(x3d.get('vWinkel')) === 0) && bauteile.findBy('id', 'viewpointOben').get('cameraProperties').objectAt(0).get('setBind') !== true)) {
        value = true;
      }

      return value;
    }).property('model.firstObject.lastViewpoint', 'model.firstObject.abstaendeAnzeigen'),

    getMassketteBreite: (function () {
      var value = false;
      var x3d = this.get('model').objectAt(0);
      var bauteile = x3d.get('bauteile');

      if (x3d.get('ergebnisGeladen') !== true && (x3d.get('abstaendeAnzeigen') && (this.get('name') === 'nebenTraeger' || this.get('name') === 'hauptTraeger1' || this.get('name') === 'mittelbauteil' || this.get('name') === 'seitenbauteil0' || this.get('name') === 'verstaerkungBlass1' || this.get('name') === 'verstaerkungWerner1' && x3d.get('sFolgeHolzHolz') === true) && bauteile.findBy('id', 'viewpointFront').get('cameraProperties').objectAt(0).get('setBind') !== true)) {
        value = true;
      }

      return value;
    }).property('model.firstObject.lastViewpoint', 'model.firstObject.abstaendeAnzeigen'),

    getMassketteSetztiefe: (function () {
      var value = false;
      var name = this.get('name');
      var x3d = this.get('model').objectAt(0);
      var bauteile = x3d.get('bauteile');

      if (x3d.get('abstaendeAnzeigen') && (this.get('name') === 'hauptTraeger1' && Number(bauteile.findBy('id', 'schraubeStandard1').get('screwProperties').objectAt(0).get('setztiefe')) !== 0) && bauteile.findBy('id', 'viewpointFront').get('cameraProperties').objectAt(0).get('setBind') !== true) {
        value = true;
      }

      return value;
    }).property('model.firstObject.transformHelper', 'model.firstObject.lastViewpoint', 'model.firstObject.abstaendeAnzeigen'),

    getscherkraftPfeil: (function () {
      var value = false;
      var name = this.get('name');
      var x3d = this.get('model').objectAt(0);
      var bauteile = x3d.get('bauteile');

      if ((this.get('name') === 'hauptTraeger1' || name === 'seitenbauteil0') && x3d.get('scherkraftEingetragen') === true && bauteile.findBy('id', 'viewpointLinks').get('cameraProperties').objectAt(0).get('setBind') !== true) {
        value = true;
      }

      return value;
    }).property('model.firstObject.transformHelper', 'model.firstObject.lastViewpoint')

  });

  exports['default'] = Transform;

  // import Ember from 'ember';
  //
  // var Transform = Ember.Component.extend({
  //
  //   tagName: 'transform',
  //   layoutName: 'traege-r',
  //   attributeBindings: ['coordIndex', 'colorindex', 'colorPerVertex', 'ccw'],
  //   classNames: ['testClassNameXXX'],
  //   colorPerVertex: "false",
  //   bboxsize: "-1,-1,-1",
  //   ccw: "true",
  //
  //   objektSkalierung: 1,
  //
  //   ueberstand: 2,
  //
  //   yDeltaOben: 0,
  //   yDeltaUnten: 0,
  //
  //   translation: function () {
  //     var name = this.get('name');
  //     var x3d = this.get('model').objectAt(0);
  //     var bauteile = x3d.get('bauteile');
  //     var item = bauteile.findBy('id', name);
  //
  //     var values = '0 0 0';
  //
  //     var xVerschiebung = 0, yVerschiebung = 0, zVerschiebung = 0, verstaerkung = 0, deltaVerstaerkung = 0;
  //
  //     if(x3d.get('verstaerkungBlass') === true && x3d.get('verstaerkungWerner') === false){
  //       verstaerkung = Number(x3d.get('dickeBlass'))/10;
  //       deltaVerstaerkung = verstaerkung;
  //     }else if(x3d.get('verstaerkungBlass') === true && (x3d.get('verstaerkungWerner') === true && x3d.get('sFolgeHolzHolz') === true)){
  //       verstaerkung = Number(x3d.get('dickeWerner'))/10;
  //       deltaVerstaerkung = 2*verstaerkung;
  //     }else if(x3d.get('verstaerkungBlass') === true && (x3d.get('verstaerkungWerner') === true && x3d.get('sFolgeHolzHolz') === false)){
  //       verstaerkung = Number(x3d.get('dickeWerner'))/10;
  //       deltaVerstaerkung = verstaerkung;
  //     }
  //
  //     switch (name) {
  //       case 'nebenTraeger':
  //         xVerschiebung = item.get('translations').objectAt(0).get('x')/10;
  //         yVerschiebung = -(bauteile.findBy('id', 'nebenTraeger').get('boxsizes').objectAt(0).get('y')/10 - bauteile.findBy('id', 'hauptTraeger1').get('boxsizes').objectAt(0).get('y')/10)/2;
  //         zVerschiebung = item.get('translations').objectAt(0).get('z')/10;
  //       break;
  //       case 'verstaerkungBlass1':
  //         xVerschiebung = item.get('translations').objectAt(0).get('x')/10;
  //         yVerschiebung = -(bauteile.findBy('id', 'nebenTraeger').get('boxsizes').objectAt(0).get('y')/10 - bauteile.findBy('id', 'hauptTraeger1').get('boxsizes').objectAt(0).get('y')/10)/2
  //         zVerschiebung = verstaerkung/2 + bauteile.findBy('id', 'nebenTraeger').get('boxsizes').objectAt(0).get('z')/20;
  //       break;
  //       case 'verstaerkungBlass2':
  //         xVerschiebung = item.get('translations').objectAt(0).get('x')/10;
  //         yVerschiebung = -(bauteile.findBy('id', 'nebenTraeger').get('boxsizes').objectAt(0).get('y')/10 - bauteile.findBy('id', 'hauptTraeger1').get('boxsizes').objectAt(0).get('y')/10)/2
  //         zVerschiebung = -(verstaerkung/2 + bauteile.findBy('id', 'nebenTraeger').get('boxsizes').objectAt(0).get('z')/20);
  //       break;
  //       case 'bBox':
  //         xVerschiebung = 0;
  //         yVerschiebung = -item.get('boxsizes').objectAt(0).get('y')/20;
  //         zVerschiebung = 0;
  //       break;
  //     }
  //
  //     values = (xVerschiebung + " " + yVerschiebung + " " + zVerschiebung);
  //
  //     return values;
  //   }.property('model.firstObject.transformHelper', 'model.firstObject.istHt', 'model.firstObject.schraubenGekreuzt'),
  //
  //   rotation: function() {
  //     var name = this.get('name');
  //     var x3d = this.get('model').objectAt(0);
  //     var bauteile = x3d.get('bauteile');
  //     var values =  '0 0 0 0';
  //
  //
  //     return values;
  //   }.property('model.firstObject.transformHelper', 'model.firstObject.vWinkel', 'model.firstObject.hWinkel', 'model.firstObject.schraubenGekreuzt'),
  //
  //   koordinaten: function(){
  //     var name = this.get('name');
  //     var typ = this.get('typ');
  //     var x3d = this.get('model').objectAt(0);
  //     var bauteile = x3d.get('bauteile');
  //
  //     var item = x3d.get('bauteile').findBy('id', name);
  //
  //     var p0 = '0 0 0', p1 = '0 0 0', p2 = '0 0 0', p3 = '0 0 0', p4 = '0 0 0', p5 = '0 0 0', p6 = '0 0 0', p7 = '0 0 0', p8 = '0 0 0', p9 = '0 0 0';
  //
  //     // if(name.substring(0, 12) === 'hauptTraeger' || name.substring(0, 18) === 'verstaerkungWerner'){
  //
  //       var x = item.get('boxsizes').objectAt(0).get('x')/10;
  //       var y = item.get('boxsizes').objectAt(0).get('y')/10;
  //       var z = item.get('boxsizes').objectAt(0).get('z')/10;
  //
  //       var deltaXnt = 0;
  //       var xMittelbauteil = 0;
  //       var xSeitenbauteilUnten = 0;
  //       var xPlusSeitenbauteilUnten = 0;
  //       var xSeitenbauteilOben = 0;
  //
  //       var ueberstand = 2;
  //
  //       var x1 = 0, x2 = 0;
  //
  //       var deltaXnt = Math.abs(x3d.get('xSeitenbauteil')/10) + ueberstand;
  //
  //       var b1positiv = Number(x3d.get('b1')) >= 0 ? (this.getXSeitenbauteilUnten() + this.getXZusatsDurchA('b1')) : deltaXnt;
  //       var b1negativ = Number(x3d.get('b1')) < 0 ? (this.getXSeitenbauteilUnten() + Math.abs(this.getXZusatsDurchA('b1'))) : deltaXnt;
  //       var b2positiv = Number(x3d.get('b2')) >= 0 ? (this.getXSeitenbauteilOben() + this.getXZusatsDurchA('b2')) : deltaXnt;
  //       var b2negativ = Number(x3d.get('b2')) < 0 ? (this.getXSeitenbauteilOben() + Math.abs(this.getXZusatsDurchA('b2'))) : deltaXnt;
  //
  //       xSeitenbauteilOben = this.getXSeitenbauteilOben();
  //       xSeitenbauteilUnten = this.getXSeitenbauteilUnten();
  //       xPlusSeitenbauteilUnten = this.getXDeltaSeitenbauteilUnten(xSeitenbauteilUnten);
  //
  //       var p0 = (-b2negativ) +' '+ (0+Number(this.get('yDeltaUnten'))) +' '+ -z/2;
  //       var p1 = (-b2negativ) +' '+ (0+Number(this.get('yDeltaUnten'))) +' '+ z/2;
  //       var p2 = (b1positiv) +' '+ (0+Number(this.get('yDeltaUnten'))) +' '+ z/2;
  //       var p3 = (b1positiv) +' '+ (0+Number(this.get('yDeltaUnten'))) +' '+ -z/2;
  //       var p4 = (-b1negativ) +' '+ (y-Number(this.get('yDeltaOben'))) +' '+ -z/2;
  //       var p5 = (-b1negativ) +' '+ (y-Number(this.get('yDeltaOben'))) +' '+ z/2;
  //       var p6 = (b2positiv) +' '+ (y-Number(this.get('yDeltaOben'))) +' '+ z/2;
  //       var p7 = (b2positiv) +' '+ (y-Number(this.get('yDeltaOben'))) +' '+ -z/2;
  //       var p8 = 0+' '+0+' '+0;
  //       var p9 = 0+' '+0+' '+0;
  //
  //       if(x3d.get('ausmittigerstabanschluss') === true && (name.substring(0, 12) === 'hauptTraeger' || name.substring(0, 18) === 'verstaerkungWerner')){
  //         p8 = (-deltaXnt-(xSeitenbauteilUnten)-this.getXExzentrizitaet('x')) +' '+ (this.getXExzentrizitaet('y')) +' '+ -z/2;
  //         p9 = (-deltaXnt-(xSeitenbauteilUnten)-this.getXExzentrizitaet('x')) +' '+ (this.getXExzentrizitaet('y')) +' '+ z/2;
  //       }
  //     // }
  //
  //     var cords = p0 +', '+ p1 +', '+ p2 +', '+ p3 +', '+ p4 +', '+ p5 +', '+ p6 +', '+ p7 +', '+ p8 +', '+ p9;
  //
  //     return cords;
  //   }.property('model.firstObject.transformHelper'),
  //
  //   coordIndex: function(){
  //     var name = this.get('name');
  //     var typ = this.get('typ');
  //     var x3d = this.get('model').objectAt(0);
  //     var bauteile = x3d.get('bauteile');
  //     var values ='';
  //
  //     var item = x3d.get('bauteile').findBy('id', name);
  //
  //     var values = "0 3 2 1 0 -1 1 2 6 5 1 -1 4 5 6 7 4 -1 0 4 7 3 0 -1 0 1 5 4 0 -1 2 3 7 6 2 -1";
  //
  //     if(x3d.get('ausmittigerstabanschluss') === true && (name.substring(0, 12) === 'hauptTraeger' || name.substring(0, 18) === 'verstaerkungWerner')){
  //       values = "0 3 2 1 0 -1 1 2 6 5 9 1 -1 4 5 6 7 4 -1 0 8 4 7 3 0 -1 0 1 9 8 0 -1 4 8 9 5 4 -1 2 3 7 6 2 -1";
  //     }else if(x3d.get('ausmittigerstabanschluss') === true && name.substring(0, 13) === 'seitenbauteil'){
  //       values = "0 8 3 2 1 0 -1 4 5 6 7 9 4 -1 0 4 9 8 0 -1 0 1 5 4 0 -1 1 2 6 5 1 -1 2 3 7 6 2 -1 8 9 7 3 8 -1";
  //     }
  //
  //     return values;
  //   }.property('model.firstObject.transformHelper', 'model.firstObject.istHt'),
  //
  //   colorindex: function(){
  //
  //     var values = "0 0 0 0 0 0 0";
  //
  //     return values;
  //   }.property('model.firstObject.transformHelper', 'model.firstObject.istHt'),
  //
  //   farbe: function(){
  //
  //     var name = this.get('name');
  //     var typ = this.get('typ');
  //     var x3d = this.get('model').objectAt(0);
  //     var bauteile = x3d.get('bauteile');
  //
  //     var item = x3d.get('bauteile').findBy('id', name);
  //     var values = item.get('diffusecolors').objectAt(0).get('rot') +" "+ item.get('diffusecolors').objectAt(0).get('gruen') +" "+ item.get('diffusecolors').objectAt(0).get('blau');
  //
  //     return values;
  //   }.property('model.firstObject.transformHelper', 'model.firstObject.istHt'),
  //
  //   getXSeitenbauteilOben: function() {
  //     var name = this.get('name');
  //
  //     var x3d = this.get('model').objectAt(0);
  //     var bauteile = x3d.get('bauteile');
  //
  //     var hDiag = bauteile.findBy('id', 'hauptTraeger1').get('boxsizes').objectAt(0).get('y');
  //     var hGurt = bauteile.findBy('id', 'nebenTraeger').get('boxsizes').objectAt(0).get('y');;
  //
  //     var alfa = parseFloat(x3d.get('phi'));
  //     var beta = parseFloat(x3d.get('winkelEndAbschnittDiagonaleGRAD'));
  //     var ueDiagVert = parseFloat(x3d.get('ueberstandDiagonaleVertikal'));
  //
  //     var value = 0;
  //
  //     if(alfa !== 0 && alfa !== 90){
  //       var exzentrizitaet = 0;
  //
  //       if(x3d.get('ausmittigerstabanschluss') === true){
  //         exzentrizitaet = x3d.get('exzentrizitaetDiagonale');
  //       }else{
  //         exzentrizitaet = (Number((hDiag/2) / Math.sin(alfa * Math.PI / 180)) + Number((hGurt/2) / Math.tan(alfa * Math.PI / 180)));
  //
  //         if(Number(x3d.get('winkelEndAbschnittGRAD')) === 1 && Number(ueDiagVert) > hDiag * Math.cos(alfa * Math.PI / 180)){
  //           exzentrizitaet = exzentrizitaet + (ueDiagVert - hDiag*Math.cos(alfa * Math.PI / 180))/Math.tan(alfa * Math.PI / 180);
  //         }else if(Number(x3d.get('winkelEndAbschnittGRAD')) === 2){
  //           exzentrizitaet = exzentrizitaet + ueDiagVert/Math.tan(alfa * Math.PI / 180);
  //         }
  //
  //       }
  //
  //       value = (exzentrizitaet / Math.cos(alfa*Math.PI/180) - hDiag/2 * Math.tan(alfa * Math.PI / 180))/10;
  //
  //     }else if(alfa === 90){
  //       value = hGurt/20 + ueDiagVert/10;
  //     }else{
  //       value = Math.abs(x3d.get('xSeitenbauteil')/10) + Math.sin(alfa * Math.PI / 180) * ueDiagVert/10;
  //     }
  //
  //     if(value < -(Math.abs(x3d.get('xMittelbauteil')/10) + Number(this.get('ueberstand')))){
  //       var xOben = Math.abs(value) - Math.abs(Math.abs(x3d.get('xMittelbauteil')/10) + Number(this.get('ueberstand')));
  //
  //       var yDeltaOben = Math.abs(xOben) / Math.tan(alfa*Math.PI/180);
  //
  //       this.set('yDeltaOben', yDeltaOben);
  //
  //       value = -(Math.abs(x3d.get('xMittelbauteil')/10) + Number(this.get('ueberstand')));
  //
  //     }else{
  //       this.set('yDeltaOben', 0);
  //     }
  //
  //     return value;
  //   },
  //
  //   getXZusatsDurchA: function(parameter){
  //     var name = this.get('name');
  //
  //     var x3d = this.get('model').objectAt(0);
  //     var bauteile = x3d.get('bauteile');
  //
  //     var a = Number(x3d.get(parameter))/10;
  //
  //     var phi = parseFloat(x3d.get('phi')) * Math.PI / 180;
  //
  //     var value = Number(x3d.get('phi')) !== 0 ? a/Math.sin(phi) : 0;
  //
  //     return value;
  //   },
  //
  //   getXSeitenbauteilUnten: function() {
  //     var name = this.get('name');
  //
  //     var x3d = this.get('model').objectAt(0);
  //     var bauteile = x3d.get('bauteile');
  //
  //     var hDiag = bauteile.findBy('id', 'hauptTraeger1').get('boxsizes').objectAt(0).get('y');
  //     var hGurt = bauteile.findBy('id', 'nebenTraeger').get('boxsizes').objectAt(0).get('y');
  //
  //     var alfa = parseFloat(x3d.get('phi'));
  //     var beta = parseFloat(x3d.get('winkelEndAbschnittDiagonaleGRAD'));
  //     var ueDiagVert = parseFloat(x3d.get('ueberstandDiagonaleVertikal'));
  //
  //     var exzentrizitaet = x3d.get('exzentrizitaetDiagonale');
  //
  //     var v1 = 0, v2 = 0, l = 0, value = 0;
  //
  //     v1 = (hDiag/2)/Math.sin(alfa * Math.PI / 180) + (hGurt/2)/Math.tan(alfa * Math.PI / 180) - exzentrizitaet;
  //     v2 = hDiag/Math.sin(alfa * Math.PI / 180);
  //
  //     l = v2 - v1;
  //
  //
  //     if(alfa !== 0 && alfa !== 90){
  //
  //       value = ((hGurt/2) / Math.sin(alfa * Math.PI / 180)) - ((v2 - (hDiag/2)/Math.sin(alfa * Math.PI / 180)) * Math.cos(alfa * Math.PI / 180));
  //
  //       if(x3d.get('ausmittigerstabanschluss') === false){
  //
  //         if(Number(x3d.get('winkelEndAbschnittGRAD')) === 1 && Number(ueDiagVert) >= Number((hDiag * Math.cos(alfa * Math.PI / 180)).toFixed(1))){
  //           value = value + hDiag/Math.tan(alfa * Math.PI / 180) + (ueDiagVert - hDiag*Math.cos(alfa * Math.PI / 180))/Math.sin(alfa * Math.PI / 180);
  //         }else if(Number(x3d.get('winkelEndAbschnittGRAD')) === 2){
  //           value = value + ueDiagVert/Math.sin(alfa * Math.PI / 180);
  //         }
  //
  //       }else if(x3d.get('ausmittigerstabanschluss') === true){
  //
  //         if(Number(x3d.get('winkelEndAbschnittGRAD')) === 1){
  //           // value = value + (l-ueDiagVert/Math.tan(alfa * Math.PI / 180)) * Math.cos(alfa * Math.PI / 180) + ueDiagVert/Math.sin(alfa * Math.PI / 180);
  //           value = value + ueDiagVert/Math.sin(alfa * Math.PI / 180);
  //         }else if(Number(x3d.get('winkelEndAbschnittGRAD')) === 2){
  //           value = value + ueDiagVert/Math.sin(alfa * Math.PI / 180);
  //         }
  //
  //         if(value >= Number(((hDiag/2 + exzentrizitaet/Math.sin(alfa * Math.PI / 180)) * Math.tan(alfa * Math.PI / 180)).toFixed(1))){
  //           value = (hDiag/2 + exzentrizitaet/Math.sin(alfa * Math.PI / 180)) * Math.tan(alfa * Math.PI / 180);
  //         }
  //       }
  //
  //       value = value/10
  //     }else if(alfa === 90){
  //       value = hGurt/20 + ueDiagVert/10;
  //     }else{
  //       value = Math.abs(x3d.get('xSeitenbauteil')/10) + Math.sin(alfa * Math.PI / 180) * ueDiagVert/10;
  //     }
  //
  //     if(value < -(Math.abs(x3d.get('xMittelbauteil')/10) + Number(this.get('ueberstand')))){
  //       var xUnten = Math.abs(value) - Math.abs(Math.abs(x3d.get('xMittelbauteil')/10) + Number(this.get('ueberstand')));
  //
  //       var yDeltaUnten = Math.abs(xUnten) * Math.tan(alfa*Math.PI/180);
  //
  //       this.set('yDeltaUnten', yDeltaUnten);
  //
  //     }else{
  //       this.set('yDeltaUnten', 0);
  //     }
  //
  //     return value;
  //   },
  //
  //   getXDeltaSeitenbauteilUnten: function(value){
  //
  //     var name = this.get('name');
  //
  //     var x3d = this.get('model').objectAt(0);
  //
  //     if(value < -(Math.abs(x3d.get('xMittelbauteil')/10) + Number(this.get('ueberstand')))){
  //
  //       value = -(Math.abs(x3d.get('xMittelbauteil')/10) + Number(this.get('ueberstand')));
  //
  //     }
  //
  //     return value;
  //   },
  //
  //   getXExzentrizitaet:function(achse){
  //     var name = this.get('name');
  //
  //     var x3d = this.get('model').objectAt(0);
  //     var bauteile = x3d.get('bauteile');
  //
  //     var alfa = parseFloat(x3d.get('phi'));
  //     var beta = parseFloat(x3d.get('winkelEndAbschnittDiagonaleGRAD'));
  //
  //     var ueDiagVert = parseFloat(x3d.get('ueberstandDiagonaleVertikal'));
  //
  //     var hDiag = bauteile.findBy('id', 'hauptTraeger1').get('boxsizes').objectAt(0).get('y');
  //     var hGurt = bauteile.findBy('id', 'nebenTraeger').get('boxsizes').objectAt(0).get('y');;
  //     var exzentrizitaet = Number(x3d.get('exzentrizitaetDiagonale'));
  //
  //     var v1 = 0, v2 = 0, l = 0, value = 0;
  //
  //     v1 = hDiag/2/Math.sin(alfa * Math.PI / 180) + hGurt/2/Math.tan(alfa * Math.PI / 180) - exzentrizitaet;
  //     v2 = hDiag/Math.sin(alfa * Math.PI / 180);
  //
  //     l = v2 - v1;
  //
  //     if(l - ueDiagVert/Math.tan(alfa * Math.PI / 180) >= 0){
  //       if(achse === 'x'){
  //
  //         if(Number(x3d.get('winkelEndAbschnittGRAD')) === 1){
  //           value = 0;
  //         }else if(Number(x3d.get('winkelEndAbschnittGRAD')) === 2){
  //           value = (l - ueDiagVert/Math.tan(alfa * Math.PI / 180)) * Math.cos(alfa * Math.PI / 180);
  //         }
  //
  //       }else if(achse === 'y'){
  //
  //         if(Number(x3d.get('winkelEndAbschnittGRAD')) === 1){
  //           value = (l - ueDiagVert/Math.tan(alfa * Math.PI / 180)) / Math.sin(alfa * Math.PI / 180);
  //         }else if(Number(x3d.get('winkelEndAbschnittGRAD')) === 2){
  //           value = (l - ueDiagVert/Math.tan(alfa * Math.PI / 180)) * Math.sin(alfa * Math.PI / 180) ;
  //         }
  //
  //       }
  //     }
  //
  //     value = value/10;
  //
  //     return value;
  //   },
  //
  //   koordinatenFaserrichtungsPfeil: function(){
  //
  //     var name = this.get('name');
  //     var x3d = this.get('model').objectAt(0);
  //     var bauteile = x3d.get('bauteile');
  //     var item = bauteile.findBy('id', name);
  //
  //     var delta = 0.15;
  //
  //     var ueberstand = Number(this.get('ueberstand'));
  //
  //     var x = 0, y = 0, z = 0;
  //
  //     var winkel = 0;
  //
  //     if(item.get('typ') === 'box'){
  //       x = bauteile.findBy('id', name).get('boxsizes').objectAt(0).get('x')/20;
  //       y = 0.15;
  //       z = (item.get('boxsizes').objectAt(0).get('z'))/20;
  //
  //       var xSeitenbauteil = Math.abs(Number(x3d.get('xSeitenbauteil')))/10;
  //       var xMittelbauteil = Math.abs(Number(x3d.get('xMittelbauteil')))/10;
  //
  //       if(name === 'hauptTraeger1' || name === 'hauptTraeger2'){
  //         x = -x/2 - ueberstand + delta;
  //       }else{
  //         x = x + xMittelbauteil/2;
  //       }
  //
  //     }else if(item.get('typ') === 'platte'){
  //
  //         x = -Number(item.get('punkt').objectAt(1).get('x'))/10;
  //         y = Number(item.get('punkt').objectAt(1).get('y'))/10 + 0.15;
  //         z = Number(item.get('punkt').objectAt(1).get('z'))/10 + item.get('platte').objectAt(0).get('thickness')/10;
  //
  //         if (name.substring(0, 21) === 'verstaerkungdiagonale' || name.substring(0, 13) === 'seitenbauteil'){
  //           winkel = x3d.get('phi')*Math.PI/180;
  //         }
  //     }
  //
  //     var  cords = (-x+this.deltaX(0.15+delta, delta*2, winkel)) +' '+ (y+this.deltaY(0.15+delta, delta*2, winkel)) +' '+ (z) +', '+ (-x+this.deltaX(delta, delta, winkel)) +' '+ (y+this.deltaY(delta, delta, winkel)) +' '+ (z) + ', '+ (-x+this.deltaX(1, delta, winkel)) +' '+ (y+this.deltaY(1, delta, winkel)) +' '+ (z) +', '+ (-x+this.deltaX(0.85, 0, winkel)) +' '+ (y+this.deltaY(0.85, 0, winkel)) +' '+ (z);
  //
  //     return cords;
  //   }.property('model.firstObject.transformHelper'),
  //
  //   deltaX: function(x, y, winkel){
  //     var erg = x * Math.cos(winkel) - y * Math.sin(winkel);
  //     return erg;
  //   },
  //
  //
  //   deltaY: function(x, y, winkel){
  //     var erg = x * Math.sin(winkel) + y * Math.cos(winkel);
  //     return erg;
  //   },
  //
  //   transparency: function () {
  //
  //     var name = this.get('name');
  //     var x3d = this.get('model').objectAt(0);
  //     var bauteile = x3d.get('bauteile');
  //     var value;
  //
  //     var item = bauteile.findBy('id', name)
  //
  //     if ((item.get('typ')=== 'box' || item.get('typ')=== 'platte') && (!x3d.get('istGitterModell'))){
  //       value = item.get('appearances').objectAt(0).get('transparency');
  //     }else if((item.get('typ')=== 'box' || item.get('typ')=== 'platte') && (x3d.get('istGitterModell'))){
  //       value = 1;
  //     }else{
  //       value = 0;
  //     }
  //
  //     return value;
  //
  //   }.property('model.x3d.bauteile', 'model.firstObject.istGitterModell' ),
  //
  //
  // });
  //
  // export default Transform;

});