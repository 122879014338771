define('m03/components/uscheib-e', ['exports', 'ember'], function (exports, Ember) {

  'use strict';

  var Transform = Ember['default'].Component.extend({

    tagName: 'transform',
    layoutName: 'uscheib-e',
    attributeBindings: [],
    classNames: ['testClassNameXXX'],
    colorPerVertex: "false",
    bboxsize: "-1,-1,-1",
    ccw: "true",

    objektSkalierung: 1,

    segmente: 32,

    ueberstand: 2,

    yDeltaOben: 0,
    yDeltaUnten: 0,

    innenradius: function innenradius() {
      var name = this.get('name');
      var item = this.get('model').objectAt(0).get('bauteile').findBy('id', name);

      return item.get('screwProperties').objectAt(0).get('diameter') / 2;
    },
    aussenradius: function aussenradius() {
      var name = this.get('name');
      var item = this.get('model').objectAt(0).get('bauteile').findBy('id', name);
      var uScheibe = this.get('model').objectAt(0).get('bauteile').findBy('id', "uScheibeStandard1");

      return Number(item.get('screwProperties').objectAt(0).get('diameter')) / 2 * Number(uScheibe.get('abmessungen').objectAt(0).get('aussenradius'));
    },
    hoehe: function hoehe() {
      var name = this.get('name');
      var item = this.get('model').objectAt(0).get('bauteile').findBy('id', name);
      var uScheibe = this.get('model').objectAt(0).get('bauteile').findBy('id', "uScheibeStandard1");

      var hoehe = Number(item.get('screwProperties').objectAt(0).get('diameter')) * Number(uScheibe.get('abmessungen').objectAt(0).get('hoehe')) - 0.02;

      console.log("hoehe: " + hoehe);

      return hoehe;
    },

    schraubenLaenge: function schraubenLaenge() {
      // Die Schraubenänge sollte ein vielfaches von 6 sein
      // da eine Schraubenumdrehung aus 6 Stufen besteht
      var name = this.get('name');
      var bauteile = this.get('model').objectAt(0).get('bauteile');
      var x = 0;

      x = bauteile.findBy('id', name).get('screwProperties').objectAt(0).get('length');

      var item = this.get('model').objectAt(0).get('bauteile').findBy('id', name);

      var headID = Number(item.get('screwProperties').objectAt(0).get('headformID'));

      if ((headID === 1 || headID === 2 || headID === 3 || headID === 7 || headID === 10 || headID === 11 || headID === 13) && Number(bauteile.findBy('id', name).get('screwProperties').objectAt(0).get('headheight')) > 0.2) {} else if (Number(bauteile.findBy('id', name).get('screwProperties').objectAt(0).get('headheight')) < 0.2) {
        x = x + 0.05;
      } else {
        x = x - Number(bauteile.findBy('id', 'uScheibeStandard1').get('abmessungen').objectAt(0).get('hoehe')) * Number(bauteile.findBy('id', 'schraubeStandard1').get('screwProperties').objectAt(0).get('diameter'));
      }

      return Math.abs(x);
    },

    translation: (function () {
      var self = this;
      var name = self.get('name');
      var x3d = self.get('model').objectAt(0);
      var bauteile = x3d.get('bauteile');

      var hoehe = self.hoehe() / 10;

      var verstaerkung = 0;

      if (x3d.get('verstaerkungBlass') === true && x3d.get('verstaerkungWerner') === false) {
        verstaerkung = Number(x3d.get('dickeBlass')) / 10;
      } else if (x3d.get('verstaerkungBlass') === true && (x3d.get('verstaerkungWerner') === true && x3d.get('sFolgeHolzHolz') === true)) {
        verstaerkung = Number(x3d.get('dickeWerner')) / 10;
      } else if (x3d.get('verstaerkungBlass') === true && (x3d.get('verstaerkungWerner') === true && x3d.get('sFolgeHolzHolz') === false)) {
        verstaerkung = Number(x3d.get('dickeWerner')) / 10;
      }

      var x = 0;
      var y = self.schraubenLaenge() / 10 + hoehe / 2;
      var z = 0;

      if (x3d.get('ergebnisGeladen') === true) {
        x = bauteile.findBy('id', name).get('punkt').objectAt(0).get('x') / 10;
        y = bauteile.findBy('id', name).get('punkt').objectAt(0).get('y') / 10;
        z = bauteile.findBy('id', name).get('punkt').objectAt(0).get('z') / 10;
      } else {
        // x = bauteile.findBy('id', name).get('translations').objectAt(0).get('x')/10;
        // y = bauteile.findBy('id', 'hauptTraeger1').get('boxsizes').objectAt(0).get('y')/20;
      }

      var values = x + " " + y + " " + z;

      return values;
    }).property('model.firstObject.transformHelper', 'model.firstObject.uScheibe'),

    rotation: (function () {
      var self = this;
      var name = this.get('name');
      var x3d = this.get('model').objectAt(0);
      var bauteile = x3d.get('bauteile');

      var values = '';
      var drehwinckelYAchse = 0,
          drehwinckelZAchse = 0;

      if (x3d.get('ergebnisGeladen')) {

        var x = bauteile.findBy('id', name).get('richtungsvektor').objectAt(0).get('x');
        var y = bauteile.findBy('id', name).get('richtungsvektor').objectAt(0).get('y');
        var z = bauteile.findBy('id', name).get('richtungsvektor').objectAt(0).get('z');

        drehwinckelYAchse = self.getWinkel({ x: x, y: 0, z: z }, { x: 0, y: 0, z: 1 });
        drehwinckelZAchse = self.getWinkel({ x: x, y: y, z: z }, { x: 0, y: 1, z: 0 });
      } else {
        drehwinckelZAchse = Number(this.get('model').objectAt(0).get('bauteile').findBy('id', name).get('rotations').objectAt(0).get('z'));
      }

      var xWertRotationsachse = Math.cos(drehwinckelYAchse);
      var zWertRotationsachse = -Math.sin(drehwinckelYAchse);

      values = xWertRotationsachse + ' 0 ' + zWertRotationsachse + ' ' + drehwinckelZAchse;

      return values;
    }).property('model.firstObject.transformHelper', 'model.firstObject.uScheibe'),

    koordinaten: (function () {
      var self = this;
      var name = self.get('name');
      var typ = self.get('typ');
      // var x3d = self.get('model').objectAt(0);
      var x3d = self.get('model').objectAt(0);
      var bauteile = x3d.get('bauteile');
      var item = x3d.get('bauteile').findBy('id', name);

      var rinnen = self.innenradius() / 10;
      var raussen = self.aussenradius() / 10;
      var hoehe = self.hoehe() / 10;
      var segmente = self.get("segmente");

      var omega = 360 * Math.PI / 180 / segmente;
      var gamma = omega / 2;

      var p0 = '0 0 0',
          p1 = '0 0 0',
          p2 = '0 0 0',
          p3 = '0 0 0',
          p4 = '0 0 0',
          p5 = '0 0 0',
          p6 = '0 0 0',
          p7 = '0 0 0',
          p8 = '0 0 0',
          p9 = '0 0 0';

      p0 = -raussen * Math.tan(gamma) + ' ' + -raussen + ' ' + -hoehe / 2;
      p1 = -raussen * Math.tan(gamma) + ' ' + -raussen + ' ' + hoehe / 2;
      p2 = raussen * Math.tan(gamma) + ' ' + -raussen + ' ' + hoehe / 2;
      p3 = raussen * Math.tan(gamma) + ' ' + -raussen + ' ' + -hoehe / 2;
      p4 = -rinnen * Math.tan(gamma) + ' ' + -rinnen + ' ' + -hoehe / 2;
      p5 = -rinnen * Math.tan(gamma) + ' ' + -rinnen + ' ' + hoehe / 2;
      p6 = rinnen * Math.tan(gamma) + ' ' + -rinnen + ' ' + hoehe / 2;
      p7 = rinnen * Math.tan(gamma) + ' ' + -rinnen + ' ' + -hoehe / 2;

      var cords = p0 + ', ' + p1 + ', ' + p2 + ', ' + p3 + ', ' + p4 + ', ' + p5 + ', ' + p6 + ', ' + p7 + ', ' + p8 + ', ' + p9;

      return cords;
    }).property('model.firstObject.transformHelper', 'model.firstObject.uScheibe'),

    coordIndex: (function () {

      var values = "0 3 2 1 -1 1 2 6 5 -1 4 5 6 7 -1 0 4 7 3 -1";

      return values;
    }).property('model.firstObject.transformHelper'),

    colorindex: (function () {

      var values = "0 0 0 0 0 0 0";

      return values;
    }).property('model.firstObject.transformHelper'),

    farbe: (function () {
      // var name = this.get('name');
      var name = "uScheibeStandard1";
      var x3d = this.get('model').objectAt(0);
      var bauteile = x3d.get('bauteile');
      var item = bauteile.findBy('id', name);

      var values = item.get('diffusecolors').objectAt(0).get('rot') + " " + item.get('diffusecolors').objectAt(0).get('gruen') + " " + item.get('diffusecolors').objectAt(0).get('blau');

      // var values = "0.4 0.4 1.0";

      return values;
    }).property('model.firstObject.transformHelper'),

    segmentliste: (function () {
      var self = this;
      var liste = [];

      var segmente = self.get("segmente");
      var omega = 360 * Math.PI / 180 / segmente;

      for (var i = 1; i < segmente; i++) {
        liste.push({ id: i, rotation: "0 0 1 " + i * omega });
      }

      return liste;
    }).property('model.firstObject.transformHelper', 'model.firstObject.uScheibe'),

    drehzentrum: (function () {

      return "0 0 0";
    }).property('model.firstObject.transformHelper')

  });

  exports['default'] = Transform;

});