define('m03/templates/winkelbemassun-g', ['exports'], function (exports) {

  'use strict';

  exports['default'] = Ember.HTMLBars.template((function() {
    return {
      isHTMLBars: true,
      revision: "Ember@1.12.0",
      blockParams: 0,
      cachedFragment: null,
      hasRendered: false,
      build: function build(dom) {
        var el0 = dom.createDocumentFragment();
        var el1 = dom.createElement("shape");
        var el2 = dom.createTextNode("\n  ");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("appearance");
        var el3 = dom.createTextNode("\n    ");
        dom.appendChild(el2, el3);
        var el3 = dom.createElement("material");
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("\n  ");
        dom.appendChild(el2, el3);
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n  ");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("arc2D");
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n");
        dom.appendChild(el1, el2);
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n");
        dom.appendChild(el0, el1);
        var el1 = dom.createElement("transform");
        dom.setAttribute(el1,"rotation","0 0 0 0");
        dom.setAttribute(el1,"bboxcenter","0 0 0");
        dom.setAttribute(el1,"bboxsize","-1,-1,-1");
        dom.setAttribute(el1,"center","0 0 0");
        dom.setAttribute(el1,"scale","1,1,1");
        dom.setAttribute(el1,"scaleorientation","0,0,0,0");
        dom.setAttribute(el1,"render","true");
        var el2 = dom.createTextNode("\n  ");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("shape");
        var el3 = dom.createTextNode("\n    ");
        dom.appendChild(el2, el3);
        var el3 = dom.createElement("appearance");
        var el4 = dom.createTextNode("\n      ");
        dom.appendChild(el3, el4);
        var el4 = dom.createElement("material");
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("\n    ");
        dom.appendChild(el3, el4);
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("\n    ");
        dom.appendChild(el2, el3);
        var el3 = dom.createElement("text");
        dom.setAttribute(el3,"solid","false");
        dom.setAttribute(el3,"lit","false");
        dom.setAttribute(el3,"ccw","true");
        dom.setAttribute(el3,"usegeocache","true");
        var el4 = dom.createTextNode("\n      ");
        dom.appendChild(el3, el4);
        var el4 = dom.createElement("fontstyle");
        dom.setAttribute(el4,"family","Helvetica");
        dom.setAttribute(el4,"horizontal","true");
        dom.setAttribute(el4,"justify","middle");
        dom.setAttribute(el4,"lefttoright","true");
        dom.setAttribute(el4,"spacing","1.0");
        dom.setAttribute(el4,"toptobottom","true");
        dom.setAttribute(el4,"quality","10.0");
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("\n    ");
        dom.appendChild(el3, el4);
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("\n  ");
        dom.appendChild(el2, el3);
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n");
        dom.appendChild(el1, el2);
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n\n");
        dom.appendChild(el0, el1);
        var el1 = dom.createComment(" Ende des Bogens - wohl niucht benötigt ");
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n");
        dom.appendChild(el0, el1);
        var el1 = dom.createComment(" <transform>\n  <shape>\n    <appearance>\n      <material emissivecolor={{emissivecolor}} />\n    </appearance>\n      <indexedLineSet coordIndex={{koordinatenIndex}} >\n      <coordinate point={{koordinaten}} />\n    </indexedLineSet>\n  </shape>\n</transform> ");
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n");
        dom.appendChild(el0, el1);
        return el0;
      },
      render: function render(context, env, contextualElement) {
        var dom = env.dom;
        var hooks = env.hooks, get = hooks.get, attribute = hooks.attribute;
        dom.detectNamespace(contextualElement);
        var fragment;
        if (env.useFragmentCache && dom.canClone) {
          if (this.cachedFragment === null) {
            fragment = this.build(dom);
            if (this.hasRendered) {
              this.cachedFragment = fragment;
            } else {
              this.hasRendered = true;
            }
          }
          if (this.cachedFragment) {
            fragment = dom.cloneNode(this.cachedFragment, true);
          }
        } else {
          fragment = this.build(dom);
        }
        var element0 = dom.childAt(fragment, [0]);
        var element1 = dom.childAt(element0, [1, 1]);
        var element2 = dom.childAt(element0, [3]);
        var element3 = dom.childAt(fragment, [2]);
        var element4 = dom.childAt(element3, [1]);
        var element5 = dom.childAt(element4, [1, 1]);
        var element6 = dom.childAt(element4, [3]);
        var element7 = dom.childAt(element6, [1]);
        var attrMorph0 = dom.createAttrMorph(element1, 'emissivecolor');
        var attrMorph1 = dom.createAttrMorph(element2, 'radius');
        var attrMorph2 = dom.createAttrMorph(element2, 'startAngle');
        var attrMorph3 = dom.createAttrMorph(element2, 'endAngle');
        var attrMorph4 = dom.createAttrMorph(element3, 'translation');
        var attrMorph5 = dom.createAttrMorph(element5, 'diffusecolor');
        var attrMorph6 = dom.createAttrMorph(element6, 'string');
        var attrMorph7 = dom.createAttrMorph(element7, 'size');
        attribute(env, attrMorph0, element1, "emissivecolor", get(env, context, "emissivecolor"));
        attribute(env, attrMorph1, element2, "radius", get(env, context, "radius"));
        attribute(env, attrMorph2, element2, "startAngle", get(env, context, "anfangsWinkel"));
        attribute(env, attrMorph3, element2, "endAngle", get(env, context, "endWinkel"));
        attribute(env, attrMorph4, element3, "translation", get(env, context, "texttranslation"));
        attribute(env, attrMorph5, element5, "diffusecolor", get(env, context, "emissivecolor"));
        attribute(env, attrMorph6, element6, "string", get(env, context, "wert"));
        attribute(env, attrMorph7, element7, "size", get(env, context, "textgroesse"));
        return fragment;
      }
    };
  }()));

});