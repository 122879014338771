define('m03/components/winkelbemassun-g', ['exports', 'ember'], function (exports, Ember) {

  'use strict';

  var Transform = Ember['default'].Component.extend({

    tagName: 'transform',
    layoutName: 'winkelbemassun-g',
    attributeBindings: ['translation', 'rotation', 'bboxcenter', 'bboxsize', 'center', 'scale', 'scaleorientation'],
    classNames: ['testClassNameXXX'],
    bboxcenter: "0 0 0",
    bboxsize: "-1,-1,-1",
    center: "0 0 0",
    scale: "1,1,1",
    scaleorientation: "0,0,0,0",

    translation: (function () {
      var name = this.get('name');
      var x3d = this.get('model').objectAt(0);
      var bauteile = x3d.get('bauteile');
      var cords = '0 0 0';

      var xVerschiebung = 0,
          yVerschiebung = 0,
          zVerschiebung = 0;

      var vorzeichenBWerte = Number(x3d.get('vorzeichenBWerte'));

      var nebenTraeger = bauteile.findBy('id', 'nebenTraeger');
      var hauptTraeger = bauteile.findBy('id', 'hauptTraeger1');

      var xMittelbauteil = Number(nebenTraeger.get('boxsizes').objectAt(0).get('x')) / 10;
      var yMittelbauteil = Number(nebenTraeger.get('boxsizes').objectAt(0).get('y')) / 10;
      var zMittelbauteil = Number(nebenTraeger.get('boxsizes').objectAt(0).get('z')) / 10;

      var xSeitenbauteil = Number(hauptTraeger.get('boxsizes').objectAt(0).get('x')) / 10;
      var ySeitenbauteil = Number(hauptTraeger.get('boxsizes').objectAt(0).get('y')) / 10;
      var zSeitenbauteil = Number(hauptTraeger.get('boxsizes').objectAt(0).get('z')) / 10;

      if (name.substring(0, 17) === 'verstaerkungBlass' && x3d.get('verstaerkungBlass') === true && x3d.get('verstaerkungWerner') === true) {
        z = x3d.get('dickeWerner') / 10;
      }

      var Hg = yMittelbauteil;
      var Hd = Number(x3d.get('bauteile').findBy('id', 'hauptTraeger1').get('boxsizes').objectAt(0).get('y')) / 10;

      var deltaXht = 0;
      var xSeitenbauteilOben = 0;
      var xSeitenbauteilUnten = 0;

      var xMinusOberkanteMittelbauteil = 0;
      var xMinusUnterkanteMittelbauteil = 0;
      var xPlusOberkanteMittelbauteil = 0;
      var xPlusUnterkanteMittelbauteil = 0;

      var phi = Number(x3d.get('phi'));

      var b1 = Number(x3d.get('b1')) / 10;
      var b2 = Number(x3d.get('b2')) / 10;

      var tanPhi = Number(x3d.get('phi')) === 90 ? 1 : Number(x3d.get('phi')) === 0 ? 1 : Math.tan(phi * Math.PI / 180);
      var sinPhi = Number(x3d.get('phi')) === 0 ? 1 : Math.sin(phi * Math.PI / 180);

      var l1 = Hd / 2 / sinPhi + Hg / 2 / tanPhi;
      var l2 = Number(x3d.get('phi')) === 90 ? 0 : Number(x3d.get('phi')) === 0 ? 0 : Hd / sinPhi;

      xMinusOberkanteMittelbauteil = vorzeichenBWerte === -1 ? l2 - l1 + b2 : l1 + 1;
      xMinusUnterkanteMittelbauteil = vorzeichenBWerte === -1 ? l1 - b1 : l1 + 1;
      xPlusOberkanteMittelbauteil = vorzeichenBWerte === 1 ? l1 + b2 : l1;
      xPlusUnterkanteMittelbauteil = vorzeichenBWerte === 1 ? l2 - l1 + b1 : l1;

      switch (name) {
        case "alpha1":
          xVerschiebung = 0;
          yVerschiebung = 0;
          zVerschiebung = Number(bauteile.findBy('id', 'nebenTraeger').get('boxsizes').objectAt(0).get('z')) / 20 + Number(bauteile.findBy('id', 'hauptTraeger1').get('boxsizes').objectAt(0).get('z')) / 10;

          if (x3d.get('verstaerkungBlass') === true && x3d.get('verstaerkungWerner') === false) {
            zVerschiebung = zVerschiebung + x3d.get('dickeBlass') / 10;
          } else if (x3d.get('verstaerkungBlass') === true && x3d.get('verstaerkungWerner') === true) {
            zVerschiebung = zVerschiebung + x3d.get('dickeWerner') / 10 * 2;
          }

          break;
        case "alpha2":
          xVerschiebung = 0;
          yVerschiebung = 0;
          zVerschiebung = Number(bauteile.findBy('id', 'nebenTraeger').get('boxsizes').objectAt(0).get('z')) / 20 + Number(bauteile.findBy('id', 'hauptTraeger1').get('boxsizes').objectAt(0).get('z')) / 10;

          if (x3d.get('verstaerkungBlass') === true && x3d.get('verstaerkungWerner') === false) {
            zVerschiebung = zVerschiebung + x3d.get('dickeBlass') / 10;
          } else if (x3d.get('verstaerkungBlass') === true && x3d.get('verstaerkungWerner') === true) {
            zVerschiebung = zVerschiebung + x3d.get('dickeWerner') / 10 * 2;
          }

          break;
        case "beta1":
          xVerschiebung = -xMinusUnterkanteMittelbauteil;
          yVerschiebung = -(yMittelbauteil - ySeitenbauteil) / 2;
          zVerschiebung = zMittelbauteil / 2;
          break;
        case "beta2":
          xVerschiebung = 0;
          yVerschiebung = 0;
          zVerschiebung = 0;
          break;
      }

      cords = xVerschiebung + ' ' + yVerschiebung + ' ' + zVerschiebung;

      return cords;
    }).property('model.firstObject.arc2dPositionTriggern', 'model.firstObject.phi', 'model.firstObject.vWinkelScherkraft', 'model.firstObject.Transformhelper', 'model.firstObject.vWinkel'),

    rotation: (function () {
      var values = '0 0 0 0';
      return values;
    }).property('model.firstObject.arc2dPositionTriggern', 'model.firstObject.phi', 'model.firstObject.vWinkelScherkraft', 'model.firstObject.vWinkel'),

    anfangsWinkel: (function () {
      var name = this.get('name');
      var x3d = this.get('model').objectAt(0);
      var value = 0;

      if (name === 'beta1') {
        value = 0;
      } else if (name === 'beta2') {
        value = -Number(x3d.get(name)) * Math.PI / 180;
      }

      return value;
    }).property('model.firstObject.arc2dPositionTriggern', 'model.firstObject.phi', 'model.firstObject.vWinkelScherkraft', 'model.firstObject.vWinkel'),

    endWinkel: (function () {
      var name = this.get('name');
      var x3d = this.get('model').objectAt(0);
      var value = 0;

      if (name === 'beta1') {
        value = Number(x3d.get(name)) * Math.PI / 180;
      } else if (name === 'beta2') {
        value = 0;
      }

      return value;
    }).property('model.firstObject.arc2dPositionTriggern', 'model.firstObject.phi', 'model.firstObject.vWinkelScherkraft', 'model.firstObject.vWinkel'),

    radius: (function () {
      var value = 0.5;

      return value;
    }).property('model.firstObject.ergebnisGeladen', 'model.firstObject.arc2dPositionTriggern', 'model.firstObject.phi', 'model.firstObject.vWinkelScherkraft', 'model.firstObject.vWinkelScherkraftAktiv'),

    emissivecolor: (function () {
      var name = this.get('name');
      var typ = this.get('typ');
      var x3d = this.get('model').objectAt(0);

      var emsvcolor = '0 0 0';

      if (x3d.get('beta1Aktiv') === true && name === 'beta1' && typ === 'winkel') {
        emsvcolor = '1 0 0';
      }

      return emsvcolor;
    }).property('model.firstObject.arc2dPositionTriggern', 'model.firstObject.phiAktiv', 'model.firstObject.vWinkelScherkraftAktiv', 'model.firstObject.vWinkelAktiv'),

    getMittelwert: function getMittelwert(a, b) {
      return (a + b) / 2;
    },

    textgroesse: (function () {
      var skalierungsfaktor = this.get('model').objectAt(0).get('skalierungsfaktor');
      var value = 0.3 * skalierungsfaktor;

      return value;
    }).property('model.firstObject.skalierungsfaktor', 'model.firstObject.transformHelper'),

    wert: (function () {

      var name = this.get('name');
      var x3d = this.get('model').objectAt(0);

      return String(x3d.get(name));
    }).property('model.firstObject.transformHelper')

  });

  exports['default'] = Transform;

});