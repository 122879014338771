define('m03/overrides/select', ['exports', 'ember'], function (exports, Ember) {

  'use strict';

  exports['default'] = Ember['default'].SelectOption.reopen({
    attributeBindings: ['disabled'],

    init: function init() {
      this.disabledPathDidChange();
      this._super();
    },

    disabledPathDidChange: Ember['default'].observer('parentView.optionDisabledPath', function () {
      var valuePath = Ember['default'].get(this, 'parentView.optionDisabledPath');

      if (!valuePath) {
        return;
      }

      Ember['default'].defineProperty(this, 'disabled', Ember['default'].computed(function () {
        return Ember['default'].get(this, valuePath);
      }).property(valuePath));
    })
  });

});