define('m03/models/masskette', ['exports', 'ember-data'], function (exports, DS) {

  'use strict';

  exports['default'] = DS['default'].Model.extend({

    masstext: DS['default'].attr(''),
    altpos: DS['default'].attr(''),
    ebene: DS['default'].attr('')

  });

});