define('m03/models/x3ddefault', ['exports', 'ember-data'], function (exports, DS) {

  'use strict';

  var X3D = DS['default'].Model.extend({
    modul: DS['default'].attr(''),
    transformHelper: DS['default'].attr('boolean'),
    arc2dPositionTriggern: DS['default'].attr('boolean', { defaultValue: false }),
    viewpointHelper: DS['default'].attr('boolean'),
    masskettenHelper: DS['default'].attr('boolean', { defaultValue: false }),

    bauteile: DS['default'].hasMany('bauteil', { async: false }),

    dimensionen: DS['default'].hasMany('dimension', { async: false }),

    resetFieldOfView: DS['default'].attr('boolean', { defaultValue: false }),

    lastViewpoint: DS['default'].attr('string', { defaultValue: 'viewpointDefault' }),
    erstesVerbindungsmittel: DS['default'].attr('string', { defaultValue: 'schraubeStandard1' }),
    aktuellerSchraubenTyp: DS['default'].attr('string', { defaultValue: 'standardSchraube' }),

    sFolgeHolzHolz: DS['default'].attr('boolean', { defaultValue: true }),
    zweiSchnittig: DS['default'].attr('boolean', { defaultValue: false }),
    zweiSchaerflaechen: DS['default'].attr('boolean', { defaultValue: false }),

    xMittelbauteil: DS['default'].attr('number'),
    xSeitenbauteil: DS['default'].attr('number'),

    setztiefeAktiv: DS['default'].attr('boolean', { defaultValue: false }),
    setztiefeEingetragen: DS['default'].attr('boolean', { defaultValue: false }),

    ueberstandDiagonaleVertikal: DS['default'].attr('number', { defaultValue: 0 }),
    ueberstandDiagonaleVertikalAktiv: DS['default'].attr('boolean', { defaultValue: false }),
    ueberstandDiagonaleVertikalEingetragen: DS['default'].attr('boolean', { defaultValue: false }),

    ausmittigerstabanschluss: DS['default'].attr('boolean', { defaultValue: false }),

    exzentrizitaetDiagonale: DS['default'].attr('number', { defaultValue: 0 }),
    exzentrizitaetDiagonaleAktiv: DS['default'].attr('boolean', { defaultValue: false }),
    exzentrizitaetDiagonaleEingetragen: DS['default'].attr('boolean', { defaultValue: false }),

    vWinkel: DS['default'].attr('number', { defaultValue: 0 }),
    vWinkelAktiv: DS['default'].attr('boolean', { defaultValue: false }),
    vWinkelEingetragen: DS['default'].attr('boolean', { defaultValue: false }),

    winkelEndAbschnittGRAD: DS['default'].attr('number', { defaultValue: 1 }),

    winkelEndAbschnittDiagonaleGRAD: DS['default'].attr('number', { defaultValue: 90 }),
    winkelEndAbschnittDiagonaleGRADAktiv: DS['default'].attr('boolean', { defaultValue: false }),
    winkelEndAbschnittDiagonaleGRADEingetragen: DS['default'].attr('boolean', { defaultValue: false }),

    winkelAbschnittDiagonaleGRAD: DS['default'].attr('number', { defaultValue: 90 }),
    winkelAbschnittDiagonaleGRADAktiv: DS['default'].attr('boolean', { defaultValue: false }),
    winkelAbschnittDiagonaleGRADEingetragen: DS['default'].attr('boolean', { defaultValue: false }),

    vWinkelScherkraft: DS['default'].attr('number', { defaultValue: 0 }),
    vWinkelScherkraftAktiv: DS['default'].attr('boolean', { defaultValue: false }),
    vWinkelScherkraftEingetragen: DS['default'].attr('boolean', { defaultValue: false }),

    phi: DS['default'].attr('number', { defaultValue: 0 }),
    phiAktiv: DS['default'].attr('boolean', { defaultValue: false }),
    phiEingetragen: DS['default'].attr('boolean', { defaultValue: false }),

    htAnschnittIndex: DS['default'].attr('number', { defaultValue: 0 }),
    ntAnschnittIndex: DS['default'].attr('number', { defaultValue: 0 }),

    vorzeichenAWerte: DS['default'].attr('number', { defaultValue: 0 }),
    vorzeichenBWerte: DS['default'].attr('number', { defaultValue: 0 }),

    a1: DS['default'].attr('number', { defaultValue: 0 }),
    a1Aktiv: DS['default'].attr('boolean', { defaultValue: false }),
    a1Eingetragen: DS['default'].attr('boolean', { defaultValue: false }),

    a2: DS['default'].attr('number', { defaultValue: 0 }),
    a2Aktiv: DS['default'].attr('boolean', { defaultValue: false }),
    a2Eingetragen: DS['default'].attr('boolean', { defaultValue: false }),

    alpha1: DS['default'].attr('number', { defaultValue: 90 }),
    alpha1Aktiv: DS['default'].attr('boolean', { defaultValue: false }),
    alpha1Eingetragen: DS['default'].attr('boolean', { defaultValue: false }),

    alpha2: DS['default'].attr('number', { defaultValue: 90 }),
    alpha2Aktiv: DS['default'].attr('boolean', { defaultValue: false }),
    alpha2Eingetragen: DS['default'].attr('boolean', { defaultValue: false }),

    alpha3: DS['default'].attr('number', { defaultValue: 180 }),
    alpha3Aktiv: DS['default'].attr('boolean', { defaultValue: false }),
    alpha3Eingetragen: DS['default'].attr('boolean', { defaultValue: false }),

    b1: DS['default'].attr('number', { defaultValue: 0 }),
    b1Aktiv: DS['default'].attr('boolean', { defaultValue: false }),
    b1Eingetragen: DS['default'].attr('boolean', { defaultValue: false }),

    b2: DS['default'].attr('number', { defaultValue: 0 }),
    b2Aktiv: DS['default'].attr('boolean', { defaultValue: false }),
    b2Eingetragen: DS['default'].attr('boolean', { defaultValue: false }),

    beta1: DS['default'].attr('number', { defaultValue: 90 }),
    beta1Aktiv: DS['default'].attr('boolean', { defaultValue: false }),
    beta1Eingetragen: DS['default'].attr('boolean', { defaultValue: false }),

    beta2: DS['default'].attr('number', { defaultValue: 90 }),
    beta2Aktiv: DS['default'].attr('boolean', { defaultValue: false }),
    beta2Eingetragen: DS['default'].attr('boolean', { defaultValue: false }),

    beta3: DS['default'].attr('number', { defaultValue: 180 }),
    beta3Aktiv: DS['default'].attr('boolean', { defaultValue: false }),
    beta3Eingetragen: DS['default'].attr('boolean', { defaultValue: false }),

    htbreiteAktiv: DS['default'].attr('boolean', { defaultValue: false }),
    htBreiteEingetragen: DS['default'].attr('boolean', { defaultValue: false }),

    hthoeheAktiv: DS['default'].attr('boolean', { defaultValue: false }),
    htHoeheEingetragen: DS['default'].attr('boolean', { defaultValue: false }),

    ntbreiteAktiv: DS['default'].attr('boolean', { defaultValue: false }),
    ntBreiteEingetragen: DS['default'].attr('boolean', { defaultValue: false }),

    nthoeheAktiv: DS['default'].attr('boolean', { defaultValue: false }),
    ntHoeheEingetragen: DS['default'].attr('boolean', { defaultValue: false }),

    axialkraft: DS['default'].attr('number', { defaultValue: 0 }),
    axialkraftAktiv: DS['default'].attr('boolean', { defaultValue: false }),
    axialkraftEingetragen: DS['default'].attr('boolean', { defaultValue: false }),

    scherkraft: DS['default'].attr('number', { defaultValue: 0 }),
    scherkraftAktiv: DS['default'].attr('boolean', { defaultValue: false }),
    scherkraftEingetragen: DS['default'].attr('boolean', { defaultValue: false }),

    verstaerkungBlass: DS['default'].attr('boolean', { defaultValue: false }),
    dickeBlass: DS['default'].attr('number', { defaultValue: 0 }),
    dickeBlassAktiv: DS['default'].attr('boolean', { defaultValue: false }),
    dickeBlassEingetragen: DS['default'].attr('boolean', { defaultValue: false }),

    verstaerkungWerner: DS['default'].attr('boolean', { defaultValue: false }),
    dickeWerner: DS['default'].attr('number', { defaultValue: 0 }),
    dickeWernerAktiv: DS['default'].attr('boolean', { defaultValue: false }),
    dickeWernerEingetragen: DS['default'].attr('boolean', { defaultValue: false }),

    uScheibe: DS['default'].attr('boolean', { defaultValue: false }),

    schraubenlisteAktualisiert: DS['default'].attr('boolean', { defaultValue: false }),

    skalierungsfaktor: DS['default'].attr('number', { defaultValue: function defaultValue() {
        return 0.5;
      } }),
    startDistance: DS['default'].attr('number', { defaultValue: 9 }),
    aktuelleDistance: DS['default'].attr('number', { defaultValue: 9 }),
    ebene: DS['default'].attr('number', { defaultValue: 1 }),
    textRefresh: DS['default'].attr('number', { defaultValue: 0 }),

    xAbstand1: DS['default'].attr('number', { defaultValue: 0 }),
    xAbstand2: DS['default'].attr('number', { defaultValue: 0 }),
    yAbstand1: DS['default'].attr('number', { defaultValue: 0 }),
    yAbstand2: DS['default'].attr('number', { defaultValue: 0 }),
    schraubenAbstandX: DS['default'].attr('number', { defaultValue: 0 }),
    schraubenAbstandY: DS['default'].attr('number', { defaultValue: 0 }),

    abstaendeAnzeigen: DS['default'].attr('boolean', { defaultValue: true }),
    ergebnisGeladen: DS['default'].attr('boolean', { defaultValue: false }),

    istGitterModell: DS['default'].attr('boolean', { defaultValue: false }),

    laengenFaktor: DS['default'].attr('number'),
    laengenLabel: DS['default'].attr('string'),
    laengenNachkomma: DS['default'].attr('number'),
    syslaengenFaktor: DS['default'].attr('number'),
    syslaengenLabel: DS['default'].attr('string'),
    syslaengenNachkomma: DS['default'].attr('number'),
    kraefteFaktor: DS['default'].attr('number'),
    kraefteLabel: DS['default'].attr('string'),
    kraefteNachkomma: DS['default'].attr('number'),

    crosssectionString: DS['default'].attr('string'),
    orientationString: DS['default'].attr('string'),
    scaleString: DS['default'].attr('string'),
    spineString: DS['default'].attr('string')

  });

  exports['default'] = X3D;

});