define('m03/locales_ITW/fr/translations', ['exports'], function (exports) {

  'use strict';

  exports['default'] = {

    berechnung_pdf: 'Aperçu PDF',
    setztiefe: 'Profondeur de pénétration',
    vorgebohrt: 'Trous pré-percés',
    bauteile: 'Composants',
    hausnummer: 'Numéro de rue *',
    gew_schraube: 'Sélectionné',
    SH: 'Acier-Bois',
    email: 'E-mail ',
    scherkraft: 'Effort de cisaillement',
    nicht_vorgebohrt: 'Trous non pré-percés',
    scherfl_vbm: 'Sections de cisaillement par fixation',
    beschreibung: 'Description',
    festigkeit: 'Classe de résistance',
    scherfl2: '2 sections de cisaillement',
    scherfl1: '1 section de cisaillement',
    modul_alt: 'M03',
    mat: 'Matériau 1',
    staendig: 'permanent',
    lasteinwirkung2: 'Effort',
    tt_text: 'Enregistrer en format PDF',
    ergebnis: 'Résultat',
    eingabeerforderlich: 'Entrée obligatoire !',
    plz: 'Code Postal',
    kompakterg: 'Résumé',
    eingabe_ok: 'Entrée correcte',
    winkel: 'Angle entre les composants',
    sehr_kurz: 'instantanée',
    msg: 'Aperçu des fixations non valides',
    bezeichnung: 'Nom',
    lang: 'long terme',
    tel: 'Téléphone',
    schnittig2: 'Double cisaillement',
    ort: 'Localité ',
    schnittig1: 'Cisaillement simple',
    land: 'Pays',
    projektdaten: 'Données du projet',
    fax: 'Fax',
    kdnum: 'Numéro de client',
    verbindungsmittel: 'Fixation',
    geometrie: 'Géométrie',
    def_bereich: 'Domaine de définition',
    schichtfolge: 'Succession des couches',
    wklscherkraft: 'Angle de l\'effort',
    artnr: 'Code article',
    name: 'Nom',
    nkl: 'Classe de service',
    adresse: 'Rue, Numéro de maison',
    mittel: 'moyen terme',
    lasteinwirkung: 'Charge de service calculée',
    modul: 'M03',
    led: 'Type de charge',
    scherfl_anschl: 'Sections de cisaillement par connexion',
    verarbeiter: 'Maître d’oeuvre/Maître d’ouvrage',
    vbms: 'Fixation',
    nkl3: 'Classe de service 3',
    hoehe: 'Hauteur',
    mat1: 'Matériau 2',
    nkl2: 'Classe de service 2',
    nkl1: 'Classe de service 1',
    position: 'Position',
    axialkraft: 'Traction',
    strasse: 'Rue ',
    antrieb: 'Embout',
    bauvorhaben: 'Projet de construction',
    vbm: 'Fixation',
    hinweisLaubholz: 'Pré-perçage obligatoire dans les bois feuillus !',
    auftraggeber: 'Maître d’ouvrage',
    dicke: 'Epaisseur',
    raxtd: 'Valeur de calcul de la capacité de charge axiale',
    HH: 'Bois/Panneau - Bois',
    rlatd: 'Valeur de calcul de la capacité de charge latérale',
    kurz: 'court terme',

    nachBlass: 'Couche intermédiaire selon BLASS',
    nachWerner: 'Renforcement selon WERNER',
    winkelKlammerruecken: 'Angle inclinaison agrafe / fibre',
    zwischenschichtVerstaerkung: 'Couche intermédiaire/Renforcement',
    zwischenschicht: 'Couche intermédiaire',
    verstaerkung: 'Renforcement',
    umwelteinfluesse: 'Environnement',
    erhoehtesKorrosionsrisiko: 'risque de corrosion plus élevé',

    bemessungssoftware: 'Logiciel de calcul ITW',
    wertebereich: "Plage de valeurs autorisée:",
    ergebnisanzeigen: "Afficher les résultats:",
    einstellung: "Configuration",
    spracheinstellungen: "Sélection langues",
    eingabe: "Entrée",
    ausgabe: "Sortie",
    landeseinstellungen: "Paramètrage local (pays)",
    bemessungsvorschrift: "Règles de conception",
    liefersortiment: "Gamme de produit",
    produktkatalog: "Catalogue",
    produktinfo: "Fiche technique",
    eta: "ATE",
    cad: "CAD",
    impressum: "A propos",
    extras: "Documents",
    bemerkungen: "Commentaires",
    bemerkungstext: "Texte du commentaire",
    eingabedaten: "les données saisies",
    berechnungalspdf: "Aperçu PDF",
    berechnungspeichern: "Enregistrer le projet",
    berechnungoeffnen: "Ouvrir le projet",
    downloadinstructions1: "Ouvrir le fichier dans une nouvelle fenêtre:  clic gauche sur les icônes.",
    downloadinstructions2: "Faites un clic droit sur les icônes ->  « Enregistrer sous » / « Enregistrer le lien sous » pour un fichier sur le disque dur pour stocker localement.",
    downloadinstructions3: "Indiquer un nom de fichier sous lequel vous voulez télécharger les fichiers sélectionnés comme package commun.",
    eingabedatennichtgespeichert: "Les données d\'entrée ont pas été enregistrées.",
    pdfnochnichterstellt: "Le PDF n\'a pas encore été généré",
    dlfilename: "nom du fichier",
    dlpaketErstellen: "Créer un dossier de téléchargement",
    nochkeineschraubegewaehlt: "Aucune fixation n\'a encore été sélectionnée",
    nochkeindlerzeugt: "Aucun dossier de téléchargement n\'a encore été créé.",
    neueberechnung: "Nouveau calcul",
    kontakt: "Contact",
    herausgeber: "Rédacteur",
    registergericht: "Tribunal de commerce",
    geschaeftsfuehrer: "Directeur général",
    entwickeltvon: "Développé par",

    dansk: "Danois",
    deutsch: "Deutsch",
    englisch: "English",
    franzoesisch: "Français",
    italienisch: "Italien",
    nederlands: "Néerlandais",
    polnisch: "Polonais",
    espanol: "Espagnol",
    suomi: "Finnois",
    schwedisch: "Suédois",

    schweiz: "Suisse",
    daenemark: "Danemark",
    deutschland: "Allemagne",
    oesterreich: "Österreich",
    england: "Angleterre",
    frankreich: "France",
    italien: "Italie",
    niederlande: "Pays-Bas",
    polen: "Pologne",
    spanien: "Espagne",
    chile: "Chili",
    finnland: "Finlande",
    schweden: "Suède",

    MatGrp0000: "Bois massif, bois tendre",
    MatGrp0001: "Bois lamellé collé, homogène",
    MatGrp0002: "Lamibois (LVL)",
    MatGrp0003: "Bois massif reconstitué (double, triple)",
    MatGrp0004: "Panneaux bois massif",
    MatGrp0005: "xContreplaqué",
    MatGrp0006: "Lamibois (fibres avec orientation parallèle)",
    MatGrp0007: "Panneau de particules",
    MatGrp0008: "Panneaux de particules liées au ciment ",
    MatGrp0009: "Panneau de fibres, dur HB.HLA2",
    MatGrp0010: "Panneau de fibres, medium MBH.LA2",
    MatGrp0012: "Bois massif, bois feuillu",
    MatGrp0013: "Lamellé collé, combiné",
    MatGrp0015: "Bois lamellé croisé",
    MatGrp0020: "Panneau OSB",
    MatGrp0021: "Contreplaqué",
    MatGrp0022: "Plaque de plâtre (DIN 18180)",
    MatGrp0030: "LVL Hêtre Pollmeier",
    MatGrp1000: "Acier",
    MatGrp9100: "Beton",
    MatGrp9999: "Luft",

    Mat1000: "Acier S235JR",
    Mat1001: "Acier S355JR",
    Mat9999: "----",
    Mat000: "C14",
    Mat001: "C16",
    Mat002: "C18",
    Mat003: "C20",
    Mat004: "C22",
    Mat005: "C24",
    Mat006: "C27",
    Mat007: "C30",
    Mat008: "C35",
    Mat009: "C40",
    Mat010: "C45",
    Mat011: "C50",
    Mat050: "D30",
    Mat051: "D35",
    Mat052: "D40",
    Mat053: "D50",
    Mat054: "D60",
    Mat055: "D70",
    Mat020: "GL24h",
    Mat030: "GL24c",
    Mat021: "GL28h",
    Mat031: "GL28c",
    Mat022: "GL32h",
    Mat032: "GL32c",
    Mat023: "GL36h",
    Mat033: "GL36c",
    Mat160: "Panneau de particules classe P4 (EN 312)",
    Mat170: "Panneau de particules classe P5 (EN 312)",
    Mat180: "Panneau de particules classe P6 (EN 312)",
    Mat190: "Panneau de particules classe P7 (EN 312)",
    Mat200: "Panneaux de particules liées au ciment classe 1+2 (EN 634)",
    Mat220: "Panneau de fibres, dur HB.HLA2 (EN 622-2)",
    Mat230: "Panneau de fibres, medium  MBH.LA2 (EN 622-3)",
    Mat125: "OSB/2 (EN 300)",
    Mat126: "OSB/3 (EN 300)",
    Mat127: "OSB/4 (EN 300)",
    Mat110: "Contreplaqué F25/10",
    Mat111: "Contreplaqué F40/40 E60/40",
    Mat112: "Contreplaqué F50/25 E70/25",
    Mat113: "Contreplaqué F60/10 E90/10",
    Mat520: "Plaque de plâtre (DIN 18180)",
    Mat9120: "C20/25",
    Mat9125: "C25/30",
    Mat9130: "C30/37",
    Mat9135: "C35/45",
    Mat9140: "C40/50",
    Mat9145: "C45/55",
    Mat024: "GL30h",
    Mat034: "GL30c",
    Mat301: "GL70",

    ohneZwischenschicht: "Sans couche intermédiaire",
    zwischenschichtBlass: "Couche intermédiaire selon BLASS",
    verstaerkungWerner: "Renforcement selon WERNER",
    groesserGleich30: "≥ 30°",
    kleiner30: "< 30°",

    anschlussgeometrie: "#no value#",
    anschlussvarianten: "#no value#",
    winkelEndabschnittDiagonale: "#no value#",
    ueberstandDiagonaleVertikal: "#no value#",
    ausmittigerStabanschluss: "#no value#",
    winkelabschnittDiagonale: "#no value#",
    exzentrizitaetDiagonale: "##Exzentrizitaet Diagonale",
    abschnittSenkrechtDiagonalenachse: "#no value#",
    abschnittParallelGurtachse: "#no value#",
    freieEingabe: "Champ libre",

    starr: "Couche intermédiaire rigide",
    verschieblich: "Couche intermédiaire non rigide",
    nachricht: "##Nachricht",
    nachrichtversenden: "##Ihre Eingabedaten werden automatisch mit der Nachricht übermittelt.",
    nachrichtversendet: "##Ihre Nachricht wurde übermittelt.",
    nachrichtSenden: "##Nachricht senden",
    neueNachricht: "##Neue Nachricht",

    hilfe: "Aider",
    bemessungslastText: "Si la charge de la conception est inconnue, la capacité maximale des moyens de connexion est affiché si vous entrez zéro.",
    ledTooltipStaendig: "plus de 10 ans, ex. poids propre",
    ledTooltipLang: "6 mois à 10 ans, ex. stockage",
    ledTooltipMittel: "1 semaine à 6 mois, ex. charge d’occupation, neige",
    ledTooltipKurz: "moins d’une semaine, ex. neige, vent",
    ledTooltipSehrKurz: "ex. vent, action accidentelle",

    nklTooltip1: "est caractérisée par une humidité dans les matériaux correspondant à une température de 20 °C et une humidité relative de l’air environnant ne dépassant 65% que quelques semaines par an. (Par ex. les bâtiments fermé, chauffés)",
    nklTooltip2: "est caractérisée par une humidité dans les matériaux correspondant à une température de 20 °C et une humidité relative de l’air ne dépassant 85% que quelques semaines par an. (Par ex. les bâtiments ouverts, protégés)",
    nklTooltip3: "Conditions climatiques amenant des humidités supérieures à celles de la classe de service 2. (Par ex. les bâtiments exposés aux conditions météorologiques)",

    nutzungsbedingungen: "Les conditions générales",

    errors: {
      inclusion: "is not included in the list",
      exclusion: "is reserved",
      invalid: "Saisie non valide.",
      confirmation: "doesn\'t match {{attribute}}",
      accepted: "must be accepted",
      empty: "can\'t be empty",
      blank: "can\'t be blank",
      present: "must be blank",
      tooLong: "Nombre de caractères maximum autorisé est {{chiffre}} caractères.",
      tooShort: "!2",
      wrongLength: "is the wrong length (should be {{count}} characters)",
      notANumber: "is not a number",
      notAnInteger: "must be an integer",
      greaterThan: "must be greater than {{count}}",
      greaterThanOrEqualTo: "must be greater than or equal to {{count}}",
      equalTo: "must be equal to {{count}}",
      lessThan: "must be less than {{count}}",
      lessThanOrEqualTo: "must be less than or equal to {{count}}",
      otherThan: "must be other than {{count}}",
      odd: "must be odd",
      even: "must be even"
    },
    'language-select.language.de': "Deutsch",
    'language-select.language.en': "English"

  };

});