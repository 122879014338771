define('m03/locales_ITW/de/translations', ['exports'], function (exports) {

  'use strict';

  exports['default'] = {

    berechnung_pdf: 'Berechnung als PDF',
    setztiefe: 'Setztiefe',
    vorgebohrt: 'Löcher vorgebohrt',
    bauteile: 'Bauteile',
    hausnummer: 'Hausnummer',
    gew_schraube: 'Gewählt',
    SH: 'Stahl-Holz',
    email: 'E-mail',
    scherkraft: 'Scherkraft',
    nicht_vorgebohrt: 'Löcher nicht vorgebohrt',
    scherfl_vbm: 'Scherflächen je Verbindungsmittel',
    beschreibung: 'Beschreibung',
    festigkeit: 'Festigkeitsklasse',
    scherfl2: '2 Scherflächen',
    scherfl1: '1 Scherfläche',
    modul_alt: 'M03',
    mat: 'Material 1',
    staendig: 'ständig',
    lasteinwirkung2: 'Lasteinwirkung',
    tt_text: 'Ausgabe im PDF-Format',
    ergebnis: 'Ergebnis',
    eingabeerforderlich: 'Eingabe erforderlich !',
    plz: 'Postleitzahl',
    kompakterg: 'Kompaktergebnis',
    eingabe_ok: 'Korrekte Eingabe',
    winkel: 'Winkel zwischen Bauteilen',
    sehr_kurz: 'sehr kurz',
    msg: 'Unzulässige Verbindungsmittel einblenden',
    bezeichnung: 'Bezeichnung',
    lang: 'lang',
    tel: 'Telefon',
    schnittig2: '2-schnittig',
    ort: 'Ort',
    schnittig1: '1-schnittig',
    land: 'Land',
    projektdaten: 'Projektdaten',
    fax: 'Fax',
    kdnum: 'Kundennummer',
    verbindungsmittel: 'Verbindungsmittel',
    geometrie: 'Geometrie',
    def_bereich: 'Definitionsbereich',
    schichtfolge: 'Schichtfolge',
    wklscherkraft: 'Richtungswinkel der Scherkraft',
    artnr: 'Artikelnummer',
    name: 'Name',
    nkl: 'Nutzungsklasse',
    adresse: 'Straße, Hausnummer',
    mittel: 'mittel',
    lasteinwirkung: 'Lasteinwirkung (Bemessungslasten)',
    modul: 'M03',
    led: 'Lasteinwirkungsdauer',
    scherfl_anschl: 'Scherfläche je Anschluss',
    verarbeiter: 'Verarbeiter/Auftraggeber',
    vbms: 'Verbindungsmittel',
    nkl3: 'Nutzungsklasse 3',
    hoehe: 'Höhe',
    mat1: 'Material 2',
    nkl2: 'Nutzungsklasse 2',
    nkl1: 'Nutzungsklasse 1',
    position: 'Position',
    axialkraft: 'Axialkraft',
    strasse: 'Straße',
    antrieb: 'Antrieb',
    bauvorhaben: 'Bauvorhaben',
    vbm: 'Verbindungsmittel',
    hinweisLaubholz: 'Verbindungsmittel müssen bei der Anwendung in Laubholz vorgebohrt werden !',
    auftraggeber: 'Auftraggeber',
    dicke: 'Dicke',
    raxtd: 'Bemessungswert der Zugtragfähigkeit',
    HH: 'Holz/HWS-Holz',
    rlatd: 'Bemessungswert der Schertragfähigkeit',
    kurz: 'kurz',

    nachBlass: 'Zwischenschicht nach BLASS',
    nachWerner: 'Verstärkung nach WERNER',
    winkelKlammerruecken: 'Winkel Klammerrücken - Faserrichtung',
    zwischenschichtVerstaerkung: 'Zwischenschicht/Verstärkung',
    zwischenschicht: 'Zwischenschicht',
    verstaerkung: 'Verstärkung',
    umwelteinfluesse: 'Umwelteinflüsse, Imprägnierung',
    erhoehtesKorrosionsrisiko: 'erhöhtes Korrosionsrisiko',

    bemessungssoftware: 'ITW Bemessungssoftware',
    wertebereich: "Zulässiger Wertebereich: ",
    ergebnisanzeigen: "Ergebnis anzeigen: ",
    einstellung: "Einstellung",
    spracheinstellungen: "Spracheinstellungen",
    eingabe: "Eingabe",
    ausgabe: "Ausgabe",
    landeseinstellungen: "Landeseinstellungen",
    bemessungsvorschrift: "Bemessungsvorschrift",
    liefersortiment: "Liefersortiment",
    produktkatalog: "Produktkatalog",
    produktinfo: "Produktinformation",
    eta: "ETA",
    cad: "CAD",
    impressum: "Impressum",
    extras: "Extras",
    bemerkungen: "Bemerkungen",
    bemerkungstext: "Bemerkungstext",
    eingabedaten: "Eingabedaten",
    berechnungalspdf: "Berechnung als PDF",
    berechnungspeichern: "Berechnung speichern",
    berechnungoeffnen: "Berechnung öffnen",
    downloadinstructions1: "Linksklick auf Icons: Datei in neuem Fenster öffnen.",
    downloadinstructions2: "Rechtsklick auf Icons -> « Ziel speichern unter » / « Link speichern unter » um Datei auf der Festplatte lokal abzuspeichern.",
    downloadinstructions3: "Geben Sie einen Dateinamen an, unter welchem Sie die oben ausgewählten Dateien als zusammengefasstes Paket herunterladen möchten.",
    eingabedatennichtgespeichert: "Eingabedaten wurden noch nicht gespeichert.",
    pdfnochnichterstellt: "Es wurde noch keine PDF erzeugt.",
    dlfilename: "Dateiname",
    dlpaketErstellen: "Downloadpaket erstellen",
    nochkeineschraubegewaehlt: "Es wurde noch kein Verbindungsmittel ausgewählt.",
    nochkeindlerzeugt: "Es wurde noch kein Downloadpaket erstellt.",
    neueberechnung: "Neue Berechnung",
    kontakt: "Kontakt",
    herausgeber: "Herausgeber",
    registergericht: "Registergericht",
    geschaeftsfuehrer: "Geschäftsführer",
    entwickeltvon: "Entwickelt von",

    dansk: "Dänisch",
    deutsch: "Deutsch",
    englisch: "Englisch",
    franzoesisch: "Französisch",
    italienisch: "Italienisch",
    nederlands: "Holländisch",
    polnisch: "Polnisch",
    espanol: "Spanisch",
    suomi: "Finnisch",
    schwedisch: "Schwedisch",

    schweiz: "Schweiz",
    daenemark: "Dänemark",
    deutschland: "Deutschland",
    oesterreich: "Österreich",
    england: "England",
    frankreich: "Frankreich",
    italien: "Italien",
    niederlande: "Niederlande",
    polen: "Polen",
    spanien: "Spanien",
    chile: "Chile",
    finnland: "Finnland",
    schweden: "Schweden",

    MatGrp0000: "Vollholz Nadelholz",
    MatGrp0001: "Brettschichtholz homogen",
    MatGrp0002: "Furnierschichtholz (LVL)",
    MatGrp0003: "Balkenschichtholz",
    MatGrp0004: "Massivholzplatte",
    MatGrp0005: "xBaufurniersperrholz",
    MatGrp0006: "Furnierschichtholz (alle Fasern parallel)",
    MatGrp0007: "Kunstharzgebundene Holzspanplatten",
    MatGrp0008: "Zementgebundene Holzspanplatten",
    MatGrp0009: "Holzfaserplatten, hart HB.HLA2",
    MatGrp0010: "Holzfaserplatten, mittelhart MBH.LA2",
    MatGrp0012: "Vollholz Laubholz",
    MatGrp0013: "Brettschichtholz kombiniert",
    MatGrp0015: "Brettsperrholz",
    MatGrp0020: "OSB - Platten",
    MatGrp0021: "Baufurniersperrholz",
    MatGrp0022: "Gipskartonplatten (DIN 18180)",
    MatGrp0030: "Pollmeier BauBuche",
    MatGrp1000: "Stahl",
    MatGrp9100: "Beton",
    MatGrp9999: "Luft",

    Mat1000: "Stahl S235JR",
    Mat1001: "Stahl S355JR",
    Mat9999: "----",
    Mat000: "C14",
    Mat001: "C16",
    Mat002: "C18",
    Mat003: "C20",
    Mat004: "C22",
    Mat005: "C24",
    Mat006: "C27",
    Mat007: "C30",
    Mat008: "C35",
    Mat009: "C40",
    Mat010: "C45",
    Mat011: "C50",
    Mat050: "D30",
    Mat051: "D35",
    Mat052: "D40",
    Mat053: "D50",
    Mat054: "D60",
    Mat055: "D70",
    Mat020: "GL24h",
    Mat030: "GL24c",
    Mat021: "GL28h",
    Mat031: "GL28c",
    Mat022: "GL32h",
    Mat032: "GL32c",
    Mat023: "GL36h",
    Mat033: "GL36c",
    Mat160: "Kunstharzgeb. Spanpl Kl. P4 (EN 312)",
    Mat170: "Kunstharzgeb. Spanpl Kl. P5 (EN 312)",
    Mat180: "Kunstharzgeb. Spanpl Kl. P6 (EN 312)",
    Mat190: "Kunstharzgeb. Spanpl Kl. P7 (EN 312)",
    Mat200: "Zementgeb. Holzspanpl. Kl. 1+2 (EN 634)",
    Mat220: "Holzfaserplatten, hart HB.HLA2 (EN 622-2)",
    Mat230: "Holzfaserplatten, mittelhart MBH.LA2 (EN 622-3)",
    Mat125: "OSB/2 (EN 300)",
    Mat126: "OSB/3 (EN 300)",
    Mat127: "OSB/4 (EN 300)",
    Mat110: "Sperrholz F25/10",
    Mat111: "Sperrholz F40/40 E60/40",
    Mat112: "Sperrholz F50/25 E70/25",
    Mat113: "Sperrholz F60/10 E90/10",
    Mat520: "Gipskartonplatten (DIN 18180)",
    Mat9120: "C20/25",
    Mat9125: "C25/30",
    Mat9130: "C30/37",
    Mat9135: "C35/45",
    Mat9140: "C40/50",
    Mat9145: "C45/55",
    Mat024: "GL30h",
    Mat034: "GL30c",
    Mat301: "GL70",

    ohneZwischenschicht: "ohne Zwischenschicht",
    zwischenschichtBlass: "Zwischenschicht nach BLASS",
    verstaerkungWerner: "Verstärkung nach WERNER",
    groesserGleich30: "≥ 30°",
    kleiner30: "< 30°",

    anschlussgeometrie: "Anschlussgeometrie",
    anschlussvarianten: "Anschlussvarianten",
    winkelEndabschnittDiagonale: "Winkelendabschnitt Diagonale",
    ueberstandDiagonaleVertikal: "Überstand Diagonale Vertikal",
    ausmittigerStabanschluss: "Ausmittiger Stabanschluss",
    winkelabschnittDiagonale: "Winkelabschnitt Diagonale",
    exzentrizitaetDiagonale: "Exzentrizitaet Diagonale",
    abschnittSenkrechtDiagonalenachse: "Abschnitt senkrecht zur Diagonalachse",
    abschnittParallelGurtachse: "Abschnitt parallel zur Gurtachse",
    freieEingabe: "Freie Eingabe",

    starr: "Starre Zwischenschicht",
    verschieblich: "Verschiebliche Zwischenschicht",
    nachricht: "Nachricht",
    nachrichtversenden: "Ihre Eingabedaten werden automatisch mit der Nachricht übermittelt.",
    nachrichtversendet: "Ihre Nachricht wurde übermittelt.",
    nachrichtSenden: "Nachricht senden",
    neueNachricht: "Neue Nachricht",

    hilfe: "Hilfe",
    bemessungslastText: "Sollte die Bemessungslast nicht bekannt sein, wird bei Eingabe von Null die maximale Tragfähigkeit des Verbindungsmittels angezeigt.",
    ledTooltipStaendig: "länger als 10 Jahre, z.B. Eigengewicht",
    ledTooltipLang: "6 Monate - 10 Jahre, z.B. Lagerstoffe",
    ledTooltipMittel: "1 Woche - 6 Monate, z.B. Verkehrslasten, Schnee",
    ledTooltipKurz: "kürzer als 1 Woche, z.B. Schnee, Wind",
    ledTooltipSehrKurz: "z.B. Wind und außergewöhnliche Einwirkungen",

    nklTooltip1: "Holzfeuchte, die einer Temperatur von 20°C und einer rel. Luftfeuchte der umgebenden Luft entspricht, die nur wenige Wochen im Jahr 65% übersteigt (z.B. geschlossene, beheizte Bauwerke)",
    nklTooltip2: "Holzfeuchte, die einer Temperatur von 20°C und einer rel. Luftfeuchte der umgebenden Luft entspricht, die nur wenige Wochen im Jahr 85% übersteigt (z.B. überdachte, offene Bauwerke)",
    nklTooltip3: "Klimabedingungen, die zu einer höheren Holzfeuchte führen als in Nutzungsklasse 2 angegeben (z.B. Konstruktionen, die der Witterung ausgesetzt sind)",

    nutzungsbedingungen: "Nutzungsbedingungen",

    errors: {
      inclusion: "is not included in the list",
      exclusion: "is reserved",
      invalid: "Ungültige Eingabe.",
      confirmation: "doesn\'t match {{attribute}}",
      accepted: "must be accepted",
      empty: "can\'t be empty",
      blank: "can\'t be blank",
      present: "must be blank",
      tooLong: "Eingabe zu lang. Maximal erlaubte Länge sind {{count}} Zeichen.",
      tooShort: "!2",
      wrongLength: "is the wrong length (should be {{count}} characters)",
      notANumber: "is not a number",
      notAnInteger: "must be an integer",
      greaterThan: "must be greater than {{count}}",
      greaterThanOrEqualTo: "must be greater than or equal to {{count}}",
      equalTo: "must be equal to {{count}}",
      lessThan: "must be less than {{count}}",
      lessThanOrEqualTo: "must be less than or equal to {{count}}",
      otherThan: "must be other than {{count}}",
      odd: "must be odd",
      even: "must be even"
    },
    'language-select.language.de': "Deutsch",
    'language-select.language.en': "English"
  };

});