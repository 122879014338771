define('m03/controllers/projektdaten', ['exports', 'ember', 'ember-validations'], function (exports, Ember, EmberValidations) {

  'use strict';

  /**
   * Controller für den Projektdaten-Reiter.
   *
   * @class projektdaten
   */

  exports['default'] = Ember['default'].Controller.extend(EmberValidations['default'], {

    initTrigger: false,

    itw: false, //don't change this!
    swg: false, //don't change this!

    debug: true,
    plzservice: true,
    displayErrors: true,
    displayTooltips: false,

    bvort: "",
    vort: "",
    orte: "",
    bvname: "",
    bvplz: "",
    bvstrasse: "",
    vname: "",
    vstrasse: "",
    vplz: "",
    vtel: "",
    vfax: "",
    vemail: "",
    bezeichnung: "",
    beschreibung: "",
    kdnum: "",
    bemerkungstext: "",

    bvortHilfetextFocused: false,
    bvortHilfetext: "",
    vortHilfetextFocused: false,
    vortHilfetext: "",
    orteHilfetextFocused: false,
    orteHilfetext: "",
    bvnameHilfetextFocused: false,
    bvnameHilfetext: "",
    bvplzHilfetextFocused: false,
    bvplzHilfetext: "",
    bvstrasseHilfetextFocused: false,
    bvstrasseHilfetext: "",
    vnameHilfetextFocused: false,
    vnameHilfetext: "",
    vstrasseHilfetextFocused: false,
    vstrasseHilfetext: "",
    vplzHilfetextFocused: false,
    vplzHilfetext: "",
    vtelHilfetextFocused: false,
    vtelHilfetext: "",
    vfaxHilfetextFocused: false,
    vfaxHilfetext: "",
    vemailHilfetextFocused: false,
    vemailHilfetext: "",
    bezeichnungHilfetextFocused: false,
    bezeichnungHilfetext: "",
    beschreibungHilfetextFocused: false,
    beschreibungHilfetext: "",
    kdnumHilfetextFocused: false,
    kdnumHilfetext: "",
    bemerkungstextHilfetextFocused: false,
    bemerkungstextHilfetext: "",

    validations: {
      bvname: {
        length: {
          minimum: 3
        }
      },
      bvstrasse: {
        length: {
          minimum: 3
        }
      },
      bvplz: {
        length: 5,
        format: {
          'with': /^[0-9]/
        }
      },
      bvort: {
        length: {
          minimum: 2
        }
      },
      vname: {
        length: {
          minimum: 3
        }
      },
      vstrasse: {
        length: {
          minimum: 3
        }
      },
      vplz: {
        length: 5,
        format: {
          'with': /^[0-9]/
        }
      },
      vort: {
        length: {
          minimum: 2
        }
      },
      vfax: {
        format: {
          'with': /^[0-9]{7,25}/
        }
      },
      vtel: {
        format: {
          'with': /^[0-9]{7,25}/
        }
      },
      vemail: {
        format: {
          'with': /^[-a-z0-9~!$%^&*_=+}{\'?]+(\.[-a-z0-9~!$%^&*_=+}{\'?]+)*@([a-z0-9_][-a-z0-9_]*(\.[-a-z0-9_]+)*\.(aero|arpa|biz|com|coop|edu|gov|info|int|mil|museum|name|net|org|pro|travel|mobi|[a-z][a-z])|([0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}))(:[0-9]{1,5})?$/i
        }
      },
      bezeichnung: {
        length: {
          minimum: 2
        }
      },
      beschreibung: {
        length: {
          minimum: 2
        }
      },
      kdnum: {
        format: {
          'with': /^[0-9]{5,25}/
        }
      }
    },

    setValues: function setValues(values) {

      this.set('plzservice', false);

      if (this.debug) {
        console.log("values in projektdaten: ");
      }

      this.set('bvname', values.bvname);
      this.set('bvplz', values.bvplz);
      this.set('bvort', values.bvort);
      this.set('bvstrasse', values.bvstrasse);
      this.set('vname', values.vname);
      this.set('vstrasse', values.vstrasse);
      this.set('vort', values.vort);
      this.set('vplz', values.vplz);
      this.set('vtel', values.vtel);
      this.set('vfax', values.vfax);
      this.set('vemail', values.vemail);
      this.set('bezeichnung', values.bezeichnung);
      this.set('beschreibung', values.beschreibung);
      this.set('kdnum', values.kdnum);
      this.set('bemerkungstext', values.bemerkungstext);

      this.send('storeValues');
    },

    /**
     * Observes the Bvplz input. If the input length is 5 it triggers a webservice to get the city name to the zip code.
     * The city name is set as city in the input field.
     *
     * @method watchBvplz
     */
    watchBvplz: (function () {

      if (this.plzservice === true) {
        var self = this;
        console.log(self.get('bvplz'));
        var bvplz = self.get('bvplz');

        var application = this.controllerFor('application');
        var environment = externalConfig.environments[application.get('environment')];
        var url = externalConfig[environment].uriPLZservice;

        if (bvplz.length === 5) {

          $.get(url + bvplz, function (data) {

            var orte = [];
            var ortelength = data.length;

            var ortsdaten = data[0];
            var ort = ortsdaten.split(";")[1];
            console.log(ort);
            self.set('bvort', ort);
          });
        }
      }
    }).observes('bvplz'),

    /**
     * Observes the vplz input. If the input length is 5 it triggers a webservice to get the city name to the zip code.
     * The city name is set as city in the input field.
     *
     * @method watchVplz
     */
    watchVplz: (function () {

      if (this.plzservice === true) {
        var self = this;
        console.log(self.get('vplz'));
        var vplz = self.get('vplz');

        var application = this.controllerFor('application');
        var environment = externalConfig.environments[application.get('environment')];
        var url = externalConfig[environment].uriPLZservice;

        if (vplz.length === 5) {

          $.get(url + vplz, function (data) {

            var orte = [];
            var ortelength = data.length;

            var ortsdaten = data[0];
            var ort = ortsdaten.split(";")[1];
            console.log(ort);
            self.set('vort', ort);
          });
        }
      }
    }).observes('vplz'),

    actions: {

      /**
       * Stores all values from the input forms in the projektdaten store.
       *
       * @method ACTION: storeValues
       */

      storeValues: function storeValues() {

        var self = this;
        var projektdaten = this.controllerFor('application').get('model').projektdaten.objectAt(0);

        // var applicationdata = self.controllerFor('application').get('model').application.objectAt(0);
        // applicationdata.set('treeLoaded', false);
        // applicationdata.set('initialized', false);
        //
        // var application = self.controllerFor('application');
        // application.set('verbindungsmittelInvalid', true);

        self.controllerFor('projektdaten').set('bname', 'Horst');
        projektdaten.set('bvname', self.get('bvname').replace(",", "."));
        projektdaten.set('bvplz', self.get('bvplz').replace(",", "."));
        projektdaten.set('bvstrasse', self.get('bvstrasse').replace(",", "."));
        projektdaten.set('bvort', self.get('bvort').replace(",", "."));
        projektdaten.set('vname', self.get('vname').replace(",", "."));
        projektdaten.set('vstrasse', self.get('vstrasse').replace(",", "."));
        projektdaten.set('vplz', self.get('vplz').replace(",", "."));
        projektdaten.set('vort', self.get('vort').replace(",", "."));
        projektdaten.set('vtel', self.get('vtel').replace(",", "."));
        projektdaten.set('vfax', self.get('vfax').replace(",", "."));
        projektdaten.set('vemail', self.get('vemail').replace(",", "."));
        projektdaten.set('bezeichnung', self.get('bezeichnung').replace(",", "."));
        projektdaten.set('beschreibung', self.get('beschreibung').replace(",", "."));
        projektdaten.set('kdnum', self.get('kdnum').replace(",", "."));
        projektdaten.set('bemerkungstext', self.get('bemerkungstext'));
      },

      bvnameIsSelected: function bvnameIsSelected() {
        document.getElementsByName('bvname')[0].setSelectionRange(0, this.get('bvname').length);
      },

      bvstrasseIsSelected: function bvstrasseIsSelected() {
        document.getElementsByName('bvstrasse')[0].setSelectionRange(0, this.get('bvstrasse').length);
      },

      bvplzIsSelected: function bvplzIsSelected() {
        document.getElementsByName('bvplz')[0].setSelectionRange(0, this.get('bvplz').length);
      },

      bvortIsSelected: function bvortIsSelected() {
        document.getElementsByName('bvort')[0].setSelectionRange(0, this.get('bvort').length);
      },

      bezeichnungIsSelected: function bezeichnungIsSelected() {
        document.getElementsByName('bezeichnung')[0].setSelectionRange(0, this.get('bezeichnung').length);
      },

      beschreibungIsSelected: function beschreibungIsSelected() {
        document.getElementsByName('beschreibung')[0].setSelectionRange(0, this.get('beschreibung').length);
      },

      vnameIsSelected: function vnameIsSelected() {
        document.getElementsByName('vname')[0].setSelectionRange(0, this.get('vname').length);
      },

      kdnumIsSelected: function kdnumIsSelected() {
        document.getElementsByName('kdnum')[0].setSelectionRange(0, this.get('kdnum').length);
      },

      vstrasseIsSelected: function vstrasseIsSelected() {
        document.getElementsByName('vstrasse')[0].setSelectionRange(0, this.get('vstrasse').length);
      },

      vplzIsSelected: function vplzIsSelected() {
        document.getElementsByName('vplz')[0].setSelectionRange(0, this.get('vplz').length);
      },

      vortIsSelected: function vortIsSelected() {
        document.getElementsByName('vort')[0].setSelectionRange(0, this.get('vort').length);
      },

      vtelIsSelected: function vtelIsSelected() {
        document.getElementsByName('vtel')[0].setSelectionRange(0, this.get('vtel').length);
      },

      vfaxIsSelected: function vfaxIsSelected() {
        document.getElementsByName('vfax')[0].setSelectionRange(0, this.get('vfax').length);
      },

      vemailIsSelected: function vemailIsSelected() {
        document.getElementsByName('vemail')[0].setSelectionRange(0, this.get('vemail').length);
      },

      bemerkungstextIsSelected: function bemerkungstextIsSelected() {
        document.getElementsByName('bemerkungstext')[0].setSelectionRange(0, this.get('bemerkungstext').length);
      },

      toggleTextArea: function toggleTextArea() {
        $('#bemerkungenTextAreaID').toggleClass('fullsize');

        if ($('#bemerkungenTextAreaID').offset().top < $('#threedee').offset().top + $('#x3d').height() + 20) {
          var newMargin = $('#threedee').offset().top + $('#x3d').height() + 20 - $('#bemerkungenTextAreaID').offset().top;
          $('#bemerkungenArea').css('margin-top', newMargin);
        }
      },

      removeClass: function removeClass(element) {
        var self = this;
        self.set(element, false);
      }

    },

    // hilfeTexte obeserver

    observes_bvnameHilfetext: (function () {
      var self = this;
      var hilfetextArray = self.controllerFor('supercontroller').getHelpText("Projektdaten", "bvname", "0");
      self.controllerFor("supercontroller").setHilfetexte("projektdaten", hilfetextArray);
    }).observes('bvname', 'initTrigger'),

    observes_bvstrasseHilfetext: (function () {
      var self = this;
      var hilfetextArray = self.controllerFor('supercontroller').getHelpText("Projektdaten", "bvstrasse", "0");
      self.controllerFor("supercontroller").setHilfetexte("projektdaten", hilfetextArray);
    }).observes('bvstrasse', 'initTrigger'),

    observes_bvplzHilfetext: (function () {
      var self = this;
      var hilfetextArray = self.controllerFor('supercontroller').getHelpText("Projektdaten", "bvplz", "0");
      self.controllerFor("supercontroller").setHilfetexte("projektdaten", hilfetextArray);
    }).observes('bvplz', 'initTrigger'),

    observes_bvortHilfetext: (function () {
      var self = this;
      var hilfetextArray = self.controllerFor('supercontroller').getHelpText("Projektdaten", "bvort", "0");
      self.controllerFor("supercontroller").setHilfetexte("projektdaten", hilfetextArray);
    }).observes('bvort', 'initTrigger'),

    observes_bezeichnungHilfetext: (function () {
      var self = this;
      var hilfetextArray = self.controllerFor('supercontroller').getHelpText("Projektdaten", "bezeichnung", "0");
      self.controllerFor("supercontroller").setHilfetexte("projektdaten", hilfetextArray);
    }).observes('bezeichnung', 'initTrigger'),

    observes_beschreibungHilfetext: (function () {
      var self = this;
      var hilfetextArray = self.controllerFor('supercontroller').getHelpText("Projektdaten", "beschreibung", "0");
      self.controllerFor("supercontroller").setHilfetexte("projektdaten", hilfetextArray);
    }).observes('beschreibung', 'initTrigger'),

    observes_vnameHilfetext: (function () {
      var self = this;
      var hilfetextArray = self.controllerFor('supercontroller').getHelpText("Projektdaten", "vname", "0");
      self.controllerFor("supercontroller").setHilfetexte("projektdaten", hilfetextArray);
    }).observes('vname', 'initTrigger'),

    observes_kdnumHilfetext: (function () {
      var self = this;
      var hilfetextArray = self.controllerFor('supercontroller').getHelpText("Projektdaten", "kdnum", "0");
      self.controllerFor("supercontroller").setHilfetexte("projektdaten", hilfetextArray);
    }).observes('kdnum', 'initTrigger'),

    observes_vstrasseHilfetext: (function () {
      var self = this;
      var hilfetextArray = self.controllerFor('supercontroller').getHelpText("Projektdaten", "vstrasse", "0");
      self.controllerFor("supercontroller").setHilfetexte("projektdaten", hilfetextArray);
    }).observes('vstrasse', 'initTrigger'),

    observes_vplzHilfetext: (function () {
      var self = this;
      var hilfetextArray = self.controllerFor('supercontroller').getHelpText("Projektdaten", "vplz", "0");
      self.controllerFor("supercontroller").setHilfetexte("projektdaten", hilfetextArray);
    }).observes('vplz', 'initTrigger'),

    observes_vortHilfetext: (function () {
      var self = this;
      var hilfetextArray = self.controllerFor('supercontroller').getHelpText("Projektdaten", "vort", "0");
      self.controllerFor("supercontroller").setHilfetexte("projektdaten", hilfetextArray);
    }).observes('vort', 'initTrigger'),

    observes_vtelHilfetext: (function () {
      var self = this;
      var hilfetextArray = self.controllerFor('supercontroller').getHelpText("Projektdaten", "vtel", "0");
      self.controllerFor("supercontroller").setHilfetexte("projektdaten", hilfetextArray);
    }).observes('vtel', 'initTrigger'),

    observes_vfaxHilfetext: (function () {
      var self = this;
      var hilfetextArray = self.controllerFor('supercontroller').getHelpText("Projektdaten", "vfax", "0");
      self.controllerFor("supercontroller").setHilfetexte("projektdaten", hilfetextArray);
    }).observes('vfax', 'initTrigger'),

    observes_vemailHilfetext: (function () {
      var self = this;
      var hilfetextArray = self.controllerFor('supercontroller').getHelpText("Projektdaten", "vemail", "0");
      self.controllerFor("supercontroller").setHilfetexte("projektdaten", hilfetextArray);
    }).observes('vemail', 'initTrigger'),

    observes_bemerkungstextHilfetext: (function () {
      var self = this;
      var hilfetextArray = self.controllerFor('supercontroller').getHelpText("Projektdaten", "bemerkungstext", "0");
      self.controllerFor("supercontroller").setHilfetexte("projektdaten", hilfetextArray);
    }).observes('bemerkungstext', 'initTrigger'),

    // hilfeTexte display properties

    display_bvnameHilfetext: (function () {
      var self = this;
      return self.controllerFor("supercontroller").setHilfetexteDisplayed(self.get('bvnameHilfetext'));
    }).property('bvnameHilfetext'),

    display_bvstrasseHilfetext: (function () {
      var self = this;
      return self.controllerFor("supercontroller").setHilfetexteDisplayed(self.get('bvstrasseHilfetext'));
    }).property('bvstrasseHilfetext'),

    display_bvplzHilfetext: (function () {
      var self = this;
      return self.controllerFor("supercontroller").setHilfetexteDisplayed(self.get('bvplzHilfetext'));
    }).property('bvplzHilfetext'),

    display_bvortHilfetext: (function () {
      var self = this;
      return self.controllerFor("supercontroller").setHilfetexteDisplayed(self.get('bvortHilfetext'));
    }).property('bvortHilfetext'),

    display_bezeichnungHilfetext: (function () {
      var self = this;
      return self.controllerFor("supercontroller").setHilfetexteDisplayed(self.get('bezeichnungHilfetext'));
    }).property('bezeichnungHilfetext'),

    display_beschreibungHilfetext: (function () {
      var self = this;
      return self.controllerFor("supercontroller").setHilfetexteDisplayed(self.get('beschreibungHilfetext'));
    }).property('beschreibungHilfetext'),

    display_vnameHilfetext: (function () {
      var self = this;
      return self.controllerFor("supercontroller").setHilfetexteDisplayed(self.get('vnameHilfetext'));
    }).property('vnameHilfetext'),

    display_kdnumHilfetext: (function () {
      var self = this;
      return self.controllerFor("supercontroller").setHilfetexteDisplayed(self.get('kdnumHilfetext'));
    }).property('kdnumHilfetext'),

    display_vstrasseHilfetext: (function () {
      var self = this;
      return self.controllerFor("supercontroller").setHilfetexteDisplayed(self.get('vstrasse'));
    }).property('vstrasse'),

    display_vplzHilfetext: (function () {
      var self = this;
      return self.controllerFor("supercontroller").setHilfetexteDisplayed(self.get('vplzHilfetext'));
    }).property('vplzHilfetext'),

    display_vortHilfetext: (function () {
      var self = this;
      return self.controllerFor("supercontroller").setHilfetexteDisplayed(self.get('vortHilfetext'));
    }).property('vortHilfetext'),

    display_vtelHilfetext: (function () {
      var self = this;
      return self.controllerFor("supercontroller").setHilfetexteDisplayed(self.get('vtelHilfetext'));
    }).property('vtelHilfetext'),

    display_vfaxHilfetext: (function () {
      var self = this;
      return self.controllerFor("supercontroller").setHilfetexteDisplayed(self.get('vfaxHilfetext'));
    }).property('vfaxHilfetext'),

    display_vemailHilfetext: (function () {
      var self = this;
      return self.controllerFor("supercontroller").setHilfetexteDisplayed(self.get('vemailHilfetext'));
    }).property('vemailHilfetext'),

    display_bemerkungstextHilfetext: (function () {
      var self = this;
      return self.controllerFor("supercontroller").setHilfetexteDisplayed(self.get('bemerkungstextHilfetext'));
    }).property('bemerkungstextHilfetext')

  });

});