define('m03/models/optionen', ['exports', 'ember-data'], function (exports, DS) {

	'use strict';

	var optionen = DS['default'].Model.extend({

		verbindungsmittelVersetzt: DS['default'].attr('boolean'),
		uScheibe: DS['default'].attr('boolean'),
		querschnittsnachweise: DS['default'].attr('boolean'),
		aussermitteBeruecksichtigen: DS['default'].attr('boolean')
	});

	optionen.reopenClass({
		FIXTURES: [{
			id: 1,
			verbindungsmittelVersetzt: true,
			uScheibe: false,
			querschnittsnachweise: false,
			aussermitteBeruecksichtigen: true
		}]
	});

	exports['default'] = optionen;

});