define('m03/controllers/optionen', ['exports', 'ember', 'ember-validations', 'ember-i18n'], function (exports, Ember, EmberValidations, ember_i18n) {

  'use strict';

  exports['default'] = Ember['default'].Controller.extend(EmberValidations['default'], {

    i18n: Ember['default'].inject.service(),

    initTrigger: false,

    verbindungsmittelVersetzt: true,
    uScheibe: false,
    querschnittsnachweise: false,
    aussermitteBeruecksichtigen: true,

    openFromFile: false,

    vm_um_1d_versetzenHilfetext: "",
    vm_um_1d_versetzenHilfetextFocused: false,
    uScheibeHilfetext: "",
    uScheibeHilfetextFocused: false,
    querschnittsnachweiseFuehrenHilfetext: "",
    querschnittsnachweiseFuehrenHilfetextFocused: false,
    aussermitteBeruecksichtigenHilfetext: "",
    aussermitteBeruecksichtigenHilfetextFocused: false,

    init: function init() {
      this._super();
    },

    setValues: function setValues(values) {

      var self = this;

      if (self.debug) {
        console.log("values in bauteile: ");
        console.log(values);
      }

      // this.set('openFromFile', true);

      this.set('verbindungsmittelVersetzt', values.verbindungsmittelVersetzt);
      this.set('querschnittsnachweise', values.querschnittsnachweise);
      this.set('aussermitteBeruecksichtigen', values.aussermitteBeruecksichtigen);

      // this.send('validation');
      //
      // this.set('openFromFile', false);
    },

    watchverbindungsmittelVersetzt: (function () {
      var self = this;
      self.controllerFor('supercontroller').resetApplication();
      var application = this.controllerFor('application');
      var optionenData = application.get('model').optionen.objectAt(0);
      optionenData.set('verbindungsmittelVersetzt', self.get('verbindungsmittelVersetzt'));
    }).observes('verbindungsmittelVersetzt'),

    watchUScheibe: (function () {
      var self = this;
      self.controllerFor('supercontroller').resetApplication();
      var application = this.controllerFor('application');
      var optionenData = application.get('model').optionen.objectAt(0);
      optionenData.set('uScheibe', self.get('uScheibe'));

      var x3d = application.get('model').x3ddefault.objectAt(0);
      x3d.set("uScheibe", self.get('uScheibe'));

      self.controllerFor('bauteile').setSchraubenlaenge();
      this.controllerFor('application').get('model').x3ddefault.objectAt(0).set('transformHelper', !application.get('model').x3ddefault.objectAt(0).get('transformHelper'));
    }).observes('uScheibe'),

    watchQuerschnittsnachweise: (function () {
      var self = this;
      self.controllerFor('supercontroller').resetApplication();
      var application = this.controllerFor('application');
      var optionenData = application.get('model').optionen.objectAt(0);
      optionenData.set('querschnittsnachweise', self.get('querschnittsnachweise'));
    }).observes('querschnittsnachweise'),

    watchAussermitteBeruecksichtigen: (function () {
      var self = this;
      self.controllerFor('supercontroller').resetApplication();
      var application = this.controllerFor('application');
      var optionenData = application.get('model').optionen.objectAt(0);
      optionenData.set('aussermitteBeruecksichtigen', self.get('aussermitteBeruecksichtigen'));
    }).observes('aussermitteBeruecksichtigen'),

    setX3D: function setX3D(value, event) {

      var application = this.controllerFor('application');
      var x3d = application.get('model').x3ddefault.objectAt(0);

      var optionenData = application.get('model').optionen.objectAt(0);

      application.get('model').x3ddefault.objectAt(0).set('ergebnisGeladen', false);
      application.get('model').x3ddefault.objectAt(0).set('aktuellerSchraubenTyp', 'standardSchraube');
      application.get('model').x3ddefault.objectAt(0).set('schraubenlisteAktualisiert', !application.get('model').x3ddefault.objectAt(0).get('schraubenlisteAktualisiert'));

      value = parseFloat(value).toFixed(1);

      switch (event.target.name) {
        case 'verbindungsmittelVersetzt':
          if (this.errors._min_n0.length === 0) {} else {}
          break;
      }

      this.controllerFor('application').get('model').x3ddefault.objectAt(0).set('transformHelper', !application.get('model').x3ddefault.objectAt(0).get('transformHelper'));
      this.controllerFor('application').zentriertObjekt();
    },

    actions: {

      validation: function validation(value, event) {

        var self = this;

        if (!self.openFromFile) {

          this.setX3D(value, event);
        }

        self.validate().then(function () {
          // all validations pass

        })['catch'](function () {
          // any validations fail

        })['finally'](function () {

          var validation = self.get('isValid');
          var application = self.controllerFor('application');
          if (validation === true) {
            self.set('displayErrors', false);
            application.set('optionenInvalid', false);

            self.controllerFor('supercontroller').resetApplication();
          } else {
            self.set('displayErrors', true);
            application.set('optionenInvalid', true);
          }
        });
      },

      removeClass: function removeClass(element) {
        var self = this;
        self.set(element, false);
      }

    }, // Ende actions

    // hilfeTexte observes

    observes_vm_um_1d_versetzenHilfetext: (function () {
      var self = this;

      var hilfetextArray = self.controllerFor('supercontroller').getHelpText("Optionen", "vm_um_1d_versetzen", "0");
      self.controllerFor("supercontroller").setHilfetexte("optionen", hilfetextArray);

      var helptextProperty = hilfetextArray[0].text;

      // if(self.get('verbindungsmittelVersetzt') === true){
      // self.set('vm_um_1d_versetzenHilfetext', hilfetextArray[0].text);
      // }else{
      //   self.set('vm_um_1d_versetzenHilfetext', " Nach EN 1995-1-1 8.3.1.1 (8) können die Verbindungsmittel (Nägel und Schrauben) bei einer Scherverbindung in Faserrichtung versetzt zueinander angeordnet werden.<br> Falls die Verbindungsmittel in Faserrichtung nicht um mindestens 1d versetzt zueinander angeordnet werden, ist die effektive Verbindungsmittelanzahl n<sub>ef</sub>, mit der die Gesamttragfähigkeit der Verbindung berechnet wird, in der Regel abzumindern.<br><br> Versetzen der Verbindungsmittel in einer Reihe parallel zur Faserrichtung um 1d rechtwinklig zur Faserrichtung:<br> <img src=\"http://holzbauverbinder.de/externalAssets/bilder/faserversatz.png\" alt=\"Bild Faserversatz\" style=\"width: 670px; height: 176px;\"><br> ")
      // }

      // console.log('display_vm_um_1d_versetzenHilfetext');
      // console.log(self.get('display_vm_um_1d_versetzenHilfetext'));
      // console.log('vm_um_1d_versetzenHilfetext');
      // console.log(self.get('vm_um_1d_versetzenHilfetext'));
      // console.log(self.get('i18n').t(helptextProperty));
      // console.log(self.get('i18n').t(helptextProperty).substring(0, 20));

      // for(var i=0; i<hilfetextArray.length; i++){
      //   self.set(hilfetextArray[i].bezeichnung, hilfetextArray[i].text);
      // if(self.get('i18n').t(hilfetextArray[i].text).substring(0, 20).toString() === "Missing translation:" || typeof self.get('i18n').t(hilfetextArray[i].text) === "undefined" || self.get('i18n').t(hilfetextArray[i].text) === ""){
      //   self.set("display_"+hilfetextArray[i].bezeichnung, false);
      // }else{
      //  self.set("display_"+hilfetextArray[i].bezeichnung, true);
      // }

      // }

      // if(self.get('i18n').t(helptextProperty).substring(0, 20).toString() === "Missing translation:" || typeof self.get('i18n').t(helptextProperty) === "undefined" || self.get('i18n').t(helptextProperty) === ""){
      //   return false;
      // }
      // return true;
    }).observes('verbindungsmittelVersetzt', 'initTrigger'),

    // observes_vm_um_1d_versetzenHilfetext: function(){
    //   var self = this;
    //
    //   var hilfetextArray = self.controllerFor('supercontroller').getHelpText("Optionen", "vm_um_1d_versetzen", "0");
    //   self.controllerFor("supercontroller").setHilfetexte("optionen", hilfetextArray);
    //
    //   var helptextProperty = hilfetextArray[0].text;
    // }.observes('uScheibe', 'initTrigger'),

    observes_querschnittsnachweiseFuehrenHilfetext: (function () {
      var self = this;

      var hilfetextArray = self.controllerFor('supercontroller').getHelpText("Optionen", "querschnittsnachweiseFuehren", "0");
      self.controllerFor("supercontroller").setHilfetexte("optionen", hilfetextArray);

      // if(self.get('verbindungsmittelVersetzt') === true){
      //   self.set('querschnittsnachweiseHilfetext', self.get('i18n').t('querschnittsnachweiseFuehren'))
      // }else{
      //   self.set('querschnittsnachweiseHilfetext', "leer")
      // }
      //
      // if(typeof self.get('i18n').t('querschnittsnachweiseFuehren') !== "undefined" || self.get('i18n').t('querschnittsnachweiseFuehren') !== ""){
      //   return true;
      // }
      // return false;
    }).observes('querschnittsnachweise', 'initTrigger'),

    observes_aussermitteBeruecksichtigenHilfetext: (function () {
      var self = this;
      var hilfetextArray = self.controllerFor('supercontroller').getHelpText("Optionen", "aussermitteBeruecksichtigen", "0");
      self.controllerFor("supercontroller").setHilfetexte("optionen", hilfetextArray);
    }).observes('aussermitteBeruecksichtigen', 'initTrigger'),

    // hilfeTexte display properties

    display_vm_um_1d_versetzenHilfetext: (function () {
      var self = this;
      return self.controllerFor("supercontroller").setHilfetexteDisplayed(self.get('vm_um_1d_versetzenHilfetext'));;
    }).property('vm_um_1d_versetzenHilfetext'),

    display_uScheibeHilfetext: (function () {
      var self = this;
      return self.controllerFor("supercontroller").setHilfetexteDisplayed(self.get('uScheibeHilfetext'));;
    }).property('vm_um_1d_versetzenHilfetext'),

    display_querschnittsnachweiseFuehrenHilfetext: (function () {
      var self = this;
      return self.controllerFor("supercontroller").setHilfetexteDisplayed(self.get('querschnittsnachweiseFuehrenHilfetext'));
    }).property('uScheibeHilfetext'),

    display_aussermitteBeruecksichtigenHilfetext: (function () {
      var self = this;
      return self.controllerFor("supercontroller").setHilfetexteDisplayed(self.get('aussermitteBeruecksichtigenHilfetext'));
    }).property('aussermitteBeruecksichtigenHilfetext')

  });

});