define('m03/locales_wuerth/fr/translations', ['exports'], function (exports) {

  'use strict';

  exports['default'] = {

    berechnung_pdf: 'Aperçu PDF',
    setztiefe: 'Profondeur',
    vorgebohrt: 'Trous prépercés',
    bauteile: 'Éléments de construction',
    hausnummer: 'Numéro de rue *',
    gew_schraube: 'Sélection :',
    SH: 'Acier - Bois',
    email: 'E-mail ',
    scherkraft: 'Cisaillement',
    nicht_vorgebohrt: 'Trous non prépercés',
    scherfl_vbm: 'Zones de cisaillement par vis',
    beschreibung: 'Description',
    festigkeit: 'Résistance mécanique',
    scherfl2: '2 plans de cisaillement',
    scherfl1: '1 plan de cisaillement',
    modul_alt: 'M03 - Traction - Cisaillement',
    mat: 'Matériau 1',
    staendig: 'permanent',
    lasteinwirkung2: 'Charges',
    tt_text: 'Rapport en PDF',
    ergebnis: 'Résultat',
    eingabeerforderlich: 'Entrée obligatoire !',
    plz: 'CP ',
    kompakterg: 'Résumé',
    eingabe_ok: 'Entrée correcte',
    winkel: 'Orientation de éléments de construction',
    sehr_kurz: 'instantanée',
    msg: 'Afficher toutes les vis',
    bezeichnung: 'désignation',
    lang: 'long',
    tel: 'Téléphone ',
    schnittig2: '2 plans de cisaillement par vis',
    ort: 'Localité ',
    schnittig1: '1 plan de cisaillement par vis',
    land: 'Pays',
    projektdaten: 'Données du projet',
    fax: 'Fax ',
    kdnum: 'Numéro de client',
    verbindungsmittel: 'Fixations',
    geometrie: 'Géométrie',
    def_bereich: 'domaine de définition',
    schichtfolge: 'Matériaux à assembler',
    wklscherkraft: 'Angle d\'orientation du cisaillement',
    artnr: 'Référence',
    name: 'Nom',
    nkl: 'Classe de service',
    adresse: 'Rue, Numéro de rue ',
    mittel: 'moyen',
    lasteinwirkung: 'Efforts',
    modul: 'M03 - Traction - Cisaillement',
    led: 'Durée de chargement',
    scherfl_anschl: 'Plan de cisaillement de l\'assemblage',
    verarbeiter: 'Rédacteur/Maître d\'ouvrage',
    vbms: 'Fixations',
    nkl3: 'Classe de service 3',
    hoehe: 'Hauteur',
    mat1: 'Matériau 2',
    nkl2: 'Classe de service 2',
    nkl1: 'Classe de service 1',
    position: 'Position',
    axialkraft: 'Traction',
    strasse: 'Rue ',
    antrieb: 'Embout',
    bauvorhaben: 'Projet de construction',
    vbm: 'Fixations',
    hinweisLaubholz: 'Les vis doivent être pré-percés quand elles sont utilisées en bois feuillu!',
    auftraggeber: 'Donneur d\'ordre',
    dicke: 'Épaisseur',
    raxtd: 'Résistance de calcul en traction',
    HH: 'Bois/dérivés du bois - Bois',
    rlatd: 'Résistance de calcul en cisaillement',
    kurz: 'court',

    nachBlass: 'La couche intermédiaire de Blass',
    nachWerner: 'Gain de Werner',
    winkelKlammerruecken: 'Angle agrafe - direction de la fibre',
    zwischenschichtVerstaerkung: 'Couche intermédiaire/ renfort',
    zwischenschicht: 'Couche intermédiaire',
    verstaerkung: 'Renforcement',
    umwelteinfluesse: 'Influences de l\'environnement, imprégnation',
    erhoehtesKorrosionsrisiko: 'risque fort de corrosion',

    bemessungssoftware: 'Würth: Technique de vissage - Construction Bois',
    wertebereich: "Plage de valeurs autorisée:",
    ergebnisanzeigen: "Afficher les résultats:",
    einstellung: "Configuration",
    spracheinstellungen: "Sélection langues",
    eingabe: "Configuration de langue de l\'interface",
    ausgabe: "Configuration de langue pour le rapport",
    landeseinstellungen: "Paramètrage local (pays)",
    bemessungsvorschrift: "Conforme à la norme",
    liefersortiment: "Configuration de langue du catalogue",
    produktkatalog: "Catalogue",
    produktinfo: "Fiche technique",
    eta: "ATE",
    cad: "CAD",
    impressum: "A propos",
    extras: "Documents",
    bemerkungen: " U203a Commentaires",
    bemerkungstext: "texte du commentaire",
    eingabedaten: "les données saisies",
    berechnungalspdf: "Aperçu PDF",
    berechnungspeichern: "Enregistrer le projet",
    berechnungoeffnen: "Ouvrir le Projet",
    downloadinstructions1: "Ouvrir le fichier dans une nouvelle fenêtre:  clic gauche sur les icônes.",
    downloadinstructions2: "Faites un clic droit sur les icônes ->  « Enregistrer sous » / « Enregistrer le lien sous » pour un fichier sur le disque dur pour stocker localement.",
    downloadinstructions3: "Indiquer un nom de fichier sous lequel vous voulez télécharger les fichiers sélectionnés comme package commun.",
    eingabedatennichtgespeichert: "Les données d\'entrée ont pas été enregistrées.",
    pdfnochnichterstellt: "Pdf pas encore généré",
    dlfilename: "nom du fichier",
    dlpaketErstellen: "Créer package de téléchargement",
    nochkeineschraubegewaehlt: "Vis pas encore séléectionnée",
    nochkeindlerzeugt: "Il n\'a pas créé un package de téléchargement.",
    neueberechnung: "Nouveau projet",
    kontakt: "Contact",
    herausgeber: "Rédacteur",
    registergericht: "Registergericht",
    geschaeftsfuehrer: "directeur général",
    entwickeltvon: "développé par",

    dansk: "Danois",
    deutsch: "Deutsch",
    englisch: "English",
    franzoesisch: "Français",
    italienisch: "Italien",
    nederlands: "Néerlandais",
    polnisch: "Polonais",
    espanol: "Espagnol",
    suomi: "Finnois",
    schwedisch: "Suédois",

    schweiz: "Suisse",
    daenemark: "Danemark",
    deutschland: "Allemagne",
    oesterreich: "Österreich",
    england: "Angleterre",
    frankreich: "France",
    italien: "Italie",
    niederlande: "Pays-Bas",
    polen: "Pologne",
    spanien: "Espagne",
    chile: "Chili",
    finnland: "Finlande",
    schweden: "Suède",

    MatGrp0000: "Bois massif tendre",
    MatGrp0001: "Lamellé collé, homogène",
    MatGrp0002: "Bois multi plis",
    MatGrp0003: "Poutres composites (double, triple)",
    MatGrp0004: "Contreplaqué latté",
    MatGrp0005: "xContreplaqué",
    MatGrp0006: "Bois multi plis orientés",
    MatGrp0007: "Panneau de particules",
    MatGrp0008: "Panneau fibro ciment",
    MatGrp0009: "Panneau de fibres de bois, dur HB.HLA2",
    MatGrp0010: "Panneau de fibres de bois  MBH.LA2",
    MatGrp0012: "Bois massif, bois feuillu",
    MatGrp0013: "Lamellé-collé, combiné",
    MatGrp0015: "Contreplaqué multi plis",
    MatGrp0020: "Panneau OSB",
    MatGrp0021: "Contreplaqué",
    MatGrp0022: "Plaque de plâtre (DIN 18180)",
    MatGrp0030: "Pollmeier Bau-Buche",
    MatGrp1000: "Acier",
    MatGrp9100: "Beton",
    MatGrp9999: "Luft",

    Mat1000: "Acier S235JR",
    Mat1001: "Acier S355JR",
    Mat9999: "----",
    Mat000: "C14",
    Mat001: "C16",
    Mat002: "C18",
    Mat003: "C20",
    Mat004: "C22",
    Mat005: "C24",
    Mat006: "C27",
    Mat007: "C30",
    Mat008: "C35",
    Mat009: "C40",
    Mat010: "C45",
    Mat011: "C50",
    Mat050: "D30",
    Mat051: "D35",
    Mat052: "D40",
    Mat053: "D50",
    Mat054: "D60",
    Mat055: "D70",
    Mat020: "GL24h",
    Mat030: "GL24c",
    Mat021: "GL28h",
    Mat031: "GL28c",
    Mat022: "GL32h",
    Mat032: "GL32c",
    Mat023: "GL36h",
    Mat033: "GL36c",
    Mat160: "Panneau de particules classe P4 (EN 312)",
    Mat170: "Panneau de particules classe P5 (EN 312)",
    Mat180: "Panneau de particules classe P6 (EN 312)",
    Mat190: "Panneau de particules classe P7 (EN 312)",
    Mat200: "Panneau de particules cimenté classe 1+2 (EN 634)",
    Mat220: "Panneau de fibres de bois, dur HB.HLA2 (EN 622-2)",
    Mat230: "Panneau de fibres de bois  MBH.LA2 (EN 622-3)",
    Mat125: "OSB/2 (EN 300)",
    Mat126: "OSB/3 (EN 300)",
    Mat127: "OSB/4 (EN 300)",
    Mat110: "Contreplaqué F25/10",
    Mat111: "Contreplaqué F40/40 E60/40",
    Mat112: "Contreplaqué F50/25 E70/25",
    Mat113: "Contreplaqué F60/10 E90/10",
    Mat520: "Plaque de plâtre (DIN 18180)",
    Mat9120: "C20/25",
    Mat9125: "C25/30",
    Mat9130: "C30/37",
    Mat9135: "C35/45",
    Mat9140: "C40/50",
    Mat9145: "C45/55",
    Mat024: "GL30h",
    Mat034: "GL30c",
    Mat301: "GL70",

    errors: {
      inclusion: "is not included in the list",
      exclusion: "is reserved",
      invalid: "Saisie non valide",
      confirmation: "doesn\'t match {{attribute}}",
      accepted: "must be accepted",
      empty: "can\'t be empty",
      blank: "can\'t be blank",
      present: "must be blank",
      tooLong: "Eingabe zu lang. Maximal erlaubte Länge sind {{count}} Zeichen.",
      tooShort: "!2",
      wrongLength: "is the wrong length (should be {{count}} characters)",
      notANumber: "is not a number",
      notAnInteger: "must be an integer",
      greaterThan: "must be greater than {{count}}",
      greaterThanOrEqualTo: "must be greater than or equal to {{count}}",
      equalTo: "must be equal to {{count}}",
      lessThan: "must be less than {{count}}",
      lessThanOrEqualTo: "must be less than or equal to {{count}}",
      otherThan: "must be other than {{count}}",
      odd: "must be odd",
      even: "must be even"
    },
    'language-select.language.de': "Deutsch",
    'language-select.language.en': "English"

  };

});