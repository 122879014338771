define('m03/models/cone', ['exports', 'ember-data'], function (exports, DS) {

  'use strict';

  exports['default'] = DS['default'].Model.extend({
    radiusUnten: DS['default'].attr('number'),
    radiusOben: DS['default'].attr('number'),
    hoehe: DS['default'].attr('number')
  });

});