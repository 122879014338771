define('m03/locales_wuerth/dk/translations', ['exports'], function (exports) {

  'use strict';

  exports['default'] = {

    berechnung_pdf: 'Beregning i PDF',
    setztiefe: 'Sættedybde',
    vorgebohrt: 'Forborede huller',
    bauteile: 'Bygningsdele',
    hausnummer: 'Husnummer',
    gew_schraube: 'Valgt skrue',
    SH: 'Stål-træ',
    email: 'E-mail',
    scherkraft: 'Forskydningskraft',
    nicht_vorgebohrt: 'Ingen forborede huller',
    scherfl_vbm: 'Snitflader pr. forbindelsesmiddel',
    beschreibung: 'Beskrivelse',
    festigkeit: 'Styrkeklasse',
    scherfl2: '2 snitfladerflader',
    scherfl1: '1 snitflade',
    modul_alt: 'M03 - Skruediameter',
    mat: 'Materiale1',
    staendig: 'permanent',
    lasteinwirkung2: 'Lastpåvirkning',
    tt_text: 'Statisk anbefaling udlæses i PDF-format',
    ergebnis: 'Resultat',
    eingabeerforderlich: 'Data påkrævet!',
    plz: 'Postnummer',
    kompakterg: 'Kompakt resultat',
    eingabe_ok: 'Korrekt indlæsning',
    winkel: 'Vinkel mellem emner',
    sehr_kurz: 'meget kort',
    msg: 'Vis skruer uden godkendelse',
    bezeichnung: 'Beskrivelse',
    lang: 'lang',
    tel: 'Telefon',
    schnittig2: '2 snit',
    ort: 'By',
    schnittig1: '1 snit',
    land: 'Land',
    projektdaten: 'Projektdata',
    fax: 'Fax',
    kdnum: 'Kundenummer',
    verbindungsmittel: 'Forbindelsesmiddel / skruetype',
    geometrie: 'Geometri',
    def_bereich: 'Definitionsområde',
    schichtfolge: 'Lagserie',
    wklscherkraft: 'Retningsvinkel for forskydningskraft',
    artnr: 'Artikelnummer',
    name: 'Navn',
    nkl: 'Brugsklasse',
    adresse: 'Gade, husnummer',
    mittel: 'middel',
    lasteinwirkung: 'Lastpåvirkning',
    modul: 'Skruedimension - M03',
    led: 'Lastpåvirkningstid',
    scherfl_anschl: 'Glideflade pr. forbindelse',
    verarbeiter: 'Medarbejder / opgavestiller',
    vbms: 'Forbindelsesmiddel / skruetype',
    nkl3: 'Brugsklasse 3',
    hoehe: 'Højde',
    mat1: 'Materiale2',
    nkl2: 'Brugsklasse 2',
    nkl1: 'Brugsklasse 1',
    position: 'Position',
    axialkraft: 'Aksial kraft',
    strasse: 'Gade',
    antrieb: 'Kærv',
    bauvorhaben: 'Byggeprojekt',
    vbm: 'Forbindelsesmiddel / skruetype',
    hinweisLaubholz: '#no value#',
    auftraggeber: 'Ordregiver',
    dicke: 'Tykkelse',
    raxtd: 'Regningsmæssig værdi for trækbæreevne',
    HH: 'Træ/træmaterialer - Træ',
    rlatd: 'Regningsmæssig værdi for forskydningsbæreevne',
    kurz: 'kort',

    nachBlass: '#no value#',
    nachWerner: '#no value#',
    winkelKlammerruecken: '#no value#',
    zwischenschichtVerstaerkung: '#no value#',
    zwischenschicht: '',
    verstaerkung: 'Forstærkning',
    umwelteinfluesse: '#no value#',
    erhoehtesKorrosionsrisiko: '#no value#',

    bemessungssoftware: '#no value#',
    wertebereich: "#no value#",
    ergebnisanzeigen: "#no value#",
    einstellung: "Indstillinger",
    spracheinstellungen: "Sprogindstilling",
    eingabe: "Sprogindstilling for inddata",
    ausgabe: "Sprogindstilling for uddata",
    landeseinstellungen: "#no value#",
    bemessungsvorschrift: "Dimensioneringsforskrift",
    liefersortiment: "Sprogindstilling for katalog",
    produktkatalog: "Produktkatalog",
    produktinfo: "#no value#",
    eta: "ETA",
    cad: "CAD",
    impressum: "Impressum",
    extras: "Ekstra",
    bemerkungen: "#no value#",
    bemerkungstext: "#no value#",
    eingabedaten: "#no value#",
    berechnungalspdf: "Beregning i PDF",
    berechnungspeichern: "",
    berechnungoeffnen: "",
    downloadinstructions1: "#no value#",
    downloadinstructions2: "#no value#",
    downloadinstructions3: "#no value#",
    eingabedatennichtgespeichert: "#no value#",
    pdfnochnichterstellt: "#no value#",
    dlfilename: "#no value#",
    dlpaketErstellen: "#no value#",
    nochkeineschraubegewaehlt: "#no value#",
    nochkeindlerzeugt: "#no value#",
    neueberechnung: "",
    kontakt: "#no value#",
    herausgeber: "#no value#",
    registergericht: "#no value#",
    geschaeftsfuehrer: "#no value#",
    entwickeltvon: "#no value#",

    dansk: "#no value#Danish",
    deutsch: "Tysk",
    englisch: "Engelsk",
    franzoesisch: "#no value#French",
    italienisch: "#no value#Italian",
    nederlands: "#no value#Dutch",
    polnisch: "#no value#Polish",
    espanol: "#no value#Spanish",
    suomi: "#no value#Finnish",
    schwedisch: "#no value#Swedich",

    schweiz: "#no value#Switzerland",
    daenemark: "#no value#Denmark",
    deutschland: "Tyskland",
    oesterreich: "",
    england: "#no value#England",
    frankreich: "#no value#France",
    italien: "#no value#Italy",
    niederlande: "#no value#Netherlands",
    polen: "#no value#Poland",
    spanien: "#no value#Spain",
    chile: "#no value#Chile",
    finnland: "#no value#Finland",
    schweden: "#no value#Sweden",

    MatGrp0000: "Massivt træ Nåletræ",
    MatGrp0001: "Limtræ, homogent",
    MatGrp0002: "Lamineret finér",
    MatGrp0003: "Laminerede bjælker",
    MatGrp0004: "Massiv træplade i flere lag",
    MatGrp0005: "xByggekrydsfinér",
    MatGrp0006: "Lamineret finér (alle fibre parallelle)",
    MatGrp0007: "Kunstharpiksbundne spånplader",
    MatGrp0008: "Cementbundne spånplader",
    MatGrp0009: "Træfiberplader HB.HLA2",
    MatGrp0010: "Træfiberplader MHB.LA2",
    MatGrp0012: "Massivt løvtræ",
    MatGrp0013: "Limtræ, kombineret",
    MatGrp0015: "Krydslamineret tømmer",
    MatGrp0020: "OSB - plader",
    MatGrp0021: "Byggekrydsfinér",
    MatGrp0022: "",
    MatGrp0030: "Pollmeier Bau-Buche",
    MatGrp1000: "Stål",
    MatGrp9100: "Beton",
    MatGrp9999: "Luft",

    Mat1000: "",
    Mat1001: "",
    Mat9999: "----",
    Mat000: "C14",
    Mat001: "C16",
    Mat002: "C18",
    Mat003: "C20",
    Mat004: "C22",
    Mat005: "C24",
    Mat006: "C27",
    Mat007: "C30",
    Mat008: "C35",
    Mat009: "C40",
    Mat010: "C45",
    Mat011: "C50",
    Mat050: "D30",
    Mat051: "D35",
    Mat052: "D40",
    Mat053: "D50",
    Mat054: "D60",
    Mat055: "D70",
    Mat020: "GL24h",
    Mat030: "GL24c",
    Mat021: "GL28h",
    Mat031: "GL28c",
    Mat022: "GL32h",
    Mat032: "GL32c",
    Mat023: "GL36h",
    Mat033: "GL36c",
    Mat160: "",
    Mat170: "",
    Mat180: "",
    Mat190: "",
    Mat200: "",
    Mat220: "",
    Mat230: "",
    Mat125: "",
    Mat126: "",
    Mat127: "",
    Mat110: "",
    Mat111: "",
    Mat112: "",
    Mat113: "",
    Mat520: "",
    Mat9120: "C20/25",
    Mat9125: "C25/30",
    Mat9130: "C30/37",
    Mat9135: "C35/45",
    Mat9140: "C40/50",
    Mat9145: "C45/55",
    Mat024: "GL30h",
    Mat034: "GL30c",
    Mat301: "GL70",

    errors: {
      inclusion: "is not included in the list",
      exclusion: "is reserved",
      invalid: "#no value#",
      confirmation: "doesn\'t match {{attribute}}",
      accepted: "must be accepted",
      empty: "can\'t be empty",
      blank: "can\'t be blank",
      present: "must be blank",
      tooLong: "Eingabe zu lang. Maximal erlaubte Länge sind {{count}} Zeichen.",
      tooShort: "!2",
      wrongLength: "is the wrong length (should be {{count}} characters)",
      notANumber: "is not a number",
      notAnInteger: "must be an integer",
      greaterThan: "must be greater than {{count}}",
      greaterThanOrEqualTo: "must be greater than or equal to {{count}}",
      equalTo: "must be equal to {{count}}",
      lessThan: "must be less than {{count}}",
      lessThanOrEqualTo: "must be less than or equal to {{count}}",
      otherThan: "must be other than {{count}}",
      odd: "must be odd",
      even: "must be even"
    },
    'language-select.language.de': "Tysk",
    'language-select.language.en': "English"

  };

});