define('m03/components/klamme-r', ['exports', 'ember'], function (exports, Ember) {

  'use strict';

  var Transform = Ember['default'].Component.extend({

    tagName: 'transform',
    layoutName: 'klamme-r',
    attributeBindings: ['translation', 'rotation', 'bboxcenter', 'bboxsize', 'center', 'scale', 'scaleorientation'],
    classNames: ['testClassNameXXX'],
    bboxcenter: "0 0 0",
    bboxsize: "-1,-1,-1",
    scale: "1,1,1",
    scaleorientation: "0,0,0,0",

    objektSkalierung: 1,

    indexHelper: false,

    wandDicke: 0.025,

    center: (function () {
      var name = this.get('name');
      var x3d = this.get('model').objectAt(0);
      var bauteile = x3d.get('bauteile');
      var item = bauteile.findBy('id', name);

      var wandDicke = Number(this.get('wandDicke'));

      var klammerrueckenlaenge = Number(item.get('screwProperties').objectAt(0).get('headdiameter')) / 10;

      var x = 0;
      var y = klammerrueckenlaenge / 2;
      var z = 0;

      var cords = x + ' ' + y + ' ' + z;

      return cords;
    }).property(''),

    translation: (function () {
      var name = this.get('name');
      var x3d = this.get('model').objectAt(0);
      var bauteile = x3d.get('bauteile');
      var item = bauteile.findBy('id', name);

      var wandDicke = Number(this.get('wandDicke'));

      var klammerrueckenlaenge = Number(item.get('screwProperties').objectAt(0).get('headdiameter')) / 20;

      var klammerlaenge = Number(item.get('screwProperties').objectAt(0).get('length')) / 20;

      var x = 0;
      var y = 0;
      var z = 0;

      if (x3d.get('ergebnisGeladen') === true) {
        x = Number(item.get('punkt').objectAt(0).get('x')) / 10;
        y = Number(item.get('punkt').objectAt(0).get('y')) / 10 - klammerrueckenlaenge;
        z = Number(item.get('punkt').objectAt(0).get('z')) / 10 + klammerlaenge * item.get('richtungsvektor').objectAt(0).get('z') / Math.abs(item.get('richtungsvektor').objectAt(0).get('z'));

        console.log('ypos: ' + Number(item.get('punkt').objectAt(0).get('z')));
        console.log('klammerlaenge: ' + klammerlaenge);
      } else {
        x = Number(item.get('translations').objectAt(0).get('x')) / 10;
        y = Number(item.get('translations').objectAt(0).get('y')) / 10 - klammerrueckenlaenge;
        z = Number(item.get('translations').objectAt(0).get('z')) / 10 + klammerlaenge;
      }

      var cords = x + ' ' + y + ' ' + z;

      return cords;
    }).property('model.firstObject.transformHelper'),

    rotation: (function () {
      var name = this.get('name');
      var x3d = this.get('model').objectAt(0);
      var bauteile = x3d.get('bauteile');
      var item = bauteile.findBy('id', name);

      var alpha = 0;

      if (x3d.get('ergebnisGeladen') === true) {
        alpha = Number(item.get('lagewinkel').objectAt(0).get('winkel')) * Math.PI / 180;
      }

      var x = 0;
      var y = 0;
      var z = 1;
      var winkel = 1.5708 + alpha;

      var values = x + ' ' + y + ' ' + z + ' ' + winkel;

      return values;
    }).property('model.firstObject.transformHelper'),

    koordinaten: (function () {
      var name = this.get('name');
      var typ = this.get('typ');
      var x3d = this.get('model').objectAt(0);
      var bauteile = x3d.get('bauteile');

      var cords = '0 0 0 0 0 0 0 0 0 0 0 0';

      var item = x3d.get('bauteile').findBy('id', name);

      if (x3d.get('ergebnisGeladen') === true) {
        if (item.get('richtungsvektor').objectAt(0).get('z') === -1) {
          cords = this.getKoordinatenUProfil1u2();
        } else if (item.get('richtungsvektor').objectAt(0).get('z') === 1) {
          cords = this.getKoordinatenUProfil3u4();
        }
      }

      this.set('indexHelper', !this.get('indexHelper'));

      return cords;
    }).property('model.firstObject.transformHelper', 'model.firstObject.skalierungsfaktor'),

    koordinatenIndex: (function () {
      var name = this.get('name');
      var typ = this.get('typ');
      var x3d = this.get('model').objectAt(0);
      var bauteile = x3d.get('bauteile');
      var values = '';

      var item = x3d.get('bauteile').findBy('id', name);

      if (x3d.get('ergebnisGeladen') === true) {
        if (item.get('richtungsvektor').objectAt(0).get('z') === -1) {
          values = "0 3 2 1 -1 1 2 6 5 -1 4 5 6 7-1 4 7 11 8 -1 8 11 10 9 -1 9 10 14 13 -1 12 13 14 15 -1 0 12 15 3 -1 0 1 5 4 -1 0 4 8 12 -1 8 9 13 12 -1 2 3 7 6 -1 3 15 11 7 -1 10 11 15 14 -1";
        } else if (item.get('richtungsvektor').objectAt(0).get('z') === 1) {
          values = "0 3 2 1 -1 1 2 14 13 -1 12 13 14 15 -1 8 12 15 11 -1 8 11 10 9 -1 5 9 10 6 -1 4 5 6 7 -1 0 4 7 3 -1 0 1 5 4 -1 1 13 9 5 -1 8 9 13 12 -1 2 3 7 6 -1 2 6 10 14 -1 10 11 15 14 -1";
        }
      }

      return values;
    }).property('indexHelper'),

    farbIndex: (function () {
      var name = this.get('name');
      var typ = this.get('typ');
      var x3d = this.get('model').objectAt(0);
      var bauteile = x3d.get('bauteile');
      var values = '0 0 0 0 0 0 0 0 0 0';
      var item = x3d.get('bauteile').findBy('id', name);

      values = '0 0 0 0 0 0 0 0 0 0 0 0 0 0';

      return values;
    }).property('model.firstObject.transformHelper', 'model.firstObject.istHt'),

    farbe: (function () {
      var name = this.get('name');
      var typ = this.get('typ');
      var x3d = this.get('model').objectAt(0);
      var bauteile = x3d.get('bauteile');

      var item = x3d.get('bauteile').findBy('id', name);

      var values = item.get('diffusecolors').objectAt(0).get('rot') + ' ' + item.get('diffusecolors').objectAt(0).get('gruen') + ' ' + item.get('diffusecolors').objectAt(0).get('blau');

      return values;
    }).property('model.firstObject.transformHelper'),

    getKoordinatenUProfil1u2: function getKoordinatenUProfil1u2() {
      var p0, p1, p2, p3, p4, p5, p6, p7, p8, p9, p10, p11, p12, p13, p14, p15;

      var name = this.get('name');
      var typ = this.get('typ');
      var x3d = this.get('model').objectAt(0);
      var bauteile = x3d.get('bauteile');
      var values = '';

      var item = x3d.get('bauteile').findBy('id', name);

      var x = Number(item.get('screwProperties').objectAt(0).get('diameter')) / 10;
      var y = Number(item.get('screwProperties').objectAt(0).get('headdiameter')) / 10;;
      var z = Number(item.get('screwProperties').objectAt(0).get('length')) / 10;

      var wandDicke = Number(this.get('wandDicke'));

      p0 = -(x / 2) + ' ' + 0 + ' ' + -(z / 2);
      p1 = -(x / 2) + ' ' + 0 + ' ' + z / 2;
      p2 = x / 2 + ' ' + 0 + ' ' + z / 2;
      p3 = x / 2 + ' ' + 0 + ' ' + -(z / 2);
      p4 = -(x / 2) + ' ' + wandDicke + ' ' + (-z / 2 + wandDicke);
      p5 = -(x / 2) + ' ' + wandDicke + ' ' + z / 2;
      p6 = x / 2 + ' ' + wandDicke + ' ' + z / 2;
      p7 = x / 2 + ' ' + wandDicke + ' ' + (-z / 2 + wandDicke);
      p8 = -(x / 2) + ' ' + (y - wandDicke) + ' ' + (-z / 2 + wandDicke);
      p9 = -(x / 2) + ' ' + (y - wandDicke) + ' ' + z / 2;
      p10 = x / 2 + ' ' + (y - wandDicke) + ' ' + z / 2;
      p11 = x / 2 + ' ' + (y - wandDicke) + ' ' + (-z / 2 + wandDicke);
      p12 = -(x / 2) + ' ' + y + ' ' + -(z / 2);
      p13 = -(x / 2) + ' ' + y + ' ' + z / 2;
      p14 = x / 2 + ' ' + y + ' ' + z / 2;
      p15 = x / 2 + ' ' + y + ' ' + -z / 2;

      var koordinaten = p0 + ', ' + p1 + ', ' + p2 + ', ' + p3 + ', ' + p4 + ', ' + p5 + ', ' + p6 + ', ' + p7 + ', ' + p8 + ', ' + p9 + ', ' + p10 + ', ' + p11 + ', ' + p12 + ', ' + p13 + ', ' + p14 + ', ' + p15;

      return koordinaten;
    },

    getKoordinatenUProfil3u4: function getKoordinatenUProfil3u4() {
      var p0, p1, p2, p3, p4, p5, p6, p7, p8, p9, p10, p11, p12, p13, p14, p15;

      var name = this.get('name');
      var typ = this.get('typ');
      var x3d = this.get('model').objectAt(0);
      var bauteile = x3d.get('bauteile');
      var values = '';

      var item = x3d.get('bauteile').findBy('id', name);

      var x = Number(item.get('screwProperties').objectAt(0).get('diameter')) / 10;
      var y = Number(item.get('screwProperties').objectAt(0).get('headdiameter')) / 10;;
      var z = Number(item.get('screwProperties').objectAt(0).get('length')) / 10;

      var wandDicke = Number(this.get('wandDicke'));

      p0 = -(x / 2) + ' ' + 0 + ' ' + -(z / 2);
      p1 = -(x / 2) + ' ' + 0 + ' ' + z / 2;
      p2 = x / 2 + ' ' + 0 + ' ' + z / 2;
      p3 = x / 2 + ' ' + 0 + ' ' + -(z / 2);
      p4 = -(x / 2) + ' ' + wandDicke + ' ' + (-z / 2 - wandDicke);
      p5 = -(x / 2) + ' ' + wandDicke + ' ' + (z / 2 - wandDicke);
      p6 = x / 2 + ' ' + wandDicke + ' ' + (z / 2 - wandDicke);
      p7 = x / 2 + ' ' + wandDicke + ' ' + (-z / 2 - wandDicke);
      p8 = -(x / 2) + ' ' + (y - wandDicke) + ' ' + (-z / 2 - wandDicke);
      p9 = -(x / 2) + ' ' + (y - wandDicke) + ' ' + (z / 2 - wandDicke);
      p10 = x / 2 + ' ' + (y - wandDicke) + ' ' + (z / 2 - wandDicke);
      p11 = x / 2 + ' ' + (y - wandDicke) + ' ' + (-z / 2 - wandDicke);
      p12 = -(x / 2) + ' ' + y + ' ' + -(z / 2);
      p13 = -(x / 2) + ' ' + y + ' ' + z / 2;
      p14 = x / 2 + ' ' + y + ' ' + z / 2;
      p15 = x / 2 + ' ' + y + ' ' + -z / 2;

      var koordinaten = p0 + ', ' + p1 + ', ' + p2 + ', ' + p3 + ', ' + p4 + ', ' + p5 + ', ' + p6 + ', ' + p7 + ', ' + p8 + ', ' + p9 + ', ' + p10 + ', ' + p11 + ', ' + p12 + ', ' + p13 + ', ' + p14 + ', ' + p15;
      return koordinaten;
    }

  });

  exports['default'] = Transform;

});