define('m03/components/arctwo-d', ['exports', 'ember'], function (exports, Ember) {

  'use strict';

  var Transform = Ember['default'].Component.extend({

    tagName: 'transform',
    layoutName: 'arctwo-d',
    attributeBindings: ['translation', 'rotation', 'bboxcenter', 'bboxsize', 'center', 'scale', 'scaleorientation'],
    classNames: ['testClassNameXXX'],
    bboxcenter: "0 0 0",
    bboxsize: "-1,-1,-1",
    center: "0 0 0",
    scale: "1,1,1",
    scaleorientation: "0,0,0,0",

    translation: (function () {
      var name = this.get('name');
      var x3d = this.get('model').objectAt(0);
      var bauteile = x3d.get('bauteile');
      var cords = '0 0 0';

      var xVerschiebung = 0,
          yVerschiebung = 0;

      // console.log("##### arctwo-d translation #####");

      if (x3d.get('ergebnisGeladen') === false) {
        yVerschiebung = Number(bauteile.findBy('id', 'hauptTraeger1').get('boxsizes').objectAt(0).get('y')) / 20;
      } else {
        // xVerschiebung = Number(bauteile.findBy('id', 'seitenbauteil0').get('punkt').objectAt(1).get('x'))/10;

        var x1 = Number(bauteile.findBy('id', 'seitenbauteil0').get('punkt').objectAt(1).get('x')) / 10;
        var y1 = Number(bauteile.findBy('id', 'seitenbauteil0').get('punkt').objectAt(1).get('y')) / 10;
        var x2 = Number(bauteile.findBy('id', 'seitenbauteil0').get('punkt').objectAt(2).get('x')) / 10;
        var y2 = Number(bauteile.findBy('id', 'seitenbauteil0').get('punkt').objectAt(2).get('y')) / 10;

        var m = (y2 - y1) / (x2 - x1);

        var hDiag = Number(bauteile.findBy('id', 'hauptTraeger1').get('boxsizes').objectAt(0).get('y')) / 10;
        var alfa = x3d.get(name) * Math.PI / 180;
        var dh = hDiag / Math.cos(alfa);

        // xVerschiebung = Math.abs((m*x1 + y1)/(m));
        yVerschiebung = this.getMittelwert(Number(bauteile.findBy('id', 'mittelbauteil').get('punkt').objectAt(0).get('y')) / 10, Number(bauteile.findBy('id', 'mittelbauteil').get('punkt').objectAt(1).get('y')) / 10);
        xVerschiebung = (yVerschiebung - (y1 + dh / 2) + m * x1) / m;
        // yVerschiebung = Number(bauteile.findBy('id', 'mittelbauteil').get('punkt').objectAt(1).get('y'))/10;
      }

      var zVerschiebung = Number(bauteile.findBy('id', 'nebenTraeger').get('boxsizes').objectAt(0).get('z')) / 20 + Number(bauteile.findBy('id', 'hauptTraeger1').get('boxsizes').objectAt(0).get('z')) / 10;

      if (x3d.get('verstaerkungBlass') === true && x3d.get('verstaerkungWerner') === false) {
        zVerschiebung = zVerschiebung + x3d.get('dickeBlass') / 10;
      } else if (x3d.get('verstaerkungBlass') === true && x3d.get('verstaerkungWerner') === true) {
        zVerschiebung = zVerschiebung + x3d.get('dickeWerner') / 10 * 2;
      }

      cords = xVerschiebung + ' ' + yVerschiebung + ' ' + zVerschiebung;

      return cords;
    }).property('model.firstObject.arc2dPositionTriggern', 'model.firstObject.phi', 'model.firstObject.vWinkelScherkraft', 'model.firstObject.Transformhelper', 'model.firstObject.vWinkel'),

    rotation: (function () {
      var name = this.get('name');
      var x3d = this.get('model').objectAt(0);
      var values = '0 0 0 0';
      // if (name === 'vWinkelScherkraft'){
      //   values = '1 0 0 -1.5707';
      // }else if (name === 'vWinkel'){
      //   var winkel = 1.5707-(Number(x3d.get('vWinkelScherkraft'))*Math.PI)/180;
      //   values = '0 1 0 '+winkel;
      // }
      return values;
    }).property('model.firstObject.arc2dPositionTriggern', 'model.firstObject.phi', 'model.firstObject.vWinkelScherkraft', 'model.firstObject.vWinkel'),

    anfangsWinkel: (function () {
      var name = this.get('name');
      var x3d = this.get('model').objectAt(0);
      var bauteile = x3d.get('bauteile');
      var value = 0;
      var vwinkel = x3d.get('vWinkelEingetragen') === true ? Number(x3d.get('vWinkel')) : x3d.get('phiEingetragen') === true ? Number(x3d.get('phi')) : 0;
      vwinkel = Number(vwinkel) * Math.PI / 180;
      var vwinkelScherkraft = Number(x3d.get('vWinkelScherkraft')) * Math.PI / 180;

      if (name === 'vWinkelScherkraft' && vwinkelScherkraft > 0) {
        value = vwinkel + 0.00001;
      } else if (name === 'vWinkelScherkraft' && vwinkelScherkraft < 0) {
        value = vwinkel + vwinkelScherkraft;
      }

      return value;
    }).property('model.firstObject.arc2dPositionTriggern', 'model.firstObject.phi', 'model.firstObject.vWinkelScherkraft', 'model.firstObject.vWinkel'),

    endWinkel: (function () {
      var name = this.get('name');
      var x3d = this.get('model').objectAt(0);
      var bauteile = x3d.get('bauteile');
      var value = 0;
      var vwinkel = x3d.get('vWinkelEingetragen') === true ? Number(x3d.get('vWinkel')) : x3d.get('phiEingetragen') === true ? Number(x3d.get('phi')) : 0;
      vwinkel = Number(vwinkel) * Math.PI / 180;
      var vwinkelScherkraft = Number(x3d.get('vWinkelScherkraft')) * Math.PI / 180;

      if (name === 'vWinkelScherkraft' && vwinkelScherkraft > 0) {
        value = vwinkel + vwinkelScherkraft + 0.0001;
      }if (name === 'vWinkelScherkraft' && vwinkelScherkraft < 0) {
        value = vwinkel;
      } else if (name === 'vWinkel' || name === 'phi') {
        value = vwinkel + 0.0001;
      }

      return value;
    }).property('model.firstObject.arc2dPositionTriggern', 'model.firstObject.phi', 'model.firstObject.vWinkelScherkraft', 'model.firstObject.vWinkel'),

    radius: (function () {
      var name = this.get('name');
      var x3d = this.get('model').objectAt(0);
      var bauteile = x3d.get('bauteile');
      var ueberstand = 2;
      var value = 0;

      value = bauteile.findBy('id', 'hauptTraeger1').get('boxsizes').objectAt(0).get('x') / 20;
      if (name === 'vWinkelScherkraft') {
        value = value + 4.5;
      } else if (name === 'vWinkel' || name === 'phi') {
        value = value + 3.5;
      }

      return value;
    }).property('model.firstObject.ergebnisGeladen', 'model.firstObject.arc2dPositionTriggern', 'model.firstObject.phi', 'model.firstObject.vWinkelScherkraft', 'model.firstObject.vWinkelScherkraftAktiv'),

    emissivecolor: (function () {
      var name = this.get('name');
      var typ = this.get('typ');
      var x3d = this.get('model').objectAt(0);

      var emsvcolor = '0 0 0';

      if (x3d.get('vWinkelScherkraftAktiv') === true && name === 'vWinkelScherkraft' && typ === 'vWinkelScherkraft') {
        emsvcolor = '1 0 0';
      }

      if (x3d.get('vWinkelAktiv') === true && name === 'vWinkel' && typ === 'vWinkel') {
        emsvcolor = '1 0 0';
      }

      if (x3d.get('phiAktiv') === true && name === 'phi' && typ === 'vWinkel') {
        emsvcolor = '1 0 0';
      }

      return emsvcolor;
    }).property('model.firstObject.arc2dPositionTriggern', 'model.firstObject.phiAktiv', 'model.firstObject.vWinkelScherkraftAktiv', 'model.firstObject.vWinkelAktiv'),

    getMittelwert: function getMittelwert(a, b) {
      return (a + b) / 2;
    }

  });

  exports['default'] = Transform;

});