define('m03/locales_ITW/pl/translations', ['exports'], function (exports) {

  'use strict';

  exports['default'] = {

    berechnung_pdf: 'Wyliczenie jako PDF',
    setztiefe: 'Głębokość osadzenia',
    vorgebohrt: 'Wstępnie nawiercone otwory',
    bauteile: 'Elementy ',
    hausnummer: 'Numer',
    gew_schraube: 'Wybrany',
    SH: 'Stal - Drewno',
    email: 'Email',
    scherkraft: 'Siła ścinająca',
    nicht_vorgebohrt: 'Bez wstępnego nawiercenia',
    scherfl_vbm: 'Powierzchnia ścinająca przypadająca na łącznik',
    beschreibung: 'Opis',
    festigkeit: 'Klasa wytrzymałości',
    scherfl2: '2-powierzchnia ścinania',
    scherfl1: '1-powierzchnia ścinania',
    modul_alt: 'M03 - Projektowanie łączników',
    mat: 'Materiał 1',
    staendig: 'stałe',
    lasteinwirkung2: 'Obciążenie',
    tt_text: 'Zestawienie zaleceń statycznych w formacie PDF',
    ergebnis: 'Wynik',
    eingabeerforderlich: 'Wymagane wprowadzenie danych !',
    plz: 'Kod pocztowy',
    kompakterg: 'Wynik',
    eingabe_ok: 'Prawidłowe wprowadzanie danych',
    winkel: 'Kąt między elementami konstrukcyjnymi',
    sehr_kurz: 'chwilowe',
    msg: 'Ukryć łączniki niezgodne z ETA',
    bezeichnung: 'Oznaczenie',
    lang: 'długotrwałe',
    tel: 'Telefon',
    schnittig2: '2-przekroje',
    ort: 'Miejscowość',
    schnittig1: '1-przekrój',
    land: 'Kraj',
    projektdaten: 'Dane projektowe',
    fax: 'Fax',
    kdnum: 'Nr.klienta',
    verbindungsmittel: 'Typ łącznika',
    geometrie: 'Geometria',
    def_bereich: 'Obszar definicji',
    schichtfolge: 'Kolejność warstw',
    wklscherkraft: 'Kąt kierunkowy siły ścinającej',
    artnr: 'Numer artykułu',
    name: 'Nazwisko',
    nkl: 'Klasa użytkowania',
    adresse: 'Ulica, Numer',
    mittel: 'średniotrwałe',
    lasteinwirkung: 'Działanie obciążenia (obciążenie obliczeniowe)',
    modul: 'Projektowanie łączników - M03',
    led: 'Czas oddziaływania obciążenia',
    scherfl_anschl: 'Powierzchnia ścinająca przypadająca na połączenie',
    verarbeiter: 'Opracowujący/Zleceniodawca',
    vbms: 'Typ łącznika',
    nkl3: 'Klasa użytkowania 3',
    hoehe: 'Wysokość',
    mat1: 'Materiał 2',
    nkl2: 'Klasa użytkowania 2',
    nkl1: 'Klasa użytkowania 1',
    position: 'Stanowisko',
    axialkraft: 'Siła osiowa',
    strasse: 'Ulica',
    antrieb: 'Gniazdo napędu',
    bauvorhaben: 'Projekt',
    vbm: 'Typ łącznika',
    hinweisLaubholz: 'Przed wkręceniem w drewno liściaste należy dokonać wstępnego nawiercenia!',
    auftraggeber: 'Klient',
    dicke: 'Grubość',
    raxtd: 'Wartość obliczeniowa wytrzymałości na rozciąganie',
    HH: 'Drewno/Materiał drzewny - Drewno',
    rlatd: 'Wartość obliczeniowa siły ścinającej',
    kurz: 'krótkotrwałe',

    nachBlass: 'Warstwa pośrednia wg Blassa',
    nachWerner: 'Wzmocnienie wg Wernera',
    winkelKlammerruecken: 'Kąt grzbiet zszywki - kierunek włókien',
    zwischenschichtVerstaerkung: 'Warstwa pośrednia/Wzmocnienie',
    zwischenschicht: 'Warstwa pośrednia',
    verstaerkung: 'Wzmocnienie',
    umwelteinfluesse: 'Wpływ środowiska, impregnacja',
    erhoehtesKorrosionsrisiko: 'podwyższone ryzyko korozji',

    bemessungssoftware: 'Würth Obliczanie połączeń konstrukcji drewnianych',
    wertebereich: "Dopuszczalny zakres wartości:",
    ergebnisanzeigen: "Pokaż wynik:",
    einstellung: "Ustawienia",
    spracheinstellungen: "Ustawienia języka",
    eingabe: "Ustawienie języka - dane",
    ausgabe: "Ustawienie języka - wyniki",
    landeseinstellungen: "Ustawienia krajowe",
    bemessungsvorschrift: "Dokument odniesienia",
    liefersortiment: "Ustawienie języka - katalog",
    produktkatalog: "Katalog produktów",
    produktinfo: "Informacja o produkcie",
    eta: "ETA",
    cad: "CAD",
    impressum: "Informacja",
    extras: "Dodatki",
    bemerkungen: '› Uwagi',
    bemerkungstext: "Tekst-Uwagi",
    eingabedaten: "Dane wejściowe",
    berechnungalspdf: "Wyliczenie jako PDF",
    berechnungspeichern: "Zapisz obliczenia",
    berechnungoeffnen: "Otwórz obliczenia",
    downloadinstructions1: "lewy przycisk myszy na ikonie: otwórz dane w nowym oknie.",
    downloadinstructions2: "prawy przycisk myszy na ikonie -> « Zapisz element docelowy jako » / « Zapisz link » aby zapisać dane na dysku lokalnym.",
    downloadinstructions3: "Proszę podać nazwę, pliku pod którą ma zostać zapisany pakiet danych",
    eingabedatennichtgespeichert: "Dane wejściowe nie zostały jeszcze zapisane",
    pdfnochnichterstellt: "Plik PDF nie został jeszcze utworzony",
    dlfilename: "Nazwa pliku",
    dlpaketErstellen: "Generowanie pakietu danych do pobrania",
    nochkeineschraubegewaehlt: "Łączniki nie zostały jeszcze wybrane",
    nochkeindlerzeugt: "Pakiet danych do pobrania nie jest jeszcze gotowy",
    neueberechnung: "Nowe obliczenie",
    kontakt: "Kontakt",
    herausgeber: "Wydawca",
    registergericht: "Sąd rejestrowy",
    geschaeftsfuehrer: "Kierownik",
    entwickeltvon: "opracowane przez",

    dansk: "Duński",
    deutsch: "Niemiecki",
    englisch: "Angielski",
    franzoesisch: "Francuski",
    italienisch: "Włoski",
    nederlands: "Holenderski",
    polnisch: "Polski",
    espanol: "Hiszpański",
    suomi: "Fiński",
    schwedisch: "Svedese",

    schweiz: "Szwajcaria",
    daenemark: "Dania",
    deutschland: "Niemcy",
    oesterreich: "Austria",
    england: "Anglia",
    frankreich: "Francja",
    italien: "Włochy",
    niederlande: "Niderlandy",
    polen: "Polska",
    spanien: "Hiszpania",
    chile: "Chile",
    finnland: "Finlandia",
    schweden: "Szwecja",

    MatGrp0000: "Drewno iglaste",
    MatGrp0001: "Drewno klejone wielowarstwowo (równolegle)",
    MatGrp0002: "LVL",
    MatGrp0003: "Drewno klejone 2 i 3 warstwowe (równolegle)",
    MatGrp0004: "Drewno klejone warstwowo (krzyżowo)",
    MatGrp0005: "xSklejka",
    MatGrp0006: "LVL (równolegle klejone)",
    MatGrp0007: "Płyty wiórowe",
    MatGrp0008: "Płyty cementowo wiórowa",
    MatGrp0009: "Płyta pilśniowa twarda HB.HLA2 ",
    MatGrp0010: "Płyta pilśniowa półtwarda MBH.LA2",
    MatGrp0012: "Drewno liściaste",
    MatGrp0013: "Drewno klejone warstwowo kombinowane",
    MatGrp0015: "Drewno klejone warstwowo (krzyżowo)",
    MatGrp0020: "Płyty OSB",
    MatGrp0021: "Sklejka",
    MatGrp0022: "Płyta gipsowo kartonowa (DIN 18180)",
    MatGrp0030: "Pollmeier, drewno klejone bukowe",
    MatGrp1000: "Stal",
    MatGrp9100: "Beton",
    MatGrp9999: "Luft",

    Mat1000: "Stal S235JR",
    Mat1001: "Stal S355JR",
    Mat9999: "----",
    Mat000: "C14",
    Mat001: "C16",
    Mat002: "C18",
    Mat003: "C20",
    Mat004: "C22",
    Mat005: "C24",
    Mat006: "C27",
    Mat007: "C30",
    Mat008: "C35",
    Mat009: "C40",
    Mat010: "C45",
    Mat011: "C50",
    Mat050: "D30",
    Mat051: "D35",
    Mat052: "D40",
    Mat053: "D50",
    Mat054: "D60",
    Mat055: "D70",
    Mat020: "GL24h",
    Mat030: "GL24c",
    Mat021: "GL28h",
    Mat031: "GL28c",
    Mat022: "GL32h",
    Mat032: "GL32c",
    Mat023: "GL36h",
    Mat033: "GL36c",
    Mat160: "Płyta wiórowa Kl. P4 (EN 312)",
    Mat170: "Płyta wiórowa Kl. P5 (EN 312)",
    Mat180: "Płyta wiórowa Kl. P6 (EN 312)",
    Mat190: "Płyta wiórowa Kl. P7 (EN 312)",
    Mat200: "Płyta wiórowo cementowa. Kl. 1+2 (EN 634)",
    Mat220: "Płyta pilśniowa twarda HB.HLA2 (EN 622-2)",
    Mat230: "Płyta pilśniowa półtwarda MBH.LA2 (EN 622-3)",
    Mat125: "OSB/2 (EN 300)",
    Mat126: "OSB/3 (EN 300)",
    Mat127: "OSB/4 (EN 300)",
    Mat110: "Sklejka F25/10",
    Mat111: "Sklejka F40/40 E60/40",
    Mat112: "Sklejka F50/25 E70/25",
    Mat113: "Sklejka F60/10 E90/10",
    Mat520: "Płyta gipsowo kartonowa (DIN 18180)",
    Mat9120: "C20/25",
    Mat9125: "C25/30",
    Mat9130: "C30/37",
    Mat9135: "C35/45",
    Mat9140: "C40/50",
    Mat9145: "C45/55",
    Mat024: "GL30h",
    Mat034: "GL30c",
    Mat301: "GL70",

    errors: {
      inclusion: "is not included in the list",
      exclusion: "is reserved",
      invalid: "Dane nieaktualne",
      confirmation: "doesn\'t match {{attribute}}",
      accepted: "must be accepted",
      empty: "can\'t be empty",
      blank: "can\'t be blank",
      present: "must be blank",
      tooLong: "Eingabe zu lang. Maximal erlaubte Länge sind {{count}} Zeichen.",
      tooShort: "!2",
      wrongLength: "is the wrong length (should be {{count}} characters)",
      notANumber: "is not a number",
      notAnInteger: "must be an integer",
      greaterThan: "must be greater than {{count}}",
      greaterThanOrEqualTo: "must be greater than or equal to {{count}}",
      equalTo: "must be equal to {{count}}",
      lessThan: "must be less than {{count}}",
      lessThanOrEqualTo: "must be less than or equal to {{count}}",
      otherThan: "must be other than {{count}}",
      odd: "must be odd",
      even: "must be even"
    },
    'language-select.language.de': "Niemiecki",
    'language-select.language.en': "English"

  };

});