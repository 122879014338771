define('m03/locales_wuerth/en/translations', ['exports'], function (exports) {

  'use strict';

  exports['default'] = {

    berechnung_pdf: 'Calculation as PDF',
    setztiefe: 'Seating depth',
    vorgebohrt: 'Predrilled holes',
    bauteile: 'Component parts',
    hausnummer: 'House number',
    gew_schraube: 'Selected screw',
    SH: 'steel-timber',
    email: 'E-mail',
    scherkraft: 'Shear force',
    nicht_vorgebohrt: 'Non-predrilled holes',
    scherfl_vbm: 'shear planes per connector',
    beschreibung: 'Description',
    festigkeit: 'Property class',
    scherfl2: '2 shear planes',
    scherfl1: '1 shear plane',
    modul_alt: 'M03 - Screwdesign',
    mat: 'material 1',
    staendig: 'permanent',
    lasteinwirkung2: 'Load effect',
    tt_text: 'edition of the structural engineer recommendation in PDF format',
    ergebnis: 'Result',
    eingabeerforderlich: 'Input required!',
    plz: 'Postcode',
    kompakterg: 'Compact result',
    eingabe_ok: 'Correct entry',
    winkel: 'Angle between components',
    sehr_kurz: 'very short',
    msg: 'Show the incorrect screws',
    bezeichnung: 'Designation',
    lang: 'long',
    tel: 'Phone',
    schnittig2: 'Double-shear',
    ort: 'Place',
    schnittig1: 'Single-shear',
    land: 'Country',
    projektdaten: 'Project data',
    fax: 'Fax',
    kdnum: 'Customer number',
    verbindungsmittel: 'Connector',
    geometrie: 'Geometry',
    def_bereich: 'Definition area',
    schichtfolge: 'succession of layers',
    wklscherkraft: 'Direction angle of the shear force',
    artnr: 'item number ',
    name: 'Name',
    nkl: 'service class',
    adresse: 'Street, house number',
    mittel: 'medium',
    lasteinwirkung: 'load effect',
    modul: 'Screwdesign - M03',
    led: 'Duration of load effect',
    scherfl_anschl: 'shear plane per connector',
    verarbeiter: 'fabricator/principal',
    vbms: 'Connector',
    nkl3: 'service class 3',
    hoehe: 'Height',
    mat1: 'material 2',
    nkl2: 'service class 2',
    nkl1: 'service class 1',
    position: 'position',
    axialkraft: 'Axial force',
    strasse: 'Street',
    antrieb: 'Drive',
    bauvorhaben: 'construction project',
    vbm: 'Connector',
    hinweisLaubholz: 'Screws must be pre-drilled when used in hardwood !',
    auftraggeber: 'Client',
    dicke: 'Thickness',
    raxtd: 'Rated value of tensile strength',
    HH: 'Wood / Plywood',
    rlatd: 'Rated value of shear load capacity',
    kurz: 'short',

    nachBlass: 'Intermediate layer according to Blass',
    nachWerner: 'Reinforced according to Werner',
    winkelKlammerruecken: 'Angle clamp back - fiber direction',
    zwischenschichtVerstaerkung: 'Interlayer / reinforcement',
    zwischenschicht: 'Interlayer',
    verstaerkung: 'Reinforcement',
    umwelteinfluesse: 'Environmental influences, impregnation',
    erhoehtesKorrosionsrisiko: 'Increased risk of corrosion',

    bemessungssoftware: 'Würth timber construction software',
    wertebereich: "Allowed range",
    ergebnisanzeigen: "Show result:",
    einstellung: "Settings",
    spracheinstellungen: "Language setting",
    eingabe: "Language setting for data input",
    ausgabe: "Language setting for output data",
    landeseinstellungen: "Country settings",
    bemessungsvorschrift: "Measurement rule",
    liefersortiment: "Language setting for the catalogue",
    produktkatalog: "Product catalogue",
    produktinfo: "Product information",
    eta: "ETA",
    cad: "CAD",
    impressum: "Impressum",
    extras: "Extras",
    bemerkungen: '› remark',
    bemerkungstext: "Remark text",
    eingabedaten: "Input data",
    berechnungalspdf: "Calculation as PDF",
    berechnungspeichern: "Save calculation",
    berechnungoeffnen: "Open calculation",
    downloadinstructions1: "Left klick on Icons: Open file in window",
    downloadinstructions2: "Right klick on Icons-> « Save Target under» / « Save link under » to save file lokal on hard disk.",
    downloadinstructions3: "Specify a filename under which you want to download the selected files as a bundled package.",
    eingabedatennichtgespeichert: "Input data has not yet been saved.",
    pdfnochnichterstellt: "No PDF has been generated yet.",
    dlfilename: "Filename",
    dlpaketErstellen: "Create download package",
    nochkeineschraubegewaehlt: "No screw has been selected yet.",
    nochkeindlerzeugt: "No download package has been created.",
    neueberechnung: "New calculation",
    kontakt: "Contact",
    herausgeber: "Publisher",
    registergericht: "Register court",
    geschaeftsfuehrer: "Managing Director",
    entwickeltvon: "Developed by",

    dansk: "Danish",
    deutsch: "German",
    englisch: "English",
    franzoesisch: "French",
    italienisch: "Italian",
    nederlands: "Dutch",
    polnisch: "Polish",
    espanol: "Spanish",
    suomi: "Finnish",
    schwedisch: "Swedich",

    schweiz: "Switzerland",
    daenemark: "Denmark",
    deutschland: "Germany",
    oesterreich: "Austria",
    england: "England",
    frankreich: "France",
    italien: "Italy",
    niederlande: "Netherlands",
    polen: "Poland",
    spanien: "Spain",
    chile: "Chile",
    finnland: "Finland",
    schweden: "Sweden",

    MatGrp0000: "Solid timber, Softwood",
    MatGrp0001: "Glued laminated timber, homogeneous",
    MatGrp0002: "Laminated veneer lumber",
    MatGrp0003: "Beam laminated timber",
    MatGrp0004: "Multi-layered solid-wood panel",
    MatGrp0005: "xPlywood",
    MatGrp0006: "Veneer laminated wood (all fibres parallel)",
    MatGrp0007: "Particle boards laminated with synthetic resin",
    MatGrp0008: "Cement-bonded particle boards",
    MatGrp0009: "Fibreboards HB.HLA2",
    MatGrp0010: "Fibreboards MHB.LA2",
    MatGrp0012: "Solid timber, hardwood",
    MatGrp0013: "Glued laminated timber, combined",
    MatGrp0015: "Cross-laminated timber",
    MatGrp0020: "Oriented Strand Boards (OSB)",
    MatGrp0021: "Plywood",
    MatGrp0022: "Gypsum plasterboard (DIN 18180)",
    MatGrp0030: "Pollmeier Bau-Buche",
    MatGrp1000: "Steel",
    MatGrp9100: "Concrete",
    MatGrp9999: "Luft",

    Mat1000: "Steel S235JR",
    Mat1001: "Steel S355JR",
    Mat9999: "----",
    Mat000: "C14",
    Mat001: "C16",
    Mat002: "C18",
    Mat003: "C20",
    Mat004: "C22",
    Mat005: "C24",
    Mat006: "C27",
    Mat007: "C30",
    Mat008: "C35",
    Mat009: "C40",
    Mat010: "C45",
    Mat011: "C50",
    Mat050: "D30",
    Mat051: "D35",
    Mat052: "D40",
    Mat053: "D50",
    Mat054: "D60",
    Mat055: "D70",
    Mat020: "GL24h",
    Mat030: "GL24c",
    Mat021: "GL28h",
    Mat031: "GL28c",
    Mat022: "GL32h",
    Mat032: "GL32c",
    Mat023: "GL36h",
    Mat033: "GL36c",
    Mat160: "Particleboard class P4 (EN 312)",
    Mat170: "Particleboard class P5 (EN 312)",
    Mat180: "Particleboard class P6 (EN 312)",
    Mat190: "Particleboard class P7 (EN 312)",
    Mat200: "Cementbonded Particleboard class 1+2 (EN 634)",
    Mat220: "Fibreboard, hard HB.HLA2 (EN 622-2)",
    Mat230: "Fibreboard, hard MBH.LA2 (EN 622-3)",
    Mat125: "OSB/2 (EN 300)",
    Mat126: "OSB/3 (EN 300)",
    Mat127: "OSB/4 (EN 300)",
    Mat110: "Plywood F25/10",
    Mat111: "Plywood F40/40 E60/40",
    Mat112: "Plywood F50/25 E70/25",
    Mat113: "Plywood F60/10 E90/10",
    Mat520: "Gypsum plasterboard (DIN 18180)",
    Mat9120: "C20/25",
    Mat9125: "C25/30",
    Mat9130: "C30/37",
    Mat9135: "C35/45",
    Mat9140: "C40/50",
    Mat9145: "C45/55",
    Mat024: "GL30h",
    Mat034: "GL30c",
    Mat301: "GL70",

    errors: {
      inclusion: "is not included in the list",
      exclusion: "is reserved",
      invalid: "Invalid Input.",
      confirmation: "doesn\'t match {{attribute}}",
      accepted: "must be accepted",
      empty: "can\'t be empty",
      blank: "can\'t be blank",
      present: "must be blank",
      tooLong: "Eingabe zu lang. Maximal erlaubte Länge sind {{count}} Zeichen.",
      tooShort: "!2",
      wrongLength: "is the wrong length (should be {{count}} characters)",
      notANumber: "is not a number",
      notAnInteger: "must be an integer",
      greaterThan: "must be greater than {{count}}",
      greaterThanOrEqualTo: "must be greater than or equal to {{count}}",
      equalTo: "must be equal to {{count}}",
      lessThan: "must be less than {{count}}",
      lessThanOrEqualTo: "must be less than or equal to {{count}}",
      otherThan: "must be other than {{count}}",
      odd: "must be odd",
      even: "must be even"
    },
    'language-select.language.de': "German",
    'language-select.language.en': "English"

  };

});