define('m03/models/rotation', ['exports', 'ember-data'], function (exports, DS) {

  'use strict';

  exports['default'] = DS['default'].Model.extend({
    x: DS['default'].attr('number'),
    y: DS['default'].attr('number'),
    z: DS['default'].attr('number'),
    winkel: DS['default'].attr('number')
  });
  // rotation: DS.belongsTo('bauteil', {async: true})

});