define('m03/components/bo-x', ['exports', 'ember'], function (exports, Ember) {

  'use strict';

  var Box = Ember['default'].Component.extend({
    tagName: 'box',
    attributeBindings: ['size', 'solid', 'ccw', 'usegeocache', 'lit'],
    solid: true,
    ccw: true,
    usegeocache: true,
    lit: true,

    size: (function () {

      var name = this.get('name');
      var x3d = this.get('model');
      var bauteile = x3d.x3ddefault.objectAt(0).get('bauteile');
      var values;
      bauteile.forEach(function (item, index) {
        if (item.id === name) {
          values = bauteile.objectAt(index).get('boxsizes').objectAt(0).get('x') + " " + bauteile.objectAt(index).get('boxsizes').objectAt(0).get('y') + " " + bauteile.objectAt(index).get('boxsizes').objectAt(0).get('z');
        }
      });
      return values;
    }).property('model.firstObject.transformHelper')
  });

  exports['default'] = Box;

});