define('m03/locales_ITW/en/translations', ['exports'], function (exports) {

  'use strict';

  exports['default'] = {

    berechnung_pdf: 'Calculation as PDF',
    setztiefe: 'Setting depth',
    vorgebohrt: 'Holes predrilled',
    bauteile: 'Components',
    hausnummer: 'House number',
    gew_schraube: 'Selected',
    SH: 'Steel-Timber',
    email: 'email',
    scherkraft: 'Shear force',
    nicht_vorgebohrt: 'Holes not predrilled',
    scherfl_vbm: 'Shear planes per fastener',
    beschreibung: 'Description',
    festigkeit: 'Strength class',
    scherfl2: '2 shear planes',
    scherfl1: '1 shear plane',
    modul_alt: 'M03',
    mat: 'Material 1',
    staendig: 'permanent',
    lasteinwirkung2: 'Loading',
    tt_text: 'Export as PDF',
    ergebnis: 'Result',
    eingabeerforderlich: 'Input required !',
    plz: 'Postcode',
    kompakterg: 'Compact result',
    eingabe_ok: 'Correct entry',
    winkel: 'Angle between components',
    sehr_kurz: 'instantaneous',
    msg: 'Display invalid fasteners',
    bezeichnung: 'Name',
    lang: 'long-term',
    tel: 'Telephone',
    schnittig2: 'Double-shear',
    ort: 'Place',
    schnittig1: 'Single-shear',
    land: 'Country',
    projektdaten: 'Project data',
    fax: 'Fax',
    kdnum: 'Customer number',
    verbindungsmittel: 'Fastener',
    geometrie: 'Geometry',
    def_bereich: 'Definition area',
    schichtfolge: 'Layer succession',
    wklscherkraft: 'Angle of shear force',
    artnr: 'Item number ',
    name: 'Name',
    nkl: 'Service class',
    adresse: 'Street, house number',
    mittel: 'medium-term',
    lasteinwirkung: 'Loading (design load)',
    modul: 'M03',
    led: 'Load duration',
    scherfl_anschl: 'Shear plane per connection',
    verarbeiter: 'Processor/Principal',
    vbms: 'Fastener',
    nkl3: 'Service class 3',
    hoehe: 'Height',
    mat1: 'Material 2',
    nkl2: 'Service class 2',
    nkl1: 'Service class 1',
    position: 'Position',
    axialkraft: 'Axial force',
    strasse: 'Street',
    antrieb: 'Drive',
    bauvorhaben: 'Construction project',
    vbm: 'Fastener',
    hinweisLaubholz: 'Fasteners have to be predrilled in hard wood !',
    auftraggeber: 'Client',
    dicke: 'Thickness',
    raxtd: 'Design value of axial load capacity',
    HH: 'Timber/Panel - Timber',
    rlatd: 'Design value of shear load capacity',
    kurz: 'short-term',

    nachBlass: 'Intermediate layer in accordance with BLASS',
    nachWerner: 'Strengthening in accordance with WERNER',
    winkelKlammerruecken: 'Angle between staple crown and grain',
    zwischenschichtVerstaerkung: 'Intermediate layer/Strengthening',
    zwischenschicht: 'Intermediate layer',
    verstaerkung: 'Strengthening',
    umwelteinfluesse: 'Environment, treatment',
    erhoehtesKorrosionsrisiko: 'higher risk of corrosion',

    bemessungssoftware: 'ITW Fastener Calculation Software',
    wertebereich: "Allowed range:",
    ergebnisanzeigen: "Show result:",
    einstellung: "Settings",
    spracheinstellungen: "Language setting",
    eingabe: "Input",
    ausgabe: "Output",
    landeseinstellungen: "Country settings",
    bemessungsvorschrift: "Design regulation",
    liefersortiment: "Product range",
    produktkatalog: "Product catalogue",
    produktinfo: "Product information",
    eta: "ETA",
    cad: "CAD",
    impressum: "Imprint",
    extras: "Extras",
    bemerkungen: "Remark",
    bemerkungstext: "Remark text",
    eingabedaten: "Input data",
    berechnungalspdf: "Calculation as PDF",
    berechnungspeichern: "Save calculation",
    berechnungoeffnen: "Open calculation",
    downloadinstructions1: "Left click on icons: Open file in window",
    downloadinstructions2: "Right click on icons-> « Save target under» / « Save link under » to save file locally on hard disk.",
    downloadinstructions3: "Specify a filename under which you want to download the selected files as a package.",
    eingabedatennichtgespeichert: "Input data has not yet been saved.",
    pdfnochnichterstellt: "No PDF has been generated yet.",
    dlfilename: "Filename",
    dlpaketErstellen: "Create download package",
    nochkeineschraubegewaehlt: "No fastener has been selected yet.",
    nochkeindlerzeugt: "No download package has been created yet.",
    neueberechnung: "New calculation",
    kontakt: "Contact",
    herausgeber: "Publisher",
    registergericht: "Register court",
    geschaeftsfuehrer: "Managing Director",
    entwickeltvon: "Developed by",

    dansk: "Danish",
    deutsch: "German",
    englisch: "English",
    franzoesisch: "French",
    italienisch: "Italian",
    nederlands: "Dutch",
    polnisch: "Polish",
    espanol: "Spanish",
    suomi: "Finnish",
    schwedisch: "Swedish",

    schweiz: "Switzerland",
    daenemark: "Denmark",
    deutschland: "Germany",
    oesterreich: "Austria",
    england: "England",
    frankreich: "France",
    italien: "Italy",
    niederlande: "Netherlands",
    polen: "Poland",
    spanien: "Spain",
    chile: "Chile",
    finnland: "Finland",
    schweden: "Sweden",

    MatGrp0000: "Solid timber, Softwood",
    MatGrp0001: "Glued laminated timber, homogeneous",
    MatGrp0002: "Laminated veneer lumber (LVL)",
    MatGrp0003: "Glued solid timber (duo, trio beam)",
    MatGrp0004: "Solid wood panel",
    MatGrp0005: "xPlywood",
    MatGrp0006: "Laminated veneer lumber (all fibres parallel)",
    MatGrp0007: "Particleboard",
    MatGrp0008: "Cement-bonded particleboards",
    MatGrp0009: "Fibreboard, hard HB.HLA2",
    MatGrp0010: "Fibreboard, medium MHB.LA2",
    MatGrp0012: "Solid timber, Hardwood",
    MatGrp0013: "Glued laminated timber, combined",
    MatGrp0015: "Cross laminated timber",
    MatGrp0020: "Oriented Strand Board (OSB)",
    MatGrp0021: "Plywood",
    MatGrp0022: "Gypsum plasterboard (DIN 18180)",
    MatGrp0030: "Pollmeier LVL Beech",
    MatGrp1000: "Steel",
    MatGrp9100: "Concrete",
    MatGrp9999: "Luft",

    Mat1000: "Steel S235JR",
    Mat1001: "Steel S355JR",
    Mat9999: "----",
    Mat000: "C14",
    Mat001: "C16",
    Mat002: "C18",
    Mat003: "C20",
    Mat004: "C22",
    Mat005: "C24",
    Mat006: "C27",
    Mat007: "C30",
    Mat008: "C35",
    Mat009: "C40",
    Mat010: "C45",
    Mat011: "C50",
    Mat050: "D30",
    Mat051: "D35",
    Mat052: "D40",
    Mat053: "D50",
    Mat054: "D60",
    Mat055: "D70",
    Mat020: "GL24h",
    Mat030: "GL24c",
    Mat021: "GL28h",
    Mat031: "GL28c",
    Mat022: "GL32h",
    Mat032: "GL32c",
    Mat023: "GL36h",
    Mat033: "GL36c",
    Mat160: "Particleboard class P4 (EN 312)",
    Mat170: "Particleboard class P5 (EN 312)",
    Mat180: "Particleboard class P6 (EN 312)",
    Mat190: "Particleboard class P7 (EN 312)",
    Mat200: "Cement-bonded particleboard class 1+2 (EN 634)",
    Mat220: "Fibreboard, hard HB.HLA2 (EN 622-2)",
    Mat230: "Fibreboard, medium MBH.LA2 (EN 622-3)",
    Mat125: "OSB/2 (EN 300)",
    Mat126: "OSB/3 (EN 300)",
    Mat127: "OSB/4 (EN 300)",
    Mat110: "Plywood F25/10",
    Mat111: "Plywood F40/40 E60/40",
    Mat112: "Plywood F50/25 E70/25",
    Mat113: "Plywood F60/10 E90/10",
    Mat520: "Gypsum plasterboard (DIN 18180)",
    Mat9120: "C20/25",
    Mat9125: "C25/30",
    Mat9130: "C30/37",
    Mat9135: "C35/45",
    Mat9140: "C40/50",
    Mat9145: "C45/55",
    Mat024: "GL30h",
    Mat034: "GL30c",
    Mat301: "GL70",

    ohneZwischenschicht: "Without intermediate layer",
    zwischenschichtBlass: "Intermediate layer in accordance with BLASS",
    verstaerkungWerner: "Strengthening in accordance with WERNER",
    groesserGleich30: "≥ 30°",
    kleiner30: "< 30°",

    anschlussgeometrie: "#no value#",
    anschlussvarianten: "#no value#",
    winkelEndabschnittDiagonale: "#no value#",
    ueberstandDiagonaleVertikal: "#no value#",
    ausmittigerStabanschluss: "#no value#",
    winkelabschnittDiagonale: "#no value#",
    exzentrizitaetDiagonale: "##Exzentrizitaet Diagonale",
    abschnittSenkrechtDiagonalenachse: "#no value#",
    abschnittParallelGurtachse: "#no value#",
    freieEingabe: "Free entry",

    starr: "Rigid intermediate layer",
    verschieblich: "Non-rigid intermediate layer",
    nachricht: "##Nachricht",
    nachrichtversenden: "##Ihre Eingabedaten werden automatisch mit der Nachricht übermittelt.",
    nachrichtversendet: "##Ihre Nachricht wurde übermittelt.",
    nachrichtSenden: "##Nachricht senden",
    neueNachricht: "##Neue Nachricht",

    hilfe: "Help",
    bemessungslastText: "If the rated load is not known, the maximum load-bearing capacity of the connecting agent is indicated by entering zero.",
    ledTooltipStaendig: "more than 10 years, e.g. self-weight",
    ledTooltipLang: "6 months - 10 years, e.g. storage",
    ledTooltipMittel: "1 week - 6 months, e.g. imposed floor load, snow",
    ledTooltipKurz: "less than one week, e.g. snow, wind",
    ledTooltipSehrKurz: "e.g. wind, accidental load",

    nklTooltip1: "Moisture content  in the materials corresponding to a temperature of 20°C and the relative humidity of the surrounding air only exceeding 65% for a few weeks per year (e.g. closed, heated buildings)",
    nklTooltip2: "Moisture content  in the materials corresponding to a temperature of 20°C and the relative humidity of the surrounding air only exceeding 85% for a few weeks per year (e.g. protected, open buildings)",
    nklTooltip3: "Climatic conditions leading to higher moisture contents than in service class 2 (e.g. constructions that are exposed to the weather)",

    nutzungsbedingungen: "Terms and conditions",

    errors: {
      inclusion: "is not included in the list",
      exclusion: "is reserved",
      invalid: "Invalid Input.",
      confirmation: "doesn\'t match {{attribute}}",
      accepted: "must be accepted",
      empty: "can\'t be empty",
      blank: "can\'t be blank",
      present: "must be blank",
      tooLong: "Input too long. Maximum allowed length are {{count}} characters.",
      tooShort: "!2",
      wrongLength: "is the wrong length (should be {{count}} characters)",
      notANumber: "is not a number",
      notAnInteger: "must be an integer",
      greaterThan: "must be greater than {{count}}",
      greaterThanOrEqualTo: "must be greater than or equal to {{count}}",
      equalTo: "must be equal to {{count}}",
      lessThan: "must be less than {{count}}",
      lessThanOrEqualTo: "must be less than or equal to {{count}}",
      otherThan: "must be other than {{count}}",
      odd: "must be odd",
      even: "must be even"
    },
    'language-select.language.de': "German",
    'language-select.language.en': "English"

  };

});