define('m03/locales_wuerth/it/translations', ['exports'], function (exports) {

  'use strict';

  exports['default'] = {

    berechnung_pdf: 'Calcolo in pdf',
    setztiefe: 'Profondità d\'infissione',
    vorgebohrt: 'Con preforo',
    bauteile: 'Componenti',
    hausnummer: 'Numero',
    gew_schraube: 'Scelto',
    SH: 'Acciaio - Legno',
    email: 'Email',
    scherkraft: 'Forza di taglio',
    nicht_vorgebohrt: 'Senza preforo',
    scherfl_vbm: 'Piani di taglio per ciascun mezzo di unione',
    beschreibung: 'Descrizione ',
    festigkeit: 'Classe di resistenza',
    scherfl2: '2 piani di taglio',
    scherfl1: '1 piano di taglio',
    modul_alt: 'M03 - Calcolo delle viti ',
    mat: 'Materiale 1',
    staendig: 'permanente',
    lasteinwirkung2: 'Azione del carico',
    tt_text: 'Relazione di predimensionamento in formato PDF',
    ergebnis: 'Risultato',
    eingabeerforderlich: 'Inserimento dati necessario !',
    plz: 'Cap',
    kompakterg: 'Risultato sintetico',
    eingabe_ok: 'Inserimento corretto',
    winkel: 'Angolo tra gli elementi strutturali',
    sehr_kurz: 'istantaneo',
    msg: 'Visualizza viti non verificate',
    bezeichnung: 'Descrizione',
    lang: 'lungo',
    tel: 'Telefono',
    schnittig2: 'Con 2 sezioni',
    ort: 'Località ',
    schnittig1: 'Con 1 sezione',
    land: 'Paese',
    projektdaten: 'Informazioni progetto',
    fax: 'Fax',
    kdnum: 'Codice cliente',
    verbindungsmittel: 'Mezzo di unione',
    geometrie: 'Geometria',
    def_bereich: 'Area definita',
    schichtfolge: 'Successione di strati',
    wklscherkraft: 'Angolo di direzione della forza di taglio',
    artnr: 'Codice articolo ',
    name: 'Nome',
    nkl: 'Classe di servizio',
    adresse: 'Via, Numero',
    mittel: 'medio',
    lasteinwirkung: 'Azione del carico (valore di progetto)',
    modul: 'Calcolo delle viti - M03',
    led: 'Durata del carico',
    scherfl_anschl: 'Area di taglio per collegamento',
    verarbeiter: 'Utente/Committente',
    vbms: 'Mezzo di unione',
    nkl3: 'Classe di servizio 3',
    hoehe: 'Altezza',
    mat1: 'Materiale 2',
    nkl2: 'Classe di servizio 2',
    nkl1: 'Classe di servizio 1',
    position: 'Posizione',
    axialkraft: 'Forza assiale',
    strasse: 'Via',
    antrieb: 'Intaglio',
    bauvorhaben: 'Progetto edilizio',
    vbm: 'Mezzo di unione',
    hinweisLaubholz: 'Per l\'utilizzo delle viti nel legno duro deve essere eseguito il preforo.',
    auftraggeber: 'Committente',
    dicke: 'Spessore',
    raxtd: 'Valore di progetto della resistenza a trazione',
    HH: 'Legno - compensato in legno',
    rlatd: 'Valore di progetto della resistenza a taglio',
    kurz: 'breve',

    nachBlass: 'Strato intermedio secondo Blass',
    nachWerner: 'Rinforzo secondo Werner',
    winkelKlammerruecken: 'Angolo staffa - direzione della fibra',
    zwischenschichtVerstaerkung: 'Strato intermedio/Rinforzo',
    zwischenschicht: 'Strato intermedio',
    verstaerkung: 'Rinforzo',
    umwelteinfluesse: 'agenti ambientali, impregnazione',
    erhoehtesKorrosionsrisiko: 'aumento del rischio di corrosione',

    bemessungssoftware: 'Software Würth per costruzioni in legno',
    wertebereich: "Campo di valore ammissibile:",
    ergebnisanzeigen: "Mostra risultato:",
    einstellung: "Impostazione",
    spracheinstellungen: "Impostazione lingua",
    eingabe: "Impostazione lingua di inserimento",
    ausgabe: "impostazione lingua dell\'elaborato",
    landeseinstellungen: "Impostazioni del Paese",
    bemessungsvorschrift: "Norma di dimensionamento",
    liefersortiment: "Gamma di vendita ",
    produktkatalog: "Catalogo prodotti ",
    produktinfo: "Informazione prodotto",
    eta: "ETA",
    cad: "CAD",
    impressum: "Impressum",
    extras: "Strumenti",
    bemerkungen: '› Note',
    bemerkungstext: "Testo delle note",
    eingabedaten: "Dati di ingresso",
    berechnungalspdf: "Calcolo in pdf",
    berechnungspeichern: "Salvare il calcolo",
    berechnungoeffnen: "Aprire il calcolo",
    downloadinstructions1: "Cliccare sopra le icone: Apertura dei dati in una nuova finestra.",
    downloadinstructions2: "Pulsante destro del mouse sulle icone ->« Salva come » / « Salva collegamento come » per memorizzare localmente i dati sul disco fisso.",
    downloadinstructions3: "Specificare il nome della cartella in cui si desidera scaricare i file selezionati.",
    eingabedatennichtgespeichert: "I dati di ingresso non sono stati salvati.",
    pdfnochnichterstellt: "Non è stato ancora generato il PDF.",
    dlfilename: "Nome del file",
    dlpaketErstellen: "Crea una cartella di download",
    nochkeineschraubegewaehlt: "Non è stata ancora scelta una vite.",
    nochkeindlerzeugt: "Non è stata creata una cartella per il download.",
    neueberechnung: "Nuovo calcolo",
    kontakt: "Contatto",
    herausgeber: "Distributore",
    registergericht: "Registro d\'impresa",
    geschaeftsfuehrer: "Direttore",
    entwickeltvon: "Sviluppato da",

    dansk: "Danese",
    deutsch: "Tedesco",
    englisch: "Inglese",
    franzoesisch: "Francese",
    italienisch: "Italiano",
    nederlands: "Olandese",
    polnisch: "Polacco",
    espanol: "Spagnolo",
    suomi: "Finlandese",
    schwedisch: "Svedese",

    schweiz: "Svizzera",
    daenemark: "Danimarca",
    deutschland: "Germania ",
    oesterreich: "Austria",
    england: "Inghilterra",
    frankreich: "Francia",
    italien: "Italia",
    niederlande: "Paesi Bassi",
    polen: "Polonia",
    spanien: "Spagna",
    chile: "Chile",
    finnland: "Finlandia",
    schweden: "Svezia",

    MatGrp0000: "Legno mass. conifera",
    MatGrp0001: "Legno lamellare omogeneo",
    MatGrp0002: "LVL /  legno multilamellare",
    MatGrp0003: "Legno mass. giuntato (bilama/trilama)",
    MatGrp0004: "Pann. legno mass. multistrato",
    MatGrp0005: "xLegno compensato stratificato",
    MatGrp0006: "LVL /  legno multilamellare (fibre parallele)",
    MatGrp0007: "Pann. particelle legate c. resina sint.",
    MatGrp0008: "Pann. particelle legate c. cemento",
    MatGrp0009: "Pann. fibre ad alta densità HB.HLA2",
    MatGrp0010: "Pann. fibre densità medio-alta MHB.LA2",
    MatGrp0012: "Legno mass. latifoglie",
    MatGrp0013: "Legno lamellare combinato",
    MatGrp0015: "Legno lamellare",
    MatGrp0020: "Pannello OSB",
    MatGrp0021: "Legno compensato stratificato",
    MatGrp0022: "Pannelli in cartongesso (DIN 18180)",
    MatGrp0030: "Pollmeier Bau-Buche",
    MatGrp1000: "Acciaio",
    MatGrp9100: "Calcestruzzo",
    MatGrp9999: "Luft",

    Mat1000: "Acciaio S235JR",
    Mat1001: "Acciaio S355JR",
    Mat9999: "----",
    Mat000: "C14",
    Mat001: "C16",
    Mat002: "C18",
    Mat003: "C20",
    Mat004: "C22",
    Mat005: "C24",
    Mat006: "C27",
    Mat007: "C30",
    Mat008: "C35",
    Mat009: "C40",
    Mat010: "C45",
    Mat011: "C50",
    Mat050: "D30",
    Mat051: "D35",
    Mat052: "D40",
    Mat053: "D50",
    Mat054: "D60",
    Mat055: "D70",
    Mat020: "GL24h",
    Mat030: "GL24c",
    Mat021: "GL28h",
    Mat031: "GL28c",
    Mat022: "GL32h",
    Mat032: "GL32c",
    Mat023: "GL36h",
    Mat033: "GL36c",
    Mat160: "Pannello truciolare P4 (EN 312)",
    Mat170: "Pannello truciolare P5 (EN 312)",
    Mat180: "Pannello truciolare P6 (EN 312)",
    Mat190: "Pannello truciolare P7 (EN 312)",
    Mat200: "Pannello truciolare con cemento, classe 1+2 (EN 634)",
    Mat220: "Pannelli in fibra di legno HB.HLA2 (EN 622-2)",
    Mat230: "Pannelli in fibra di legno MBH.LA2 (EN 622-3)",
    Mat125: "OSB/2 (EN 300)",
    Mat126: "OSB/3 (EN 300)",
    Mat127: "OSB/4 (EN 300)",
    Mat110: "Compensato F25/10",
    Mat111: "Compensato F40/40 E60/40",
    Mat112: "Compensato F50/25 E70/25",
    Mat113: "Compensato F60/10 E90/10",
    Mat520: "Pannelli in cartongesso (DIN 18180)",
    Mat9120: "C20/25",
    Mat9125: "C25/30",
    Mat9130: "C30/37",
    Mat9135: "C35/45",
    Mat9140: "C40/50",
    Mat9145: "C45/55",
    Mat024: "GL30h",
    Mat034: "GL30c",
    Mat301: "GL70",

    errors: {
      inclusion: "is not included in the list",
      exclusion: "is reserved",
      invalid: "Inserimento non valido.",
      confirmation: "non corrisponde {{attribute}}",
      accepted: "deve essere accettato",
      empty: "non può essere vuoto",
      blank: "non può essere in bianco",
      present: "deve essere vuoto",
      tooLong: "Inserimento troppo lungo. La massima lunghezza consentita è {{count}}",
      tooShort: "!2",
      wrongLength: "è la lunghezza sbagliata (devono essere {{count}} caratteri)",
      notANumber: "non è un numero",
      notAnInteger: "deve essere di numero intero",
      greaterThan: "deve essere maggiore di {{count}}",
      greaterThanOrEqualTo: "deve essere maggiore o uguale a {{count}}",
      equalTo: "deve essere uguale a {{count}}",
      lessThan: "deve essere minore di {{count}}",
      lessThanOrEqualTo: "deve essere minore o uguale a {{count}}",
      otherThan: "deve essere diverso da {{count}}",
      odd: "deve essere dispari",
      even: "must be even"
    },
    'language-select.language.de': "Tedesco",
    'language-select.language.en': "English"

  };

});