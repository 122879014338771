define('m03/components/seitenbautei-l', ['exports', 'ember'], function (exports, Ember) {

  'use strict';

  var Transform = Ember['default'].Component.extend({

    tagName: 'transform',
    layoutName: 'traege-r',
    attributeBindings: ['coordIndex', 'colorindex', 'colorPerVertex', 'ccw'],
    classNames: ['testClassNameXXX'],
    colorPerVertex: "false",
    bboxsize: "-1,-1,-1",
    ccw: "true",

    objektSkalierung: 1,

    ueberstand: 2,

    yDeltaOben: 0,
    yDeltaUnten: 0,

    center: (function () {

      var name = this.get('name');
      var x3d = this.get('model').objectAt(0);
      var bauteile = x3d.get('bauteile');
      var item = bauteile.findBy('id', name);

      var y = item.get('boxsizes').objectAt(0).get('y') / 10;

      return "0 " + y / 2 + " 0";
    }).property('model.firstObject.transformHelper'),

    translation: (function () {
      var name = this.get('name');
      var x3d = this.get('model').objectAt(0);
      var bauteile = x3d.get('bauteile');
      var item = bauteile.findBy('id', name);

      var values = '0 0 0';

      var xVerschiebung = 0,
          yVerschiebung = 0,
          zVerschiebung = 0,
          verstaerkung = 0,
          deltaVerstaerkung = 0;

      if (x3d.get('verstaerkungBlass') === true && x3d.get('verstaerkungWerner') === false) {
        verstaerkung = Number(x3d.get('dickeBlass')) / 10;
        deltaVerstaerkung = verstaerkung;
      } else if (x3d.get('verstaerkungBlass') === true && (x3d.get('verstaerkungWerner') === true && x3d.get('sFolgeHolzHolz') === true)) {
        verstaerkung = Number(x3d.get('dickeWerner')) / 10;
        deltaVerstaerkung = 2 * verstaerkung;
      } else if (x3d.get('verstaerkungBlass') === true && (x3d.get('verstaerkungWerner') === true && x3d.get('sFolgeHolzHolz') === false)) {
        verstaerkung = Number(x3d.get('dickeWerner')) / 10;
        deltaVerstaerkung = verstaerkung;
      }

      switch (name) {
        case 'hauptTraeger1':
          xVerschiebung = item.get('translations').objectAt(0).get('x') / 10;
          yVerschiebung = item.get('translations').objectAt(0).get('y') / 10;
          zVerschiebung = item.get('boxsizes').objectAt(0).get('z') / 20 + bauteile.findBy('id', 'nebenTraeger').get('boxsizes').objectAt(0).get('z') / 20 + deltaVerstaerkung;
          break;
        case 'hauptTraeger2':
          xVerschiebung = item.get('translations').objectAt(0).get('x') / 10;
          yVerschiebung = item.get('translations').objectAt(0).get('y') / 10;
          zVerschiebung = -(item.get('boxsizes').objectAt(0).get('z') / 20 + bauteile.findBy('id', 'nebenTraeger').get('boxsizes').objectAt(0).get('z') / 20 + deltaVerstaerkung);
          break;
        case 'verstaerkungWerner1':
          xVerschiebung = item.get('translations').objectAt(0).get('x') / 10;
          yVerschiebung = item.get('translations').objectAt(0).get('y') / 10;
          zVerschiebung = 1.5 * verstaerkung + bauteile.findBy('id', 'nebenTraeger').get('boxsizes').objectAt(0).get('z') / 20;
          break;
        case 'verstaerkungWerner2':
          xVerschiebung = item.get('translations').objectAt(0).get('x') / 10;
          yVerschiebung = item.get('translations').objectAt(0).get('y') / 10;
          zVerschiebung = -(1.5 * verstaerkung + bauteile.findBy('id', 'nebenTraeger').get('boxsizes').objectAt(0).get('z') / 20);
          break;
        case 'bBox':
          xVerschiebung = 0;
          yVerschiebung = -item.get('boxsizes').objectAt(0).get('y') / 20;
          zVerschiebung = 0;
          break;
      }

      values = xVerschiebung + " " + yVerschiebung + " " + zVerschiebung;

      return values;
    }).property('model.firstObject.transformHelper', 'model.firstObject.istHt', 'model.firstObject.schraubenGekreuzt'),

    rotation: (function () {
      var name = this.get('name');
      var x3d = this.get('model').objectAt(0);
      var bauteile = x3d.get('bauteile');
      var values = '0 0 0 0';

      if (name.substring(0, 8) === 'schraube' || name === 'cylinder') {
        values = '0 0 0 0';
      } else if (name.substring(0, 12) === 'hauptTraeger' || name.substring(0, 18) === 'verstaerkungWerner') {
        var vwinkel = x3d.get('phi') * Math.PI / 180;
        values = '0 0 1 ' + vwinkel;
      } else if (name.substring(0, 5) !== 'plate') {
        values = bauteile.findBy('id', name).get('rotations').objectAt(0).get('x') + ' ' + bauteile.findBy('id', name).get('rotations').objectAt(0).get('y') + ' ' + bauteile.findBy('id', name).get('rotations').objectAt(0).get('z') + ' ' + bauteile.findBy('id', name).get('rotations').objectAt(0).get('winkel');
      }
      return values;
    }).property('model.firstObject.transformHelper', 'model.firstObject.vWinkel', 'model.firstObject.hWinkel', 'model.firstObject.schraubenGekreuzt'),

    koordinaten: (function () {
      var name = this.get('name');
      var typ = this.get('typ');
      var x3d = this.get('model').objectAt(0);
      var bauteile = x3d.get('bauteile');

      var item = x3d.get('bauteile').findBy('id', name);

      var p0 = '0 0 0',
          p1 = '0 0 0',
          p2 = '0 0 0',
          p3 = '0 0 0',
          p4 = '0 0 0',
          p5 = '0 0 0',
          p6 = '0 0 0',
          p7 = '0 0 0',
          p8 = '0 0 0',
          p9 = '0 0 0';

      var vorzeichen = Number(x3d.get('vorzeichenAWerte'));

      var x = item.get('boxsizes').objectAt(0).get('x') / 10;
      var y = Number(item.get('boxsizes').objectAt(0).get('y')) / 10;
      var z = item.get('boxsizes').objectAt(0).get('z') / 10;

      var Hd = y;
      var Hg = Number(x3d.get('bauteile').findBy('id', 'nebenTraeger').get('boxsizes').objectAt(0).get('y')) / 10;

      var deltaXht = 0;
      var xSeitenbauteilOben = 0;
      var xSeitenbauteilUnten = 0;

      var xMinusOberkante = 0;
      var xMinusUnterkante = 0;
      var xPlusOberkante = 0;
      var xPlusUnterkante = 0;

      var phi = Number(x3d.get('phi'));

      var a1 = Number(x3d.get('a1')) / 10;
      var a2 = Number(x3d.get('a2')) / 10;

      var tanPhi = Number(x3d.get('phi')) === 90 ? 1 : Number(x3d.get('phi')) === 0 ? 1 : Math.tan(phi * Math.PI / 180);
      var sinPhi = Number(x3d.get('phi')) === 0 ? 1 : Math.sin(phi * Math.PI / 180);

      var l1 = Hd / 2 / tanPhi + Hg / 2 / sinPhi;
      var l2 = Number(x3d.get('phi')) === 90 ? 0 : Number(x3d.get('phi')) === 0 ? 0 : Hd / tanPhi;

      // console.log(' ');
      // console.log('tanPhi: '+tanPhi);
      // console.log('sinPhi: '+sinPhi);
      // console.log('(Hd/2)/tanPhi: '+(Hd/2)/tanPhi);
      // console.log('(Hg/2)/sinPhi: '+(Hg/2)/sinPhi);

      // console.log('vozeichen: '+vorzeichen);
      // console.log('l1: '+l1);
      // console.log('l2: '+l2);
      // console.log('l1-l2: '+(l1-l2));
      //
      // console.log('a1/sinPhi: '+Math.abs(a1)+'/'+sinPhi+' = '+Math.abs(a1)/sinPhi);
      // console.log('a2/sinPhi: '+a2+'/'+sinPhi+' = '+a2/sinPhi);
      //
      // console.log('l1+a2/sinPhi: '+(l1+a2/sinPhi));
      // console.log('(l1-l2)-Math.abs(a1)/sinPhi: '+((l1-l2)+Math.abs(a1)/sinPhi));

      xMinusOberkante = vorzeichen === -1 ? l1 + a2 / sinPhi : l1 + 0.5;
      xMinusUnterkante = vorzeichen === -1 ? l1 - l2 + Math.abs(a1) / sinPhi : l1 + 0.5;

      xPlusOberkante = vorzeichen === 1 ? l1 - l2 + Math.abs(a1) / sinPhi : l1 + 2;
      xPlusUnterkante = vorzeichen === 1 ? l1 + a2 / sinPhi : l1 + 2;

      // console.log('xMinusOberkante: '+xMinusOberkante);
      // console.log('xMinusUnterkante: '+xMinusUnterkante);
      // console.log('xPlusOberkante: '+xPlusOberkante);
      // console.log('xPlusUnterkante: '+xPlusUnterkante);

      var alpha1 = Number(x3d.get('alpha1'));
      var alpha2 = Number(x3d.get('alpha2'));

      // if(vorzeichen === -1 && -xMinusOberkante > xPlusOberkante){
      //   var dx = Math.abs(xMinusOberkante) - xPlusOberkante;
      //   var y = y - dx * Math.tan(alpha1*Math.PI/180);
      //   xMinusOberkante = -xPlusOberkante;
      // }else if(vorzeichen === 1 && -xMinusUnterkante > xPlusUnterkante){
      //   var dx = Math.abs(xPlusUnterkante) - Math.abs(xMinusUnterkante);
      //   var dy = dx * Math.tan(alpha1*Math.PI/180);
      //   xPlusUnterkante= -xMinusUnterkante;
      // }

      // console.log('alpha3: '+Number(x3d.get('alpha3')));

      var p8 = 0 + ' ' + 0 + ' ' + 0;
      var p9 = 0 + ' ' + 0 + ' ' + 0;

      var dx = 0;
      var dy = 0;

      if (parseInt(x3d.get('alpha3')) !== 180) {

        // console.log('----- koordinaten: alpha3 !== 180 -----');

        var xy = vorzeichen === -1 ? this.getXYbyVector(vorzeichen, { "x": -xMinusUnterkante, "y": 0 }, { "x": -xMinusOberkante, "y": y }, alpha1, -alpha2) : this.getXYbyVector(vorzeichen, { "x": xPlusUnterkante, "y": 0 }, { "x": xPlusOberkante, "y": y }, -alpha2, alpha1);

        dx = xy.x;
        dy = xy.y;

        if (dx > xPlusOberkante) {

          console.log("##### Schnitt größer als dargestellte Trägerlänge #####");
        }

        p8 = dx + ' ' + dy + ' ' + -z / 2;
        p9 = dx + ' ' + dy + ' ' + z / 2;
      }

      var p0 = -xMinusUnterkante + ' ' + 0 + ' ' + -z / 2;
      var p1 = -xMinusUnterkante + ' ' + 0 + ' ' + z / 2;
      var p2 = xPlusUnterkante + ' ' + 0 + ' ' + z / 2;
      var p3 = xPlusUnterkante + ' ' + 0 + ' ' + -z / 2;
      var p4 = -xMinusOberkante + ' ' + y + ' ' + -z / 2;
      var p5 = -xMinusOberkante + ' ' + y + ' ' + z / 2;
      var p6 = xPlusOberkante + ' ' + y + ' ' + z / 2;
      var p7 = xPlusOberkante + ' ' + y + ' ' + -z / 2;

      var cords = p0 + ', ' + p1 + ', ' + p2 + ', ' + p3 + ', ' + p4 + ', ' + p5 + ', ' + p6 + ', ' + p7 + ', ' + p8 + ', ' + p9;

      return cords;
    }).property('model.firstObject.transformHelper'),

    coordIndex: (function () {
      var name = this.get('name');
      var typ = this.get('typ');
      var x3d = this.get('model').objectAt(0);
      var bauteile = x3d.get('bauteile');
      var values = '';

      var item = x3d.get('bauteile').findBy('id', name);

      var values = "0 3 2 1 0 -1 1 2 6 5 1 -1 4 5 6 7 4 -1 0 4 7 3 0 -1 0 1 5 4 0 -1 2 3 7 6 2 -1";

      if (Number(x3d.get('alpha3')) !== 180 && Number(x3d.get('a1')) < 0) {
        values = "0 3 2 1 0 -1 1 2 6 5 9 1 -1 4 5 6 7 4 -1 0 8 4 7 3 0 -1 0 1 9 8 0 -1 4 8 9 5 4 -1 2 3 7 6 2 -1";
      } else if (Number(x3d.get('alpha3')) !== 180 && Number(x3d.get('a1')) > 0) {
        values = "0 3 2 1 0 -1 1 2 9 6 5 1 -1 4 5 6 7 4 -1 0 4 7 8 3 0 -1 0 1 5 4 0 -1 7 6 9 8 7 -1 3 8 9 2 3 -1";
      }

      return values;
    }).property('model.firstObject.transformHelper', 'model.firstObject.istHt'),

    colorindex: (function () {

      var values = "0 0 0 0 0 0 0";

      return values;
    }).property('model.firstObject.transformHelper', 'model.firstObject.istHt'),

    farbe: (function () {

      var name = this.get('name');
      var typ = this.get('typ');
      var x3d = this.get('model').objectAt(0);
      var bauteile = x3d.get('bauteile');

      var item = x3d.get('bauteile').findBy('id', name);
      var values = item.get('diffusecolors').objectAt(0).get('rot') + " " + item.get('diffusecolors').objectAt(0).get('gruen') + " " + item.get('diffusecolors').objectAt(0).get('blau');

      return values;
    }).property('model.firstObject.transformHelper', 'model.firstObject.istHt'),

    koordinatenFaserrichtungsPfeil: (function () {

      var name = this.get('name');
      var x3d = this.get('model').objectAt(0);
      var bauteile = x3d.get('bauteile');
      var item = bauteile.findBy('id', name);

      var delta = 0.15;

      var ueberstand = Number(this.get('ueberstand'));

      var x = 0,
          y = 0,
          z = 0;

      var winkel = 0;

      if (item.get('typ') === 'box') {
        x = bauteile.findBy('id', name).get('boxsizes').objectAt(0).get('x') / 20;
        y = 0.15;
        z = item.get('boxsizes').objectAt(0).get('z') / 20;

        var xSeitenbauteil = Math.abs(Number(x3d.get('xSeitenbauteil'))) / 10;
        var xMittelbauteil = Math.abs(Number(x3d.get('xMittelbauteil'))) / 10;

        if (name === 'hauptTraeger1' || name === 'hauptTraeger2' || name.substring(0, 18) === 'verstaerkungWerner') {
          x = 0;
        } else {
          x = x + xMittelbauteil / 2;
        }
      } else if (item.get('typ') === 'platte') {

        x = -Number(item.get('punkt').objectAt(1).get('x')) / 10;
        y = Number(item.get('punkt').objectAt(1).get('y')) / 10 + 0.15;
        z = Number(item.get('punkt').objectAt(1).get('z')) / 10 + item.get('platte').objectAt(0).get('thickness') / 10;

        if (name.substring(0, 21) === 'verstaerkungdiagonale' || name.substring(0, 13) === 'seitenbauteil') {
          winkel = x3d.get('phi') * Math.PI / 180;
        }
      }

      var cords = -x + this.deltaX(0.15 + delta, delta * 2, winkel) + ' ' + (y + this.deltaY(0.15 + delta, delta * 2, winkel)) + ' ' + z + ', ' + (-x + this.deltaX(delta, delta, winkel)) + ' ' + (y + this.deltaY(delta, delta, winkel)) + ' ' + z + ', ' + (-x + this.deltaX(1, delta, winkel)) + ' ' + (y + this.deltaY(1, delta, winkel)) + ' ' + z + ', ' + (-x + this.deltaX(0.85, 0, winkel)) + ' ' + (y + this.deltaY(0.85, 0, winkel)) + ' ' + z;

      return cords;
    }).property('model.firstObject.transformHelper'),

    deltaX: function deltaX(x, y, winkel) {
      var erg = x * Math.cos(winkel) - y * Math.sin(winkel);
      return erg;
    },

    deltaY: function deltaY(x, y, winkel) {
      var erg = x * Math.sin(winkel) + y * Math.cos(winkel);
      return erg;
    },

    transparency: (function () {

      var name = this.get('name');
      var x3d = this.get('model').objectAt(0);
      var bauteile = x3d.get('bauteile');
      var value;

      var item = bauteile.findBy('id', name);

      if ((item.get('typ') === 'box' || item.get('typ') === 'platte') && !x3d.get('istGitterModell')) {
        value = item.get('appearances').objectAt(0).get('transparency');
      } else if ((item.get('typ') === 'box' || item.get('typ') === 'platte') && x3d.get('istGitterModell')) {
        value = 1;
      } else {
        value = 0;
      }

      return value;
    }).property('model.x3d.bauteile', 'model.firstObject.istGitterModell'),

    getXYbyVector: function getXYbyVector(vorzeichen, AB, CD, alpha1, alpha2) {

      // console.log(' ');

      // console.log('vorzeichen: '+vorzeichen);
      // console.log('AB.x: '+AB.x+" AB.y: "+AB.y);
      // console.log('CD.x: '+CD.x+" CD.y: "+CD.y);
      // console.log('alpha1: '+alpha1+ ' alpha2: '+alpha2);

      var vUntenX = 0 - AB.x;
      var vUntenY = 0 - AB.y;

      var vObenX = 0 - CD.x;
      var vObenY = CD.y - CD.y;

      // console.log('vUntenX: '+vUntenX+" vUntenY: "+vUntenY);
      // console.log('vObenX: '+vObenX+" vObenY: "+vObenY);

      var ax = vUntenX * Math.cos(alpha1 * Math.PI / 180) - vUntenY * Math.sin(alpha1 * Math.PI / 180);
      var ay = vUntenX * Math.sin(alpha1 * Math.PI / 180) + vUntenY * Math.cos(alpha1 * Math.PI / 180);

      var bx = vObenX * Math.cos(alpha2 * Math.PI / 180) - vObenY * Math.sin(alpha2 * Math.PI / 180);
      var by = vObenX * Math.sin(alpha2 * Math.PI / 180) + vObenY * Math.cos(alpha2 * Math.PI / 180);

      // console.log('ax: '+ax+" ay: "+ay);
      // console.log('bx: '+bx+" by: "+by);

      var r = (AB.y + ay * ((CD.x - AB.x) / ax) - CD.y) / (by - ay * (bx / ax));
      // console.log("AB.y - ay*(CD.x-AB.x)/ax - CD.y = "+AB.y +"+"+ ay+"*("+CD.x+"-"+AB.x+")/"+ax+" - "+CD.y+" = "+( AB.y+(ay*((CD.x-AB.x)/ax))-CD.y));
      // console.log("by-ay*bx/ax = "+ by+ "-"+ay+ "*("+bx+"/"+ax+") = "+(by-(ay*(bx/ax))));
      // console.log("r: "+r);
      var x = CD.x + r * bx;
      var y = CD.y + r * by;

      // console.log('x: '+x+" y: "+y);

      return { "x": x, "y": y };
    },

    get_dlxy: function get_dlxy() {

      // console.log(' ');

      var name = this.get('name');
      var x3d = this.get('model').objectAt(0);
      var bauteile = x3d.get('bauteile');
      var item = x3d.get('bauteile').findBy('id', name);

      var vorzeichen = Number(x3d.get('vorzeichenAWerte'));

      var y = Number(item.get('boxsizes').objectAt(0).get('y')) / 10;

      var Hd = y;
      var Hg = Number(x3d.get('bauteile').findBy('id', 'nebenTraeger').get('boxsizes').objectAt(0).get('y')) / 10;

      // console.log('Hd: '+Hd);

      var alpha1 = Number(x3d.get('alpha1'));
      var alpha2 = Number(x3d.get('alpha2'));
      var alpha3 = Number(x3d.get('alpha3'));

      var phi = Number(x3d.get('phi'));

      var a1 = Number(x3d.get('a1')) / 10;
      var a2 = Number(x3d.get('a2')) / 10;

      // console.log('a1: '+a1);
      // console.log('a2: '+a2);

      var tanPhi = phi === 90 ? 1 : phi === 0 ? 1 : Math.tan(phi * Math.PI / 180);
      var sinPhi = phi === 0 ? 1 : Math.sin(phi * Math.PI / 180);

      var l1 = Hd / 2 / tanPhi + Hg / 2 / sinPhi;
      var l2 = Number(x3d.get('phi')) === 90 ? 0 : Number(x3d.get('phi')) === 0 ? 0 : Hd / tanPhi;

      var loberkante = vorzeichen === -1 ? l1 + a2 / sinPhi : l1 - l2 + Math.abs(a1) / sinPhi;
      var lunterkante = vorzeichen === -1 ? l1 - l2 + Math.abs(a1) / sinPhi : l1 + a2 / sinPhi;

      // console.log('lunterkante: '+lunterkante);
      // console.log('loberkante: '+loberkante);

      var lk = vorzeichen === -1 ? lunterkante - loberkante : loberkante - lunterkante;

      // console.log('lk: '+lk);

      var hypotenuse = Math.sqrt(Math.pow(lk, 2) + Math.pow(Hd, 2));

      // console.log('hypotenuse: '+hypotenuse);

      // console.log('lk/Hd: '+lk/Hd);
      // console.log('Hd/lk: '+Hd/lk);
      // console.log('Math.atan(lk/Hd): '+Math.atan(lk/Hd));

      // console.log('Math.atan(2.2/2.2452): '+Math.atan(2.2/2.2452));

      // console.log('alpha1: '+alpha1);
      // console.log('alpha2: '+alpha2);

      var dalpha2 = 0;
      var dalpha3 = 0;
      var gamma = 0;

      // if(alpha1 < 90 && (Math.atan(Hd/lk))*180/Math.PI < 90 ){
      if (lk < 0 && alpha1 < 90) {
        // console.log('lk < 0 && alpha1 < 90');
        gamma = Math.abs(Math.atan(Hd / Math.abs(lk))) - alpha2 * Math.PI / 180;
        dalpha3 = (360 - alpha3) * Math.PI / 180;
      } else if (lk < 0 && alpha1 >= 90) {
        // console.log('dlk < 0 && alpha1 > 90');

        if (alpha2 > Math.atan(Hd / Math.abs(lk)) * 180 / Math.PI) {
          // console.log('alpha2 > '+(Math.atan(Hd/Math.abs(lk)))*180/Math.PI);
          // console.log('gamma: '+180 +' - '+ alpha3 +' - ('+ (alpha2 +' - '+ Math.atan(Hd/Math.abs(lk))*180/Math.PI +')'));
          gamma = (alpha2 - Math.atan(Hd / Math.abs(lk)) * 180 / Math.PI) * Math.PI / 180;
          dalpha3 = alpha3 * Math.PI / 180;
        } else {
          // console.log('alpha2 < '+(Math.atan(Hd/Math.abs(lk)))*180/Math.PI);

          gamma = Math.atan(Hd / Math.abs(lk)) - alpha2 * Math.PI / 180;
          dalpha3 = (360 - alpha3) * Math.PI / 180;
        }
      } else if (lk > 0 && alpha1 < 90) {
        // console.log('dlk > 0 && alpha1 < 90');

        dalpha3 = (360 - alpha3) * Math.PI / 180;

        // console.log('alpha2: '+alpha2);

        gamma = 90 * Math.PI / 180 + Math.atan(Math.abs(lk) / Hd) - alpha2 * Math.PI / 180;
      } else if (lk > 0 && alpha1 >= 90) {
        // console.log('dlk > 0 && alpha1 > 90');

        dalpha3 = alpha3 * Math.PI / 180;
        gamma = alpha2 * Math.PI / 180 - (90 * Math.PI / 180 + Math.atan(Math.abs(lk) / Hd));
      }

      // console.log('dalpha2: '+dalpha2*180/Math.PI);
      // console.log('dalpha3: '+dalpha3*180/Math.PI);
      // console.log('gamma: '+gamma*180/Math.PI);

      return hypotenuse / Math.sin(dalpha3) * Math.sin(gamma);
    },

    getXSeitenbauteil: function getXSeitenbauteil() {
      var name = this.get('name');
      var x3d = this.get('model').objectAt(0);
      var bauteile = x3d.get('bauteile');
      var item = x3d.get('bauteile').findBy('id', name);

      var omega = (180 - Number(x3d.get('alpha1'))) * Math.PI / 180;

      return this.get_dlxy() * Math.cos(omega);
    },

    getYSeitenbauteil: function getYSeitenbauteil() {
      var name = this.get('name');
      var x3d = this.get('model').objectAt(0);
      var bauteile = x3d.get('bauteile');
      var item = x3d.get('bauteile').findBy('id', name);

      var omega = (180 - Number(x3d.get('alpha1'))) * Math.PI / 180;

      return this.get_dlxy() * Math.sin(omega);
    },

    getMassketteHoehe: (function () {
      var value = false;
      var x3d = this.get('model').objectAt(0);
      var bauteile = x3d.get('bauteile');

      if (x3d.get('ergebnisGeladen') !== true && (x3d.get('abstaendeAnzeigen') && (this.get('name') === 'nebenTraeger' || this.get('name') === 'hauptTraeger1' || this.get('name') === 'mittelbauteil' || this.get('name') === 'seitenbauteil0' && Number(x3d.get('vWinkel')) === 0) && bauteile.findBy('id', 'viewpointOben').get('cameraProperties').objectAt(0).get('setBind') !== true)) {
        value = true;
      }

      return value;
    }).property('model.firstObject.lastViewpoint', 'model.firstObject.abstaendeAnzeigen'),

    getMassketteBreite: (function () {
      var value = false;
      var x3d = this.get('model').objectAt(0);
      var bauteile = x3d.get('bauteile');

      if (x3d.get('ergebnisGeladen') !== true && (x3d.get('abstaendeAnzeigen') && (this.get('name') === 'nebenTraeger' || this.get('name') === 'hauptTraeger1' || this.get('name') === 'mittelbauteil' || this.get('name') === 'seitenbauteil0' || this.get('name') === 'verstaerkungBlass1' || this.get('name') === 'verstaerkungWerner1' && x3d.get('sFolgeHolzHolz') === true) && bauteile.findBy('id', 'viewpointFront').get('cameraProperties').objectAt(0).get('setBind') !== true)) {
        value = true;
      }

      return value;
    }).property('model.firstObject.lastViewpoint', 'model.firstObject.abstaendeAnzeigen'),

    getMassketteSetztiefe: (function () {
      var value = false;
      var name = this.get('name');
      var x3d = this.get('model').objectAt(0);
      var bauteile = x3d.get('bauteile');

      if (x3d.get('abstaendeAnzeigen') && (this.get('name') === 'hauptTraeger1' && Number(bauteile.findBy('id', 'schraubeStandard1').get('screwProperties').objectAt(0).get('setztiefe')) !== 0) && bauteile.findBy('id', 'viewpointFront').get('cameraProperties').objectAt(0).get('setBind') !== true) {
        value = true;
      }

      return value;
    }).property('model.firstObject.transformHelper', 'model.firstObject.lastViewpoint', 'model.firstObject.abstaendeAnzeigen'),

    getscherkraftPfeil: (function () {
      var value = false;
      var name = this.get('name');
      var x3d = this.get('model').objectAt(0);
      var bauteile = x3d.get('bauteile');

      if ((this.get('name') === 'hauptTraeger1' || name === 'seitenbauteil0') && x3d.get('scherkraftEingetragen') === true && bauteile.findBy('id', 'viewpointLinks').get('cameraProperties').objectAt(0).get('setBind') !== true) {
        value = true;
      }

      return value;
    }).property('model.firstObject.transformHelper', 'model.firstObject.lastViewpoint')

  });

  // !!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!
  //
  //            !!!!! ab hier ist alles ALT !!!!!
  //
  // !!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!

  exports['default'] = Transform;

});