define('m03/models/zwischenschichtverstaerkung', ['exports', 'ember-data'], function (exports, DS) {

	'use strict';

	var zwischenschichtverstaerkung = DS['default'].Model.extend({

		materialBlass: DS['default'].attr('string'),
		fklBlass: DS['default'].attr('string'),
		dickeBlass: DS['default'].attr('string'),
		StarreZwischenSchicht: DS['default'].attr('boolean'),

		Blass_alphaRAD_KraftFaser: DS['default'].attr('string'),
		Blass_alphaRAD_vmFaser: DS['default'].attr('string'),
		Blass_betaRAD_vmFurnier: DS['default'].attr('string'),

		materialWerner: DS['default'].attr('string'),
		fklWerner: DS['default'].attr('string'),
		dickeWerner: DS['default'].attr('string'),

		Werner_alphaRAD_KraftFaser: DS['default'].attr('string'),
		Werner_alphaRAD_vmFaser: DS['default'].attr('string'),
		Werner_betaRAD_vmFurnier: DS['default'].attr('string'),

		MatKeyInterlayer: DS['default'].attr('string'),
		FKLKeyInterlayer: DS['default'].attr('string'),
		materialBlassPredrilled: DS['default'].attr('boolean'),
		materialWernerPredrilled: DS['default'].attr('boolean')

	});

	zwischenschichtverstaerkung.reopenClass({
		FIXTURES: [{
			id: 1,
			materialBlass: "0",
			fklBlass: "5",
			dickeBlass: "0",
			StarreZwischenSchicht: true,

			Blass_alphaRAD_KraftFaser: "0.0",
			Blass_alphaRAD_vmFaser: "90.0",
			Blass_betaRAD_vmFurnier: "90.0",

			materialBlassPredrilled: false,
			materialWerner: "21",
			fklWerner: "110",
			dickeWerner: "0",

			Werner_alphaRAD_KraftFaser: "0.0",
			Werner_alphaRAD_vmFaser: "90.0",
			Werner_betaRAD_vmFurnier: "90.0",

			materialWernerPredrilled: false,
			MatKeyInterlayer: "0",
			FKLKeyInterlayer: "5"
		}]
	});

	exports['default'] = zwischenschichtverstaerkung;

});