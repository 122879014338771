define('m03/locales_ITW/nl/translations', ['exports'], function (exports) {

  'use strict';

  exports['default'] = {

    berechnung_pdf: 'Berekening als PDF',
    setztiefe: 'Diepte-instelling',
    vorgebohrt: 'Voorgeboorde gaten',
    bauteile: 'Componenten',
    hausnummer: 'Huisnummer',
    gew_schraube: 'Geselecteerd',
    SH: 'Staal-Hout',
    email: 'E-mail',
    scherkraft: 'Afschuifkracht',
    nicht_vorgebohrt: 'Niet voorgeboorde gaten',
    scherfl_vbm: 'Schuifvlakken per verbinding',
    beschreibung: 'Omschrijving',
    festigkeit: 'Sterkteklasse',
    scherfl2: '2 schuifvlakken',
    scherfl1: '1 schuifvlak',
    modul_alt: 'M03',
    mat: 'Materiaal 1',
    staendig: 'permanent',
    lasteinwirkung2: 'Belasting',
    tt_text: 'Exporteer als PDF',
    ergebnis: 'Resultaat',
    eingabeerforderlich: 'Ingaven is noodzakelijk !',
    plz: 'Postcode',
    kompakterg: 'Compact resultaat',
    eingabe_ok: 'Correcte ingaven',
    winkel: 'Hoek tussen de delen',
    sehr_kurz: 'zeer kort',
    msg: 'Toon ongeldige bevestigingsmiddel',
    bezeichnung: 'Naam',
    lang: 'lang',
    tel: 'Telefoon',
    schnittig2: 'Dubbele afschuiving',
    ort: 'Woonplaats',
    schnittig1: 'Enkele afschuiving',
    land: 'Land',
    projektdaten: 'Projectgegevens',
    fax: 'Fax',
    kdnum: 'Klantnummer',
    verbindungsmittel: 'Bevestigingsmiddel',
    geometrie: 'Geometrie',
    def_bereich: 'Afmetingenbereik',
    schichtfolge: 'Opeenvolgende lagen',
    wklscherkraft: 'Hoek van de afschuifkracht',
    artnr: 'Artikelnummer',
    name: 'Naam',
    nkl: 'Serviceklasse',
    adresse: 'Straatnaam, Huisnummer',
    mittel: 'middel',
    lasteinwirkung: 'Lastinwerking (ontwerpwaarde)',
    modul: 'M03',
    led: 'Belastingduur',
    scherfl_anschl: 'Schuifvlakken per verbinding',
    verarbeiter: 'Constructeur/Opdrachtgever',
    vbms: 'Bevestigingsmiddel',
    nkl3: 'Serviceklasse 3',
    hoehe: 'Hoogte',
    mat1: 'Materiaal 2',
    nkl2: 'Serviceklasse 2',
    nkl1: 'Serviceklasse 1',
    position: 'Positie',
    axialkraft: 'Axiaalkracht',
    strasse: 'Straatnaam',
    antrieb: 'Aandrijving',
    bauvorhaben: 'Bouwproject',
    vbm: 'Bevestigingsmiddel',
    hinweisLaubholz: 'Bevestiginsmiddel moet voorgeboord voor gebruik in hardhout !',
    auftraggeber: 'Opdrachtgever',
    dicke: 'Dikte',
    raxtd: 'Berekende waarde van het axiale belastingvermogen',
    HH: 'Hout/Plaatmateriaal - Hout',
    rlatd: 'Berekende waarde van het afschuiv belastingvermogen',
    kurz: 'kort',

    nachBlass: 'Tussenlaag volgens BLASS',
    nachWerner: 'Versterking volgens WERNER',
    winkelKlammerruecken: 'Hoek tussen de rug van de niet  en de houtnerf',
    zwischenschichtVerstaerkung: 'Tussenlaag/Versterking',
    zwischenschicht: 'Tussenlaag',
    verstaerkung: 'Versterking',
    umwelteinfluesse: 'Milieu-invloeden, impregnatie',
    erhoehtesKorrosionsrisiko: 'verhoogd corrosiegevaar',

    bemessungssoftware: 'ITW Berekeningssoftware',
    wertebereich: "Toelaatbare waardebereik:",
    ergebnisanzeigen: "Bekijk resultaten:",
    einstellung: "Instelling",
    spracheinstellungen: "Taalinstelling",
    eingabe: "Input",
    ausgabe: "Output",
    landeseinstellungen: "Landinstellingen",
    bemessungsvorschrift: "Ontwerpvoorschriften",
    liefersortiment: "Leveringsprogramma",
    produktkatalog: "Productcatalogus",
    produktinfo: "Productinformatie",
    eta: "ETA",
    cad: "CAD",
    impressum: "Afdruk",
    extras: "Extra\'s",
    bemerkungen: "Opmerkingen",
    bemerkungstext: "Opmerkingen tekst",
    eingabedaten: "Invoergegevens",
    berechnungalspdf: "Berekening als PDF",
    berechnungspeichern: "Berekening opslaan",
    berechnungoeffnen: "Berekening openen",
    downloadinstructions1: "Klik met de linkermuisknop op pictogrammen: bestand openen in nieuw venster.",
    downloadinstructions2: "Klik met de rechtermuisknop op pictogrammen - > «doel opslaan onder» / «koppeling opslaan onder» lokaal opslaan op uw vaste schijf.",
    downloadinstructions3: "Geef een bestandsnaam waaronder u de geselecteerde bestanden als een pakket wilt downloaden.",
    eingabedatennichtgespeichert: "Ingevoerde gegevens zijn nog niet opgeslagen.",
    pdfnochnichterstellt: "Nog geen pdf gecreëerd.",
    dlfilename: "Bestandsnaam",
    dlpaketErstellen: "Downloadpakket maken",
    nochkeineschraubegewaehlt: "Nog steeds geen bevestigingsmiddel geselecteerd.",
    nochkeindlerzeugt: "Nog steeds geen downloadpakket gemaakt",
    neueberechnung: "Nieuwe berekening",
    kontakt: "Contact",
    herausgeber: "Uitgever",
    registergericht: "Geregistreerd bij Rechtbank",
    geschaeftsfuehrer: "Gedelegeerd bestuurder",
    entwickeltvon: "Ontwikkeld door",

    dansk: "Deens",
    deutsch: "Duits",
    englisch: "Engels",
    franzoesisch: "Frans",
    italienisch: "Italiaans",
    nederlands: "Nederlands",
    polnisch: "Pools",
    espanol: "Spaans",
    suomi: "Fins",
    schwedisch: "Zweeds",

    schweiz: "Zwiterland",
    daenemark: "Denemarken",
    deutschland: "Duitsland",
    oesterreich: "Oostenrijk",
    england: "Engeland",
    frankreich: "Frankrijk",
    italien: "Italië",
    niederlande: "Nederland ",
    polen: "Polen",
    spanien: "Spanje",
    chile: "Chili",
    finnland: "Finland",
    schweden: "Zweden",

    MatGrp0000: "Massief Naaldhout",
    MatGrp0001: "Glulam homogeen",
    MatGrp0002: "Gelamineerd fineer (LVL)",
    MatGrp0003: "Gelamineerde houten balken",
    MatGrp0004: "Meerlaags massief houten paneel",
    MatGrp0005: "xMultiplex",
    MatGrp0006: "Gelamineerd fineer (alle vezels parallel)",
    MatGrp0007: "Spaanplaat",
    MatGrp0008: "Cementgebonden spaanplaat",
    MatGrp0009: "Hard-board  HB.HLA2",
    MatGrp0010: "Medium-board MBH.LA2",
    MatGrp0012: "Massief hardhout",
    MatGrp0013: "Glulam gecombineerd",
    MatGrp0015: "Kruislings gelamineerd hout",
    MatGrp0020: "OSB - platen",
    MatGrp0021: "Multiplex",
    MatGrp0022: "Gipsplaat (DIN 18180)",
    MatGrp0030: "Pollmeier LVL Beech",
    MatGrp1000: "Staal",
    MatGrp9100: "Beton",
    MatGrp9999: "Luft",

    Mat1000: "Staal S235JR",
    Mat1001: "Staal S355JR",
    Mat9999: "----",
    Mat000: "C14",
    Mat001: "C16",
    Mat002: "C18",
    Mat003: "C20",
    Mat004: "C22",
    Mat005: "C24",
    Mat006: "C27",
    Mat007: "C30",
    Mat008: "C35",
    Mat009: "C40",
    Mat010: "C45",
    Mat011: "C50",
    Mat050: "D30",
    Mat051: "D35",
    Mat052: "D40",
    Mat053: "D50",
    Mat054: "D60",
    Mat055: "D70",
    Mat020: "GL24h",
    Mat030: "GL24c",
    Mat021: "GL28h",
    Mat031: "GL28c",
    Mat022: "GL32h",
    Mat032: "GL32c",
    Mat023: "GL36h",
    Mat033: "GL36c",
    Mat160: "Spaanplaat Klasse P4 (EN 312)",
    Mat170: "Spaanplaat Klasse P5 (EN 312)",
    Mat180: "Spaanplaat Klasse P6 (EN 312)",
    Mat190: "Spaanplaat Klasse P7 (EN 312)",
    Mat200: "Cementgeb. Houtspaanpl. Kl. 1+2 (EN 634)",
    Mat220: "Houtvezelplaat, hard HB.HLA2 (EN 622-2)",
    Mat230: "Houtvezelplaat, medium MBH.LA2 (EN 622-3)",
    Mat125: "OSB/2 (EN 300)",
    Mat126: "OSB/3 (EN 300)",
    Mat127: "OSB/4 (EN 300)",
    Mat110: "Multiplex F25/10",
    Mat111: "Multiplex F40/40 E60/40",
    Mat112: "Multiplex F50/25 E70/25",
    Mat113: "Multiplex F60/10 E90/10",
    Mat520: "Gipsplaat (DIN 18180)",
    Mat9120: "C20/25",
    Mat9125: "C25/30",
    Mat9130: "C30/37",
    Mat9135: "C35/45",
    Mat9140: "C40/50",
    Mat9145: "C45/55",
    Mat024: "GL30h",
    Mat034: "GL30c",
    Mat301: "GL70",

    ohneZwischenschicht: "Zonder tussenlaag",
    zwischenschichtBlass: "Tussenlaag volgens BLASS",
    verstaerkungWerner: "Versterking volgens WERNER",
    groesserGleich30: "≥ 30°",
    kleiner30: "< 30°",

    anschlussgeometrie: "#no value#",
    anschlussvarianten: "#no value#",
    winkelEndabschnittDiagonale: "#no value#",
    ueberstandDiagonaleVertikal: "#no value#",
    ausmittigerStabanschluss: "#no value#",
    winkelabschnittDiagonale: "#no value#",
    exzentrizitaetDiagonale: "##Exzentrizitaet Diagonale",
    abschnittSenkrechtDiagonalenachse: "#no value#",
    abschnittParallelGurtachse: "#no value#",
    freieEingabe: "Vrije ingave",

    starr: "Onbuigzame tussenlaag",
    verschieblich: "Buigzame tussenlaag",
    nachricht: "##Nachricht",
    nachrichtversenden: "##Ihre Eingabedaten werden automatisch mit der Nachricht übermittelt.",
    nachrichtversendet: "##Ihre Nachricht wurde übermittelt.",
    nachrichtSenden: "##Nachricht senden",
    neueNachricht: "##Neue Nachricht",

    hilfe: "Help",
    bemessungslastText: "Als het ontwerp belasting niet bekend is, wordt de maximale capaciteit van de verbindingsmiddelen getoond als u nul in te voeren.",
    ledTooltipStaendig: "meer dan 10 jaar, bijvoorbeeld eigen gewicht",
    ledTooltipLang: "6 maanden - 10 jaar, bijvoorbeeld opslagruimte",
    ledTooltipMittel: "1 week - 6 maanden, bijvoorbeeld verkeersbelasting, sneeuw",
    ledTooltipKurz: "minder dan een week, bijvoorbeeld sneeuw, wind",
    ledTooltipSehrKurz: "bv. wind, incidentele belasting",

    nklTooltip1: "Vochtgehalte in het materiaal welke, bij een temperatuur van 20 ° C en de relatieve vochtigheid van de omringende lucht, slechts enkele weken per jaar de 65% overschreid (bijv. gesloten, verwarmde gebouwen)",
    nklTooltip2: "Vochtgehalte in het materiaal welke, bij een temperatuur van 20 ° C en de relatieve vochtigheid van de omringende lucht, slechts enkele weken per jaar de 85% overschreid (bijv. overdekte, open gebouwen)",
    nklTooltip3: "Weersomstandigheden die leiden tot een hoger vochtgehalte dan in service klasse 2 aangegeven. (bv. constructies die zijn blootgesteld aan het weer)",

    nutzungsbedingungen: "Algemene Voorwaarden",

    errors: {
      inclusion: "is not included in the list",
      exclusion: "is reserved",
      invalid: "Ongeldige invoer.",
      confirmation: "doesn\'t match {{attribute}}",
      accepted: "must be accepted",
      empty: "can\'t be empty",
      blank: "can\'t be blank",
      present: "must be blank",
      tooLong: "De ingave is te lang. Maximaal toegestande tekens is {{count}}.",
      tooShort: "!2",
      wrongLength: "is the wrong length (should be {{count}} characters)",
      notANumber: "is not a number",
      notAnInteger: "must be an integer",
      greaterThan: "must be greater than {{count}}",
      greaterThanOrEqualTo: "must be greater than or equal to {{count}}",
      equalTo: "must be equal to {{count}}",
      lessThan: "must be less than {{count}}",
      lessThanOrEqualTo: "must be less than or equal to {{count}}",
      otherThan: "must be other than {{count}}",
      odd: "must be odd",
      even: "must be even"
    },
    'language-select.language.de': "Duits",
    'language-select.language.en': "English"

  };

});