define('m03/locales_wuerth/nl/translations', ['exports'], function (exports) {

  'use strict';

  exports['default'] = {

    berechnung_pdf: 'Berekening als PDF',
    setztiefe: 'Plaatsingsdiepte ',
    vorgebohrt: 'Gat voorgeboord',
    bauteile: 'Bouwdeel',
    hausnummer: 'Huisnummer',
    gew_schraube: 'Gekozen',
    SH: 'Staal - Hout',
    email: 'E-mail',
    scherkraft: 'Afschuifkracht',
    nicht_vorgebohrt: 'Gat niet voorgeboord',
    scherfl_vbm: 'Afschuifbelasting van het verbindingsmiddel',
    beschreibung: 'Omschrijving',
    festigkeit: 'Sterkteklasse',
    scherfl2: '2 snedige afschuifbelasting',
    scherfl1: '1 snedige afschuifbelasting',
    modul_alt: 'M03 - Afmeting van de schroeven',
    mat: 'Materiaal 1',
    staendig: 'permanent',
    lasteinwirkung2: 'Lastinwerking',
    tt_text: 'Output van de statische aanbeveling op PDF formaat',
    ergebnis: 'Resultaat',
    eingabeerforderlich: 'Ingaven is noodzakelijk !',
    plz: 'Postcode',
    kompakterg: 'Compact resultaat',
    eingabe_ok: 'Correcte ingaven',
    winkel: 'Hoek tussen Bouwdelen',
    sehr_kurz: 'erg kort',
    msg: 'Schroeven vervangen zijn niet volgens goedkeuring',
    bezeichnung: 'Aanwijzing',
    lang: 'lang',
    tel: 'Telefoon',
    schnittig2: '2 snedige ',
    ort: 'Woonplaats',
    schnittig1: '1 snedige ',
    land: 'Land',
    projektdaten: 'Projectgegevens',
    fax: 'Fax',
    kdnum: 'Klantnummer',
    verbindungsmittel: 'Verbindingsmiddel',
    geometrie: 'Geometrie',
    def_bereich: 'Afmetingenbereik',
    schichtfolge: 'Laag formatie',
    wklscherkraft: 'Richtingshoek van de afschuifkracht',
    artnr: 'Artikelnummer',
    name: 'Naam',
    nkl: 'Gebruiksklasse',
    adresse: 'Straatnaam, Huisnummer',
    mittel: 'middel',
    lasteinwirkung: 'Lastinwerking (ontwerpwaarde)',
    modul: 'Afmeting van de schroeven - M03',
    led: 'Inwerkingstijd van de belasting',
    scherfl_anschl: 'Afschuifvlak van de verbinding',
    verarbeiter: 'Aannemer / Opdrachtgever',
    vbms: 'Verbindingsmiddel',
    nkl3: 'Gebruiksklasse 3',
    hoehe: 'Hoogte',
    mat1: 'Materiaal 2',
    nkl2: 'Gebruiksklasse 2',
    nkl1: 'Gebruiksklasse 1',
    position: 'Positie',
    axialkraft: 'Axiaal kracht',
    strasse: 'Straatnaam',
    antrieb: 'Aandrijving',
    bauvorhaben: 'Bouwproject',
    vbm: 'Verbindingsmiddel',
    hinweisLaubholz: '#no value#',
    auftraggeber: 'Opdrachtgever',
    dicke: 'Dikte',
    raxtd: 'Ontwerpwaarde van de uitrekweerstand',
    HH: 'Hout/Houtmateriaal - Hout',
    rlatd: 'Ontwerpwaarde van de afschuifkracht',
    kurz: 'kort',

    nachBlass: '#no value#',
    nachWerner: '#no value#',
    winkelKlammerruecken: '#no value#',
    zwischenschichtVerstaerkung: '#no value#',
    zwischenschicht: '',
    verstaerkung: 'Versterking',
    umwelteinfluesse: '#no value#',
    erhoehtesKorrosionsrisiko: '#no value#',

    bemessungssoftware: '#no value#',
    wertebereich: "#no value#",
    ergebnisanzeigen: "#no value#",
    einstellung: "Instelling",
    spracheinstellungen: "Taalinstelling",
    eingabe: "Taalinstelling voor ingaven",
    ausgabe: "Taalinstelling voor uitgaven",
    landeseinstellungen: "#no value#",
    bemessungsvorschrift: "Ontwerpvoorschrift",
    liefersortiment: "Leveringsprogramma",
    produktkatalog: "Productcatalogus",
    produktinfo: "#no value#",
    eta: "ETA",
    cad: "CAD",
    impressum: "Afdruk",
    extras: "Extra\'s",
    bemerkungen: "#no value#",
    bemerkungstext: "#no value#",
    eingabedaten: "#no value#",
    berechnungalspdf: "Berekening als PDF",
    berechnungspeichern: "",
    berechnungoeffnen: "",
    downloadinstructions1: "#no value#",
    downloadinstructions2: "#no value#",
    downloadinstructions3: "#no value#",
    eingabedatennichtgespeichert: "#no value#",
    pdfnochnichterstellt: "#no value#",
    dlfilename: "#no value#",
    dlpaketErstellen: "#no value#",
    nochkeineschraubegewaehlt: "#no value#",
    nochkeindlerzeugt: "#no value#",
    neueberechnung: "",
    kontakt: "#no value#",
    herausgeber: "#no value#",
    registergericht: "#no value#",
    geschaeftsfuehrer: "#no value#",
    entwickeltvon: "#no value#",

    dansk: "#no value#Danish",
    deutsch: "Duits",
    englisch: "Engels",
    franzoesisch: "#no value#French",
    italienisch: "#no value#Italian",
    nederlands: "#no value#Dutch",
    polnisch: "#no value#Polish",
    espanol: "#no value#Spanish",
    suomi: "#no value#Finnish",
    schwedisch: "#no value#Swedich",

    schweiz: "#no value#Switzerland",
    daenemark: "#no value#Denmark",
    deutschland: "Duitsland",
    oesterreich: "",
    england: "#no value#England",
    frankreich: "#no value#France",
    italien: "#no value#Italy",
    niederlande: "#no value#Netherlands",
    polen: "#no value#Poland",
    spanien: "#no value#Spain",
    chile: "#no value#Chile",
    finnland: "#no value#Finland",
    schweden: "#no value#Sweden",

    MatGrp0000: "Naaldhout",
    MatGrp0001: "Glulam homogeen",
    MatGrp0002: "Gelamineerd fineer",
    MatGrp0003: "Gelamineerde houten balken",
    MatGrp0004: "Meerlaags massief houten paneel",
    MatGrp0005: "xMultiplex",
    MatGrp0006: "Gelamineerd fineer (alle vezels parallel)",
    MatGrp0007: "Harsgebonden spaanplaat",
    MatGrp0008: "Cementgebonden spaanplaat",
    MatGrp0009: "Houtvezelplaat HB.HLA2",
    MatGrp0010: "Houtvezelplaat MHB.LA2",
    MatGrp0012: "Massief hardhout",
    MatGrp0013: "Glulam gecombineerd",
    MatGrp0015: "Kruislings gelamineerd hout",
    MatGrp0020: "OSB - platen",
    MatGrp0021: "Multiplex",
    MatGrp0022: "Gipsplaat (DIN 18180)",
    MatGrp0030: "Pollmeier Bau-Buche",
    MatGrp1000: "Staal",
    MatGrp9100: "Beton",
    MatGrp9999: "Luft",

    Mat1000: "Staal S235JR",
    Mat1001: "Staal S355JR",
    Mat9999: "----",
    Mat000: "C14",
    Mat001: "C16",
    Mat002: "C18",
    Mat003: "C20",
    Mat004: "C22",
    Mat005: "C24",
    Mat006: "C27",
    Mat007: "C30",
    Mat008: "C35",
    Mat009: "C40",
    Mat010: "C45",
    Mat011: "C50",
    Mat050: "D30",
    Mat051: "D35",
    Mat052: "D40",
    Mat053: "D50",
    Mat054: "D60",
    Mat055: "D70",
    Mat020: "GL24h",
    Mat030: "GL24c",
    Mat021: "GL28h",
    Mat031: "GL28c",
    Mat022: "GL32h",
    Mat032: "GL32c",
    Mat023: "GL36h",
    Mat033: "GL36c",
    Mat160: "Kunstharsgeb. Spaanpl Kl. P4 (EN 312)",
    Mat170: "Kunstharsgeb. Spaanpl Kl. P5 (EN 312)",
    Mat180: "Kunstharsgeb. Spaanpl Kl. P6 (EN 312)",
    Mat190: "Kunstharsgeb. Spaanpl Kl. P7 (EN 312)",
    Mat200: "Cementgeb. Houtspaanpl. Kl. 1+2 (EN 634)",
    Mat220: "Hardboarplatten HB.HLA2 (EN 622-2)",
    Mat230: "Mediuboardplatten MBH.LA2 (EN 622-2)",
    Mat125: "OSB/2 (EN 300)",
    Mat126: "OSB/3 (EN 300)",
    Mat127: "OSB/4 (EN 300)",
    Mat110: "Multiplex F25/10",
    Mat111: "Multiplex F40/40 E60/40",
    Mat112: "Multiplex F50/25 E70/25",
    Mat113: "Multiplex F60/10 E90/10",
    Mat520: "Gipsplaat (DIN 18180)",
    Mat9120: "C20/25",
    Mat9125: "C25/30",
    Mat9130: "C30/37",
    Mat9135: "C35/45",
    Mat9140: "C40/50",
    Mat9145: "C45/55",
    Mat024: "GL30h",
    Mat034: "GL30c",
    Mat301: "GL70",

    errors: {
      inclusion: "is not included in the list",
      exclusion: "is reserved",
      invalid: "#no value#",
      confirmation: "doesn\'t match {{attribute}}",
      accepted: "must be accepted",
      empty: "can\'t be empty",
      blank: "can\'t be blank",
      present: "must be blank",
      tooLong: "Eingabe zu lang. Maximal erlaubte Länge sind {{count}} Zeichen.",
      tooShort: "!2",
      wrongLength: "is the wrong length (should be {{count}} characters)",
      notANumber: "is not a number",
      notAnInteger: "must be an integer",
      greaterThan: "must be greater than {{count}}",
      greaterThanOrEqualTo: "must be greater than or equal to {{count}}",
      equalTo: "must be equal to {{count}}",
      lessThan: "must be less than {{count}}",
      lessThanOrEqualTo: "must be less than or equal to {{count}}",
      otherThan: "must be other than {{count}}",
      odd: "must be odd",
      even: "must be even"
    },
    'language-select.language.de': "Duits",
    'language-select.language.en': "English"

  };

});