define('m03/components/klammerkontu-r', ['exports', 'ember'], function (exports, Ember) {

  'use strict';

  var Transform = Ember['default'].Component.extend({

    tagName: 'transform',
    layoutName: 'klammerkontu-r',
    attributeBindings: ['translation', 'rotation', 'bboxcenter', 'bboxsize', 'center', 'scale', 'scaleorientation'],
    classNames: ['testClassNameXXX'],
    bboxcenter: "0 0 0",
    bboxsize: "-1,-1,-1",
    center: "0 0 0",
    scale: "1,1,1",
    scaleorientation: "0,0,0,0",

    objektSkalierung: 1,

    wandDicke: 0.025,

    translation: (function () {
      var name = this.get('name');
      var typ = this.get('typ');
      var x3d = this.get('model').objectAt(0);
      var bauteile = x3d.get('bauteile');

      var x = 0;
      var y = 0;
      var z = 0;

      var cords;

      var item = x3d.get('bauteile').findBy('id', name);

      // x = item.get('translations').objectAt(0).get('x');
      // y = item.get('translations').objectAt(0).get('y');
      // z = item.get('translations').objectAt(0).get('z');

      cords = x + ' ' + y + ' ' + z;

      return cords;
    }).property('model.firstObject.transformHelper'),

    rotation: (function () {
      var name = this.get('name');
      var x3d = this.get('model').objectAt(0);
      var bauteile = x3d.get('bauteile');
      var values;

      var x = 0;
      var y = 0;
      var z = 0;
      var winkel = 0;

      var item = x3d.get('bauteile').findBy('id', name);

      // var x = item.get('translations').objectAt(0).get('x');
      // var y = item.get('translations').objectAt(0).get('y');
      // var z = item.get('translations').objectAt(0).get('z');
      // var winkel = item.get('translations').objectAt(0).get('z');

      var values = x + ' ' + y + ' ' + z + ' ' + winkel;

      return values;
    }).property('model.firstObject.transformHelper'),

    koordinaten: (function () {
      var name = this.get('name');
      var typ = this.get('typ');
      var x3d = this.get('model').objectAt(0);
      var bauteile = x3d.get('bauteile');

      var cords;

      var item = x3d.get('bauteile').findBy('id', name);

      if (x3d.get('ergebnisGeladen') === true) {
        if (typ === 'kontur' && item.get('richtungsvektor').objectAt(0).get('z') === -1) {
          cords = this.getKoordinatenUProfil1u2();
        } else if (typ === 'kontur' && item.get('richtungsvektor').objectAt(0).get('z') === 1) {
          cords = this.getKoordinatenUProfil3u4();
        }
      }

      return cords;
    }).property('model.firstObject.transformHelper', 'model.firstObject.skalierungsfaktor', 'model.firstObject.schraubenGekreuzt', 'model.firstObject.lastViewpoint'),

    koordinatenIndex: (function () {
      var name = this.get('name');
      var typ = this.get('typ');
      var x3d = this.get('model').objectAt(0);
      var bauteile = x3d.get('bauteile');
      var values = "1 2 -1 0 3 -1 4 5 -1 6 7 -1 8 9 -1";

      var item = x3d.get('bauteile').findBy('id', name);

      if (x3d.get('ergebnisGeladen') === true) {
        if (typ === 'kontur' && item.get('richtungsvektor').objectAt(0).get('z') === -1) {
          values = "0 1 5 4 8 9 13 12 0 -1 2 3 15 14 10 11 7 6 2 -1 0 3 -1 1 2 -1 5 6 -1 4 7 -1 8 11 -1 9 10 -1 13 14 -1 12 15 -1";
        } else if (typ === 'kontur' && item.get('richtungsvektor').objectAt(0).get('z') === 1) {
          values = "0 1 13 12 8 9 5 4 0 -1 2 3 7 6 10 11 15 14 2 -1 0 3 -1 1 2 -1 5 6 -1 4 7 -1 8 11 -1 9 10 -1 13 14 -1 12 15 -1";
        }
      }

      return values;
    }).property('model.firstObject.transformHelper'),

    emissivecolor: (function () {
      var name = this.get('name');
      var typ = this.get('typ');
      var x3d = this.get('model').objectAt(0);

      var emsvcolor = '0 0 0';

      return emsvcolor;
    }).property('model.firstObject.htbreiteAktiv', 'model.firstObject.hthoeheAktiv', 'model.firstObject.breiteMaterial2Aktiv', 'model.firstObject.hoeheMaterial2Aktiv', 'model.firstObject.systemlaengeAktiv', 'model.firstObject.vWinkelAktiv', 'model.firstObject.laengeFehlstelleAktiv', 'model.firstObject.ueberkopplungslaengeAktiv', 'model.firstObject.auflagerlaengeAktiv'),

    getKoordinatenUProfil1u2: function getKoordinatenUProfil1u2() {
      var p0, p1, p2, p3, p4, p5, p6, p7, p8, p9, p10, p11, p12, p13, p14, p15;

      var name = this.get('name');
      var typ = this.get('typ');
      var x3d = this.get('model').objectAt(0);
      var bauteile = x3d.get('bauteile');
      var values = '';

      var item = x3d.get('bauteile').findBy('id', name);

      var x = Number(item.get('screwProperties').objectAt(0).get('diameter')) / 10;
      var y = Number(item.get('screwProperties').objectAt(0).get('headdiameter')) / 10;
      var z = Number(item.get('screwProperties').objectAt(0).get('length')) / 10;

      var wandDicke = Number(this.get('wandDicke'));

      p0 = -(x / 2) + ' ' + 0 + ' ' + -(z / 2);
      p1 = -(x / 2) + ' ' + 0 + ' ' + z / 2;
      p2 = x / 2 + ' ' + 0 + ' ' + z / 2;
      p3 = x / 2 + ' ' + 0 + ' ' + -(z / 2);
      p4 = -(x / 2) + ' ' + wandDicke + ' ' + (-z / 2 + wandDicke);
      p5 = -(x / 2) + ' ' + wandDicke + ' ' + z / 2;
      p6 = x / 2 + ' ' + wandDicke + ' ' + z / 2;
      p7 = x / 2 + ' ' + wandDicke + ' ' + (-z / 2 + wandDicke);
      p8 = -(x / 2) + ' ' + (y - wandDicke) + ' ' + (-z / 2 + wandDicke);
      p9 = -(x / 2) + ' ' + (y - wandDicke) + ' ' + z / 2;
      p10 = x / 2 + ' ' + (y - wandDicke) + ' ' + z / 2;
      p11 = x / 2 + ' ' + (y - wandDicke) + ' ' + (-z / 2 + wandDicke);
      p12 = -(x / 2) + ' ' + y + ' ' + -(z / 2);
      p13 = -(x / 2) + ' ' + y + ' ' + z / 2;
      p14 = x / 2 + ' ' + y + ' ' + z / 2;
      p15 = x / 2 + ' ' + y + ' ' + -z / 2;

      var koordinaten = p0 + ', ' + p1 + ', ' + p2 + ', ' + p3 + ', ' + p4 + ', ' + p5 + ', ' + p6 + ', ' + p7 + ', ' + p8 + ', ' + p9 + ', ' + p10 + ', ' + p11 + ', ' + p12 + ', ' + p13 + ', ' + p14 + ', ' + p15;

      return koordinaten;
    },

    getKoordinatenUProfil3u4: function getKoordinatenUProfil3u4() {
      var p0, p1, p2, p3, p4, p5, p6, p7, p8, p9, p10, p11, p12, p13, p14, p15;

      var name = this.get('name');
      var typ = this.get('typ');
      var x3d = this.get('model').objectAt(0);
      var bauteile = x3d.get('bauteile');
      var values = '';

      var item = x3d.get('bauteile').findBy('id', name);

      var x = Number(item.get('screwProperties').objectAt(0).get('diameter')) / 10;
      var y = Number(item.get('screwProperties').objectAt(0).get('headdiameter')) / 10;
      var z = Number(item.get('screwProperties').objectAt(0).get('length')) / 10;

      var wandDicke = Number(this.get('wandDicke'));

      p0 = -(x / 2) + ' ' + 0 + ' ' + -(z / 2);
      p1 = -(x / 2) + ' ' + 0 + ' ' + z / 2;
      p2 = x / 2 + ' ' + 0 + ' ' + z / 2;
      p3 = x / 2 + ' ' + 0 + ' ' + -(z / 2);
      p4 = -(x / 2) + ' ' + wandDicke + ' ' + (-z / 2 - wandDicke);
      p5 = -(x / 2) + ' ' + wandDicke + ' ' + (z / 2 - wandDicke);
      p6 = x / 2 + ' ' + wandDicke + ' ' + (z / 2 - wandDicke);
      p7 = x / 2 + ' ' + wandDicke + ' ' + (-z / 2 - wandDicke);
      p8 = -(x / 2) + ' ' + (y - wandDicke) + ' ' + (-z / 2 - wandDicke);
      p9 = -(x / 2) + ' ' + (y - wandDicke) + ' ' + (z / 2 - wandDicke);
      p10 = x / 2 + ' ' + (y - wandDicke) + ' ' + (z / 2 - wandDicke);
      p11 = x / 2 + ' ' + (y - wandDicke) + ' ' + (-z / 2 - wandDicke);
      p12 = -(x / 2) + ' ' + y + ' ' + -(z / 2);
      p13 = -(x / 2) + ' ' + y + ' ' + z / 2;
      p14 = x / 2 + ' ' + y + ' ' + z / 2;
      p15 = x / 2 + ' ' + y + ' ' + -z / 2;

      var koordinaten = p0 + ', ' + p1 + ', ' + p2 + ', ' + p3 + ', ' + p4 + ', ' + p5 + ', ' + p6 + ', ' + p7 + ', ' + p8 + ', ' + p9 + ', ' + p10 + ', ' + p11 + ', ' + p12 + ', ' + p13 + ', ' + p14 + ', ' + p15;

      return koordinaten;
    }

  });

  exports['default'] = Transform;

});