define('m03/controllers/_anschlussgeometrie', ['exports', 'ember', 'ember-validations', 'ember-i18n'], function (exports, Ember, EmberValidations, ember_i18n) {

  'use strict';

  exports['default'] = Ember['default'].Controller.extend(EmberValidations['default'], {

    initTrigger: false,

    debug: false,
    openFromFile: false,
    displayErrors: true,
    schraubendisabled: true,
    schraubenanordnung: true,
    ausmittigDisabled: true,

    exzentritaetDiagonaleDisabled: true,
    winkelEndAbschnittDiagonaleGRADDisabled: true,
    ausmittigerstabanschluss: false,

    exzentrizitaetDiagonale: "",
    winkel: "",
    ueberstandDiagonaleVertikal: "",
    winkelEndAbschnittGRAD: 1,
    winkelAbschnittDiagonaleGRAD: "",
    winkelEndAbschnittDiagonaleGRAD: "",

    //-- folgende werte nicht anpassen --
    laengenFaktor: 1, // 1 = default (cm). dieser wert ändert sich dynamisch und sollte nicht händisch geändert werden
    laengenLabel: "cm", // cm = default. dieser wert ändert sich dynamisch und sollte nicht händisch geändert werden
    laengenNachkomma: 1, // 1 = default (cm). dieser wert ändert sich dynamisch und sollte nicht händisch geändert werden
    syslaengenFaktor: 100, // 100 = default (m). dieser wert ändert sich dynamisch und sollte nicht händisch geändert werden
    syslaengenLabel: "m", // m = default. dieser wert ändert sich dynamisch und sollte nicht händisch geändert werden
    syslaengenNachkomma: 3, // 3 = default (m). dieser wert ändert sich dynamisch und sollte nicht händisch geändert werden
    kraefteFaktor: 1, // 1 = default (kN). dieser wert ändert sich dynamisch und sollte nicht händisch geändert werden
    kraefteLabel: "kN", // kN = default. dieser wert ändert sich dynamisch und sollte nicht händisch geändert werden
    kraefteNachkomma: 2, // 2 = default (kN). dieser wert ändert sich dynamisch und sollte nicht händisch geändert werden
    //--

    ttExDiagonale: Ember['default'].computed('i18n.locale', {

      get: function get(key) {
        return this.get('i18n').t('wertebereich') + " 0 - 999 [cm]";
      },
      set: function set(key, value) {
        return value;
      }

    }),
    ttUeberstandDiagonaleVertikal: Ember['default'].computed('i18n.locale', {

      get: function get(key) {
        return this.get('i18n').t('wertebereich') + " 0 - 999 [cm]";
      },
      set: function set(key, value) {
        return value;
      }
    }),
    ttRwinkelH: Ember['default'].computed('i18n.locale', {

      get: function get(key) {
        return this.get('i18n').t('wertebereich') + " 30 - 90 [°]";
      },
      set: function set(key, value) {
        return value;
      }
    }),
    ttphi: Ember['default'].computed('i18n.locale', {

      get: function get(key) {
        return this.get('i18n').t('wertebereich') + " 0 - 90 [°]";
      },
      set: function set(key, value) {
        return value;
      }
    }),
    tta1: Ember['default'].computed('i18n.locale', {

      get: function get(key) {
        return this.get('i18n').t('wertebereich') + " 0 - 999 [cm]";
      },
      set: function set(key, value) {
        return value;
      }
    }),
    tta2: Ember['default'].computed('i18n.locale', {

      get: function get(key) {
        return this.get('i18n').t('wertebereich') + " 0 - 999 [cm]";
      },
      set: function set(key, value) {
        return value;
      }
    }),
    ttalpha1: Ember['default'].computed('i18n.locale', {

      get: function get(key) {
        return this.get('i18n').t('wertebereich') + " 5 - 175 [°]";
      },
      set: function set(key, value) {
        return value;
      }
    }),
    ttalpha2: Ember['default'].computed('i18n.locale', {

      get: function get(key) {
        return this.get('i18n').t('wertebereich') + " 5 - 175 [°]";
      },
      set: function set(key, value) {
        return value;
      }
    }),
    ttalpha3: Ember['default'].computed('i18n.locale', {

      get: function get(key) {
        return this.get('i18n').t('wertebereich') + " 0 - 360 [°]";
      },
      set: function set(key, value) {
        return value;
      }
    }),
    ttb1: Ember['default'].computed('i18n.locale', {

      get: function get(key) {
        return this.get('i18n').t('wertebereich') + " 0 - 999 [cm]";
      },
      set: function set(key, value) {
        return value;
      }
    }),
    ttb2: Ember['default'].computed('i18n.locale', {

      get: function get(key) {
        return this.get('i18n').t('wertebereich') + " 0 - 999 [cm]";
      },
      set: function set(key, value) {
        return value;
      }
    }),
    ttbeta1: Ember['default'].computed('i18n.locale', {

      get: function get(key) {
        return this.get('i18n').t('wertebereich') + " 5 - 175 [°]";
      },
      set: function set(key, value) {
        return value;
      }
    }),
    ttbeta2: Ember['default'].computed('i18n.locale', {

      get: function get(key) {
        return this.get('i18n').t('wertebereich') + " 5 - 175 [°]";
      },
      set: function set(key, value) {
        return value;
      }
    }),
    ttbeta3: Ember['default'].computed('i18n.locale', {

      get: function get(key) {
        return this.get('i18n').t('wertebereich') + " 0 - 360 [°]";
      },
      set: function set(key, value) {
        return value;
      }
    }),

    i18n: Ember['default'].inject.service(),

    validations: {
      ueberstandDiagonaleVertikal: {

        type: "l",
        tooltip: "ttUeberstandDiagonaleVertikal",
        numericality: {
          greaterThanOrEqualTo: 0,
          lessThanOrEqualTo: 999
        }
      },
      exzentrizitaetDiagonale: {
        type: "l",
        tooltip: "ttExDiagonale",
        numericality: {
          greaterThanOrEqualTo: 0,
          lessThanOrEqualTo: 999
        }
      },
      winkelAbschnittDiagonaleGRAD: {
        type: "w",
        tooltip: "ttWinkelAbschnittDiagonaleGRAD",
        numericality: {
          greaterThanOrEqualTo: 90,
          lessThanOrEqualTo: 90
        }
      },
      winkel: {
        type: "w",
        tooltip: "ttRwinkelH",
        numericality: {
          greaterThanOrEqualTo: 0,
          lessThanOrEqualTo: 90
        }
      },
      phi: {
        type: "w",
        tooltip: "ttphi",
        numericality: {
          greaterThanOrEqualTo: 0,
          lessThanOrEqualTo: 90
        }
      },
      a1: {
        id: "a1",
        type: "l",
        tooltip: "tta1",
        numericality: {
          greaterThanOrEqualTo: 0,
          lessThanOrEqualTo: 999
        }
      },
      a2: {
        type: "l",
        tooltip: "tta2",
        numericality: {
          greaterThanOrEqualTo: 0,
          lessThanOrEqualTo: 999
        }
      },
      alpha1: {
        type: "w",
        tooltip: "ttalpha1",
        numericality: {
          greaterThanOrEqualTo: 5,
          lessThanOrEqualTo: 175
        }
      },
      alpha2: {
        type: "w",
        tooltip: "ttalpha2",
        numericality: {
          greaterThanOrEqualTo: 5,
          lessThanOrEqualTo: 175
        }
      },
      alpha3: {
        type: "w",
        tooltip: "ttalpha3",
        numericality: {
          greaterThan: 0,
          lessThan: 360
        }
      },
      b1: {
        type: "l",
        tooltip: "ttb1",
        numericality: {
          greaterThanOrEqualTo: 0,
          lessThanOrEqualTo: 999
        }
      },
      b2: {
        type: "l",
        tooltip: "ttb2",
        numericality: {
          greaterThanOrEqualTo: 0,
          lessThanOrEqualTo: 999
        }
      },
      beta1: {
        type: "w",
        tooltip: "ttbeta1",
        numericality: {
          greaterThanOrEqualTo: 5,
          lessThanOrEqualTo: 175
        }
      },
      beta2: {
        type: "w",
        tooltip: "ttbeta2",
        numericality: {
          greaterThanOrEqualTo: 5,
          lessThanOrEqualTo: 175
        }
      },
      beta3: {
        type: "w",
        tooltip: "ttbeta3",
        numericality: {
          greaterThan: 0,
          lessThan: 360
        }
      }
    },

    winkelEndContentArray: [{
      name: "abschnittSenkrechtDiagonalenachse",
      ind: 1,
      disabled: false
    }, {
      name: "abschnittParallelGurtachse",
      ind: 2,
      disabled: false
    }, {
      name: "freieEingabe",
      ind: 3,
      disabled: true
    }],

    winkelEndContent: [],

    winkelHilfetext: "",
    winkelHilfetextFocused: false,
    winkelEndAbschnittGRADHilfetext: "",
    winkelEndAbschnittGRADHilfetextFocused: false,
    winkelEndAbschnittDiagonaleGRADHilfetext: "",
    winkelEndAbschnittDiagonaleGRADHilfetextFocused: false,
    ueberstandDiagonaleVertikalHilfetext: "",
    ueberstandDiagonaleVertikalHilfetextFocused: false,
    ausmittigerstabanschlussHilfetext: "",
    ausmittigerstabanschlussHilfetextFocused: false,
    winkelAbschnittDiagonaleGRADHilfetext: "",
    winkelAbschnittDiagonaleGRADHilfetextFocused: false,
    exzentrizitaetDiagonaleHilfetext: "",
    exzentrizitaetDiagonaleHilfetextFocused: false,

    phiHilfetext: "",
    phiHilfetextFocused: false,
    a1Hilfetext: "",
    a1HilfetextFocused: false,
    a2Hilfetext: "",
    a2HilfetextFocused: false,
    alpha1Hilfetext: "",
    alpha1HilfetextFocused: false,
    alpha2Hilfetext: "",
    alpha2HilfetextFocused: false,
    alpha3Hilfetext: "",
    alpha3HilfetextFocused: false,
    b1Hilfetext: "",
    b1HilfetextFocused: false,
    b2Hilfetext: "",
    b2HilfetextFocused: false,
    beta1Hilfetext: "",
    beta1HilfetextFocused: false,
    bet2Hilfetext: "",
    bet2HilfetextFocused: false,
    beta3Hilfetext: "",
    beta3HilfetextFocused: false,

    letzteAnschlussVrianteBauteil1: 51,
    letzteAnschnittVrianteBauteil1: 11,

    letzteAnschlussVrianteBauteil2: 52,
    letzteAnschnittVrianteBauteil2: 11,

    // av1selected: false,
    av2selected: false,
    // av3selected: false,
    av4selected: false,
    av51selected: true,
    av52selected: true,
    av6selected: false,
    // av7selected: false,
    av8selected: false,
    // av9selected: false,

    asv11selected: false,
    asv12selected: false,
    asv13selected: false,
    asv14selected: false,
    asv21selected: false,
    asv22selected: false,
    asv23selected: false,
    asv24selected: false,
    asv31selected: false,
    asv32selected: false,
    asv33selected: false,
    asv34selected: false,
    asv41selected: false,
    asv42selected: false,
    asv43selected: false,
    asv44selected: false,

    phi: "",

    a1: "",
    a2: "",
    alpha1: "",
    alpha2: "",
    alpha3: "",

    b1: "",
    b2: "",
    beta1: "",
    beta2: "",
    beta3: "",

    a1InputDisabled: false,
    a2InputDisabled: false,
    alpha1InputDisabled: false,
    alpha2InputDisabled: false,
    alpha3InputDisabled: true,

    b1InputDisabled: false,
    b2InputDisabled: false,
    beta1InputDisabled: false,
    beta2InputDisabled: false,
    beta3InputDisabled: true,

    vorzeichenAWerte: 0,
    vorzeichenBWerte: 0,

    abschnittRechts: true,
    abschnittLinks: false,
    abschnittOben: true,
    abschnittUnten: false,

    alteEingabenAnzeigen: false,

    aktuelleAnschlussgeometrieDiagonale: "assets/images/mitte.png",
    aktuelleAnschlussgeometrieGurt: "assets/images/mitte.png",

    classDisplaySubmenuDiagonale: false,
    classDisplaySubmenuGurt: false,

    init: function init() {

      this._super();

      this.set("winkel", "0.0");
      this.set("ueberstandDiagonaleVertikal", "0.0");
      this.set("exzentrizitaetDiagonale", "0.0");
      this.set("winkelAbschnittDiagonaleGRAD", "90.0");
      this.set("winkelEndAbschnittDiagonaleGRAD", "90.0");

      this.set("phi", "0.0");

      this.set("a1", "0.0");
      this.set("a2", "0.0");
      this.set("alpha1", "90.0");
      this.set("alpha2", "90.0");
      this.set("alpha3", "180.0");

      this.set("b1", "0.0");
      this.set("b2", "0.0");
      this.set("beta1", "90.0");
      this.set("beta2", "90.0");
      this.set("beta3", "180.0");

      this.setSelectFieldsContent();
    },

    applyFactor: function applyFactor(m) {
      // console.log("applyfactor(): " + this.laengenFaktor + " / " + this.kraefteFaktor);
      var result = "";
      for (var p in this[m]) {
        // console.log("for");
        if (this[m].hasOwnProperty(p)) {
          // console.log(p);
          if (this[m][p].type == "l") {
            // console.log("laenge wird angepasst");
            this[m][p].numericality.lessThanOrEqualTo = parseFloat(this[m][p].numericality.lessThanOrEqualTo * this.laengenFaktor);
            this[m][p].numericality.greaterThanOrEqualTo = parseFloat(this[m][p].numericality.greaterThanOrEqualTo * this.laengenFaktor);
            // this.set(this[m][p].tooltip, this.get('i18n').t('wertebereich') + parseFloat(this[m][p].numericality.greaterThanOrEqualTo).toFixed(this.laengenNachkomma) + " - " +  parseFloat(this[m][p].numericality.lessThanOrEqualTo).toFixed(this.laengenNachkomma) + " [" + this.laengenLabel + "]");
          } else if (this[m][p].type == "sl") {
              // console.log("systemlaenge wird angepasst");
              this[m][p].numericality.lessThanOrEqualTo = parseFloat(this[m][p].numericality.lessThanOrEqualTo * this.syslaengenFaktor);
              this[m][p].numericality.greaterThanOrEqualTo = parseFloat(this[m][p].numericality.greaterThanOrEqualTo * this.syslaengenFaktor);
              // this.set(this[m][p].tooltip, this.get('i18n').t('wertebereich') + parseFloat(this[m][p].numericality.greaterThanOrEqualTo).toFixed(this.syslaengenNachkomma) + " - " +  parseFloat(this[m][p].numericality.lessThanOrEqualTo).toFixed(this.syslaengenNachkomma) + " [" + this.syslaengenLabel + "]");
            } else if (this[m][p].type == "k") {
                // console.log("kraft wird angepasst");
                this[m][p].numericality.lessThanOrEqualTo = parseFloat(this[m][p].numericality.lessThanOrEqualTo * this.kraefteFaktor);
                this[m][p].numericality.greaterThanOrEqualTo = parseFloat(this[m][p].numericality.greaterThanOrEqualTo * this.kraefteFaktor);
                // this.set(this[m][p].tooltip, this.get('i18n').t('wertebereich') + parseFloat(this[m][p].numericality.greaterThanOrEqualTo).toFixed(this.kraefteNachkomma) + " - " +  parseFloat(this[m][p].numericality.lessThanOrEqualTo).toFixed(this.kraefteNachkomma) + " [" + this.kraefteLabel + "]");
              }
        }
      }
    },

    setValues: function setValues(values) {
      var self = this;

      if (self.debug) {
        console.log("values in anschlussgeometrie: ");
        console.log(values);
      }

      var winkelAbschnittDiagonaleGRADEvent = {
        target: {
          name: "winkelAbschnittDiagonaleGRAD"
        }
      };
      var winkelEvent = {
        target: {
          name: "winkel"
        }
      };
      var exzentrizitaetDiagonaleEvent = {
        target: {
          name: "exzentrizitaetDiagonale"
        }
      };

      this.set('openFromFile', true);

      this.set('winkelAbschnittDiagonaleGRAD', parseFloat(values.winkelAbschnittDiagonaleGRAD).toFixed(1));

      this.set('winkel', parseFloat(values.ALFA_Elements_GRAD).toFixed(1));
      this.set('lagenebentraeger', parseInt(values.SrVar));
      this.set('hthoehe', values.SrPaare);

      this.set('ueberstandDiagonaleVertikal', (Number(values.ueberstandDiagonaleVertikal) * self.laengenFaktor).toFixed(self.laengenNachkomma));
      this.set('winkelAbschnittDiagonaleGRAD', Number(values.winkelAbschnittDiagonaleGRAD));
      this.set('winkelEndAbschnittDiagonaleGRAD', Number(values.winkelEndAbschnittDiagonaleGRAD));
      this.set('winkelEndAbschnittDiagonaleGRAD', Number(values.winkelEndAbschnittDiagonaleGRAD));
      this.set('ausmittigerstabanschluss', values.abschnittDiagonaleBerechnen);
      this.set('exzentrizitaetDiagonale', (values.exzentrizitaetDiagonale * self.laengenFaktor).toFixed(self.laengenNachkomma));
      this.setX3D((values.exzentrizitaetDiagonale * self.laengenFaktor).toFixed(self.laengenNachkomma), exzentrizitaetDiagonaleEvent);

      this.setX3D(parseFloat(values.winkelAbschnittDiagonaleGRAD), winkelAbschnittDiagonaleGRADEvent);
      this.setX3D(parseFloat(values.ALFA_Elements_GRAD), winkelEvent);

      this.set('abschnittRechts', values.abschnittRechts);
      this.set('abschnittLinks', values.abschnittLinks);

      this.set('a1', Number(values.a1));
      this.set('a2', Number(values.a2));
      this.set('alpha1', Number(values.alpha1));
      this.set('alpha2', Number(values.alpha2));
      this.set('alpha3', Number(values.alpha3));

      this.set('abschnittOben', values.abschnittOben);
      this.set('abschnittUnten', values.abschnittUnten);

      this.set('b1', Number(values.b1));
      this.set('b2', Number(values.b2));
      this.set('beta1', Number(values.beta1));
      this.set('beta2', Number(values.beta2));
      this.set('beta3', Number(values.beta3));

      this.send('validation');

      this.set('openFromFile', false);
    },

    setSelectFieldsContent: function setSelectFieldsContent() {
      var self = this;
      self.setWinkelEndAbschnittGRAD();
    },

    setWinkelEndAbschnittGRAD: function setWinkelEndAbschnittGRAD() {
      var self = this;
      var indices = [1, 2, 3];
      self.set('winkelEndContent', self.getSelectFieldContent('winkelEndContent', indices));
    },

    getSelectFieldContent: function getSelectFieldContent(db, indexes) {
      var self = this;
      var tmp = [];
      var dbContent = self.getDBContent(db);

      for (var i = 0; i < indexes.length; i++) {
        for (var k = 0; k < dbContent.length; k++) {
          if (dbContent[k].ind === indexes[i]) {
            tmp.push({ ind: dbContent[k].ind, name: this.get('i18n').t(dbContent[k].name), disabled: dbContent[k].disabled });
          }
        }
      }
      return tmp;
    },

    getDBContent: function getDBContent(db) {
      var self = this;
      var dbContent = [];
      switch (db) {
        case 'winkelEndContent':
          dbContent = self.winkelEndContentArray;
          break;
      }
      return dbContent;
    },

    watchAusmittigerStabanschluss: (function () {
      var self = this;
      var application = self.controllerFor('application');
      var x3d = application.get('model').x3ddefault.objectAt(0);
      var anschlussgeometrie = this.controllerFor('application').get('model').anschlussgeometrie.objectAt(0);

      if (x3d.get('ergebnisGeladen') === true) {
        x3d.set('ergebnisGeladen', false);
        x3d.set('aktuellerSchraubenTyp', 'standardSchraube');
        x3d.set('schraubenlisteAktualisiert', !x3d.get('schraubenlisteAktualisiert'));
      }

      if (self.get('ausmittigerstabanschluss')) {
        anschlussgeometrie.set('abschnittDiagonaleBerechnen', true);
      } else {
        anschlussgeometrie.set('abschnittDiagonaleBerechnen', false);
        self.set('exzentrizitaetDiagonale', '0.0');
        x3d.set('exzentrizitaetDiagonale', 0);
        anschlussgeometrie.set('exzentrizitaetDiagonale', '0.0');
      }

      var applicationdata = self.controllerFor('application').get('model').application.objectAt(0);
      applicationdata.set('treeLoaded', false);
      applicationdata.set('initialized', false);
      applicationdata.set('pdfErstellt', false);
      applicationdata.set('dxfErstellt', false);

      self.set('exzentritaetDiagonaleDisabled', !self.get('exzentritaetDiagonaleDisabled'));
      x3d.set('ausmittigerstabanschluss', self.get('ausmittigerstabanschluss'));

      x3d.set('transformHelper', !x3d.get('transformHelper'));
      x3d.set('arc2dPositionTriggern', !x3d.get('arc2dPositionTriggern'));
      application.zentriertObjekt();
    }).observes('ausmittigerstabanschluss'),

    watchUeberstandDiagonale: (function () {

      var self = this;
      var anschlussgeometrie = this.controllerFor('application').get('model').anschlussgeometrie.objectAt(0);

      if (parseFloat(self.get('ueberstandDiagonaleVertikal')) > 0) {
        anschlussgeometrie.set('ueberstandDiagonaleBerechnen', true);
      } else {
        anschlussgeometrie.set('ueberstandDiagonaleBerechnen', false);
      }
    }).observes('ueberstandDiagonaleVertikal'),

    watchNumericalInputs: (function () {

      if (!this.openFromFile) {

        if (this.get('winkel').indexOf(",") !== -1) {
          this.set('winkel', this.get('winkel').replace(",", "."));
        }

        if (this.get('ueberstandDiagonaleVertikal').indexOf(",") !== -1) {
          this.set('ueberstandDiagonaleVertikal', this.get('ueberstandDiagonaleVertikal').replace(",", "."));
        }

        if (this.get('exzentrizitaetDiagonale').indexOf(",") !== -1) {
          this.set('exzentrizitaetDiagonale', this.get('exzentrizitaetDiagonale').replace(",", "."));
        }

        if (this.get('phi').indexOf(",") !== -1) {
          this.set('phi', this.get('phi').replace(",", "."));
        }

        if (this.get('a1').indexOf(",") !== -1) {
          this.set('a1', this.get('a1').replace(",", "."));
        }

        if (this.get('a2').indexOf(",") !== -1) {
          this.set('a2', this.get('a2').replace(",", "."));
        }

        if (this.get('alpha1').indexOf(",") !== -1) {
          this.set('alpha1', this.get('alpha1').replace(",", "."));
        }

        if (this.get('alpha2').indexOf(",") !== -1) {
          this.set('alpha2', this.get('alpha2').replace(",", "."));
        }

        if (this.get('alpha3').indexOf(",") !== -1) {
          this.set('alpha3', this.get('alpha3').replace(",", "."));
        }

        if (this.get('b1').indexOf(",") !== -1) {
          this.set('b1', this.get('b1').replace(",", "."));
        }

        if (this.get('b2').indexOf(",") !== -1) {
          this.set('b2', this.get('b2').replace(",", "."));
        }

        if (this.get('beta1').indexOf(",") !== -1) {
          this.set('beta1', this.get('beta1').replace(",", "."));
        }

        if (this.get('beta2').indexOf(",") !== -1) {
          this.set('beta2', this.get('beta2').replace(",", "."));
        }

        if (this.get('beta3').indexOf(",") !== -1) {
          this.set('beta3', this.get('beta3').replace(",", "."));
        }
      }
    }).observes('phi', 'ueberstandDiagonaleVertikal', 'exzentrizitaetDiagonale', 'a1', 'a2', 'alpha1', 'alpha2', 'alpha3', 'b1', 'b2', 'beta1', 'beta2', 'beta3'),

    watchPhi: (function () {
      if (parseFloat(this.get('phi')) === 90.0 || parseFloat(this.get('phi')) === 0.0) {
        this.set('exzentritaetDiagonaleDisabled', true);
        this.set('ausmittigDisabled', true);
      } else {
        //this.set('exzentritaetDiagonaleDisabled', false);
        this.set('ausmittigDisabled', false);
      }

      this.maxExzDiagBerechnen();
    }).observes('phi'),

    watchUeDiagVertikal: (function () {
      this.maxExzDiagBerechnen();
    }).observes('ueberstandDiagonaleVertikal'),

    watchComboboxes: (function () {

      var self = this;

      var applicationdata = self.controllerFor('application').get('model').application.objectAt(0);
      applicationdata.set('treeLoaded', false);
      applicationdata.set('initialized', false);
      applicationdata.set('pdfErstellt', false);
      applicationdata.set('dxfErstellt', false);

      var application = self.controllerFor('application');
      application.set('verbindungsmittelInvalid', true);

      var downloadcenter = self.controllerFor('downloadcenter');
      downloadcenter.set('pdfErstellt', false);
      downloadcenter.set('dxfErstellt', false);

      var x3d = application.get('model').x3ddefault.objectAt(0);
      x3d.set('winkelEndAbschnittGRAD', self.get('winkelEndAbschnittGRAD'));

      if (x3d.get('ergebnisGeladen') === true) {
        x3d.set('ergebnisGeladen', false);
        x3d.set('aktuellerSchraubenTyp', 'standardSchraube');
        x3d.set('schraubenlisteAktualisiert', !x3d.get('schraubenlisteAktualisiert'));
      }

      var anschlussgeometrie = this.controllerFor('application').get('model').anschlussgeometrie.objectAt(0);

      var tmp = self.get('winkelEndAbschnittGRAD');
      if (tmp === 1) {
        self.set('winkelEndAbschnittDiagonaleGRAD', "90.0");
        x3d.set('winkelEndAbschnittDiagonaleGRAD', "90.0");
      } else if (tmp === 2) {
        self.set('winkelEndAbschnittDiagonaleGRAD', self.get('winkel'));
        x3d.set('winkelEndAbschnittDiagonaleGRAD', self.get('winkel'));
      }

      self.send('validation', self.get("ueberstandDiagonaleVertikal"), { target: { name: "ueberstandDiagonaleVertikal" } });

      x3d.set('transformHelper', !x3d.get('transformHelper'));
      x3d.set('arc2dPositionTriggern', !x3d.get('arc2dPositionTriggern'));
      application.zentriertObjekt();
    }).observes('winkelEndAbschnittGRAD'),

    setX3D: function setX3D(value, event) {

      var application = this.controllerFor('application');
      var x3d = application.get('model').x3ddefault.objectAt(0);
      var bauteile = x3d.get('bauteile');

      var self = this;

      x3d.set('ergebnisGeladen', false);
      x3d.set('aktuellerSchraubenTyp', 'standardSchraube');
      x3d.set('schraubenlisteAktualisiert', !x3d.get('schraubenlisteAktualisiert'));

      value = parseFloat(value).toFixed(1);

      // console.log('##### setX3D #####');

      switch (event.target.name) {
        case 'phi':
          if (this.errors.phi.length === 0) {
            x3d.set('phi', value);
            x3d.set('phiEingetragen', true);

            if (self.get('asv12selected') === true || self.get('asv42selected') === true) {

              var value = Number(value) === 0 ? 90 : value;

              self.set('alpha1', (180 - value).toFixed(1).toString());
              self.set('alpha2', Number(value).toFixed(1).toString());

              self.set('beta1', (180 - value).toFixed(1).toString());
              self.set('beta2', Number(value).toFixed(1).toString());
            }
          } else {
            x3d.set('phiEingetragen', false);
          }
          break;
        case 'a1':
          if (this.errors.a1.length === 0) {
            x3d.set('a1', self.get('vorzeichenAWerte') * value);
            x3d.set('a1Eingetragen', true);
          } else {
            x3d.set('a1Eingetragen', false);
          }
          break;
        case 'a2':
          if (this.errors.a2.length === 0) {
            // x3d.set('a2', self.get('vorzeichenAWerte') * value);
            x3d.set('a2', value);
            x3d.set('a2Eingetragen', true);
          } else {
            x3d.set('a2Eingetragen', false);
          }
          break;
        case 'alpha1':
          if (this.errors.alpha1.length === 0) {
            x3d.set('alpha1', value);
            x3d.set('alpha1Eingetragen', true);

            this.setAlpha2Grenzwert();

            if (self.get('alpha2InputDisabled') === true) {
              self.set('alpha2', (180 - value).toFixed(1).toString());
            }
          } else {
            x3d.set('alpha1Eingetragen', false);
          }
          break;
        case 'alpha2':
          if (this.errors.alpha2.length === 0) {
            x3d.set('alpha2', value);
            x3d.set('alpha2Eingetragen', true);
          } else {
            x3d.set('alpha2Eingetragen', false);
          }
          break;
        case 'alpha3':
          if (this.errors.alpha3.length === 0) {
            x3d.set('alpha3', value);
            x3d.set('alpha3Eingetragen', true);
          } else {
            x3d.set('alpha3Eingetragen', false);
          }
          break;
        case 'b1':
          if (this.errors.b1.length === 0) {
            x3d.set('b1', self.get('vorzeichenBWerte') * value);
            x3d.set('b1Eingetragen', true);
          } else {
            x3d.set('b1Eingetragen', false);
          }
          break;
        case 'b2':
          if (this.errors.b2.length === 0) {
            // x3d.set('b2', self.get('vorzeichenBWerte') * value);
            x3d.set('b2', value);
            x3d.set('b2Eingetragen', true);
          } else {
            x3d.set('b2Eingetragen', false);
          }
          break;
        case 'beta1':
          if (this.errors.beta1.length === 0) {
            x3d.set('beta1', value);
            x3d.set('beta1Eingetragen', true);

            if (self.get('beta2InputDisabled') === true) {
              self.set('beta2', (180 - value).toFixed(1).toString());
            }
          } else {
            x3d.set('beta1Eingetragen', false);
          }
          break;
        case 'beta2':
          if (this.errors.beta2.length === 0) {
            x3d.set('beta2', value);
            x3d.set('beta2Eingetragen', true);
          } else {
            x3d.set('beta2Eingetragen', false);
          }
          break;
        case 'beta3':
          if (this.errors.beta3.length === 0) {
            x3d.set('beta3', value);
            x3d.set('beta3Eingetragen', true);
          } else {
            x3d.set('beta3Eingetragen', false);
          }
          break;
        case 'winkelEndAbschnittDiagonaleGRAD':
          if (this.errors.winkelEndAbschnittDiagonaleGRAD.length === 0) {
            x3d.set('winkelEndAbschnittDiagonaleGRAD', value);
            x3d.set('winkelEndAbschnittDiagonaleGRADEingetragen', true);
          } else {
            x3d.set('winkelEndAbschnittDiagonaleGRADEingetragen', false);
          }
          break;
        case 'ueberstandDiagonaleVertikal':
          if (this.errors.ueberstandDiagonaleVertikal.length === 0) {
            x3d.set('ueberstandDiagonaleVertikal', value);
            x3d.set('ueberstandDiagonaleVertikalEingetragen', true);
          } else {
            x3d.set('ueberstandDiagonaleVertikalEingetragen', false);
          }
          break;
        case 'winkelAbschnittDiagonaleGRAD':
          if (this.errors.winkelAbschnittDiagonaleGRAD.length === 0) {
            x3d.set('winkelAbschnittDiagonaleGRAD', value);
            x3d.set('winkelAbschnittDiagonaleGRADEingetragen', true);
          } else {
            x3d.set('winkelAbschnittDiagonaleGRADEingetragen', false);
          }
          break;
        case 'exzentrizitaetDiagonale':
          if (this.errors.exzentrizitaetDiagonale.length === 0) {
            x3d.set('exzentrizitaetDiagonale', value);
            x3d.set('exzentrizitaetDiagonaleEingetragen', true);
          } else {
            x3d.set('exzentrizitaetDiagonaleEingetragen', false);
          }
          break;
      }

      self.setGeometrieSeitenbauteil();
      self.setGeometrieMittelbauteil();

      x3d.set('transformHelper', !x3d.get('transformHelper'));
      x3d.set('arc2dPositionTriggern', !x3d.get('arc2dPositionTriggern'));

      application.zentriertObjekt();
    },

    setGeometrieMittelbauteil: function setGeometrieMittelbauteil() {
      var self = this;
      var application = self.controllerFor('application');
      var x3d = application.get('model').x3ddefault.objectAt(0);
      var bauteile = x3d.get('bauteile');

      var b1 = Number(self.get('b1'));
      var Hd = Number(bauteile.findBy('id', 'hauptTraeger1').get('boxsizes').objectAt(0).get('y'));

      // console.log('Hd: '+Hd);

      var phi = x3d.get('phi');

      var beta1 = Number(self.get('beta1'));
      x3d.set('beta1', beta1);

      if (self.get('beta2InputDisabled') === true) {
        x3d.set('beta2', 180 - beta1);
      }

      var beta3 = 360 - (beta1 + Number(self.get('beta2')));

      x3d.set('beta3', beta3.toFixed(1).toString());
      self.set('beta3', beta3.toFixed(1).toString());

      // console.log('##### Berechnung #####');
      // console.log('beta1: '+beta1);
      // console.log('winkel: '+(90-phi));

      // var winkel = (180 - beta1 - phi) * Math.PI / 180;
      var winkel = (90 - phi) * Math.PI / 180;

      // console.log('winkel: '+winkel);
      // console.log('Math.sin(winkel): '+Math.sin(winkel));
      // console.log('b1: '+b1);
      // console.log('Hd: '+Hd);
      // console.log('Hd * Math.sin(winkel): '+(Hd * Math.sin(winkel)));
      // console.log('Hd/Math.tan(alpha1*Math.PI/180): '+Hd/Math.tan(alpha1*Math.PI/180));
      // console.log('Hd/Math.tan(alpha1*Math.PI/180)*Math.sin(phi*Math.PI/180): '+Hd/Math.tan(alpha1*Math.PI/180)*Math.sin(phi*Math.PI/180));

      b1 = beta1 < 90 ? b1 - Hd / Math.tan(beta1 * Math.PI / 180) * Math.sin(phi * Math.PI / 180) : beta1 > 90 ? b1 + Hd * Math.tan((beta1 - 90) * Math.PI / 180) * Math.sin(phi * Math.PI / 180) : b1;

      // console.log(' ');
      // console.log('a1: '+a1);

      var b2 = Number(self.get('vorzeichenBWerte')) < 0 ? b1 + Hd * Math.sin(winkel) : b1 - Hd * Math.sin(winkel);

      // console.log('a2: '+a2);

      if (self.get('b2InputDisabled') === true) {
        self.set('b2', b2.toFixed(1).toString());
        x3d.set('b2', b2);
      }
    },

    setGeometrieSeitenbauteil: function setGeometrieSeitenbauteil() {
      var self = this;
      var application = self.controllerFor('application');
      var x3d = application.get('model').x3ddefault.objectAt(0);
      var bauteile = x3d.get('bauteile');

      var a1 = Number(self.get('a1'));
      var Hd = Number(bauteile.findBy('id', 'hauptTraeger1').get('boxsizes').objectAt(0).get('y'));

      // console.log('Hd: '+Hd);

      var phi = x3d.get('phi');

      var alpha1 = Number(self.get('alpha1'));
      x3d.set('alpha1', alpha1);

      if (self.get('alpha2InputDisabled') === true) {
        x3d.set('alpha2', 180 - alpha1);
      }

      var alpha3 = 360 - (alpha1 + Number(self.get('alpha2')));

      x3d.set('alpha3', alpha3.toFixed(1).toString());
      self.set('alpha3', alpha3.toFixed(1).toString());

      // console.log('##### Berechnung #####');
      // console.log('alpha1: '+alpha1);
      // console.log('winkel: '+(90-phi));

      // var winkel = (180 - alpha1 - phi) * Math.PI / 180;
      var winkel = (90 - phi) * Math.PI / 180;

      // console.log('winkel: '+winkel);
      // console.log('Math.sin(winkel): '+Math.sin(winkel));
      // console.log('a1: '+a1);
      // console.log('Hd: '+Hd);
      // console.log('Hd * Math.sin(winkel): '+(Hd * Math.sin(winkel)));
      // console.log('Hd/Math.tan(alpha1*Math.PI/180): '+Hd/Math.tan(alpha1*Math.PI/180));
      // console.log('Hd/Math.tan(alpha1*Math.PI/180)*Math.sin(phi*Math.PI/180): '+Hd/Math.tan(alpha1*Math.PI/180)*Math.sin(phi*Math.PI/180));

      a1 = alpha1 < 90 ? a1 - Hd / Math.tan(alpha1 * Math.PI / 180) * Math.sin(phi * Math.PI / 180) : alpha1 > 90 ? a1 + Hd * Math.tan((alpha1 - 90) * Math.PI / 180) * Math.sin(phi * Math.PI / 180) : a1;

      // console.log(' ');
      // console.log('a1: '+a1);

      var a2 = a1 - Hd * Math.sin(winkel);

      // console.log('a2: '+a2);

      if (self.get('a2InputDisabled') === true) {
        self.set('a2', a2.toFixed(1).toString());
        x3d.set('a2', a2);
      }
    },

    observPhi: (function () {
      var self = this;
      if (Number(self.get('phi')) === 0) {
        self.set('a1', 0..toFixed(1).toString());
        self.set('alpha1', 90..toFixed(1).toString());
      }
    }).observes('phi'),

    maxExzDiagBerechnen: function maxExzDiagBerechnen() {
      var self = this;
      var bauteile = self.controllerFor('bauteile');
      var hDiag = bauteile.get('hthoehe') / Number(bauteile.get('laengenFaktor'));
      if (hDiag === "") {
        hDiag = 0;
      } else {
        hDiag = parseFloat(hDiag);
      }
      var hGurt = bauteile.get('nthoehe') / Number(bauteile.get('laengenFaktor'));
      if (hGurt === "") {
        hGurt = 0;
      } else {
        hGurt = parseFloat(hGurt);
      }
      var alfa = parseFloat(self.get('phi'));
      var beta = parseFloat(self.get('winkelEndAbschnittDiagonaleGRAD'));
      var ueDiagVert = parseFloat(self.get('ueberstandDiagonaleVertikal'));

      var dy = ueDiagVert - hDiag * Math.sin(beta * Math.PI / 180 - alfa * Math.PI / 180);

      var maxExzDiag = 999;

      if (alfa !== 0) {
        maxExzDiag = (dy / Math.tan(alfa * Math.PI / 180) + hDiag / 2 / Math.sin(alfa * Math.PI / 180) + hGurt / 2 / Math.tan(alfa * Math.PI / 180)).toFixed(1);
      }

      // console.log("###: " + maxExzDiag);

      self.set('validations.exzentrizitaetDiagonale.numericality.greaterThanOrEqualTo', 0);

      self.set('validations.exzentrizitaetDiagonale.numericality.lessThanOrEqualTo', Number(maxExzDiag));

      self.set('ttExDiagonale', self.get('i18n').t('wertebereich') + "0 - " + maxExzDiag + " [cm]");
    },

    actions: {
      validation: function validation(value, event) {

        var self = this;

        if (!self.openFromFile) {

          this.setX3D(value, event);
        }

        self.validate().then(function () {
          // all validations pass

        })['catch'](function () {
          // any validations fail

        })['finally'](function () {

          var validation = self.get('isValid');
          var application = self.controllerFor('application');
          if (validation === true) {
            self.set('displayErrors', false);
            application.set('anschlussgeometrieInvalid', false);

            var applicationdata = self.controllerFor('application').get('model').application.objectAt(0);
            applicationdata.set('treeLoaded', false);
            applicationdata.set('initialized', false);
            applicationdata.set('pdfErstellt', false);
            applicationdata.set('dxfErstellt', false);

            var downloadcenter = self.controllerFor('downloadcenter');
            downloadcenter.set('pdfErstellt', false);
            downloadcenter.set('dxfErstellt', false);

            application.set('verbindungsmittelInvalid', true);

            var anschlussgeometrie = self.controllerFor('application').get('model').anschlussgeometrie.objectAt(0);

            anschlussgeometrie.set('ueberstandDiagonaleVertikal', parseFloat(self.get('ueberstandDiagonaleVertikal')).toFixed(1));
            anschlussgeometrie.set('winkelEndAbschnittDiagonaleGRAD', parseFloat(self.get('winkelEndAbschnittDiagonaleGRAD')).toFixed(1));
            anschlussgeometrie.set('winkelAbschnittDiagonaleGRAD', parseFloat(self.get('winkelAbschnittDiagonaleGRAD')).toFixed(1));
            anschlussgeometrie.set('ALFA_Elements_GRAD', parseFloat(self.get('phi')).toFixed(1));
            anschlussgeometrie.set('exzentrizitaetDiagonale', parseFloat(self.get('exzentrizitaetDiagonale')).toFixed(1));

            anschlussgeometrie.set('abschnittLinks', self.get('abschnittLinks'));
            anschlussgeometrie.set('abschnittRechts', self.get('abschnittRechts'));

            anschlussgeometrie.set('a1', parseFloat(Math.abs(self.get('a1'))).toFixed(1));
            anschlussgeometrie.set('a2', parseFloat(Math.abs(self.get('a2'))).toFixed(1));
            anschlussgeometrie.set('alpha1', parseFloat(self.get('alpha1')).toFixed(1));
            anschlussgeometrie.set('alpha2', parseFloat(self.get('alpha2')).toFixed(1));
            anschlussgeometrie.set('alpha3', parseFloat(self.get('alpha3')).toFixed(1));

            anschlussgeometrie.set('abschnittOben', self.get('abschnittOben'));
            anschlussgeometrie.set('abschnittUnten', self.get('abschnittUnten'));

            anschlussgeometrie.set('b1', parseFloat(Math.abs(self.get('b1'))).toFixed(1));
            anschlussgeometrie.set('b2', parseFloat(Math.abs(self.get('b2'))).toFixed(1));
            anschlussgeometrie.set('beta1', parseFloat(self.get('beta1')).toFixed(1));
            anschlussgeometrie.set('beta2', parseFloat(self.get('beta2')).toFixed(1));
            anschlussgeometrie.set('beta3', parseFloat(self.get('beta3')).toFixed(1));
          } else {
            self.set('displayErrors', true);
            application.set('anschlussgeometrieInvalid', true);
          }
        });
      },

      phiIsSelected: function phiIsSelected() {
        var self = this;
        var aktiv = this.controllerFor('application').get('model').x3ddefault.objectAt(0).get('phiAktiv');
        this.controllerFor('application').get('model').x3ddefault.objectAt(0).set('phiAktiv', !aktiv);

        var tmp = self.get('winkelEndAbschnittGRAD');
        if (tmp === 2) {
          self.set('winkelEndAbschnittDiagonaleGRAD', self.get('phi'));
          this.controllerFor('application').get('model').x3ddefault.objectAt(0).set('winkelEndAbschnittDiagonaleGRAD', Number(self.get('phi')));
        }

        if (this.get('phi') !== "") {
          var work = parseFloat(this.get('phi').replace(",", "."));
          var rounded = work.toFixed(1);
          this.set('phi', rounded);
          this.send('validation', rounded, { target: { name: "phi" } });
        }

        if (aktiv === false) {
          document.getElementsByName('phi')[0].setSelectionRange(0, this.get('phi').length);
        }
      },

      a1IsSelected: function a1IsSelected() {
        var aktiv = this.controllerFor('application').get('model').x3ddefault.objectAt(0).get('a1Aktiv');
        this.controllerFor('application').get('model').x3ddefault.objectAt(0).set('a1Aktiv', !aktiv);

        if (this.get('a1') !== "") {
          var value = parseFloat(this.get('a1').replace(",", "."));
          var valuerounded = value.toFixed(1);
          this.set('a1', valuerounded);
          this.send('validation', valuerounded, { target: { name: "a1" } });
        }

        if (aktiv === false) {
          document.getElementsByName('a1')[0].setSelectionRange(0, this.get('a1').length);
        }
      },

      a2IsSelected: function a2IsSelected() {
        var aktiv = this.controllerFor('application').get('model').x3ddefault.objectAt(0).get('a2Aktiv');
        this.controllerFor('application').get('model').x3ddefault.objectAt(0).set('a2Aktiv', !aktiv);

        if (this.get('a2') !== "") {
          var value = parseFloat(this.get('a2').replace(",", "."));
          var valuerounded = value.toFixed(1);
          this.set('a2', valuerounded);
          this.send('validation', valuerounded, { target: { name: "a2" } });
        }

        if (aktiv === false) {
          document.getElementsByName('a2')[0].setSelectionRange(0, this.get('a2').length);
        }
      },

      alpha1IsSelected: function alpha1IsSelected() {
        var aktiv = this.controllerFor('application').get('model').x3ddefault.objectAt(0).get('alpha1Aktiv');
        this.controllerFor('application').get('model').x3ddefault.objectAt(0).set('alpha1Aktiv', !aktiv);

        if (this.get('alpha1') !== "") {
          var value = parseFloat(this.get('alpha1').replace(",", "."));
          var valuerounded = value.toFixed(1);
          this.set('alpha1', valuerounded);
          this.send('validation', valuerounded, { target: { name: "alpha1" } });
        }

        if (aktiv === false) {
          document.getElementsByName('alpha1')[0].setSelectionRange(0, this.get('alpha1').length);
        }
      },

      alpha2IsSelected: function alpha2IsSelected() {
        var aktiv = this.controllerFor('application').get('model').x3ddefault.objectAt(0).get('alpha2Aktiv');
        this.controllerFor('application').get('model').x3ddefault.objectAt(0).set('alpha2Aktiv', !aktiv);

        if (this.get('alpha2') !== "") {
          var value = parseFloat(this.get('alpha2').replace(",", "."));
          var valuerounded = value.toFixed(1);
          this.set('alpha2', valuerounded);
          this.send('validation', valuerounded, { target: { name: "alpha2" } });
        }

        if (aktiv === false) {
          document.getElementsByName('alpha2')[0].setSelectionRange(0, this.get('alpha2').length);
        }
      },

      alpha3IsSelected: function alpha3IsSelected() {
        var aktiv = this.controllerFor('application').get('model').x3ddefault.objectAt(0).get('alpha3Aktiv');
        this.controllerFor('application').get('model').x3ddefault.objectAt(0).set('alpha3Aktiv', !aktiv);

        if (this.get('alpha3') !== "") {
          var value = parseFloat(this.get('alpha3').replace(",", "."));
          var valuerounded = value.toFixed(1);
          this.set('alpha3', valuerounded);
          this.send('validation', valuerounded, { target: { name: "alpha3" } });
        }

        if (aktiv === false) {
          document.getElementsByName('alpha3')[0].setSelectionRange(0, this.get('alpha3').length);
        }
      },

      b1IsSelected: function b1IsSelected() {
        var aktiv = this.controllerFor('application').get('model').x3ddefault.objectAt(0).get('b1Aktiv');
        this.controllerFor('application').get('model').x3ddefault.objectAt(0).set('b1Aktiv', !aktiv);

        if (this.get('b1') !== "") {
          var value = parseFloat(this.get('b1').replace(",", "."));
          var valuerounded = value.toFixed(1);
          this.set('b1', valuerounded);
          this.send('validation', valuerounded, { target: { name: "b1" } });
        }

        if (aktiv === false) {
          document.getElementsByName('b1')[0].setSelectionRange(0, this.get('b1').length);
        }
      },

      b2IsSelected: function b2IsSelected() {
        var aktiv = this.controllerFor('application').get('model').x3ddefault.objectAt(0).get('b2Aktiv');
        this.controllerFor('application').get('model').x3ddefault.objectAt(0).set('b2Aktiv', !aktiv);

        if (this.get('b2') !== "") {
          var value = parseFloat(this.get('b2').replace(",", "."));
          var valuerounded = value.toFixed(1);
          this.set('b2', valuerounded);
          this.send('validation', valuerounded, { target: { name: "b2" } });
        }

        if (aktiv === false) {
          document.getElementsByName('b2')[0].setSelectionRange(0, this.get('b2').length);
        }
      },

      beta1IsSelected: function beta1IsSelected() {
        var aktiv = this.controllerFor('application').get('model').x3ddefault.objectAt(0).get('beta1Aktiv');
        this.controllerFor('application').get('model').x3ddefault.objectAt(0).set('beta1Aktiv', !aktiv);

        if (this.get('beta1') !== "") {
          var value = parseFloat(this.get('beta1').replace(",", "."));
          var valuerounded = value.toFixed(1);
          this.set('beta1', valuerounded);
          this.send('validation', valuerounded, { target: { name: "beta1" } });
        }

        if (aktiv === false) {
          document.getElementsByName('beta1')[0].setSelectionRange(0, this.get('beta1').length);
        }
      },

      beta2IsSelected: function beta2IsSelected() {
        var aktiv = this.controllerFor('application').get('model').x3ddefault.objectAt(0).get('beta2Aktiv');
        this.controllerFor('application').get('model').x3ddefault.objectAt(0).set('beta2Aktiv', !aktiv);

        if (this.get('beta2') !== "") {
          var value = parseFloat(this.get('beta2').replace(",", "."));
          var valuerounded = value.toFixed(1);
          this.set('beta2', valuerounded);
          this.send('validation', valuerounded, { target: { name: "beta2" } });
        }

        if (aktiv === false) {
          document.getElementsByName('beta2')[0].setSelectionRange(0, this.get('beta2').length);
        }
      },

      beta3IsSelected: function beta3IsSelected() {
        var aktiv = this.controllerFor('application').get('model').x3ddefault.objectAt(0).get('beta3Aktiv');
        this.controllerFor('application').get('model').x3ddefault.objectAt(0).set('beta3Aktiv', !aktiv);

        if (this.get('beta3') !== "") {
          var value = parseFloat(this.get('beta3').replace(",", "."));
          var valuerounded = value.toFixed(1);
          this.set('beta3', valuerounded);
          this.send('validation', valuerounded, { target: { name: "beta3" } });
        }

        if (aktiv === false) {
          document.getElementsByName('beta3')[0].setSelectionRange(0, this.get('beta3').length);
        }
      },

      hWinkelIsSelected: function hWinkelIsSelected() {
        var hwinkelaktiv = this.controllerFor('application').get('model').x3ddefault.objectAt(0).get('hWinkelAktiv');
        this.controllerFor('application').get('model').x3ddefault.objectAt(0).set('hWinkelAktiv', !hwinkelaktiv);

        if (this.get('rwinkelh') !== "") {
          var rwinkelhwork = parseFloat(this.get('rwinkelh').replace(",", "."));
          var rwinkelhrounded = rwinkelhwork.toFixed(1);
          this.set('rwinkelh', rwinkelhrounded);
          this.send('validation');
        }

        if (hwinkelaktiv === false) {
          document.getElementsByName('rwinkelh')[0].setSelectionRange(0, this.get('rwinkelh').length);
        }
      },

      winkelAbschnittDiagonaleGRADIsSelected: function winkelAbschnittDiagonaleGRADIsSelected() {
        var winkelAbschnittDiagonaleGRAD = this.controllerFor('application').get('model').x3ddefault.objectAt(0).get('winkelAbschnittDiagonaleGRADAktiv');
        this.controllerFor('application').get('model').x3ddefault.objectAt(0).set('winkelAbschnittDiagonaleGRADAktiv', !winkelAbschnittDiagonaleGRAD);

        if (this.get('winkelAbschnittDiagonaleGRAD') !== "") {
          var value = parseFloat(this.get('winkelAbschnittDiagonaleGRAD').replace(",", "."));
          var valuerounded = value.toFixed(1);
          this.set('winkelAbschnittDiagonaleGRAD', valuerounded);
          this.send('validation', valuerounded, { target: { name: "winkelAbschnittDiagonaleGRAD" } });
        }

        if (winkelAbschnittDiagonaleGRAD === false) {
          document.getElementsByName('winkelAbschnittDiagonaleGRAD')[0].setSelectionRange(0, this.get('winkelAbschnittDiagonaleGRAD').length);
        }
      },

      ueberstandDiagonaleVertikalIsSelected: function ueberstandDiagonaleVertikalIsSelected() {
        var ueberstandDiagonaleVertikal = this.controllerFor('application').get('model').x3ddefault.objectAt(0).get('ueberstandDiagonaleVertikalAktiv');
        this.controllerFor('application').get('model').x3ddefault.objectAt(0).set('ueberstandDiagonaleVertikalAktiv', !ueberstandDiagonaleVertikal);

        if (this.get('ueberstandDiagonaleVertikal') !== "") {
          var value = parseFloat(this.get('ueberstandDiagonaleVertikal').replace(",", "."));
          var valuerounded = value.toFixed(1);
          this.set('ueberstandDiagonaleVertikal', valuerounded);
          this.send('validation', valuerounded, { target: { name: "ueberstandDiagonaleVertikal" } });
        }

        if (ueberstandDiagonaleVertikal === false) {
          document.getElementsByName('ueberstandDiagonaleVertikal')[0].setSelectionRange(0, this.get('ueberstandDiagonaleVertikal').length);
        }
      },

      exzentrizitaetDiagonaleIsSelected: function exzentrizitaetDiagonaleIsSelected() {
        var exzentrizitaetDiagonale = this.controllerFor('application').get('model').x3ddefault.objectAt(0).get('exzentrizitaetDiagonaleAktiv');
        this.controllerFor('application').get('model').x3ddefault.objectAt(0).set('exzentrizitaetDiagonaleAktiv', !exzentrizitaetDiagonale);

        if (this.get('exzentrizitaetDiagonale') !== "") {
          var value = parseFloat(this.get('exzentrizitaetDiagonale').replace(",", "."));
          var valuerounded = value.toFixed(1);
          this.set('exzentrizitaetDiagonale', valuerounded);
          this.send('validation', valuerounded, { target: { name: "exzentrizitaetDiagonale" } });
        }

        if (exzentrizitaetDiagonale === false) {
          document.getElementsByName('exzentrizitaetDiagonale')[0].setSelectionRange(0, this.get('exzentrizitaetDiagonale').length);
        }
      },

      winkelIsSelected: function winkelIsSelected() {
        var self = this;
        var vWinkelAktiv = this.controllerFor('application').get('model').x3ddefault.objectAt(0).get('vWinkelAktiv');
        this.controllerFor('application').get('model').x3ddefault.objectAt(0).set('vWinkelAktiv', !vWinkelAktiv);

        //self.set('validations.winkelEndAbschnittDiagonaleGRAD.numericality.greaterThanOrEqualTo', parseFloat(self.get('winkel')));
        //self.set('ttBreiteA', self.get('i18n').t('wertebereich') + self.get('winkel') + " - 90 [°]");

        var tmp = self.get('winkelEndAbschnittGRAD');
        if (tmp === 2) {
          self.set('winkelEndAbschnittDiagonaleGRAD', self.get('winkel'));
          this.controllerFor('application').get('model').x3ddefault.objectAt(0).set('winkelEndAbschnittDiagonaleGRAD', Number(self.get('winkel')));
        }

        if (this.get('winkel') !== "") {
          var winkelwork = parseFloat(this.get('winkel').replace(",", "."));
          var winkelrounded = winkelwork.toFixed(1);
          this.set('winkel', winkelrounded);
          this.send('validation', winkelrounded, {
            target: {
              name: "winkel"
            }
          });
        }

        if (vWinkelAktiv === false) {
          document.getElementsByName('winkel')[0].setSelectionRange(0, this.get('winkel').length);
        }
      },

      removeClass: function removeClass(element) {
        var self = this;
        self.set(element, false);
      },

      // setAnschlussVarianteEins: function(index, vorzeichenAWerte, vorzeichenBWerte){
      //   var self = this;
      //   self.setAnschlussVarianteSelected(index, vorzeichenAWerte, vorzeichenBWerte);
      //   self.setInputsDisabled({"a1": false, "a2": false, "alpha1": false, "alpha2": false, "alpha3": true, "b1": false, "b2": false, "beta1": false, "beta2": false, "beta3": true });
      //   self.resetParameter(0, 0, 90, 90);
      // },

      setAnschlussVarianteZwei: function setAnschlussVarianteZwei(index, vorzeichenAWerte, vorzeichenBWerte, bla) {
        var self = this;
        self.setAnschlussVarianteSelectedBauteil1(index, vorzeichenAWerte);
        self.resetParameterA(0, 90);
        self.send("setAnschnittVarianteA1", "11");
      },

      // setAnschlussVarianteDrei: function(index, vorzeichenAWerte, vorzeichenBWerte){
      //   var self = this;
      //   self.setAnschlussVarianteSelected(index, vorzeichenAWerte, vorzeichenBWerte);
      //   self.resetParameter(0, 0, 90, 90);
      //   self.setInputsDisabled({"a1": false, "a2": false, "alpha1": false, "alpha2": false, "alpha3": true, "b1": false, "b2": false, "beta1": false, "beta2": false, "beta3": true });
      // },

      setAnschlussVarianteVier: function setAnschlussVarianteVier(index, vorzeichenBWerte) {
        var self = this;
        self.setAnschlussVarianteSelectedBauteil2(index, vorzeichenBWerte);
        self.send("setAnschnittVarianteB1", "21");
        self.resetParameterB(0, 90);
      },

      setAnschlussVarianteFuenfBauteil1: function setAnschlussVarianteFuenfBauteil1(index, vorzeichenAWerte, imagepfad) {
        var self = this;
        self.set("aktuelleAnschlussgeometrieDiagonale", imagepfad);
        self.setAnschlussVarianteSelectedBauteil1(index, vorzeichenAWerte);
        self.setInputsDisabled({ "a1": self, "a2": true, "alpha1": true, "alpha2": true, "alpha3": true, "b1": self.get('b1InputDisabled'), "b2": self.get('b2InputDisabled'), "beta1": self.get('beta1InputDisabled'), "beta2": self.get('beta2InputDisabled'), "beta3": self.get('beta3InputDisabled') });
        self.resetParameterA(0, 90);

        self.controllerFor('application').get('model').x3ddefault.objectAt(0).set('htAnschnittIndex', 0);
      },

      setAnschlussVarianteFuenfBauteil2: function setAnschlussVarianteFuenfBauteil2(index, vorzeichenBWerte, imagepfad) {
        var self = this;
        self.set("aktuelleAnschlussgeometrieGurt", imagepfad);
        self.setAnschlussVarianteSelectedBauteil2(index, vorzeichenBWerte);
        self.setInputsDisabled({ "a1": self.get('a1InputDisabled'), "a2": self.get('a2InputDisabled'), "alpha1": self.get('alpha1InputDisabled'), "alpha2": self.get('alpha2InputDisabled'), "alpha3": self.get('alpha3InputDisabled'), "b1": true, "b2": true, "beta1": true, "beta2": true, "beta3": true });
        self.resetParameterB(0, 90);

        self.controllerFor('application').get('model').x3ddefault.objectAt(0).set('ntAnschnittIndex', 0);
      },

      setAnschlussVarianteSechs: function setAnschlussVarianteSechs(index, vorzeichenBWerte) {
        var self = this;
        self.setAnschlussVarianteSelectedBauteil2(index, vorzeichenBWerte);
        self.send("setAnschnittVarianteB1", "31");
        self.resetParameterB(0, 90);
      },

      // setAnschlussVarianteSieben: function(index, vorzeichenAWerte, vorzeichenBWerte){
      //   var self = this;
      //   self.setAnschlussVarianteSelected(index, vorzeichenAWerte, vorzeichenBWerte);
      //   self.setInputsDisabled({"a1": false, "a2": false, "alpha1": false, "alpha2": false, "alpha3": true, "b1": false, "b2": false, "beta1": false, "beta2": false, "beta3": true });
      //   self.resetParameter(0, 0, 90, 90);
      // },

      setAnschlussVarianteAcht: function setAnschlussVarianteAcht(index, vorzeichenAWerte) {
        var self = this;
        self.setAnschlussVarianteSelectedBauteil1(index, vorzeichenAWerte);
        self.send("setAnschnittVarianteA1", "41");
        self.resetParameterA(0, 90);
      },

      // setAnschlussVarianteNeun: function(index, vorzeichenAWerte, vorzeichenBWerte){
      //   var self = this;
      //   self.setAnschlussVarianteSelected(index, vorzeichenAWerte, vorzeichenBWerte);
      //   self.setInputsDisabled({"a1": false, "a2": false, "alpha1": false, "alpha2": false, "alpha3": true, "b1": false, "b2": false, "beta1": false, "beta2": false, "beta3": true });
      //   self.resetParameter(0, 0, 90, 90);
      // },

      setAnschnittVarianteA1: function setAnschnittVarianteA1(anschlussIndex, anschnittIndex, vorzeichenAWerte, imagepfad) {
        var self = this;
        self.set("aktuelleAnschlussgeometrieDiagonale", imagepfad);
        self.setAnschlussVarianteSelectedBauteil1(anschlussIndex, vorzeichenAWerte);
        self.setAnschnittVarianteSelectedBauteil1(anschnittIndex);
        self.setInputsDisabled({ "a1": false, "a2": true, "alpha1": true, "alpha2": true, "alpha3": true, "b1": self.get('b1InputDisabled'), "b2": self.get('b2InputDisabled'), "beta1": self.get('beta1InputDisabled'), "beta2": self.get('beta2InputDisabled'), "beta3": self.get('beta3InputDisabled') });
        self.resetParameterA(0, 90);
      },

      setAnschnittVarianteA2: function setAnschnittVarianteA2(anschlussIndex, anschnittIndex, vorzeichenAWerte, imagepfad) {
        var self = this;
        self.set("aktuelleAnschlussgeometrieDiagonale", imagepfad);
        self.setAnschlussVarianteSelectedBauteil1(anschlussIndex, vorzeichenAWerte);
        self.setAnschnittVarianteSelectedBauteil1(anschnittIndex);
        self.setInputsDisabled({ "a1": false, "a2": true, "alpha1": true, "alpha2": true, "alpha3": true, "b1": self.get('b1InputDisabled'), "b2": self.get('b2InputDisabled'), "beta1": self.get('beta1InputDisabled'), "beta2": self.get('beta2InputDisabled'), "beta3": self.get('beta3InputDisabled') });
        self.resetParameterA(0, 180 - Number(self.get('phi')));
      },
      setAnschnittVarianteA3: function setAnschnittVarianteA3(anschlussIndex, anschnittIndex, vorzeichenAWerte, imagepfad) {
        var self = this;
        self.set("aktuelleAnschlussgeometrieDiagonale", imagepfad);
        self.setAnschlussVarianteSelectedBauteil1(anschlussIndex, vorzeichenAWerte);
        self.setAnschnittVarianteSelectedBauteil1(anschnittIndex);
        self.setInputsDisabled({ "a1": false, "a2": true, "alpha1": false, "alpha2": true, "alpha3": true, "b1": self.get('b1InputDisabled'), "b2": self.get('b2InputDisabled'), "beta1": self.get('beta1InputDisabled'), "beta2": self.get('beta2InputDisabled'), "beta3": self.get('beta3InputDisabled') });
        self.resetParameterA(0, 90 - Number(self.get('phi')));
      },
      setAnschnittVarianteA4: function setAnschnittVarianteA4(anschlussIndex, anschnittIndex, vorzeichenAWerte, imagepfad) {
        var self = this;
        self.set("aktuelleAnschlussgeometrieDiagonale", imagepfad);
        self.setAnschlussVarianteSelectedBauteil1(anschlussIndex, vorzeichenAWerte);
        self.setAnschnittVarianteSelectedBauteil1(anschnittIndex);
        self.setInputsDisabled({ "a1": false, "a2": false, "alpha1": false, "alpha2": false, "alpha3": true, "b1": self.get('b1InputDisabled'), "b2": self.get('b2InputDisabled'), "beta1": self.get('beta1InputDisabled'), "beta2": self.get('beta2InputDisabled'), "beta3": self.get('beta3InputDisabled') });
        self.resetParameterA(0, 90);
      },

      setAnschnittVarianteB1: function setAnschnittVarianteB1(anschlussIndex, anschnittIndex, vorzeichenBWerte, imagepfad) {
        var self = this;
        self.set("aktuelleAnschlussgeometrieGurt", imagepfad);
        self.setAnschlussVarianteSelectedBauteil2(anschlussIndex, vorzeichenBWerte);
        self.setAnschnittVarianteSelectedBauteil2(anschnittIndex);
        self.setInputsDisabled({ "a1": self.get('a1InputDisabled'), "a2": self.get('a2InputDisabled'), "alpha1": self.get('alpha1InputDisabled'), "alpha2": self.get('alpha2InputDisabled'), "alpha3": self.get('alpha3InputDisabled'), "b1": false, "b2": true, "beta1": true, "beta2": true, "beta3": true });
        self.resetParameterB(0, 90);
      },
      setAnschnittVarianteB2: function setAnschnittVarianteB2(anschlussIndex, anschnittIndex, vorzeichenBWerte, imagepfad) {
        var self = this;
        self.set("aktuelleAnschlussgeometrieGurt", imagepfad);
        self.setAnschlussVarianteSelectedBauteil2(anschlussIndex, vorzeichenBWerte);
        self.setAnschnittVarianteSelectedBauteil2(anschnittIndex);
        self.setInputsDisabled({ "a1": self.get('a1InputDisabled'), "a2": self.get('a2InputDisabled'), "alpha1": self.get('alpha1InputDisabled'), "alpha2": self.get('alpha2InputDisabled'), "alpha3": self.get('alpha3InputDisabled'), "b1": false, "b2": true, "beta1": true, "beta2": true, "beta3": true });
        self.resetParameterB(0, Number(self.get('phi')));
      },
      setAnschnittVarianteB3: function setAnschnittVarianteB3(anschlussIndex, anschnittIndex, vorzeichenBWerte, imagepfad) {
        var self = this;
        self.set("aktuelleAnschlussgeometrieGurt", imagepfad);
        self.setAnschlussVarianteSelectedBauteil2(anschlussIndex, vorzeichenBWerte);
        self.setAnschnittVarianteSelectedBauteil2(anschnittIndex);
        self.setInputsDisabled({ "a1": self.get('a1InputDisabled'), "a2": self.get('a2InputDisabled'), "alpha1": self.get('alpha1InputDisabled'), "alpha2": self.get('alpha2InputDisabled'), "alpha3": self.get('alpha3InputDisabled'), "b1": false, "b2": true, "beta1": false, "beta2": true, "beta3": true });
        self.resetParameterB(0, 180 - Number(self.get('phi')));
      },
      setAnschnittVarianteB4: function setAnschnittVarianteB4(anschlussIndex, anschnittIndex, vorzeichenBWerte, imagepfad) {
        var self = this;
        self.set("aktuelleAnschlussgeometrieGurt", imagepfad);
        self.setAnschlussVarianteSelectedBauteil2(anschlussIndex, vorzeichenBWerte);
        self.setAnschnittVarianteSelectedBauteil2(anschnittIndex);
        self.setInputsDisabled({ "a1": self.get('a1InputDisabled'), "a2": self.get('a2InputDisabled'), "alpha1": self.get('alpha1InputDisabled'), "alpha2": self.get('alpha2InputDisabled'), "alpha3": self.get('alpha3InputDisabled'), "b1": false, "b2": false, "beta1": false, "beta2": false, "beta3": true });
        self.resetParameterB(0, 90);
      },

      toggleOpen: function toggleOpen(parameter) {
        var self = this;
        self.toggleProperty(parameter);
      }

    }, // Ende Actions

    setAnschlussVarianteSelectedBauteil1: function setAnschlussVarianteSelectedBauteil1(index, vorzeichenAWerte) {
      var self = this;
      self.set('av' + self.get('letzteAnschlussVrianteBauteil1') + 'selected', false);
      self.set('av' + index + 'selected', true);
      self.set('letzteAnschlussVrianteBauteil1', index);

      self.set('av' + self.get('letzteAnschnittVrianteBauteil1') + 'selected', false);

      self.set('vorzeichenAWerte', vorzeichenAWerte);
      self.controllerFor('application').get('model').x3ddefault.objectAt(0).set('vorzeichenAWerte', vorzeichenAWerte);

      self.set('abschnittOben', vorzeichenAWerte === 1 ? true : false);
      self.set('abschnittUnten', vorzeichenAWerte === -1 ? true : false);
    },

    setAnschlussVarianteSelectedBauteil2: function setAnschlussVarianteSelectedBauteil2(index, vorzeichenBWerte) {
      var self = this;
      self.set('av' + self.get('letzteAnschlussVrianteBauteil2') + 'selected', false);
      self.set('av' + index + 'selected', true);
      self.set('letzteAnschlussVrianteBauteil2', index);

      self.set('av' + self.get('letzteAnschnittVrianteBauteil2') + 'selected', false);

      self.set('vorzeichenBWerte', vorzeichenBWerte);

      self.controllerFor('application').get('model').x3ddefault.objectAt(0).set('vorzeichenBWerte', vorzeichenBWerte);

      self.set('abschnittLinks', vorzeichenBWerte === -1 ? true : false);
      self.set('abschnittRechts', vorzeichenBWerte === 1 ? true : false);
    },

    setAnschnittVarianteSelectedBauteil1: function setAnschnittVarianteSelectedBauteil1(index) {
      var self = this;

      console.log('htAnschnittIndex: ' + index);
      self.controllerFor('application').get('model').x3ddefault.objectAt(0).set('htAnschnittIndex', index);

      self.set('asv' + self.get('letzteAnschnittVrianteBauteil1') + 'selected', false);
      self.set('asv' + index + 'selected', true);
      self.set('letzteAnschnittVrianteBauteil1', index);
    },

    setAnschnittVarianteSelectedBauteil2: function setAnschnittVarianteSelectedBauteil2(index) {
      var self = this;

      console.log('ntAnschnittIndex: ' + index);
      self.controllerFor('application').get('model').x3ddefault.objectAt(0).set('ntAnschnittIndex', index);

      self.set('asv' + self.get('letzteAnschnittVrianteBauteil2') + 'selected', false);
      self.set('asv' + index + 'selected', true);
      self.set('letzteAnschnittVrianteBauteil2', index);
    },

    setInputsDisabled: function setInputsDisabled(values) {
      var self = this;
      self.set('a1InputDisabled', values.a1);
      self.set('a2InputDisabled', values.a2);
      self.set('alpha1InputDisabled', values.alpha1);
      self.set('alpha2InputDisabled', values.alpha2);
      self.set('alpha3InputDisabled', values.alpha3);
      self.set('b1InputDisabled', values.b1);
      self.set('b2InputDisabled', values.b2);
      self.set('beta1InputDisabled', values.beta1);
      self.set('beta2InputDisabled', values.beta2);
      self.set('beta3InputDisabled', values.beta3);
    },

    resetParameterA: function resetParameterA(a1, alpha) {

      var self = this;

      self.set('a1', Number(a1).toFixed(1).toString());

      self.set('a2', Number(a1).toFixed(1).toString());

      alpha = alpha === 180 ? 90 : alpha;

      self.set('alpha1', alpha.toFixed(1).toString());
      self.set('alpha2', (180 - alpha).toFixed(1).toString());
    },

    resetParameterB: function resetParameterB(b1, beta) {

      var self = this;

      self.set('b1', Number(b1).toFixed(1).toString());

      self.set('b2', Number(b1).toFixed(1).toString());

      beta = beta === 180 ? 90 : beta;

      self.set('beta1', beta.toFixed(1).toString());
      self.set('beta2', (180 - beta).toFixed(1).toString());
    },

    getLaengen: function getLaengen() {
      var application = this.controllerFor('application');
      var x3d = application.get('model').x3ddefault.objectAt(0);

      var Hg = Number(x3d.get('bauteile').findBy('id', 'nebenTraeger').get('boxsizes').objectAt(0).get('y')) / 10;;
      var Hd = Number(x3d.get('bauteile').findBy('id', 'hauptTraeger1').get('boxsizes').objectAt(0).get('y')) / 10;

      var xMinusOberkante = 0;

      var phi = Number(x3d.get('phi'));

      var b1 = Number(x3d.get('b1')) / 10;
      var b2 = Number(x3d.get('b2')) / 10;

      var tanPhi = Number(x3d.get('phi')) === 90 ? 1 : Number(x3d.get('phi')) === 0 ? 1 : Math.tan(phi * Math.PI / 180);
      var sinPhi = Number(x3d.get('phi')) === 0 ? 1 : Math.sin(phi * Math.PI / 180);

      var l1 = Hd / 2 / tanPhi + Hg / 2 / sinPhi;
      var l2 = Number(x3d.get('phi')) === 90 ? 0 : Number(x3d.get('phi')) === 0 ? 0 : Hd / tanPhi;

      return { "l1": l1, "l2": l2 };
    },

    setAlpha2Grenzwert: function setAlpha2Grenzwert() {

      console.log(' ');
      console.log('++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++');

      var self = this;
      var application = this.controllerFor('application');
      var x3d = application.get('model').x3ddefault.objectAt(0);
      var bauteile = x3d.get('bauteile');
      var item = x3d.get('bauteile').findBy('id', name);

      var Hd = Number(x3d.get('bauteile').findBy('id', 'hauptTraeger1').get('boxsizes').objectAt(0).get('y')) / 10;
      var Hg = Number(x3d.get('bauteile').findBy('id', 'nebenTraeger').get('boxsizes').objectAt(0).get('y')) / 10;

      var alpha1 = Number(self.get('alpha1'));

      var alpha2 = Number(self.get('alpha2'));
      var alpha3 = Number(self.get('alpha3'));

      var phi = Number(self.get('phi'));

      var a1 = Number(self.get('a1')) / 10;
      var a2 = Number(self.get('a2')) / 10;

      var tanPhi = phi === 90 ? 1 : phi === 0 ? 1 : Math.tan(phi * Math.PI / 180);
      var sinPhi = phi === 0 ? 1 : Math.sin(phi * Math.PI / 180);

      var l1 = self.getLaengen().l1;
      var l2 = self.getLaengen().l2;

      var loberkante = l1 + a2 / sinPhi;
      var lunterkante = l1 - l2 + Math.abs(a1) / sinPhi;

      var dlk = lunterkante - loberkante;

      console.log("Hd: " + Hd);
      console.log("lunterkante - loberkante: " + lunterkante + " - " + loberkante + " = " + (lunterkante - loberkante));

      var alpha2min = 0;
      var alpha2max = 180;

      // console.log('alpha1: '+alpha1);

      if (dlk < 0 && alpha1 < 90) {

        // console.log('dlk < 0 && alpha1 < 90');
        alpha2max = Math.atan(Hd / Math.abs(dlk)) * 180 / Math.PI;
      } else if (dlk < 0 && alpha1 > 90) {
        console.log('dlk < 0 && alpha1 > 90');

        if (alpha1 > 90 + Math.atan(Math.abs(dlk) / Hd) * 180 / Math.PI) {
          console.log('alpha2 > ' + Math.atan(Hd / Math.abs(dlk)) * 180 / Math.PI);
          alpha2min = Math.atan(Hd / Math.abs(dlk)) * 180 / Math.PI;
          alpha2max = 180;
        } else if (alpha1 < 90 + Math.atan(Math.abs(dlk) / Hd) * 180 / Math.PI) {
          console.log('alpha2 < ' + Math.atan(Hd / Math.abs(dlk)) * 180 / Math.PI);
          alpha2min = 0;
          alpha2max = Math.atan(Hd / Math.abs(dlk)) * 180 / Math.PI;
        } else {
          console.log('alpha2 < 90');
          alpha2min = Math.atan(Hd / Math.abs(dlk)) * 180 / Math.PI;
          alpha2max = Math.atan(Hd / Math.abs(dlk)) * 180 / Math.PI;
        }
      } else if (dlk > 0 && alpha1 < 90) {
        // console.log('dlk > 0 && alpha1 < 90');

        alpha2min = 0;
        alpha2max = 90 + Math.atan(Math.abs(dlk) / Hd) * 180 / Math.PI;
      } else if (dlk > 0 && alpha1 >= 90) {
        // console.log('dlk > 0 && alpha1 > 90');

        alpha2min = 90 + Math.atan(Math.abs(dlk) / Hd) * 180 / Math.PI;
        alpha2max = 180;
      }

      // alpha2max = alpha2max*180/Math.PI;

      self.set('validations.alpha2.numericality.greaterThanOrEqualTo', alpha2min);
      self.set('validations.alpha2.numericality.lessThanOrEqualTo', alpha2max);
      self.set('ttalpha2', this.get('i18n').t('wertebereich') + ' ' + parseFloat(self.validations.alpha2.numericality.greaterThanOrEqualTo).toFixed(this.laengenNachkomma) + " - " + parseFloat(self.validations.alpha2.numericality.lessThanOrEqualTo).toFixed(this.laengenNachkomma) + " [°]");

      // console.log('da2: '+da2);
      console.log('alpha2min: ' + alpha2min);
      console.log('alpha2max: ' + alpha2max);
      // console.log('a2Max: '+a2Max);
      console.log('++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++');
      console.log(' ');
    },

    berechneGrenzwerteGurt: function berechneGrenzwerteGurt() {},

    setGrenzwerte: function setGrenzwerte(parameter, minimum, maximum, einheit) {
      var self = this;

      self.set("validations." + parameter + ".numericality.greaterThanOrEqualTo", minimum);

      self.set("validations." + parameter + ".numericality.lessThanOrEqualTo", maximum);

      self.set("tt" + parameter, self.get('i18n').t('wertebereich') + minimum + " - " + maxExzDiag + " [" + einheit + "]");
    },

    // Display properties Eingabefelder

    display_InputsBauteil1: (function () {
      return !this.get('av51selected');
    }).property('av51selected'),

    display_InputsBauteil2: (function () {
      return !this.get('av52selected');
    }).property('av52selected'),

    //hilfeTexte observes

    observes_winkelHilfetext: (function () {
      var self = this;
      var hilfetextArray = self.controllerFor('supercontroller').getHelpText("Anschlussgeometrie", "winkel", "0");
      self.controllerFor("supercontroller").setHilfetexte("anschlussgeometrie", hilfetextArray);
    }).observes('winkel', 'initTrigger'),

    observes_phiHilfetext: (function () {
      var self = this;
      var hilfetextArray = self.controllerFor('supercontroller').getHelpText("Anschlussgeometrie", "phi", "0");
      self.controllerFor("supercontroller").setHilfetexte("anschlussgeometrie", hilfetextArray);
    }).observes('phi', 'initTrigger'),

    observes_a1Hilfetext: (function () {
      var self = this;
      var hilfetextArray = self.controllerFor('supercontroller').getHelpText("Anschlussgeometrie", "a1", "0");
      self.controllerFor("supercontroller").setHilfetexte("anschlussgeometrie", hilfetextArray);
    }).observes('a1', 'initTrigger'),

    observes_a2Hilfetext: (function () {
      var self = this;
      var hilfetextArray = self.controllerFor('supercontroller').getHelpText("Anschlussgeometrie", "a2", "0");
      self.controllerFor("supercontroller").setHilfetexte("anschlussgeometrie", hilfetextArray);
    }).observes('a2', 'initTrigger'),

    observes_alpha1Hilfetext: (function () {
      var self = this;
      var hilfetextArray = self.controllerFor('supercontroller').getHelpText("Anschlussgeometrie", "alpha1", "0");
      self.controllerFor("supercontroller").setHilfetexte("anschlussgeometrie", hilfetextArray);
    }).observes('alpha1', 'initTrigger'),

    observes_alpha2Hilfetext: (function () {
      var self = this;
      var hilfetextArray = self.controllerFor('supercontroller').getHelpText("Anschlussgeometrie", "alpha2", "0");
      self.controllerFor("supercontroller").setHilfetexte("anschlussgeometrie", hilfetextArray);
    }).observes('alpha2', 'initTrigger'),

    observes_alpha3Hilfetext: (function () {
      var self = this;
      var hilfetextArray = self.controllerFor('supercontroller').getHelpText("Anschlussgeometrie", "alpha3", "0");
      self.controllerFor("supercontroller").setHilfetexte("anschlussgeometrie", hilfetextArray);
    }).observes('alpha3', 'initTrigger'),

    observes_b1Hilfetext: (function () {
      var self = this;
      var hilfetextArray = self.controllerFor('supercontroller').getHelpText("Anschlussgeometrie", "b1", "0");
      self.controllerFor("supercontroller").setHilfetexte("anschlussgeometrie", hilfetextArray);
    }).observes('b1', 'initTrigger'),

    observes_b2Hilfetext: (function () {
      var self = this;
      var hilfetextArray = self.controllerFor('supercontroller').getHelpText("Anschlussgeometrie", "b2", "0");
      self.controllerFor("supercontroller").setHilfetexte("anschlussgeometrie", hilfetextArray);
    }).observes('b2', 'initTrigger'),

    observes_beta1Hilfetext: (function () {
      var self = this;
      var hilfetextArray = self.controllerFor('supercontroller').getHelpText("Anschlussgeometrie", "beta1", "0");
      self.controllerFor("supercontroller").setHilfetexte("anschlussgeometrie", hilfetextArray);
    }).observes('beta1', 'initTrigger'),

    observes_beta2Hilfetext: (function () {
      var self = this;
      var hilfetextArray = self.controllerFor('supercontroller').getHelpText("Anschlussgeometrie", "beta2", "0");
      self.controllerFor("supercontroller").setHilfetexte("anschlussgeometrie", hilfetextArray);
    }).observes('beta2', 'initTrigger'),

    observes_beta3Hilfetext: (function () {
      var self = this;
      var hilfetextArray = self.controllerFor('supercontroller').getHelpText("Anschlussgeometrie", "beta3", "0");
      self.controllerFor("supercontroller").setHilfetexte("anschlussgeometrie", hilfetextArray);
    }).observes('beta3', 'initTrigger'),

    observes_winkelEndAbschnittGRADHilfetext: (function () {
      var self = this;
      var hilfetextArray = self.controllerFor('supercontroller').getHelpText("Anschlussgeometrie", "winkelEndAbschnittGRAD", "0");
      self.controllerFor("supercontroller").setHilfetexte("anschlussgeometrie", hilfetextArray);
    }).observes('winkelEndAbschnittGRAD', 'initTrigger'),

    observes_winkelEndAbschnittDiagonaleGRADHilfetext: (function () {
      var self = this;
      var hilfetextArray = self.controllerFor('supercontroller').getHelpText("Anschlussgeometrie", "winkelEndAbschnittDiagonaleGRAD", "0");
      self.controllerFor("supercontroller").setHilfetexte("anschlussgeometrie", hilfetextArray);
    }).observes('winkelEndAbschnittDiagonaleGRAD', 'initTrigger'),

    observes_ueberstandDiagonaleVertikalHilfetext: (function () {
      var self = this;
      var hilfetextArray = self.controllerFor('supercontroller').getHelpText("Anschlussgeometrie", "ueberstandDiagonaleVertikal", "0");
      self.controllerFor("supercontroller").setHilfetexte("anschlussgeometrie", hilfetextArray);
    }).observes('ueberstandDiagonaleVertikal', 'initTrigger'),

    observes_ausmittigerstabanschlussHilfetext: (function () {
      var self = this;
      var hilfetextArray = self.controllerFor('supercontroller').getHelpText("Anschlussgeometrie", "ausmittigerstabanschluss", "0");
      self.controllerFor("supercontroller").setHilfetexte("anschlussgeometrie", hilfetextArray);
    }).observes('ausmittigerstabanschluss', 'initTrigger'),

    observes_winkelAbschnittDiagonaleGRADHilfetext: (function () {
      var self = this;
      var hilfetextArray = self.controllerFor('supercontroller').getHelpText("Anschlussgeometrie", "winkelAbschnittDiagonaleGRAD", "0");
      self.controllerFor("supercontroller").setHilfetexte("anschlussgeometrie", hilfetextArray);
    }).observes('winkelAbschnittDiagonaleGRAD', 'initTrigger'),

    observes_exzentrizitaetDiagonaleHilfetext: (function () {
      var self = this;
      var hilfetextArray = self.controllerFor('supercontroller').getHelpText("Anschlussgeometrie", "exzentrizitaetDiagonale", "0");
      self.controllerFor("supercontroller").setHilfetexte("anschlussgeometrie", hilfetextArray);
    }).observes('exzentrizitaetDiagonale', 'initTrigger'),

    // hilfeTexte display properties

    display_winkelHilfetext: (function () {
      var self = this;
      return self.controllerFor("supercontroller").setHilfetexteDisplayed(self.get('winkelHilfetext'));
    }).property('winkelHilfetext'),

    display_winkelEndAbschnittGRADHilfetext: (function () {
      var self = this;
      return self.controllerFor("supercontroller").setHilfetexteDisplayed(self.get('winkelEndAbschnittGRADHilfetext'));
    }).property('winkelEndAbschnittGRADHilfetext'),

    display_winkelEndAbschnittDiagonaleGRADHilfetext: (function () {
      var self = this;
      return self.controllerFor("supercontroller").setHilfetexteDisplayed(self.get('winkelEndAbschnittDiagonaleGRADHilfetext'));
    }).property('winkelEndAbschnittDiagonaleGRADHilfetext'),

    display_ueberstandDiagonaleVertikalHilfetext: (function () {
      var self = this;
      return self.controllerFor("supercontroller").setHilfetexteDisplayed(self.get('ueberstandDiagonaleVertikalHilfetext'));
    }).property('ueberstandDiagonaleVertikalHilfetext'),

    display_ausmittigerstabanschlussHilfetext: (function () {
      var self = this;
      return self.controllerFor("supercontroller").setHilfetexteDisplayed(self.get('ausmittigerstabanschlussHilfetext'));
    }).property('ausmittigerstabanschlussHilfetext'),

    display_winkelAbschnittDiagonaleGRADHilfetext: (function () {
      var self = this;
      return self.controllerFor("supercontroller").setHilfetexteDisplayed(self.get('winkelAbschnittDiagonaleGRADHilfetext'));
    }).property('winkelAbschnittDiagonaleGRADHilfetext'),

    display_exzentrizitaetDiagonaleHilfetext: (function () {
      var self = this;
      return self.controllerFor("supercontroller").setHilfetexteDisplayed(self.get('exzentrizitaetDiagonaleHilfetext'));
    }).property('exzentrizitaetDiagonaleHilfetext')

  });

});