define('m03/components/viewpoin-t', ['exports', 'ember'], function (exports, Ember) {

  'use strict';

  var Viewpoint = Ember['default'].Component.extend({
    tagName: 'OrthoViewpoint',
    attributeBindings: ['orientation', 'position', 'fieldofview', 'centerofrotation', 'description', 'set_bind', 'jump', 'retainUserOffsets', 'zFar', 'zNear'],
    centerofrotation: "0 0 0",
    description: 'camera',
    jump: true,
    retainUserOffsets: false,

    zFar: '10000',
    zNear: '0.1',

    fieldofview: (function () {
      var values = '-5, -5, 5, 5';
      return values;
    }).property(''),

    orientation: (function () {

      var name = this.get('name');
      var x3d = this.get('model');
      var bauteile = x3d.objectAt(0).get('bauteile');
      var values;

      bauteile.forEach(function (item, index) {
        if (item.id === name) {
          values = bauteile.objectAt(index).get('orientations').objectAt(0).get('x') + " " + bauteile.objectAt(index).get('orientations').objectAt(0).get('y') + " " + bauteile.objectAt(index).get('orientations').objectAt(0).get('z') + " " + bauteile.objectAt(index).get('orientations').objectAt(0).get('winkel');
        }
      });
      return values;
    }).property('model.firstObject.viewpointHelper'),

    position: (function () {
      var name = this.get('name');
      var x3d = this.get('model');
      var bauteile = x3d.objectAt(0).get('bauteile');
      var values;
      bauteile.forEach(function (item) {
        if (item.id === name) {

          var x = item.get('positions').objectAt(0).get('x');
          var y = item.get('positions').objectAt(0).get('y');
          var z = item.get('positions').objectAt(0).get('z');

          switch (name) {
            case 'viewpointDefault':

              break;
            case 'viewpointLinks':

              break;
            case 'viewpointRechts':

              break;
            case 'viewpointOben':

              break;
            case 'viewpointFront':

              break;
          }

          values = x + " " + y + " " + z;
        }
      });

      return values;
    }).property('model.x3ddefault.firstObject.viewpointHelper'),

    //set_bind wird in bower_components/viewpointmanagement/vpmanagement.js gesetzt
    set_bind: (function () {
      var name = this.get('name');
      var x3d = this.get('model');
      var bauteile = x3d.objectAt(0).get('bauteile');
      var value = '';

      bauteile.forEach(function (item) {
        if (item.id === name) {
          value = item.get('cameraProperties').objectAt(0).get('setBind');
        }
      });

      return value;
    }).property('model.firstObject.viewpointHelper')

  });

  exports['default'] = Viewpoint;

});