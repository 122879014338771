define('m03/locales_ITW/se/translations', ['exports'], function (exports) {

  'use strict';

  exports['default'] = {

    berechnung_pdf: 'Beräkningen som PDF',
    setztiefe: 'Sättningsdjup',
    vorgebohrt: 'Hål förborrade',
    bauteile: 'Komponenter',
    hausnummer: 'Husnummer',
    gew_schraube: 'Vald',
    SH: 'Stål-Trä',
    email: 'e-post',
    scherkraft: 'Skjuvkraft',
    nicht_vorgebohrt: 'Hål ej förborrade',
    scherfl_vbm: 'Skjuvplan per fästelement',
    beschreibung: 'Beskrivning',
    festigkeit: 'Hållfasthetsklass',
    scherfl2: '2 skjuvplan',
    scherfl1: '1 skjuvplan',
    modul_alt: 'M03',
    mat: 'Material 1',
    staendig: 'permanent',
    lasteinwirkung2: 'Läser in',
    tt_text: 'Exportera i PDF format',
    ergebnis: 'Resultat',
    eingabeerforderlich: 'Inmatning krävs !',
    plz: 'Postnummer',
    kompakterg: 'Kompakt resultat',
    eingabe_ok: 'Korrekt inmatning',
    winkel: 'Vinkel mellan komponenter',
    sehr_kurz: 'momentan',
    msg: 'Visa ogiltiga fästelement',
    bezeichnung: 'Namn',
    lang: 'lång tid',
    tel: 'Telefon',
    schnittig2: 'Dubbel-skjuvning',
    ort: 'Ort',
    schnittig1: 'Enkel-skjuvning',
    land: 'Land',
    projektdaten: 'Projektdata',
    fax: 'Fax',
    kdnum: 'Kundnummer',
    verbindungsmittel: 'Fästelement',
    geometrie: 'Geometri',
    def_bereich: 'Inmatningsvärden',
    schichtfolge: 'Lagerföljd',
    wklscherkraft: 'Skjuvkraftens vinkel',
    artnr: 'Artikelnummer',
    name: 'Namn',
    nkl: 'Klimatklass',
    adresse: 'Gata, husnummer',
    mittel: 'medellång tid',
    lasteinwirkung: 'Dimensionerande lastvärde',
    modul: 'M03',
    led: 'Lastvaraktighet',
    scherfl_anschl: 'Skjuvplan per förband',
    verarbeiter: 'Användare/kund',
    vbms: 'Fästelement',
    nkl3: 'Klimatklass 3 OBS! Ta även hänsyn till nationella val enligt Boverkets EKS',
    hoehe: 'Höjd',
    mat1: 'Material 2',
    nkl2: 'Klimatklass 2 OBS! Ta även hänsyn till nationella val enligt Boverkets EKS',
    nkl1: 'Klimatklass 1 OBS! Ta även hänsyn till nationella val enligt Boverkets EKS',
    position: 'Position',
    axialkraft: 'Axialkraft',
    strasse: 'Gata',
    antrieb: 'Bits',
    bauvorhaben: 'Byggprojekt',
    vbm: 'Fästelement',
    hinweisLaubholz: 'Fästelement måste förspolas i hårt trä !',
    auftraggeber: 'Kund',
    dicke: 'Tjocklek',
    raxtd: 'Dimensionerande draghållfasthet',
    HH: 'Trä/panel - Trä',
    rlatd: 'Dimensionerande skjuvbärförmåga',
    kurz: 'kort tid',

    nachBlass: 'Mellanlager enligt BLASS',
    nachWerner: 'Förstärkning enligt WERNER',
    winkelKlammerruecken: 'Vinkel mellan klammerrygg och fiberriktning',
    zwischenschichtVerstaerkung: 'Mellanlager/Förstärkning',
    zwischenschicht: 'Mellanlager',
    verstaerkung: 'Förstärkning',
    umwelteinfluesse: 'Miljö, behandling',
    erhoehtesKorrosionsrisiko: 'högre risk för korrosion',

    anschlussgeometrie: "#no value#",
    anschlussvarianten: "#no value#",
    winkelEndabschnittDiagonale: "#no value#",
    ueberstandDiagonaleVertikal: "#no value#",
    ausmittigerStabanschluss: "#no value#",
    winkelabschnittDiagonale: "#no value#",
    exzentrizitaetDiagonale: "#no value#",
    abschnittSenkrechtDiagonalenachse: "#no value#",
    abschnittParallelGurtachse: "#no value#",
    freieEingabe: "Fri input",

    bemessungssoftware: 'ITW-beräkningsprogram',
    wertebereich: "Tillåten värdesintervall:",
    ergebnisanzeigen: "Visa resultat:",
    einstellung: "Inställningar",
    spracheinstellungen: "Språkinställning",
    eingabe: "Inmatning",
    ausgabe: "Utmatning",
    landeseinstellungen: "Landsinställningar",
    bemessungsvorschrift: "Beräkningsregler",
    liefersortiment: "Leveranssortiment",
    produktkatalog: "Produktkatalog",
    produktinfo: "Produktinformation",
    eta: "ETA",
    cad: "CAD",
    impressum: "Imprint",
    extras: "Verktyg",
    bemerkungen: "Kommentarer",
    bemerkungstext: "Kommentar text",
    eingabedaten: "Indata",
    berechnungalspdf: "Beräkningen som PDF",
    berechnungspeichern: "Spara beräkningen",
    berechnungoeffnen: "Öppna beräkningen",
    downloadinstructions1: "Öppna filen i ett nytt fönster: vänsterklicka på ikonerna.",
    downloadinstructions2: "Högerklicka på ikonen -> « Spara som » / « Spara länk som » för att spara filen på hårddisken lokalt.",
    downloadinstructions3: "Ange ett filnamn under vilket du vill hämta de valda filerna som ett nedladdningsbart paket.",
    eingabedatennichtgespeichert: "Indata har inte sparats.",
    pdfnochnichterstellt: "Ingen PDF har genererats ännu.",
    dlfilename: "Filnamn",
    dlpaketErstellen: "Skapa nedladdningsbart paket",
    nochkeineschraubegewaehlt: "Ingen fästelement har valts ännu.",
    nochkeindlerzeugt: "Inget nedladdningsbart paket har skapats.",
    neueberechnung: "Ny beräkning",
    kontakt: "Kontaktperson",
    herausgeber: "Utgivare",
    registergericht: "Register domstol",
    geschaeftsfuehrer: "VD",
    entwickeltvon: "Utvecklats av",

    dansk: "Danska",
    deutsch: "Tyska",
    englisch: "Engelska",
    franzoesisch: "Franska",
    italienisch: "Italiensk",
    nederlands: "Dutch",
    polnisch: "Polska",
    espanol: "Spanska",
    suomi: "Finska",
    schwedisch: "Svenska",

    schweiz: "Schweiz",
    daenemark: "Danmark",
    deutschland: "Tyskland",
    oesterreich: "Österrike",
    england: "England",
    frankreich: "Frankrike",
    italien: "Italien",
    niederlande: "Nederländerna",
    polen: "Polen",
    spanien: "Spanien",
    chile: "Chile",
    finnland: "Finland",
    schweden: "Sverige",

    MatGrp0000: "Massivt trä, barrträ",
    MatGrp0001: "Limmat laminerat trä, homogent",
    MatGrp0002: "Fanerat trä (LVL)",
    MatGrp0003: "Limträbalkar (två eller tre skikt)",
    MatGrp0004: "Plattor av massivträ i flera lager",
    MatGrp0005: "xPlywood",
    MatGrp0006: "Fanerat trä (alla fibrer parallella)",
    MatGrp0007: "Spånskivor",
    MatGrp0008: "Cementbundna träspånskivor",
    MatGrp0009: "Träfiberplattor HB.HLA2, hårda",
    MatGrp0010: "Träfiberplattor MBH.LA2, medium",
    MatGrp0012: "Massivt trä, lövträ",
    MatGrp0013: "Limmat laminerat trä, kombinerat",
    MatGrp0015: "Korslaminerat trä",
    MatGrp0020: "OSB-skivor",
    MatGrp0021: "Plywood",
    MatGrp0022: "Gipsskivor (DIN 18180)",
    MatGrp0030: "Pollmeier LVL Beech",
    MatGrp1000: "Stål",
    MatGrp9100: "Betong",
    MatGrp9999: "Luft",

    Mat1000: "Stål S235JR",
    Mat1001: "Stål S355JR",
    Mat9999: "----",
    Mat000: "C14",
    Mat001: "C16",
    Mat002: "C18",
    Mat003: "C20",
    Mat004: "C22",
    Mat005: "C24",
    Mat006: "C27",
    Mat007: "C30",
    Mat008: "C35",
    Mat009: "C40",
    Mat010: "C45",
    Mat011: "C50",
    Mat050: "D30",
    Mat051: "D35",
    Mat052: "D40",
    Mat053: "D50",
    Mat054: "D60",
    Mat055: "D70",
    Mat020: "GL24h",
    Mat030: "GL24c",
    Mat021: "GL28h",
    Mat031: "GL28c",
    Mat022: "GL32h",
    Mat032: "GL32c",
    Mat023: "GL36h",
    Mat033: "GL36c",
    Mat160: "Konsthartsomr. Spånpl kl. P4 (EN 312)",
    Mat170: "Konsthartsomr. Spånpl kl. P5 (EN 312)",
    Mat180: "Konsthartsomr. Spånpl kl. P6 (EN 312)",
    Mat190: "Konsthartsomr. Spånpl kl. P7 (EN 312)",
    Mat200: "Cementbund. Träspånpl. Kl. 1+2 (EN 634)",
    Mat220: "Träfiberplattor HB.HLA2, hårda (EN 622-2)",
    Mat230: "Träfiberplattor MBH.LA2, medium (EN 622-3)",
    Mat125: "OSB/2 (EN 300)",
    Mat126: "OSB/3 (EN 300)",
    Mat127: "OSB/4 (EN 300)",
    Mat110: "Plywood F25/10",
    Mat111: "Plywood F40/40 E60/40",
    Mat112: "Plywood F50/25 E70/25",
    Mat113: "Plywood F60/10 E90/10",
    Mat520: "Gipsskivor (DIN 18180)",
    Mat9120: "C20/25",
    Mat9125: "C25/30",
    Mat9130: "C30/37",
    Mat9135: "C35/45",
    Mat9140: "C40/50",
    Mat9145: "C45/55",
    Mat024: "GL30h",
    Mat034: "GL30c",
    Mat301: "GL70",

    errors: {
      inclusion: "is not included in the list",
      exclusion: "is reserved",
      invalid: "Felaktig indata.",
      confirmation: "doesn\'t match {{attribute}}",
      accepted: "must be accepted",
      empty: "can\'t be empty",
      blank: "can\'t be blank",
      present: "must be blank",
      tooLong: "Inmatning för lång. Maximalt tillåten längd {{count}} tecken.",
      tooShort: "!2",
      wrongLength: "is the wrong length (should be {{count}} characters)",
      notANumber: "is not a number",
      notAnInteger: "must be an integer",
      greaterThan: "must be greater than {{count}}",
      greaterThanOrEqualTo: "must be greater than or equal to {{count}}",
      equalTo: "must be equal to {{count}}",
      lessThan: "must be less than {{count}}",
      lessThanOrEqualTo: "must be less than or equal to {{count}}",
      otherThan: "must be other than {{count}}",
      odd: "must be odd",
      even: "must be even"
    },
    'language-select.language.de': "Tyska",
    'language-select.language.en': "English"

  };

});