define('m03/models/screw-property', ['exports', 'ember-data'], function (exports, DS) {

  'use strict';

  exports['default'] = DS['default'].Model.extend({
    headformID: DS['default'].attr('number'),
    headdiameter: DS['default'].attr('number'),
    headheight: DS['default'].attr('number'),
    length: DS['default'].attr('number'),
    diameter: DS['default'].attr('number'),
    threadlengthtip: DS['default'].attr('number'),
    threadlengthhead: DS['default'].attr('number'),
    setztiefe: DS['default'].attr('number')
  });

});