define('m03/templates/con-e', ['exports'], function (exports) {

  'use strict';

  exports['default'] = Ember.HTMLBars.template((function() {
    return {
      isHTMLBars: true,
      revision: "Ember@1.12.0",
      blockParams: 0,
      cachedFragment: null,
      hasRendered: false,
      build: function build(dom) {
        var el0 = dom.createDocumentFragment();
        var el1 = dom.createElement("shape");
        var el2 = dom.createTextNode("\n  ");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("appearance");
        var el3 = dom.createTextNode("\n    ");
        dom.appendChild(el2, el3);
        var el3 = dom.createElement("material");
        dom.setAttribute(el3,"diffusecolor","0 0 0");
        dom.setAttribute(el3,"transparency","0");
        dom.setAttribute(el3,"ambientintensity","0.2");
        dom.setAttribute(el3,"shininess","0.2");
        dom.setAttribute(el3,"specularcolor","0,0,0");
        dom.setAttribute(el3,"class","ember-view");
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("\n  ");
        dom.appendChild(el2, el3);
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n    ");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("cone");
        dom.setAttribute(el2,"solid","true");
        dom.setAttribute(el2,"ccw","true");
        dom.setAttribute(el2,"usegeocache","true");
        dom.setAttribute(el2,"lit","true");
        dom.setAttribute(el2,"bottom","true");
        dom.setAttribute(el2,"side","true");
        dom.setAttribute(el2,"top","true");
        dom.setAttribute(el2,"subdivision","32");
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n");
        dom.appendChild(el1, el2);
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n");
        dom.appendChild(el0, el1);
        return el0;
      },
      render: function render(context, env, contextualElement) {
        var dom = env.dom;
        var hooks = env.hooks, get = hooks.get, attribute = hooks.attribute;
        dom.detectNamespace(contextualElement);
        var fragment;
        if (env.useFragmentCache && dom.canClone) {
          if (this.cachedFragment === null) {
            fragment = this.build(dom);
            if (this.hasRendered) {
              this.cachedFragment = fragment;
            } else {
              this.hasRendered = true;
            }
          }
          if (this.cachedFragment) {
            fragment = dom.cloneNode(this.cachedFragment, true);
          }
        } else {
          fragment = this.build(dom);
        }
        var element0 = dom.childAt(fragment, [0]);
        var element1 = dom.childAt(element0, [1, 1]);
        var element2 = dom.childAt(element0, [3]);
        var attrMorph0 = dom.createAttrMorph(element1, 'emissivecolor');
        var attrMorph1 = dom.createAttrMorph(element2, 'id');
        var attrMorph2 = dom.createAttrMorph(element2, 'bottomradius');
        var attrMorph3 = dom.createAttrMorph(element2, 'height');
        attribute(env, attrMorph0, element1, "emissivecolor", get(env, context, "emissivecolor"));
        attribute(env, attrMorph1, element2, "id", get(env, context, "coneTagID"));
        attribute(env, attrMorph2, element2, "bottomradius", get(env, context, "coneBottomradius"));
        attribute(env, attrMorph3, element2, "height", get(env, context, "coneHeight"));
        return fragment;
      }
    };
  }()));

});