define('m03/components/masskett-e', ['exports', 'ember'], function (exports, Ember) {

  'use strict';

  var Transform = Ember['default'].Component.extend({

    tagName: 'transform',
    layoutName: 'masskett-e',
    attributeBindings: ['translation', 'rotation', 'bboxcenter', 'bboxsize', 'center', 'scale', 'scaleorientation'],
    classNames: ['testClassNameXXX'],
    bboxcenter: "0 0 0",
    bboxsize: "-1,-1,-1",
    center: "0 0 0",
    scale: "1,1,1",
    scaleorientation: "0,0,0,0",

    objektSkalierung: 1,

    ueberstand: 2,

    translation: (function () {
      var name = this.get('name');
      var typ = this.get('typ');
      var x3d = this.get('model').objectAt(0);
      var bauteile = x3d.get('bauteile');
      var item = x3d.get('bauteile').findBy('id', name);

      var ueberstand = Number(this.get('ueberstand'));

      var x = 0;
      var y = 0;
      var z = 0;

      switch (typ) {
        case 'ueberstandDiagonaleVertikal':

          var skalierungsfaktor = x3d.get('skalierungsfaktor');

          z = -(bauteile.findBy('id', name).get('boxsizes').objectAt(0).get('z') / 20);

          if (x3d.get('ergebnisGeladen') === true) {
            x = Number(bauteile.findBy('id', 'mittelbauteil').get('punkt').objectAt(1).get('x')) / 10;
            y = Number(bauteile.findBy('id', 'mittelbauteil').get('punkt').objectAt(1).get('y')) / 10 - Number(x3d.get('ueberstandDiagonaleVertikal')) / 20;
          } else {
            x = -Math.abs(x3d.get('xSeitenbauteil') / 10) - ueberstand;
            y = -(bauteile.findBy('id', 'nebenTraeger').get('boxsizes').objectAt(0).get('y') / 10 - bauteile.findBy('id', 'hauptTraeger1').get('boxsizes').objectAt(0).get('y') / 10) / 2 - Number(x3d.get('ueberstandDiagonaleVertikal')) / 20;
          }

          if (bauteile.findBy('id', 'viewpointLinks').get('cameraProperties').objectAt(0).get('setBind')) {

            if (x3d.get('zweiSchaerflaechen') === true) {
              z = z - bauteile.findBy('id', 'hauptTraeger1').get('boxsizes').objectAt(0).get('z') / 10;
              if (x3d.get('verstaerkungBlass') === true && x3d.get('verstaerkungWerner') === false) {
                z = z - x3d.get('dickeBlass') / 10;
              } else if (x3d.get('verstaerkungBlass') === true && x3d.get('verstaerkungWerner') === true) {
                z = z - x3d.get('dickeWerner') / 10 * 2;
              }
            }

            if (x3d.get('ergebnisGeladen') === true) {
              z = z - 0.5 - this.postionDurchSkalierung(1);
            }
          } else {
            if (x3d.get('ergebnisGeladen') === true) {
              x = x - 0.5 - this.postionDurchSkalierung(1);
            }
          }

          break;
        case 'exzentrizitaetDiagonale':

          z = bauteile.findBy('id', name).get('boxsizes').objectAt(0).get('z') / 20 + bauteile.findBy('id', 'hauptTraeger1').get('boxsizes').objectAt(0).get('z') / 10;

          if (x3d.get('ergebnisGeladen') === true) {
            x = Number(bauteile.findBy('id', 'seitenbauteil0').get('punkt').objectAt(1).get('x')) / 10;
            y = Number(bauteile.findBy('id', 'seitenbauteil0').get('punkt').objectAt(1).get('y')) / 10 - Number(x3d.get('ueberstandDiagonaleVertikal')) / 20;
          } else {
            x = -Number(x3d.get('exzentrizitaetDiagonale')) / 20;
            y = -(bauteile.findBy('id', 'nebenTraeger').get('boxsizes').objectAt(0).get('y') / 10 - bauteile.findBy('id', 'hauptTraeger1').get('boxsizes').objectAt(0).get('y') / 10) / 2 - Number(x3d.get('ueberstandDiagonaleVertikal')) / 10;
          }

          if (x3d.get('verstaerkungBlass') === true && x3d.get('verstaerkungWerner') === false) {
            z = z + x3d.get('dickeBlass') / 10;
          } else if (x3d.get('verstaerkungBlass') === true && x3d.get('verstaerkungWerner') === true) {
            z = z + x3d.get('dickeWerner') / 10 * 2;
          }

          break;
      }

      var cords = x + ' ' + y + ' ' + z;

      return cords;
    }).property('model.firstObject.transformHelper', 'model.firstObject.masskettenHelper', 'model.firstObject.skalierungsfaktor'),

    rotation: (function () {

      var name = this.get('name');
      var typ = this.get('typ');
      var x3d = this.get('model').objectAt(0);
      var bauteile = x3d.get('bauteile');
      var item = x3d.get('bauteile').findBy('id', name);

      var x = 0;
      var y = 0;
      var z = 0;
      var winkel = 0;

      var w90Grad = 90 * Math.PI / 180;
      var w180Grad = 180 * Math.PI / 180;
      var w240Grad = 240 * Math.PI / 180;

      switch (typ) {
        case 'ueberstandDiagonaleVertikal':

          if (bauteile.findBy('id', 'viewpointLinks').get('cameraProperties').objectAt(0).get('setBind')) {
            x = 1;
            y = 1;
            z = -1;
            winkel = w240Grad;
          } else {
            x = 0;
            y = 0;
            z = 1;
            winkel = w90Grad;
          }

          break;
        case 'exzentrizitaetDiagonale':

          if (bauteile.findBy('id', 'viewpointOben').get('cameraProperties').objectAt(0).get('setBind')) {
            x = 1;
            y = 0;
            z = 0;
            winkel = w90Grad;
          } else {
            x = 1;
            y = 0;
            z = 0;
            winkel = w180Grad;
          }

          break;
      }

      var values = x + ' ' + y + ' ' + z + ' ' + winkel;

      return values;
    }).property('model.firstObject.transformHelper'),

    koordinaten: (function () {
      var name = this.get('name');
      var typ = this.get('typ');
      var x3d = this.get('model').objectAt(0);
      var bauteile = x3d.get('bauteile');

      var hwinkel = Number(x3d.get('hWinkel')) * Math.PI / 180;
      var vwinkel = Number(x3d.get('vWinkel')) * Math.PI / 180;

      var x = 0;
      var y = 0;
      var z = 0;

      var cords;

      var p1 = new Object();
      p1.y = 0;
      p1.z = 0;
      var p2 = new Object();
      p2.y = 0;
      p2.z = 0;

      var drehwinkel = new Object();
      drehwinkel.x = 0;
      drehwinkel.y = 0;
      drehwinkel.z = 0;

      var w90Grad = 90 * Math.PI / 180;
      var w180Grad = 180 * Math.PI / 180;

      var item = x3d.get('bauteile').findBy('id', name);

      var ueberstand = Number(this.get('ueberstand'));

      switch (typ) {
        case 'ueberstandDiagonaleVertikal':

          p1.x = Number(x3d.get('ueberstandDiagonaleVertikal')) / 20;
          p2.x = -Number(x3d.get('ueberstandDiagonaleVertikal')) / 20;

          cords = this.getMassketteKoordinaten(p1, p2, drehwinkel);
          break;
        case 'exzentrizitaetDiagonale':

          p1.x = Number(x3d.get('exzentrizitaetDiagonale')) / 20;
          p2.x = -Number(x3d.get('exzentrizitaetDiagonale')) / 20;

          cords = this.getMassketteKoordinaten(p1, p2, drehwinkel);
          break;
      }

      return cords;
    }).property('model.firstObject.transformHelper', 'model.firstObject.masskettenHelper', 'model.firstObject.vWinkel', 'model.firstObject.hWinkel', 'model.firstObject.istHt', 'model.firstObject.skalierungsfaktor', 'model.firstObject.schraubenGekreuzt', 'model.firstObject.viewpointHelper', 'model.firstObject.arc2dPositionTriggern'),

    koordinatenIndex: (function () {
      var name = this.get('name');
      if (name === 'queranschluss2') {
        name = 'queranschluss1';
      }

      var typ = this.get('typ');
      var x3d = this.get('model').objectAt(0);
      var bauteile = x3d.get('bauteile');
      var values = "1 2 -1 0 3 -1 4 5 -1 6 7 -1 8 9 -1";

      var item = x3d.get('bauteile').findBy('id', name);

      if (item.get('typ') === 'box' || item.get('typ') === 'platte') {

        if (typ === 'kontur') {
          if (x3d.get('ausmittigerstabanschluss') === true && name.substring(0, 12) === 'hauptTraeger') {
            values = "0 1 2 3 0 -1 4 5 6 7 4 -1 0 8 4 -1 1 9 5 -1 2 6 -1 3 7 -1 8 9";
          } else if (x3d.get('ausmittigerstabanschluss') === true && name.substring(0, 13) === 'seitenbauteil') {
            values = "0 1 2 3 8 0 -1 4 5 6 7 9 4 -1 8 9 -1 0 4 -1 1 5 -1 2 6 -1 3 7 -1";
          } else {
            values = "0 1 2 3 0 -1 4 5 6 7 4 -1 0 4 -1 1 5 -1 2 6 -1 3 7";
          }
        }

        if (typ === 'faserrichtung') {
          values = "0 1 -1 1 2 -1 2 3 -1";
        }
      } else {
        if (typ === 'lastenPfeil') {
          if (x3d.get('zweiSchaerflaechen')) {
            values = "0 1 -1 2 3 -1 4 5 -1";
          } else {
            values = "0 1 -1 2 3 -1";
          }
        }

        if (name.substring(0, 8) === 'schraube' && x3d.get('ergebnisGeladen')) {
          if (typ === 'xPos' && bauteile.findBy('id', 'viewpointLinks').get('cameraProperties').objectAt(0).get('setBind')) {
            values = "0 0";
          } else if (typ === 'zPos' && bauteile.findBy('id', 'viewpointFront').get('cameraProperties').objectAt(0).get('setBind')) {
            values = "0 0";
          } else {
            values = "1 2 -1 0 3 -1 4 5 -1 6 7 -1 8 9 -1";
          }
        }
      }

      if (this.get('typ') === 'vWinkel' && name === 'hauptTraeger1' || typ === 'vWinkelScherkraft' && name === 'hauptTraeger1') {
        values = "0 1 -1 2 3 -1";
      }

      return values;
    }).property('model.firstObject.transformHelper', 'model.firstObject.masskettenHelper', 'model.firstObject.istHt'),

    istLastenpfeil: (function () {
      var istLastenpfeil = false;

      if (this.get('typ') === 'lastenPfeil') {
        istLastenpfeil = true;
      }
      return istLastenpfeil;
    }).property(''),

    zweiSchaerflaechen: (function () {
      var value = false;
      var x3d = this.get('model').objectAt(0);

      if (this.get('name') === 'scherkraftPfeil' && x3d.get('zweiSchaerflaechen')) {
        value = true;
      }
      return value;
    }).property('model.firstObject.zweiSchaerflaechen'),

    emissivecolor: (function () {
      var name = this.get('name');
      if (name === 'queranschluss2') {
        name = 'queranschluss1';
      }

      var typ = this.get('typ');
      var x3d = this.get('model').objectAt(0);

      var emsvcolor = '0 0 0';

      if (x3d.get('ntbreiteAktiv') === true && name === 'nebenTraeger' && typ === 'breite') {
        emsvcolor = '1 0 0';
      }

      if (x3d.get('nthoeheAktiv') === true && name === 'nebenTraeger' && typ === 'hoehe') {
        emsvcolor = '1 0 0';
      }

      if (x3d.get('htbreiteAktiv') === true && name.substring(0, 12) === 'hauptTraeger' && typ === 'breite') {
        emsvcolor = '1 0 0';
      }

      if (x3d.get('hthoeheAktiv') === true && name.substring(0, 12) === 'hauptTraeger' && typ === 'hoehe') {
        emsvcolor = '1 0 0';
      }

      if (x3d.get('setztiefeAktiv') === true && name === 'hauptTraeger1' && typ === 'setztiefe') {
        emsvcolor = '1 0 0';
      }

      if (x3d.get('vWinkelScherkraftAktiv') === true && name === 'hauptTraeger1' && typ === 'vWinkelScherkraft') {
        emsvcolor = '1 0 0';
      }

      if (x3d.get('vWinkelAktiv') === true && name === 'hauptTraeger1' && typ === 'vWinkel') {
        emsvcolor = '1 0 0';
      }

      if (x3d.get('axialkraftAktiv') === true && name === 'axialkraftPfeil' && typ === 'lastenPfeil') {
        emsvcolor = '1 0 0';
      }

      if (x3d.get('scherkraftAktiv') === true && name === 'scherkraftPfeil' && typ === 'lastenPfeil') {
        emsvcolor = '1 0 0';
      }

      if ((x3d.get('dickeBlassAktiv') === true || x3d.get('dickeWernerAktiv') === true) && name.substring(0, 12) === 'verstaerkung' && typ === 'breite') {
        emsvcolor = '1 0 0';
      }

      if (x3d.get('ueberstandDiagonaleVertikalAktiv') === true && name === 'nebenTraeger' && typ === 'ueberstandDiagonaleVertikal') {
        emsvcolor = '1 0 0';
      }

      if (x3d.get('exzentrizitaetDiagonaleAktiv') === true && name === 'nebenTraeger' && typ === 'exzentrizitaetDiagonale') {
        emsvcolor = '1 0 0';
      }

      return emsvcolor;
    }).property('model.firstObject.exzentrizitaetDiagonaleAktiv', 'model.firstObject.ueberstandDiagonaleVertikalAktiv', 'model.firstObject.htbreiteAktiv', 'model.firstObject.hthoeheAktiv', 'model.firstObject.ntbreiteAktiv', 'model.firstObject.nthoeheAktiv', 'model.firstObject.axialkraftAktiv', 'model.firstObject.scherkraftAktiv', 'model.firstObject.vWinkelAktiv', 'model.firstObject.vWinkelScherkraftAktiv', 'model.firstObject.setztiefeAktiv', 'model.firstObject.dickeWernerAktiv', 'model.firstObject.dickeBlassAktiv'),

    getMassketteKoordinaten: function getMassketteKoordinaten(punkt1, punkt2, drehwinkel) {
      var typ = this.get('typ');

      // var skalierungsfaktor = this.get('model').objectAt(0).get('skalierungsfaktor')+1;
      var skalierungsfaktor = 1;

      var b05 = 0.05 * skalierungsfaktor;
      var b1 = 0.1 * skalierungsfaktor;
      var b5 = 0.5 * skalierungsfaktor;
      var b45 = b5 - b05;
      var b55 = b5 + b05;
      var b6 = 0.6 * skalierungsfaktor;

      var hwinkel = Number(this.get('model').objectAt(0).get('hWinkel')) * Math.PI / 180;
      var vwinkel = Number(this.get('model').objectAt(0).get('vWinkel')) * Math.PI / 180;

      var dp = new Object();
      var deltaX = 0;
      var deltaY = 0;

      var p0 = Number(punkt1.x) + ' ' + punkt1.y + ' ' + punkt1.z;
      var p1 = Number(punkt2.x) + ' ' + (Number(punkt2.y) + Number(deltaY)) + ' ' + punkt2.z;

      dp = this.drehungXAchse({ x: 0, y: b6, z: 0 }, drehwinkel.x);
      dp = this.drehungYAchse(dp, drehwinkel.y);
      dp = this.drehungZAchse(dp, drehwinkel.z);
      var p2 = punkt2.x + dp.x + deltaX + ' ' + (punkt2.y + dp.y) + ' ' + (punkt2.z + dp.z);
      var p3 = punkt1.x + dp.x + deltaX + ' ' + (punkt1.y + dp.y) + ' ' + (punkt1.z + dp.z);

      dp = this.drehungXAchse({ x: b1, y: b5, z: 0 }, drehwinkel.x);
      dp = this.drehungYAchse(dp, drehwinkel.y);
      dp = this.drehungZAchse(dp, drehwinkel.z);
      var p4 = punkt1.x + dp.x + deltaX + ' ' + (punkt1.y + dp.y) + ' ' + (punkt1.z + dp.z);

      dp = this.drehungXAchse({ x: -b1, y: b5, z: 0 }, drehwinkel.x);
      dp = this.drehungYAchse(dp, drehwinkel.y);
      dp = this.drehungZAchse(dp, drehwinkel.z);
      var p5 = punkt2.x + dp.x + deltaX + ' ' + (punkt2.y + dp.y) + ' ' + (punkt2.z + dp.z);

      dp = this.drehungXAchse({ x: -b05, y: b45, z: 0 }, drehwinkel.x);
      dp = this.drehungYAchse(dp, drehwinkel.y);
      dp = this.drehungZAchse(dp, drehwinkel.z);
      var p6 = punkt1.x + dp.x + deltaX + ' ' + (punkt1.y + dp.y) + ' ' + (punkt1.z + dp.z);
      var p8 = punkt2.x + dp.x + deltaX + ' ' + (punkt2.y + dp.y) + ' ' + (punkt2.z + dp.z);

      dp = this.drehungXAchse({ x: b05, y: b55, z: 0 }, drehwinkel.x);
      dp = this.drehungYAchse(dp, drehwinkel.y);
      dp = this.drehungZAchse(dp, drehwinkel.z);
      var p7 = punkt1.x + dp.x + deltaX + ' ' + (punkt1.y + dp.y) + ' ' + (punkt1.z + dp.z);
      var p9 = punkt2.x + dp.x + deltaX + ' ' + (punkt2.y + dp.y) + ' ' + (punkt2.z + dp.z);

      var koordinaten = p0 + ', ' + p1 + ', ' + p2 + ', ' + p3 + ', ' + p4 + ', ' + p5 + ', ' + p6 + ', ' + p7 + ', ' + p8 + ', ' + p9;

      return koordinaten;
    },

    drehungXAchse: function drehungXAchse(v, omega) {
      var r = new Object();
      r.x = v.x;
      r.y = v.y * Math.cos(omega) - v.z * Math.sin(omega);
      r.z = v.y * Math.sin(omega) + v.z * Math.cos(omega);
      return r;
    },

    drehungYAchse: function drehungYAchse(v, omega) {
      var r = new Object();
      r.x = v.x * Math.cos(omega) + v.z * Math.sin(omega);
      r.y = v.y;
      r.z = -v.x * Math.sin(omega) + v.z * Math.cos(omega);
      return r;
    },

    drehungZAchse: function drehungZAchse(v, omega) {
      var r = new Object();
      r.x = v.x * Math.cos(omega) - v.y * Math.sin(omega);
      r.y = v.x * Math.sin(omega) + v.y * Math.cos(omega);
      r.z = v.z;
      return r;
    },

    getMittelwert: function getMittelwert(a, b) {
      return (a + b) / 2;
    },

    postionDurchSkalierung: function postionDurchSkalierung(richtung) {
      var x3d = this.get('model').objectAt(0);
      var skalierungsfaktor = x3d.get('skalierungsfaktor') + 0.6;
      var value = 0.2 * skalierungsfaktor;
      return value;
    }

  });

  exports['default'] = Transform;

});